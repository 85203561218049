import {renderFormField} from '@common'
import {TranslationKey} from '@enums'
import {Grid, Stack, Typography} from '@mui/material'

import React from 'react'

import {UIKit} from '@components'

import {ContractSetupFormProps, useContractSetupFormModel} from './contract-setup-form-model'
import {ContractTypeButton} from './style'

/**
 * Contract Setup step
 */
export function ContractSetupForm(props: ContractSetupFormProps) {
  const {contractSetupFormFields, onSelectContractType, dropdownOptions, t} = useContractSetupFormModel(props)

  return (
    <Stack gap={2}>
      <UIKit.BackgroundCard title={t(TranslationKey['Contract setup'])}>
        <Grid container spacing={2}>
          {contractSetupFormFields.map((item, index) => (
            <Grid key={`contract-setup-form-field-${index}`} item sm={4}>
              {renderFormField(item)}
            </Grid>
          ))}
        </Grid>
      </UIKit.BackgroundCard>

      <UIKit.BackgroundCard title={t(TranslationKey['Contract type'])}>
        <Grid container spacing={2}>
          {dropdownOptions.contractTypeOptions.length ? (
            dropdownOptions.contractTypeOptions?.map((item, index) => (
              <Grid key={`contract-type-button-${index}`} item sm={2}>
                <ContractTypeButton variant="contained" onClick={() => onSelectContractType(String(item.value))}>
                  {item.label}
                </ContractTypeButton>
              </Grid>
            ))
          ) : (
            <Stack style={{paddingLeft: '1rem'}}>
              <Typography>
                {t(TranslationKey['There are no Contract types available for the selected Dealership'])}
              </Typography>
            </Stack>
          )}
        </Grid>
      </UIKit.BackgroundCard>
    </Stack>
  )
}
