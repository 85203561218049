import {
  bulkProductUpload,
  CompanyResponse,
  ContractTypeResponse,
  getCompanies,
  getCompany,
  getContractTypes,
  getContractTypesByCompany,
  ProductBulkRequest,
  handleAxiosError,
  ProductPriceData,
} from '@api'
import {
  generateDropdownOptions,
  generateReadableErrorMessages,
  getUserCompany,
  getUserData,
  isSuperAdmin,
  validateFields,
} from '@common'
import {AppContext} from '@contexts'
import {FormFieldType, LoadingStatus, TranslationKey} from '@enums'

import {useContext, useMemo, useState} from 'react'

import ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {UIKit} from '@components'

interface FormData {
  companyId?: string | number
  data: string
}

const initialFormData: FormData = {
  companyId: undefined,
  data: '',
}

interface Product {
  productNo: string
  name: string
  price: number
  residualValue: number
  servicePrice: number
  installationCompensation: number
  active: boolean
  linkedAccessories: string[]
  contractTypePrices: ProductPriceData[]
}

interface Accessory {
  id: string
  productNo: string
  name: string
  price: number
  residualValue: number
  servicePrice: number
  installationCompensation: number
  active: boolean
  contractTypePrices: ProductPriceData[]
}

export function useBulkProductUploadModel() {
  const {setLoadingStatus, setError, setIsFormDirty, handleNavigate, setSuccessMessage} = useContext(AppContext)
  const [formData, setFormData] = useState<FormData>(initialFormData)
  const [formFieldErrors, setFormFieldsError] = useState<string[]>([])
  const [companyOptions, setCompanyOptions] = useState<UIKit.DropdownItem[]>([])
  const [contractTypes, setContractTypes] = useState<ContractTypeResponse[]>([])
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(true)

  const currentUser = getUserData()
  const navigate = useNavigate()
  const {t} = useTranslation()

  /**
   * Company form field configuration
   */
  const companyFormField = {
    fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
    name: 'companyId',
    label: t(TranslationKey['Amplio partner']),
    value: formData.companyId,
    items: companyOptions,
    error: formFieldErrors.includes('companyId'),
    onChange: (item: UIKit.DropdownItem | null) => {
      setIsFormDirty(true)
      setFormFieldsError(prev => prev.filter(item => item !== 'companyId'))
      setFormData(prev => ({...prev, companyId: item ? item.value : undefined}))
    },
    disabled: true,
  }

  /**
   * Download should only be enabled when a Company is selected
   */
  const isDownloadDisabled = useMemo(() => !formData.companyId, [formData])

  /**
   * A function that handles the download of a template file
   */
  const handleDownload = async () => {
    if (isDownloadDisabled) {
      setFormFieldsError(['companyId'])
      return
    }
    const workbook = new ExcelJS.Workbook()

    const fill: ExcelJS.Fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: '122944'}}
    const font: Partial<ExcelJS.Font> = {bold: true, color: {argb: 'FFFFFF'}}
    const style = {fill, font}

    //Creating Products sheet
    const productsSheet = workbook.addWorksheet('Products')
    productsSheet.columns = [
      {header: t(TranslationKey.Name), key: 'name', width: 30},
      {header: t(TranslationKey['Product number']), key: 'productNo', width: 20},
      {header: t(TranslationKey.Price), key: 'price', width: 15},
      {header: t(TranslationKey['Residual value']), key: 'residualValue', width: 20},
      {header: t(TranslationKey['Service price']), key: 'servicePrice', width: 20},
      {header: t(TranslationKey['Installation compensation']), key: 'installationCompensation', width: 30},
      {header: t(TranslationKey.Accessories), key: 'accessories', width: 30},
    ]

    const productsHeaderRow = productsSheet.getRow(1)
    productsHeaderRow.height = 30
    ;['A', 'B', 'C', 'D', 'E', 'F', 'G'].forEach(col => {
      const cell = productsHeaderRow.getCell(col)
      cell.fill = fill
      cell.font = {...font, size: 12}
      cell.border = {bottom: {color: {argb: 'FFFFFF'}, style: 'thin'}}
    })
    productsSheet.getColumn('H').border = {left: {color: {argb: '122944'}, style: 'thick'}}
    ;['C', 'D', 'E', 'F', 'G'].forEach(col => {
      for (let i = 1; i < 1000; i++) {
        productsSheet.getCell(`${col}${i}`).alignment = {horizontal: 'right'}
      }
    })

    //Creating Accessories sheet
    const accessoriesSheet = workbook.addWorksheet('Accessories')
    accessoriesSheet.columns = [
      {header: `${t(TranslationKey.Accessory)} ID`, key: 'accessoryId', width: 15},
      {header: t(TranslationKey.Name), key: 'name', width: 30},
      {header: t(TranslationKey['Product number']), key: 'productNo', width: 20},
      {header: t(TranslationKey.Price), key: 'price', width: 15},
      {header: t(TranslationKey['Residual value']), key: 'residualValue', width: 20},
      {header: t(TranslationKey['Service price']), key: 'servicePrice', width: 20},
      {header: t(TranslationKey['Installation compensation']), key: 'installationCompensation', width: 30},
    ]

    for (let i = 1; i <= 1000; i++) {
      const row = accessoriesSheet.addRow({accessoryId: `A${i}`})
      const cellA = row.getCell('A')
      cellA.border = {bottom: {color: {argb: 'FFFFFF'}, style: 'thin'}}
      cellA.fill = fill
      cellA.font = font
    }

    const accessoriesHeaderRow = accessoriesSheet.getRow(1)
    accessoriesHeaderRow.height = 30
    ;['A', 'B', 'C', 'D', 'E', 'F', 'G'].forEach(col => {
      const cell = accessoriesHeaderRow.getCell(col)
      cell.fill = fill
      cell.font = {...font, size: 12}
      cell.border = {bottom: {color: {argb: 'FFFFFF'}, style: 'thin'}}
    })
    accessoriesSheet.getColumn('H').border = {left: {color: {argb: '122944'}, style: 'thick'}}
    ;['D', 'E', 'F', 'G'].forEach(col => {
      for (let i = 1; i < 1000; i++) {
        accessoriesSheet.getCell(`${col}${i}`).alignment = {horizontal: 'right'}
      }
    })

    //Creating Contract Type sheets
    contractTypes
      .filter(item => item.companyId === formData.companyId)
      .forEach(item => {
        const newSheet = workbook.addWorksheet(item.name)
        newSheet.columns = [
          {header: t(TranslationKey['Product name']), key: 'productName', width: 30},
          {header: t(TranslationKey.Price), key: 'price', width: 15},
          {header: t(TranslationKey['Residual value']), key: 'residualValue', width: 20},
          {header: t(TranslationKey['Service price']), key: 'servicePrice', width: 20},
          {header: t(TranslationKey['Installation compensation']), key: 'installationCompensation', width: 30},
          ...[18, 24, 36, 60].map(contractLength => ({
            header: `${contractLength} ${t(TranslationKey.Months)}`,
            key: `${contractLength}months`,
            width: 15,
          })),
          {header: ' ', key: 'blank', width: 5},
          {header: t(TranslationKey['Accessory name']), key: 'accessoryName', width: 30},
          {header: t(TranslationKey.Price), key: 'accessoryPrice', width: 15},
          {header: t(TranslationKey['Residual value']), key: 'accessoryResidualValue', width: 20},
          {header: t(TranslationKey['Service price']), key: 'accessoryServicePrice', width: 20},
          {header: t(TranslationKey['Installation compensation']), key: 'installationCompensation', width: 30},
          ...[18, 24, 36, 60].map(contractLength => ({
            header: `${contractLength} ${t(TranslationKey.Months)}`,
            key: `${contractLength}months`,
            width: 15,
          })),
        ]

        newSheet.insertRow(0, {})
        newSheet.getRow(1).height = 30
        newSheet.mergeCells('A1:I1')
        newSheet.getCell('A1').value = 'Products'
        newSheet.getCell('J1').value = item.id
        newSheet.getCell('J1').style = {font: {color: {argb: '122944'}, size: 1}, fill}
        newSheet.getCell('A1').style = {
          alignment: {horizontal: 'center'},
          fill,
          font: {...font, size: 15},
          border: {bottom: {color: {argb: 'FFFFFF'}, style: 'thin'}},
        }
        newSheet.mergeCells('K1:S1')
        newSheet.getCell('K1').value = 'Accessories'
        newSheet.getCell('K1').style = {
          alignment: {horizontal: 'center'},
          fill,
          font: {...font, size: 15},
          border: {bottom: {color: {argb: 'FFFFFF'}, style: 'thin'}},
        }

        const newSheetHeaderRow = newSheet.getRow(2)
        ;['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S'].forEach(col => {
          const cell = newSheetHeaderRow.getCell(col)
          cell.fill = fill
          cell.font = {...font, size: 12}
          cell.border = {
            bottom: {color: {argb: 'FFFFFF'}, style: 'thin'},
            top: {color: {argb: 'FFFFFF'}, style: 'thin'},
          }
        })
        ;['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S'].forEach(col => {
          for (let i = 1; i < 1000; i++) {
            newSheet.getCell(`${col}${i}`).alignment = {horizontal: 'right'}
          }
        })

        newSheet.getColumn('J').border = {left: {color: {argb: '122944'}, style: 'thick'}}
        newSheet.getColumn('T').border = {left: {color: {argb: '122944'}, style: 'thick'}}
        newSheet.getColumn('A').style = style
        newSheet.getColumn('K').style = style

        for (let i = 2; i <= 1001; i++) {
          const productCell = newSheet.getCell(`A${i}`)
          const accessoryCell = newSheet.getCell(`K${i}`)
          productCell.value = {formula: `Products!A${i - 1} & "-" & Products!B${i - 1}`}
          accessoryCell.value = {formula: `Accessories!B${i - 1} & "-" & Accessories!C${i - 1}`}
          productCell.border = {bottom: {color: {argb: 'FFFFFF'}, style: 'thin'}}
          accessoryCell.border = {bottom: {color: {argb: 'FFFFFF'}, style: 'thin'}}
        }
      })

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {type: 'application/octet-stream'})
    setIsUploadDisabled(false)
    saveAs(blob, 'AWP-bulk-upload-template.xlsx')
  }

  /**
   * A function that handles the file upload
   * @param acceptedFiles Uploaded file
   */
  const handleDrop = (acceptedFiles: File[]): void => {
    if (isDownloadDisabled) {
      setFormFieldsError(['companyId'])
      return
    }
    const file = acceptedFiles[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        setIsFormDirty(true)
        const workbook = new ExcelJS.Workbook()
        try {
          await workbook.xlsx.load(reader.result as ArrayBuffer)

          const productsWorksheet: ExcelJS.Worksheet | undefined = workbook.getWorksheet('Products')
          const products: Product[] = []
          const unlinkedAccessories: Accessory[] = []

          //Reading Products sheet
          productsWorksheet?.eachRow({includeEmpty: false}, (row, rowNumber) => {
            if (rowNumber > 1) {
              const product: Product = {
                name: row.getCell(1).value as string,
                productNo: row.getCell(2).value as string,
                price: row.getCell(3).value as number,
                residualValue: row.getCell(4).value as number,
                servicePrice: row.getCell(5).value as number,
                installationCompensation: row.getCell(6).value as number,
                active: true,
                linkedAccessories: String(row.getCell(7).value)?.split(', ') || [],
                contractTypePrices: [],
              }
              products.push(product)

              workbook.worksheets.forEach((sheet, index) => {
                if (index >= 2) {
                  const contractTypeId = sheet.getCell(1, 10).value as string
                  const rowToCheck = rowNumber + 1
                  const price = sheet.getCell(rowToCheck, 2).value as number
                  const residualValue = sheet.getCell(rowToCheck, 3).value as number
                  const servicePrice = sheet.getCell(rowToCheck, 4).value as number
                  const installationCompensation = sheet.getCell(rowToCheck, 5).value as number
                  const prices = [18, 24, 36, 60].map(
                    (item, index) => sheet.getCell(rowToCheck, 6 + index).value as number,
                  )

                  if (price || residualValue || servicePrice) {
                    const contractPrice: ProductPriceData = {
                      contractTypeId: contractTypeId,
                      price: price || 0,
                      residualValue: residualValue || 0,
                      servicePrice: servicePrice || 0,
                      installationCompensation: installationCompensation || 0,
                      override: [18, 24, 36, 60].map((item, index) => ({contractLength: item, price: prices[index]})),
                    }
                    product.contractTypePrices.push(contractPrice)
                  }
                }
              })
            }
          })

          //Reading Accessories sheet
          const accessoriesWorksheet: ExcelJS.Worksheet | undefined = workbook.getWorksheet('Accessories')
          accessoriesWorksheet?.eachRow({includeEmpty: false}, (row, rowNumber) => {
            if (rowNumber > 1) {
              const accessory: Accessory = {
                id: row.getCell(1).value as string,
                name: row.getCell(2).value as string,
                productNo: row.getCell(3).value as string,
                price: row.getCell(4).value as number,
                residualValue: row.getCell(5).value as number,
                servicePrice: row.getCell(6).value as number,
                installationCompensation: row.getCell(7).value as number,
                active: true,
                contractTypePrices: [],
              }
              unlinkedAccessories.push(accessory)

              // Reading Contract Type sheets
              workbook.worksheets.forEach((sheet, index) => {
                if (index >= 2) {
                  const contractTypeId = sheet.getCell(1, 10).value as string
                  const rowToCheck = rowNumber + 1
                  const price = sheet.getCell(rowToCheck, 12).value as number
                  const residualValue = sheet.getCell(rowToCheck, 13).value as number
                  const servicePrice = sheet.getCell(rowToCheck, 14).value as number
                  const installationCompensation = sheet.getCell(rowToCheck, 15).value as number
                  const prices = [18, 24, 36, 60].map(
                    (item, index) => sheet.getCell(rowToCheck, 16 + index).value as number,
                  )

                  if (price || residualValue || servicePrice) {
                    const contractPrice: ProductPriceData = {
                      contractTypeId: contractTypeId,
                      price: price || 0,
                      residualValue: residualValue || 0,
                      servicePrice: servicePrice || 0,
                      installationCompensation: installationCompensation || 0,
                      override: [18, 24, 36, 60].map((item, index) => ({contractLength: item, price: prices[index]})),
                    }
                    accessory.contractTypePrices.push(contractPrice)
                  }
                }
              })
            }
          })

          const bulkPayload = products.map(product => {
            const {linkedAccessories, ...rest} = product

            const accessories = unlinkedAccessories
              .filter(accessory => linkedAccessories.includes(accessory.id))
              .map(accessory => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const {id, ...rest} = accessory

                return {...rest, companyId: formData.companyId}
              })

            return {...rest, accessories, companyId: formData.companyId}
          })
          setFormData(prev => ({...prev, data: JSON.stringify(bulkPayload)}))
        } catch (error) {
          console.error('Error reading Excel file:', error)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  /**
   * Adding Company ID to the bulk payload
   */
  const prepareData = () => {
    const dataForFormatting: ProductBulkRequest[] = JSON.parse(formData.data)
    return JSON.stringify(
      dataForFormatting.map(item => ({
        ...item,
        active: true,
        companyId: formData.companyId,
        accessories: item.accessories.map(accessory => ({...accessory, active: true, companyId: formData.companyId})),
      })),
    )
  }

  /**
   * A custom validation function for the bulk upload form
   * @returns If there are any invalid fields
   */
  const validateData = (): boolean => {
    const invalidValues = validateFields(formData)

    const isFormInvalid = invalidValues.length > 0
    if (isFormInvalid) {
      setError({
        message: generateReadableErrorMessages(t, invalidValues),
        name: '',
      })

      setFormFieldsError(invalidValues)
    }
    return isFormInvalid
  }

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async () => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      let companies: CompanyResponse[] = []
      let contractTypesData: ContractTypeResponse[] = []
      if (isSuperAdmin(currentUser)) {
        companies = await getCompanies()
        contractTypesData = await getContractTypes()
        setFormData(prev => ({...prev, companyId: getUserCompany(currentUser.id)}))
      } else {
        // Company Administrator and Company User can see ONLY their Company
        companies = [await getCompany(String(currentUser.companyId))]
        contractTypesData = await getContractTypesByCompany(String(currentUser.companyId))
        setFormData(prev => ({...prev, companyId: companies[0].id}))
      }
      setCompanyOptions(generateDropdownOptions(companies))
      setContractTypes(contractTypesData)
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function that handles Discard button click
   */
  const onDiscard = (): void => {
    handleNavigate(() => navigate(-1))
  }

  /**
   * A function that handles Save button click
   * If form is valid, performs a bulk upload
   */
  const onSubmit = async () => {
    if (validateData()) return

    setLoadingStatus(LoadingStatus.LOADING)
    try {
      await bulkProductUpload(prepareData())
      setFormData(initialFormData)
      setIsFormDirty(false)
      setLoadingStatus(LoadingStatus.SUCCESS)
      setSuccessMessage(t(TranslationKey['Products successfully uploaded']))
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {
    companyFormField,
    formData,
    formFieldErrors,
    isDownloadDisabled,
    isUploadDisabled,
    t,
    handleDownload,
    onSubmit,
    onDiscard,
    loadData,
    handleDrop,
  }
}
