import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {MenuItem, Typography, BaseSelectProps, Stack} from '@mui/material'
import {CommonProps} from '@mui/material/OverridableComponent'
import {theme} from '@styles'

import React, {ReactNode} from 'react'

import {LabeledWrapper} from '../wrapper-with-label'
import {StyledSelect, iconStyle} from './style'

export type DropdownItem = {
  value: string | number
  label: string
  icon?: ReactNode
}

export interface DropdownProps {
  items: DropdownItem[]
  renderValue?: () => JSX.Element
  customStyle?: CommonProps['style']
  label?: string
  value?: string | number
  hideBorder?: boolean
  width?: number
}

/**
 * Dropdown component
 */
export function Dropdown(props: DropdownProps & BaseSelectProps) {
  const {label, hideBorder, width, items, renderValue, error, value, disabled, ...rest} = props

  const defaultRenderValue = () => {
    return (
      <Typography textAlign={'start'} color={!value ? theme.palette.grey[200] : 'initial'} variant="body2">
        {!value ? label : items.find(item => item.value === value)?.label}
      </Typography>
    )
  }

  return (
    <LabeledWrapper width={width} label={label}>
      <StyledSelect
        IconComponent={() =>
          error ? (
            <ErrorOutlineRoundedIcon style={{color: theme.palette.error.main, paddingRight: 15}} />
          ) : disabled ? (
            <></>
          ) : (
            <ExpandMoreIcon style={iconStyle} />
          )
        }
        hideBorder={hideBorder}
        displayEmpty
        disabled={disabled}
        value={value ?? ''}
        renderValue={renderValue ?? defaultRenderValue}
        {...rest}
      >
        {items?.map((item, index) => {
          const {value, label: itemLabel, icon} = item
          return (
            <MenuItem key={`${label}-dropdown-item-${index}`} value={value}>
              <Stack style={{width: '100%'}} flexDirection={'row'} justifyContent={'space-between'}>
                {itemLabel}
                {icon}
              </Stack>
            </MenuItem>
          )
        })}
      </StyledSelect>
    </LabeledWrapper>
  )
}
