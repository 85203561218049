import {APIMethod} from '@enums'

import {fetchData} from 'api/api'
import {ItemSignature} from 'common/types'

interface BaseProduct {
  productNo: string
  name: string
  price: number
  residualValue: number
  servicePrice: number
  installationCompensation: number
  active: boolean
  companyId: string | null
  contractTypePrices: ProductPriceData[]
}
export interface ProductData extends BaseProduct {
  id?: string
}
export interface ProductRequest extends BaseProduct {
  companyId: string
}
export interface ProductResponse extends BaseProduct, ItemSignature {
  id: string
  companyId: string
  accessories: ProductAccessoryResponse[]
}
export interface ProductAccessoryResponse extends BaseProduct, ItemSignature {
  id: string
}
export interface ProductPriceData {
  contractTypeId: string
  price: number
  residualValue: number
  servicePrice: number
  installationCompensation: number
  override: OverridePrice[]
}
export interface OverridePrice {
  contractLength: number
  price: number
}

export type ProductBulkRequest = ProductRequest & {accessories: ProductRequest[]}

/**
 * @returns All Products
 */
export const getProducts = async (): Promise<ProductResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: 'product',
  })
}

/**
 * @param id Company ID
 * @returns All Products with a specific Company ID
 */
export const getProductsByCompany = async (id: string): Promise<ProductResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `product/company/${id}`,
  })
}

/**
 * @param id Product ID
 * @returns A single Product with a specific ID
 */
export const getProduct = async (id: string): Promise<ProductResponse> => {
  return fetchData({
    method: APIMethod.GET,
    url: `product/${id}`,
  })
}

/**
 * Updates an existing Product
 * @param id Product ID
 * @param body Product payload data
 * @returns Updated Product data
 */
export const updateProduct = async (id: string, body: ProductRequest): Promise<ProductResponse> => {
  return fetchData({
    method: APIMethod.PUT,
    url: `product/${id}`,
    body,
  })
}

/**
 * Creates a new Product
 * @param body Product payload data
 * @returns New Product data
 */
export const createProduct = async (body: ProductRequest): Promise<ProductResponse> => {
  return fetchData({
    method: APIMethod.POST,
    url: `product`,
    body,
  })
}

/**
 * Links a Product to an Accessory
 * @param productId Product ID
 * @param accessoryId Accessory ID
 * @returns void
 */
export const linkProductToAccessory = async (productId: string, accessoryId: string): Promise<void> => {
  return fetchData({
    method: APIMethod.POST,
    url: `product/${productId}/accessories/${accessoryId}`,
  })
}

/**
 * Unlinks a Product from an Accessory
 * @param productId Product ID
 * @param accessoryId Accessory ID
 * @returns void
 */
export const unlinkProductFromAccessory = async (productId: string, accessoryId: string): Promise<void> => {
  return fetchData({
    method: APIMethod.DELETE,
    url: `product/${productId}/accessories/${accessoryId}`,
  })
}

/**
 * Upload a large number of products at once
 * @param body Bulk Product upload data
 * @returns void
 */
export const bulkProductUpload = async (body: string): Promise<void> => {
  return fetchData({
    method: APIMethod.POST,
    url: 'product/bulk-insert',
    body: JSON.parse(body),
  })
}
