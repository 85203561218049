import {APIMethod, Country} from '@enums'

import {fetchData} from 'api/api'

export interface CreditCheckRequest {
  country: Country
  personNumber: string
  purchasePrice: number
  customer?: {
    lastName: string
    firstName: string
    street: string
    houseNumber: string
    zipCode: string
    city: string
    country: string
  }
}

export interface CreditCheckResponse {
  passingCredit: boolean
}

/**
 * Checks the Credit score for a specific person
 * @param body Credit Check payload data
 * @returns Credit Check result
 */
export const creditCheck = async (body: CreditCheckRequest): Promise<CreditCheckResponse> => {
  const {country, customer, purchasePrice, personNumber} = body
  let url = ''
  let requestBody

  switch (country) {
    case Country.SWEDEN:
      url = 'credit-safe/sweden/credit-check'
      requestBody = {
        number: personNumber,
        lang: 'EN',
      }
      break
    case Country.NORWAY:
      url = `credit-safe/norway/credit-check`
      requestBody = {
        id: personNumber,
        purchasePrice,
      }
      break
    case Country.DENMARK:
      url = 'experian/denmark'
      requestBody = {
        number: personNumber,
      }
      break
    default:
      url = 'experian/general/credit-check'
      requestBody = {
        customerRefId: '',
        ...customer,
      }
  }

  return fetchData({
    method: APIMethod.POST,
    url,
    body: requestBody,
  })
}
