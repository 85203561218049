import {APIMethod, Country} from '@enums'

import {fetchData} from 'api/api'

export interface PersonInfoRequest {
  personNumber: string
  country: Country
}

export interface PersonInfoResponse {
  address: string
  town: string
  firstName: string
  lastName: string
  zipCode: string
}

/**
 * @param body Person info payload data
 * @returns Person information
 */
export const getPersonInfo = async (body: PersonInfoRequest): Promise<PersonInfoResponse> => {
  const {country, personNumber} = body
  let url = ''
  let requestBody

  switch (country) {
    case Country.SWEDEN:
      url = 'credit-safe/sweden/person-info'
      requestBody = {
        number: personNumber,
        lang: 'EN',
      }
      break
    case Country.NORWAY:
      url = `credit-safe/norway/person-info`
      requestBody = {
        id: personNumber,
        purchasePrice: 0,
      }
      break
    case Country.DENMARK:
      url = 'experian/denmark/person-info'
      requestBody = {
        number: personNumber,
      }
      break
  }

  return fetchData({
    method: APIMethod.POST,
    url,
    body: requestBody,
  })
}
