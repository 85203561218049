import {getAccessories, AccessoryResponse, getAccessoriesByCompany, LoggedInUser, handleAxiosError} from '@api'
import {
  activeCell,
  formatCurrency,
  getUserCompany,
  getUserData,
  isCompanyUser,
  isSuperAdmin,
  renderClickableCell,
} from '@common'
import {AppContext} from '@contexts'
import {LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef, GridColumnVisibilityModel} from '@mui/x-data-grid'

import {useContext, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

export interface AccessoryTableData {
  name: string
  products: number
  price: string
  active: boolean
}

export function useAccessorySearchModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const [rows, setRows] = useState<AccessoryTableData[]>([])
  const {t} = useTranslation()
  const currentUser: LoggedInUser = getUserData()
  const navigate = useNavigate()

  /**
   * Accessory table columns
   */
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 0.8,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.ACCESSORY_VIEW}/${params.row.id}`)),
    },
    {field: 'products', headerName: t(TranslationKey['No. of products']), flex: 0.5},
    {field: 'price', headerName: t(TranslationKey['Default price']), flex: 0.5, headerAlign: 'right', align: 'right'},
    activeCell(t),
  ]

  /**
   * Accessory table visibility model
   */
  const visibilityModel: GridColumnVisibilityModel = {
    company: false,
    products: false,
  }

  /**
   * Create button should only be available to Administrator Users
   */
  const showCreateButton = !isCompanyUser(currentUser)

  /**
   *  Load data function
   * Should only be executed once on the component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      let data: AccessoryResponse[] = []

      // Super Administrator can see ALL the data
      if (isSuperAdmin(currentUser)) {
        data = await getAccessories()
      }
      // Company Administrator and Company User can see ONLY their Company's Accessories
      else {
        data = await getAccessoriesByCompany(String(currentUser.companyId))
      }

      const formattedData: AccessoryTableData[] = data
        .filter(item => (isSuperAdmin(currentUser) ? item.companyId === getUserCompany(currentUser.id) : true))
        .map(item => ({
          name: item.name,
          products: item.products.length,
          price: formatCurrency(item.price),
          active: item.active,
          id: item.id,
        }))
      setRows(formattedData)
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {loadData, navigate, t, showCreateButton, visibilityModel, rows, columns}
}
