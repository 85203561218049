import {
  ContractTypeResponse,
  DealerData,
  DealerResponse,
  getContractTypesByCompany,
  getDealer,
  getUsersByCompany,
  linkDealerToContractType,
  LoggedInUser,
  unlinkDealerFromContractType,
  UserResponse,
  handleAxiosError,
  CompanyResponse,
  getCompany,
} from '@api'
import {AppContext} from '@contexts'
import {FormFieldType, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef} from '@mui/x-data-grid'

import {useContext, useMemo, useState} from 'react'

import {
  activeCell,
  FormFieldItem,
  generateDropdownOptions,
  generateReadableErrorMessages,
  getUserData,
  getUserTimeFormat,
  isCompanyUser,
  roles,
  unlinkButtonCell,
  renderClickableCell,
} from 'common'
import {UsersTableData} from 'components/pages/users/user-search/user-search-model'
import {formatDate} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import {ComposedComponents, UIKit} from '@components'

interface ContractTypeTableData {
  name: string
  id: string
  unlinkId: string
}
export interface OfficialSignatoriesRow {
  name: string
  email: string
  dateOfBirth: string
  personalNumber: string
}

export enum DealerTab {
  GENERAL,
  CONTRACT_TYPES,
  USERS,
}

/**
 * Initial data
 */
const initialDealerData: DealerData = {
  name: '',
  customerNo: '',
  email: '',
  address: '',
  city: '',
  companyNo: '',
  dealerNo: '',
  officialSignatories: [],
  allowedContractTypes: [],
  phone: '',
  vatNumber: '',
  zipCode: '',
  invoiceEmail: '',
  invoicePhone: '',
  companyId: null,
  active: true,
}

export function useDealerViewModel() {
  const {setLoadingStatus, setError, setSuccessMessage} = useContext(AppContext)
  const [dealerData, setDealerData] = useState<DealerData>(initialDealerData)
  const [company, setCompany] = useState<CompanyResponse | null>(null)
  const [userRows, setUserRows] = useState<UsersTableData[]>([])
  const [activeTab, setActiveTab] = useState<DealerTab>(DealerTab.GENERAL)
  const [contractTypeOptions, setContractTypeOptions] = useState<UIKit.DropdownItem[]>([])
  const [contractTypeRows, setContractTypeRows] = useState<ContractTypeTableData[]>([])
  const [isLinkModalShown, setIsLinkModalShown] = useState<boolean>(false)
  const [contractTypeToLink, setContractTypeToLink] = useState<string | undefined>(undefined)
  const [contractTypeFormFieldError, setContractTypeFormFieldError] = useState<string>('')
  const currentUser: LoggedInUser = getUserData()
  const isEditButtonVisible = !isCompanyUser(currentUser)
  const navigate = useNavigate()
  const {id} = useParams()
  const {t} = useTranslation()

  /**
   * Dynamically generated title text for Dealer View page
   */
  const title = useMemo(() => `${t(TranslationKey['Dealer view'])} - ${dealerData.name}`, [dealerData.name])

  /**
   * Dynamically generated modal text
   */
  const modalText: string = useMemo(
    () => `${t(TranslationKey['Choose a contract type to link to'])} ${dealerData.name}`,
    [dealerData.name],
  )

  /**
   * User table columns configuration
   */
  const userColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 0.8,
      hideable: false,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.USER_VIEW}/${params.row.id}`)),
    },
    {field: 'email', headerName: t(TranslationKey.Email), flex: 1},
    {field: 'role', headerName: t(TranslationKey.Role), flex: 0.8},
    activeCell(t),
  ]

  /**
   * Contract Type table columns configuration
   */
  const contractTypeColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 1.5,
      hideable: false,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.CONTRACT_TYPE_VIEW}/${params.row.id}`)),
    },
    ...(isEditButtonVisible ? [unlinkButtonCell(t, id => onUnlink(id))] : []),
  ]

  /**
   * Official Signatory table columns configuration
   */
  const officialSignatoriesColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 1,
    },
    {
      field: 'email',
      headerName: t(TranslationKey.Email),
      flex: 1.2,
    },
    {
      field: 'dateOfBirth',
      headerName: t(TranslationKey['Date of birth']),
      flex: 1,
      renderCell: params => (params.value ? formatDate(params.value, getUserTimeFormat(String(currentUser?.id))) : '-'),
    },
    {
      field: 'personalNumber',
      headerName: t(TranslationKey['Personal number']),
      flex: 1,
    },
  ]

  /**
   * Tab buttons configuration
   */
  const tabButtons: ComposedComponents.TabButtonProps<DealerTab>[] = [
    {
      label: t(TranslationKey['General information']),
      key: DealerTab.GENERAL,
      onClick: () => setActiveTab(DealerTab.GENERAL),
    },
    {
      label: t(TranslationKey['Contract types']),
      key: DealerTab.CONTRACT_TYPES,
      onClick: () => setActiveTab(DealerTab.CONTRACT_TYPES),
    },
    {
      label: t(TranslationKey.Users),
      key: DealerTab.USERS,
      onClick: () => setActiveTab(DealerTab.USERS),
    },
  ]

  /**
   * Official Signatories rows data
   */
  const officialSignatoriesRows: OfficialSignatoriesRow[] = dealerData.officialSignatories || []

  /**
   * Linked Contract Type form field configuration
   */
  const linkContractTypeFormField: FormFieldItem = {
    fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
    items: contractTypeOptions,
    value: contractTypeToLink,
    onChange: (item: UIKit.DropdownItem | null) => {
      setContractTypeToLink(String(item?.value))
      setContractTypeFormFieldError('')
    },
    error: !!contractTypeFormFieldError,
    placeholder: t(TranslationKey['Choose a contract type to link to']),
    listboxHeight: 100,
  }

  /**
   * Dealer form fields configuration - general section
   */
  const dealerFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Dealer name']),
      text: dealerData.name || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Dealer number']),
      text: dealerData.dealerNo || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['VAT number']),
      text: dealerData.vatNumber || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Amplio partner']),
      text: company?.name,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Company number']),
      text: dealerData.companyNo || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Customer number']),
      text: dealerData.customerNo || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Email),
      text: dealerData.email || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Phone),
      text: dealerData.phone || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Active']),
      text: dealerData.active ? t(TranslationKey.Active) : t(TranslationKey.Inactive),
    },
  ]

  /**
   * Dealer form fields configuration - contact section
   */
  const dealerContactFormFields = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Invoice email']),
      text: dealerData.invoiceEmail || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Invoice phone']),
      text: dealerData.invoicePhone || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Zip code']),
      text: dealerData.zipCode || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.City),
      text: dealerData.city || '-',
    },
  ]

  /**
   * A function handling linking the Dealer to a Contract Type
   * @param contractTypeId Contract Type ID
   */
  const onUnlink = async (contractTypeId: string): Promise<void> => {
    try {
      setLoadingStatus(LoadingStatus.LOADING)

      await unlinkDealerFromContractType(String(id), contractTypeId)
      await loadData()
      setSuccessMessage(t(TranslationKey['Successfully unlinked a contract type']))

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling linking the Dealer to a Contract Type
   * @returns void
   */
  const onLink = async (): Promise<void> => {
    if (!contractTypeToLink) {
      setContractTypeFormFieldError('contractTypeToLink')
      setError({
        message: generateReadableErrorMessages(t, ['contractTypeToLink']),
        name: '',
      })
      return
    }
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      setIsLinkModalShown(false)
      setContractTypeToLink(undefined)
      await linkDealerToContractType(String(id), contractTypeToLink)

      await loadData()
      setSuccessMessage(t(TranslationKey['Successfully linked a contract type']))
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      if (!id) {
        throw new Error('No dealer ID')
      }
      const data: DealerResponse = await getDealer(id)

      const company: CompanyResponse = await getCompany(data.companyId)

      const usersData: UserResponse[] = await getUsersByCompany(data.companyId)

      const formattedUsersData: UsersTableData[] = usersData
        .filter(item => item.dealerId === id)
        .map(item => ({
          name: item.fullName,
          email: item.email,
          active: item.active,
          company: '',
          dealer: '',
          role: roles(t).find(roleItem => roleItem.code === item.role)?.name || '',
          id: item.id,
        }))

      let contractTypeData: ContractTypeResponse[] = []

      contractTypeData = await getContractTypesByCompany(String(data.companyId))

      const formattedContractTypeOptions: UIKit.DropdownItem[] = generateDropdownOptions(
        contractTypeData.filter(
          item => !dealerData.allowedContractTypes?.map(contractType => contractType.id).includes(item.id),
        ),
      )

      setContractTypeOptions(formattedContractTypeOptions)

      const formattedData: ContractTypeTableData[] = data.allowedContractTypes.map(item => ({
        name: item.name,
        id: item.id,
        unlinkId: item.id,
      }))

      setContractTypeRows(formattedData)

      setUserRows(formattedUsersData)
      setCompany(company)

      setDealerData(data)

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling the Back button clikc
   */
  const onPressBack = (): void => {
    navigate(NavigationPathKey.DEALER_SEARCH)
  }

  /**
   * A function handling the Edit button click
   */
  const onPressEdit = (): void => {
    navigate(`${NavigationPathKey.DEALER_CREATION}/${id}`)
  }

  /**
   * A function handling the modal close
   */
  const onCloseLinkModal = () => {
    setIsLinkModalShown(false)
    setContractTypeToLink(undefined)
    setContractTypeFormFieldError('')
  }

  /**
   * Create button should be available only for Administrator users
   */
  const showCreateButton = !isCompanyUser(currentUser)

  return {
    loadData,
    navigate,
    onPressEdit,
    onPressBack,
    onCloseLinkModal,
    setIsLinkModalShown,
    onLink,
    id,
    showCreateButton,
    linkContractTypeFormField,
    modalText,
    isLinkModalShown,
    t,
    isEditButtonVisible,
    title,
    dealerFormFields,
    dealerContactFormFields,
    officialSignatoriesColumns,
    officialSignatoriesRows,
    userColumns,
    contractTypeColumns,
    contractTypeOptions,
    contractTypeRows,
    userRows,
    activeTab,
    tabButtons,
  }
}
