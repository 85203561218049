import {FormFieldItem, generateDropdownOptions, getUserData, isCompanyUser} from '@common'
import {AppContext} from '@contexts'
import {FormFieldType, ContractVariant, TranslationKey} from '@enums'

import {SetStateAction, useContext} from 'react'

import {useTranslation} from 'react-i18next'

import {UIKit} from '@components'

import {
  ContractFormData,
  ContractFormDropdownOptions,
  ContractFormFieldErrors,
  LookupMapType,
  SetupData,
  SetupFieldName,
  UpdateErrorsType,
  UpdateFormDataType,
} from '../../types'

export interface ContractSetupFormProps {
  formData: ContractFormData
  dropdownOptions: ContractFormDropdownOptions
  formFieldErrors: ContractFormFieldErrors
  setupData: SetupData
  setDropdownOptions: React.Dispatch<React.SetStateAction<ContractFormDropdownOptions>>
  setFormData: React.Dispatch<SetStateAction<ContractFormData>>
  lookupMap: LookupMapType
  updateFormData: UpdateFormDataType<ContractFormData>
  updateErrors: UpdateErrorsType
  onRightBtnClick: () => void
}

export function useContractSetupFormModel(props: ContractSetupFormProps) {
  const {
    setFormData,
    lookupMap,
    updateFormData,
    updateErrors,
    setDropdownOptions,
    onRightBtnClick,
    setupData,
    formData,
    dropdownOptions,
    formFieldErrors,
  } = props

  const {setIsFormDirty} = useContext(AppContext)
  const currentUser = getUserData()
  const {t} = useTranslation()

  /**
   * A function handling Contract Type dropdown change
   * @param contractTypeId Contract Type ID
   */
  const onSelectContractType = (contractTypeId: string) => {
    onChangeSetupFormField('contractType', {value: contractTypeId, label: ''})
    setDropdownOptions(prev => ({
      ...prev,
      productOptions: generateDropdownOptions(
        setupData.products
          .filter(item => item.companyId === formData.contractSetupData.company?.id)
          .map(item => ({...item, name: `${item.productNo} ${item.name}`})),
      ),
      accessoryOptions: setupData.products
        .filter(item => item.companyId === formData.contractSetupData.company?.id)
        .map(item => ({
          productId: item.id,
          options: generateDropdownOptions(
            item.accessories.map(item => ({...item, name: `${item.productNo} ${item.name}`})),
          ),
        })),
    }))
    setIsFormDirty(true)
    onRightBtnClick()
  }

  /**
   * A function handling contract setup form fields change
   * @param fieldName Field name
   * @param item Dropdown item
   */
  const onChangeSetupFormField = (fieldName: string, item: UIKit.DropdownItem | null) => {
    const value = item?.value ?? null

    updateErrors(
      'contractSetupFormFieldErrors',
      formFieldErrors.contractSetupFormFieldErrors.filter(item => item !== fieldName),
    )

    if (fieldName === 'contractType' && value !== null) {
      updateFormData(
        'contractSetupData',
        'length',
        setupData.contractTypes.find(contractType => contractType.id === value)?.lengths?.[0],
      )
      updateFormData(
        'contractSetupData',
        'officialSignatory',
        formData.contractSetupData.dealer?.officialSignatories[0].name,
      )
    }

    if (fieldName === 'dealer') {
      setDropdownOptions(prev => ({
        ...prev,
        contractTypeOptions: generateDropdownOptions(
          setupData.contractTypes.filter(item => item.allowedDealers.some(dealer => dealer.id === value)),
        ),
      }))
    }
    if (fieldName === 'company') {
      setDropdownOptions(prev => ({
        ...prev,
        dealerOptions: generateDropdownOptions(setupData.dealers.filter(item => item.companyId === value)),
      }))
      if (formData.contractSetupData.dealer?.companyId !== value) {
        updateFormData('contractSetupData', 'dealer', lookupMap(null)['dealer'])
        setDropdownOptions(prev => ({
          ...prev,
          contractTypeOptions: [],
        }))
      }
    }
    updateFormData('contractSetupData', fieldName, lookupMap(value)[fieldName as SetupFieldName])
  }

  /**
   * Contract Setup form fields configuration
   */
  const contractSetupFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.RADIO_BUTTON_GROUP,
      onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) =>
        setFormData(prev => ({
          ...prev,
          contractSetupData: {...prev.contractSetupData, type: value as ContractVariant},
        })),
      itemsInColumn: 1,
      radioButtons: [
        {
          label: t(TranslationKey['Create contract']),
          value: ContractVariant.CREATION,
        },
        {
          label: t(TranslationKey['Create quote']),
          value: ContractVariant.QUOTE,
        },
      ],
      label: t(TranslationKey['What do you want to do']),
      value: formData.contractSetupData.type,
    },
    {
      fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
      label: t(TranslationKey['Amplio partner']),
      items: dropdownOptions.companyOptions,
      name: 'company',
      onChange: (item: UIKit.DropdownItem | null) => onChangeSetupFormField('company', item),
      error: formFieldErrors.contractSetupFormFieldErrors.includes('company'),
      value: String(formData.contractSetupData.company?.id),
      disabled: true,
    },
    {
      fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
      label: t(TranslationKey.Dealer),
      items: dropdownOptions.dealerOptions,
      name: 'dealer',
      onChange: (item: UIKit.DropdownItem | null) => onChangeSetupFormField('dealer', item),
      error: formFieldErrors.contractSetupFormFieldErrors.includes('dealer'),
      value: String(formData.contractSetupData.dealer?.id),
      disabled: isCompanyUser(currentUser),
    },
  ]

  return {contractSetupFormFields, dropdownOptions, onSelectContractType, t}
}
