import {TranslationKey} from '@enums'
import {Grid} from '@mui/material'
import {PageWrapper} from '@wrappers'

import React from 'react'

import {onImageError, renderFormField, useLoadOnce} from 'common'

import {ComposedComponents, UIKit} from '@components'

import {ImageWrapper, imageStyle} from './style'
import {useUserFormModel} from './user-form-model'

/**
 * User Form page
 */
export function UserFormPage() {
  const {
    userGeneralFormFields,
    userAdditionalFormFields,
    userRoleFormField,
    title,
    image,
    t,
    handleDrop,
    loadData,
    onSubmit,
    onDiscard,
  } = useUserFormModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={title}>
      <UIKit.BackgroundCard title={t(TranslationKey['General information'])}>
        <Grid container spacing={2}>
          {userGeneralFormFields.map((item, index) => (
            <Grid sm={4} item key={`user-form-general-form-field-${index}`}>
              {renderFormField(item)}
            </Grid>
          ))}
        </Grid>
      </UIKit.BackgroundCard>

      <Grid container spacing={2}>
        <Grid item sm={4}>
          <UIKit.BackgroundCard>
            <UIKit.ImageDropzone
              image={
                <ImageWrapper>
                  <img src={image} alt="User image" style={imageStyle} onError={onImageError} />
                </ImageWrapper>
              }
              handleDrop={handleDrop}
            />
          </UIKit.BackgroundCard>
        </Grid>
        <Grid item sm={4}>
          <UIKit.BackgroundCard>
            <Grid container spacing={2}>
              {userAdditionalFormFields.map((item, index) => (
                <Grid sm={12} item key={`user-form-additional-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>
        </Grid>
        <Grid item sm={4}>
          <UIKit.BackgroundCard>{renderFormField(userRoleFormField)}</UIKit.BackgroundCard>
        </Grid>
      </Grid>

      <ComposedComponents.FormButtons
        leftButton={{label: t(TranslationKey.Discard), onClick: onDiscard}}
        rightButton={{label: t(TranslationKey.Save), onClick: onSubmit}}
      />
    </PageWrapper>
  )
}
