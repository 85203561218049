import {Grid, Stack, styled} from '@mui/material'

export const ImageWrapper = styled(Stack)(() => ({
  height: 169,
  justifyContent: 'center',
  alignItems: 'center',
}))

export const imageStyle = {
  height: '150px',
  width: '150px',
  objectFit: 'cover' as const,
  borderRadius: '50%',
}

export const GridImageWrapper = styled(Grid)(() => ({
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const imageGridItemStyle = {
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
