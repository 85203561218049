import {formatCurrency, getTotalContractCost, getUserData, getUserTimeFormat} from '@common'
import {TranslationKey} from '@enums'

import {formatDate, parse} from 'date-fns'
import {TFunction} from 'i18next'

import {ContractHTMLData} from './contract-pdf-overview-model'

/**
 * A function generating a HTML string from Contract data
 * @param t Translation function
 * @param formData Contract data
 * @param imageUrl Company image src
 * @returns HTML string of the Contract data
 */
export function generateHtml(t: TFunction, formData: ContractHTMLData, imageUrl: string) {
  const currentUser = getUserData()
  const userTimeFormat = getUserTimeFormat(String(currentUser.id))
  const products = formData.products.flatMap(item => [item, ...item.accessories])

  return `<body style="font-family: Arial, sans-serif; margin: 0; padding: 0;">
   <div style="width: 21cm; height: 29.7cm; padding: 0.5cm 0.75cm; box-sizing: border-box; justify-content:space-between; display:flex; flex-direction:column;">
   <div>
      <!-- Header with Image, Title, and Subtitle -->
      <div style="display: flex; align-items: flex-end; justify-content: space-between;">
         <div style="flex-grow: 1;">
            <p style="font-size: 24px; font-weight: bold; margin: 0;">${t(TranslationKey[formData.quote ? 'Quote' : 'Contract'])} - ${formData.contractType.name}</p>
            <p style="font-size: 18px; color: #555; margin: 0;">${formData.company.name}</p>
         </div>
         <img src='${imageUrl}' alt="Top Right Image" style="width: 100px; height: 100px; border-radius:5px;">
      </div>
      <!-- Customer Information Section with Blue Stripe -->
      <div style="border-radius: 0.1cm; margin-top: 0.3cm; background-color: #f0f0f0;">
         <div style="height: 0.75cm; background-color: #122944; border-top-left-radius: 0.1cm; border-top-right-radius: 0.1cm; display: flex; align-items: center; padding-left: 0.5cm;">
            <p style="color: #ffffff; font-size: 16px; margin: 0;">${t(TranslationKey.Customer)}</p>
         </div>
         <!-- Customer Information Content with New Rows -->
         <div style="padding: 0.3cm 0.5cm;">
            <div style="display: flex; justify-content: space-between; margin-bottom: 0.3cm;">
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey.Name)}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.customer.firstName} ${formData.customer.lastName}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey.Email)}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.customer.email}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey.Phone)}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.customer.phone || '-'}</p>
               </div>
            </div>
            <div style="display: flex; justify-content: space-between;">
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey.Address)}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.customer.street || '-'}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey['Zip code'])}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.customer.zipCode || '-'}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey.City)}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.customer.city || '-'}</p>
               </div>
            </div>
         </div>
      </div>
      <!-- Dealer Information Section with Blue Stripe -->
      <div style="border-radius: 0.1cm; margin-top: 0.3cm; background-color: #f0f0f0;">
         <div style="height: 0.75cm; background-color: #122944; border-top-left-radius: 0.1cm; border-top-right-radius: 0.1cm; display: flex; align-items: center; padding-left: 0.5cm;">
            <p style="color: #ffffff; font-size: 16px; margin: 0;">${t(TranslationKey.Dealer)}</p>
         </div>
         <!-- Dealer Information Content with New Rows -->
         <div style="padding: 0.3cm 0.5cm;">
            <div style="display: flex; justify-content: space-between; margin-bottom: 0.3cm;">
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey.Name)}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.dealer.name}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey['Partner name'])}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.company.name}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey['Customer number'])}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.dealer.customerNo}</p>
               </div>
            </div>
${
  !formData.quote
    ? `<div style="display: flex; justify-content: space-between; margin-bottom: 0.3cm;">
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey['VAT number'])}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.dealer.vatNumber}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey['Company number'])}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.dealer.companyNo}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey['Dealer number'])}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.dealer.dealerNo}</p>
               </div>
            </div>
            <div style="display: flex; justify-content: space-between;">
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey.Address)}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.dealer.address}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey['Zip code'])}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.dealer.zipCode}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey.City)}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.dealer.city}</p>
               </div>
            </div>`
    : ''
}
         </div>
      </div>
 ${
   !formData.quote
     ? `<div style="border-radius: 0.1cm; margin-top: 0.3cm; background-color: #f0f0f0;">
         <div style="height: 0.75cm; background-color: #122944; border-top-left-radius: 0.1cm; border-top-right-radius: 0.1cm; display: flex; align-items: center; padding-left: 0.5cm;">
            <p style="color: #ffffff; font-size: 16px; margin: 0;">${t(TranslationKey['Official signatory'])}</p>
         </div>
         <div style="padding: 0.3cm 0.5cm;">
            <div style="display: flex; justify-content: space-between;">
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey.Name)}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.signatory}</p>
               </div>
               <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">${t(TranslationKey.Email)}</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">${formData.dealer.invoiceEmail}</p>
               </div>
                 <div style="flex: 1; text-align: left;">
                  <p style="margin: 0; font-size:12px;">-</p>
                  <p style="margin: 0; font-weight: bold; font-size:14px;">-</p>
               </div>
            </div>
         </div>
      </div>`
     : ''
 }
      <!-- Products Information Section with Blue Stripe -->
      <div style=" margin-top: 0.3cm; background-color: #f0f0f0;">
         <div style="height: 0.75cm; background-color: #122944; border-top-left-radius: 0.1cm; border-top-right-radius: 0.1cm; display: flex; align-items: center; padding-left: 0.5cm;">
            <p style="color: #ffffff; font-size: 16px; margin: 0;">${t(TranslationKey.Products)}</p>
         </div>
         <!-- Products Information Content -->
         <div style="padding: 0.3cm 0.3cm 0.3cm 0;">
            <div style="display: flex; justify-content: space-between; width: 100%; margin-bottom: 0.1cm;">
               <div style="flex: 0.15; text-align: center; ">
                  <p style="margin: 0;  font-size:12px;">${t(TranslationKey['Qty.'])}</p>
               </div>
               <div style="flex: 0.5; text-align: left;">
                  <p style="margin: 0;  font-size:12px;">${t(TranslationKey.Name)}</p>
               </div>
               <div style="flex: 0.3; text-align: left;">
                  <p style="margin: 0;  font-size:12px;">${t(TranslationKey['Serial number'])}</p>
               </div>
               <div style="flex: 0.3; text-align: right;">
                  <p style="margin: 0;  font-size:12px;">${t(TranslationKey[formData.quote ? 'Insurance' : 'Monthly fee'])}</p>
               </div>
               <div style="flex: 0.3; text-align: right;">
               <p style="margin: 0;  font-size:12px;">${t(TranslationKey[formData.quote ? 'Service' : 'Residual value'])}</p>
               </div>
               <div style="flex: 0.3; text-align: right;">
                  <p style="margin: 0;  font-size:12px;">${t(TranslationKey['Total price'])}</p>
               </div>
            </div>
            ${products
              .map(
                product => `
                    <div style="display: flex; justify-content: space-between; width: 100%; padding:0.1cm 0; border-top:1px solid white; border-bottom:1px solid white;">
               <div style="flex: 0.15; text-align: center; ">
                  <p style="margin: 0;  font-size:14px; font-weight:bold;">${product.quantity}</p>
               </div>
               <div style="flex: 0.5; text-align: left;">
                  <p style="margin: 0;  font-size:14px; font-weight:bold;">${`${product.name} ${product.productNo}`}</p>
               </div>
               <div style="flex: 0.3; text-align: left;">
               ${product.serialNumber
                 .split('++')
                 .map(item => `<p style="margin: 0;  font-size:14px; font-weight:bold;">${item}</p>`)
                 .join('')}
               </div>
               <div style="flex: 0.3; text-align: right;">
                  <p style="margin: 0;  font-size:14px; font-weight:bold;">${
                    formData.quote
                      ? formData.contractType.displayInstallationCosts
                        ? formatCurrency(product.insurancePrice || 0)
                        : product.insurancePrice !== undefined && product.insurancePrice > 0
                          ? t(TranslationKey.Yes)
                          : t(TranslationKey.No)
                      : formatCurrency(product.totalCost / formData.length)
                  }</p>
               </div>
               <div style="flex: 0.3; text-align: right;">
               <p style="margin: 0;  font-size:14px; font-weight:bold;">${
                 formData.quote
                   ? formData.contractType.displayServicePrice
                     ? formatCurrency(product.servicePrice || 0)
                     : product.servicePrice !== undefined && product.servicePrice > 0
                       ? t(TranslationKey.Yes)
                       : t(TranslationKey.No)
                   : formatCurrency(product.residualValue || 0)
               }</p>
               </div>
               <div style="flex: 0.3; text-align: right;">
                  <p style="margin: 0;  font-size:14px; font-weight:bold;">${formatCurrency(product.totalCost)}</p>
               </div>
            </div>
            `,
              )
              .join('')}
          
         </div>
      </div>
      <!-- Summary Section with Blue Stripe -->
      <div style="border-bottom-left-radius: 0.1cm; border-bottom-right-radius: 0.1cm;  background-color: #122944;">
         <!-- Summary Information Content -->
         <div style="padding: 0.25cm 0.5cm;">
            <div style="display: flex; justify-content: space-between;">
               <div style="flex: 0.6; text-align: left;">
                  <p style="margin: 0; color: #ffffff; font-size:14px; padding-bottom:0.1cm;">${t(TranslationKey['Contract length'])}</p>
                  <p style="margin: 0; color: #ffffff; font-weight: bold; font-size:16px;">${`${formData.length} ${t(TranslationKey.Months)}`}</p>
               </div>
               <div style="flex: 0.6; text-align: right;">
                  <p style="margin: 0; color: #ffffff; font-size:14px;  padding-bottom:0.1cm;">${t(TranslationKey['Monthly fee'])}</p>
                  <p style="margin: 0; color: #ffffff; font-weight: bold; font-size:16px;">${formatCurrency(getTotalContractCost(formData.products, formData.extraCosts) / formData.length)}</p>
               </div>
               <div style="flex: 0.6; text-align: right;">
                  <p style="margin: 0; color: #ffffff; font-size:14px;  padding-bottom:0.1cm;">${t(TranslationKey.Total)}</p>
                  <p style="margin: 0; color: #ffffff; font-weight: bold; font-size:16px;">${formatCurrency(getTotalContractCost(formData.products, formData.extraCosts))}</p>
               </div>
            </div>
         </div>
      </div>
    ${
      formData.quote && formData.disclaimer
        ? `<div style="border-radius: 0.1cm; margin-top: 0.3cm; background-color: #f0f0f0;">
         <div style="height: 0.75cm; background-color: #122944; border-top-left-radius: 0.1cm; border-top-right-radius: 0.1cm; display: flex; align-items: center; padding-left: 0.5cm;">
            <p style="color: #ffffff; font-size: 16px; margin: 0;">${t(TranslationKey.Disclaimer)}</p>
         </div>
         <div style="padding: 0.3cm 0.5cm;">
            <p style="margin: 0; font-size:12px; text-align:justify;">${formData.disclaimer}</p>
         </div>
      </div>`
        : ''
    }
   </div>
   <div>
      <div style="display:flex; justify-content:space-between;">
         <div>
            <div style="justify-content: left; display:flex; ">
               <p style="margin: 0;  padding-right:2px; font-size:12px;">${`${t(TranslationKey['Created at'])} :`}</p>
               <p style="margin: 0;  font-size:12px; font-weight:bold;">${formData.createdAt}</p>
            </div>
          ${
            formData.quote
              ? `<div style="justify-content: left; display:flex; ">
               <p style="margin: 0;  padding-right:2px; font-size:12px;">${`${t(TranslationKey['Valid until'])} :`}</p>
               <p style="margin: 0;  font-size:12px; font-weight:bold;">${formData.expiry}</p>
            </div>`
              : ''
          }
         </div>
       ${
         formData.quote
           ? `<div style="align-items:center; display:flex; flex-direction:column;">
            <div style="justify-content: right; display:flex; width:5cm; height:16px; border-bottom:2px solid black;">
            </div>
            <div style="justify-content: right; display:flex; ">
               <p style="margin: 0;  font-size:12px;">${formData.signatory}</p>
            </div>
         </div>`
           : ''
       }
      </div>
      <div style="width: 100%; text-align: center; font-size: 10px; color: gray; padding-top: 1cm;">
         <p style="margin: 0;">© ${formatDate(parse(formData.createdAt, userTimeFormat, new Date()), 'yyyy')} Amplio Rental AB | Ågatan 7, 582 22 Linköping | info@ampliorental.se</p>
      </div>
   </div>
</body>
`
}
