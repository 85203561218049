import {APIMethod, ContractStatus, Country} from '@enums'

import {fetchData} from 'api/api'

interface BaseContract {
  length: number
  note: string
  products: ContractProduct[]
  customer: ContractCustomer
  status: ContractStatus
  creditCheckPassed: boolean
  officialSignatory: string
  extraCosts: ContractExtraCost[]
  contractCode: string
}
interface BaseContractItem {
  id?: string
  productNo: string
  serialNumber: string
  name: string
  price: number | undefined
  residualValue: number | undefined
  servicePrice: number | undefined
  installationCompensation: number | undefined
  insurancePrice: number | undefined
  quantity: number | undefined
  deliveryDate: string
  annuity: number
  amortization: number
  averageFunctionAndInterest: number
  totalCost: number
}
interface BaseContractType {
  id: string
  name: string
  type: string
  note: string
  disclaimer: string
  lockFields: boolean
  insurancePercentage: number
  productInsuranceMinimum: number
  accessoryInsuranceMinimum: number
  productDiscount: number
  serviceDiscount: number
  accessoryDiscount: number
  advancedPayment: number
  interest: number
  functionInterest: number
}
export interface ContractData extends BaseContract {
  priceData: ContractPrice
  company: ContractCompany
  dealer: ContractDealer
  contractType: ContractContractType
}
export interface ContractRequest extends BaseContract {
  companyId: string
  dealerId: string
  contractTypeId: string
  expiry: string
}
export interface ContractResponse extends BaseContract {
  id?: string
  companyId: string
  dealerId: string
  contractTypeId: string
  expiry: string
  createdAt: string
  updatedAt: string
  createdBy: string
}
export interface ContractPrice {
  annuity: number
  amortization: number
  averageFunctionAndInterest: number
  insurance: number
  servicePrice: number
  totalCost: number
}

export type ContractItem = BaseContractItem

export type ContractAccessory = BaseContractItem & {
  accessoryId?: string
}

export type ContractProduct = BaseContractItem & {
  productId?: string
  accessories: ContractAccessory[]
}
export interface ContractCustomer {
  firstName: string
  lastName: string
  street?: string
  city?: string
  zipCode?: string
  email?: string
  phone?: string
  dateOfBirth: string
  personalNumber?: string
  houseNumber?: string
}
export interface ContractCompany {
  name: string
  country: Country | null
  description: string
  note: string
  id?: string
}
export interface ContractDealer {
  name: string
  email: string
  address: string
  id?: string
}

export type ContractContractType = BaseContractType
export interface ContractExtraCost {
  label: string
  value: number
}

export interface ContractStatsResponse {
  day: string
  status: string
  count: number
}

/**
 * @returns All Contracts
 */
export const getContracts = async (): Promise<ContractResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: 'contract',
  })
}

/**
 * @param id Contract ID.
 * @returns A single Contract with a specific ID
 */

export const getContract = async (id: string): Promise<ContractResponse> => {
  return fetchData({
    method: APIMethod.GET,
    url: `contract/${id}`,
  })
}

/**
 * @param id Company ID
 * @returns All Contracts with a specific Company ID
 */
export const getContractsByCompany = async (id: string): Promise<ContractResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `contract/company/${id}`,
  })
}

/**
 * Creates a new Contract
 * @param body Contract payload data
 * @returns New Contract data
 */
export const createContract = async (body: ContractRequest): Promise<ContractResponse> => {
  return fetchData({
    method: APIMethod.POST,
    url: `contract`,
    body,
  })
}

/**
 * Updates an existing Contract
 * @param body Contract payload data
 * @param id Contract ID
 * @returns Updated Contract data
 */
export const updateContract = async (body: ContractRequest, id: string): Promise<ContractResponse> => {
  return fetchData({
    method: APIMethod.PUT,
    url: `contract/${id}`,
    body,
  })
}

/**
 *
 * @param id Company ID
 * @returns Statistics of created Contracts for last seven days
 */
export const getContractStatsByCompanyId = async (id: string): Promise<ContractStatsResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `contract/statistics/last-seven-days/company/${id}`,
  })
}

/**
 * Fetches a globally unique Contract code
 * @returns Contract code
 */
export const reserveContractCode = async (): Promise<string> => {
  return fetchData({
    method: APIMethod.POST,
    url: 'contract/reserve-code',
  })
}
