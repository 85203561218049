import {APIMethod, Country} from '@enums'

import {fetchData} from 'api/api'
import {ItemSignature} from 'common/types'

interface BaseCompany {
  name: string
  active: boolean
}
export interface CompanyData extends BaseCompany {
  id?: string
  country: Country | null
  description: string
  note: string
  image: string
}
export interface CompanyRequest extends BaseCompany {
  country: Country
  description: string
  note: string
  image: string
  advancedPayment: number
  interest: number
  functionInterest: number
}
export interface CompanyShortData extends BaseCompany {
  id: string
}
export interface CompanyResponse extends BaseCompany, ItemSignature {
  id: string
  country: Country
  description: string
  note: string
  image: string
}

/**
 * @returns All Companies
 */
export const getCompanies = async (): Promise<CompanyResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: 'company',
  })
}

/**
 * @param id Company ID
 * @returns A single Company with a specific ID
 */
export const getCompany = async (id: string): Promise<CompanyResponse> => {
  return fetchData({
    method: APIMethod.GET,
    url: `company/${id}`,
  })
}

/**
 * Updates an existing Company
 * @param id Company ID
 * @param body Company payload data
 * @returns Updated Company data
 */
export const updateCompany = async (id: string, body: CompanyRequest): Promise<CompanyResponse> => {
  return fetchData({
    method: APIMethod.PUT,
    url: `company/${id}`,
    body,
  })
}

/**
 * Creates a new Company
 * @param body Company payload data
 * @returns New Company data
 */
export const createCompany = async (body: CompanyRequest): Promise<CompanyResponse> => {
  return fetchData({
    method: APIMethod.POST,
    url: `company`,
    body,
  })
}
