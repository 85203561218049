import {renderFormField} from '@common'
import {TranslationKey} from '@enums'
import SearchIcon from '@mui/icons-material/Search'
import {Button, Grid, Stack} from '@mui/material'

import {UIKit} from '@components'

import {CustomerFormProps, useCustomerFormModel} from './customer-form-model'

/**
 * Customer step
 */
export function CustomerForm(props: CustomerFormProps) {
  const {
    customerFormFields,
    personalNumberFormField,
    isSearchBtnDisabled,
    isSearchBtnVisible,
    onGetPersonDataBtnClick,
    t,
  } = useCustomerFormModel(props)

  return (
    <UIKit.BackgroundCard title={t(TranslationKey['Customer information'])}>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid key={`customer-personal-no-form-field`} item sm={3}>
            {renderFormField(personalNumberFormField)}
          </Grid>

          {isSearchBtnVisible && (
            <Grid style={{display: 'flex', alignItems: 'flex-end'}} key={`customer-personal-no-button`} item sm={3}>
              <Button
                disabled={isSearchBtnDisabled}
                endIcon={<SearchIcon />}
                style={{margin: '0.44rem'}}
                variant="contained"
                onClick={onGetPersonDataBtnClick}
              >
                {t(TranslationKey['Get person data'])}
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2}>
          {customerFormFields.map((item, index) => (
            <Grid key={`customer-form-field-${index}`} item sm={3}>
              {renderFormField(item)}
            </Grid>
          ))}
        </Grid>
      </Stack>
    </UIKit.BackgroundCard>
  )
}
