import {useLoadOnce} from '@common'
import {NavigationPathKey, TranslationKey} from '@enums'
import {PageWrapper, SearchTableWrapper} from '@wrappers'

import React from 'react'

import {useNavigate} from 'react-router-dom'

import {UIKit} from '@components'

import {useContractSearchModel} from './contract-search-model'

/**
 * Contract Search page
 */
export function ContractSearchPage() {
  const navigate = useNavigate()
  const {loadData, t, rows, visibilityModel, columns} = useContractSearchModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={t(TranslationKey['Contract search'])}>
      <SearchTableWrapper>
        <UIKit.Table
          buttonProps={{
            buttonText: t(TranslationKey['Add new contract']),
            onClick: () => navigate(NavigationPathKey.CONTRACT_CREATION),
          }}
          columns={columns}
          rows={rows}
          visibilityModel={visibilityModel}
          title={t(TranslationKey['List of contracts'])}
        />
      </SearchTableWrapper>
    </PageWrapper>
  )
}
