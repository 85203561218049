import {Box, Button, ButtonProps, IconButton, Stack, styled} from '@mui/material'

export const NavigationButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'adminBtn',
})<ButtonProps & {active?: boolean; adminBtn?: boolean}>(({theme, active, adminBtn}) => ({
  justifyContent: 'flex-start',
  paddingLeft: adminBtn ? '3rem' : active ? '1.75rem' : '2rem',
  height: '3rem',
  borderRadius: '0',
  textTransform: 'unset',
  color: active ? theme.palette.primary.main : theme.palette.grey[100],
  ...theme.typography.body1,
  ...(active && {
    borderLeft: adminBtn ? '' : `0.25rem solid ${theme.palette.primary.main}`,
    fontWeight: 'bold',
  }),
}))

export const SideNavigationWrapper = styled(Stack)(({theme}) => ({
  height: '100vh',
  width: '18rem',
  backgroundColor: theme.palette.common.white,
  paddingTop: '2.5rem',
  position: 'fixed',
  top: 0,
  left: 0,
}))

export const NavigationWrapper = styled(Stack)(() => ({
  height: 'calc(100% - 8.25rem)',
  padding: '2.5rem 0 0 0.1rem',
}))

export const LogoLink = styled(Box)(() => ({
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
}))

export const iconStyle = {
  height: '1.5rem',
  width: '1.5rem',
  paddingBottom: '0.25rem',
}

export const imageStyle = {
  height: '2.5rem',
}

export const TopNavigationWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const DropdownsWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '15px',
}))

export const DateWrapper = styled(Stack)(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 10,
  height: '3rem',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 1rem',
}))

export const TopBarLeftSectionWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '1rem',
}))

export const TopBarMenuButton = styled(IconButton)(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '0.4rem',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))
