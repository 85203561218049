export enum LoadingStatus {
  NOT_STARTED,
  LOADING,
  SUCCESS,
  FAILED,
}

export enum Language {
  EN = 'en',
  SE = 'se',
  DE = 'de',
  FR = 'fr',
  FI = 'fi',
  NO = 'no',
  DK = 'dk',
  NL = 'nl',
}

export enum NavigationPathKey {
  DASHBOARD = '/dashboard',
  CONTRACT_SEARCH = '/contract/search',
  CONTRACT_CREATION = '/contract/create',
  CONTRACT_VIEW = '/contract/view',
  CREDIT_CHECK = '/credit-check',
  ADMIN_PANEL = '/admin',
  PRODUCT_DASHBOARD = '/admin/products-and-accessories-dashboard',
  USER_SEARCH = '/admin/user/search',
  USER_CREATION = '/admin/user/create',
  USER_VIEW = '/admin/user/view',
  COMPANY_SEARCH = '/admin/company/search',
  COMPANY_CREATION = '/admin/company/create',
  COMPANY_VIEW = '/admin/company/view',
  DEALER_SEARCH = '/admin/dealer/search',
  DEALER_CREATION = '/admin/dealer/create',
  DEALER_VIEW = '/admin/dealer/view',
  CONTRACT_TYPE_SEARCH = '/admin/contract-type/search',
  CONTRACT_TYPE_CREATION = '/admin/contract-type/create',
  CONTRACT_TYPE_VIEW = '/admin/contract-type/view',
  PRODUCT_SEARCH = '/admin/product/search',
  PRODUCT_CREATION = '/admin/product/create',
  PRODUCT_VIEW = '/admin/product/view',
  PRODUCT_BULK_CREATION = '/admin/product/bulk/create',
  ACCESSORY_SEARCH = '/admin/accessory/search',
  ACCESSORY_CREATION = '/admin/accessory/create',
  ACCESSORY_VIEW = '/admin/accessory/view',
  LOGS = '/admin/logs',
  SETTINGS = '/settings',
  LOGOUT = '/logout',
  LOGIN = '/login',
  NOT_FOUND = '/404',
}

export enum FormFieldType {
  INPUT,
  DROPDOWN,
  DATE_PICKER,
  CHECKBOX_GROUP,
  RADIO_BUTTON_GROUP,
  LABEL,
  SEARCHABLE_DROPDOWN,
  PHONE_INPUT,
  STACKED_INPUT,
}

export enum Country {
  SWEDEN = 'SWEDEN',
  NORWAY = 'NORWAY',
  DENMARK = 'DENMARK',
  FINLAND = 'FINLAND',
  GERMANY = 'GERMANY',
  AUSTRIA = 'AUSTRIA',
  NETHERLANDS = 'NETHERLANDS',
  FRANCE = 'FRANCE',
  UNITED_KINGDOM = 'UNITED_KINGDOM',
}

export enum LocalStorageItem {
  AUTHENTICATION = 'Authentication',
  USER_LANGUAGES = 'UserLanguages',
  USER_TIME_FORMAT = 'UserTimeFormats',
  USER_COMPANIES = 'UserCompanies',
  CURRENCY = 'Currency',
}

// TODO update
export enum UserRole {
  SUPER_ADMIN = 'SA',
  COMPANY_ADMIN = 'CA',
  COMPANY_USER = 'CU',
}

export enum FormState {
  NEW,
  EDIT,
  VIEW,
}

export enum APIMethod {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  DELETE = 'delete',
}

export enum APIRoute {
  // TODO
  USER = 'user',
  COMPANY = 'company',
  DEALER = 'dealer',
}

export enum ContractVariant {
  CREATION = 'CREATION',
  QUOTE = 'QUOTE',
}

export enum FormSection {
  SETUP,
  PRODUCT,
  ACCESSORY,
  CUSTOMER,
  EXTRA_COSTS,
}

export enum CreditStatus {
  GOOD,
  BAD,
  UNKNOWN,
}

export enum ImageType {
  USER = 'USER',
  COMPANY = 'COMPANY',
}

export enum ContractStatus {
  DRAFT = 'DRAFT',
  QUOTE = 'QUOTE',
  AWAITING_VERIFICATION = 'AWAITING_VERIFICATION',
  SENT_FOR_SIGNING = 'SENT_FOR_SIGNING',
  SIGNED = 'SIGNED',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export enum SigningLanguage {
  EN_US = 'en_US',
  SE_SE = 'se_SE',
  DE_DE = 'de_DE',
  FR_FR = 'fr_FR',
  FI_FI = 'fi_FI',
  NO_NO = 'no_NO',
  DK_DK = 'dk_DK',
  NL_NL = 'nl_NL',
}

export enum SigningMethod {
  EMAIL = 'email',
  BANKID_NO = 'bankid-no',
  BANKID_SE = 'bankid-se',
  NETS_EIDENT_FI = 'nets-eident-fi',
  NETS_EIDENT_DK_MITID = 'nets-eident-dk-mitid',
  EIDEASY_EE_ID = 'eideasy-ee-id',
  EIDEASY_LV_ID = 'eideasy-lv-id',
  EIDEASY_LT_ID = 'eideasy-lt-id',
  TOUCH_SIGN = 'touch-sign',
  SMS = 'sms',
}

export enum NotificationMethod {
  EMAIL = 'email',
  SMS = 'sms',
}

export enum ContractType {
  B2B = 'B2B',
  B2C = 'B2C',
}
