import React, {ReactNode} from 'react'

import {SideNavigation, TopBar} from '@components'

import {Background, RightSideWrapper, ContentWrapper, SideNavigationWrapper} from './style'

interface Props {
  title: string
  children: ReactNode
}

/**
 * Page wrapper with side navigation
 */
export function PageWrapper(props: Props) {
  const {title, children} = props

  return (
    <Background direction={'row'}>
      <SideNavigationWrapper>
        <SideNavigation />
      </SideNavigationWrapper>
      <RightSideWrapper>
        <TopBar title={title} />
        <ContentWrapper gap={5}>{children}</ContentWrapper>
      </RightSideWrapper>
    </Background>
  )
}
