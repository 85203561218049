import {
  ContractResponse,
  ContractTypeResponse,
  DealerResponse,
  getContracts,
  getContractsByCompany,
  getContractTypes,
  getContractTypesByCompany,
  getDealers,
  getDealersByCompany,
  LoggedInUser,
  handleAxiosError,
} from '@api'
import {AppContext} from '@contexts'
import {ContractStatus, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridTreeNodeWithRender} from '@mui/x-data-grid'

import {useContext, useState} from 'react'

import {
  getStatusCell,
  getUserCompany,
  getUserData,
  getUserTimeFormat,
  isCompanyUser,
  isSuperAdmin,
  renderClickableCell,
} from 'common'
import {formatDate} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

export interface ContractTableData {
  status: ContractStatus
  contractType: string
  customerType: string
  dealer: string
  customer: string
  date: string
  id: string
  contractCode: string
}

export function useContractSearchModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const {t} = useTranslation()
  const [rows, setRows] = useState<ContractTableData[]>([])
  const navigate = useNavigate()
  const currentUser: LoggedInUser = getUserData()

  /**
   * Contract table columns configuration
   */
  const columns: GridColDef[] = [
    {
      field: 'contractCode',
      headerName: t(TranslationKey['Contract code']),
      flex: 1,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.CONTRACT_VIEW}/${params.row.id}`)),
    },
    {field: 'dealer', headerName: t(TranslationKey.Dealer), flex: 1.2},
    {field: 'customer', headerName: t(TranslationKey.Customer), flex: 1, hideable: false},
    {field: 'contractType', headerName: t(TranslationKey['Contract type']), flex: 1},
    {field: 'customerType', headerName: t(TranslationKey.Type), width: 120},
    {
      field: 'status',
      headerName: t(TranslationKey.Status),
      width: 120,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) =>
        getStatusCell(t, params.value),
    },
    {field: 'date', headerName: t(TranslationKey.Date), width: 120},
  ]

  /**
   * Contract table visibility model configuration
   */
  const visibilityModel: GridColumnVisibilityModel = {
    company: false,
    contractType: false,
  }

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      let data: ContractResponse[] = []
      let dealers: DealerResponse[] = []
      let contractTypes: ContractTypeResponse[] = []

      // For a Super Administrator, we fetch ALL the data
      if (isSuperAdmin(currentUser)) {
        data = await getContracts()
        dealers = await getDealers()
        contractTypes = await getContractTypes()
      } else {
        //For Company Administrator or Company User, we fetch ONLY their contracts
        data = await getContractsByCompany(String(currentUser.companyId))
        dealers = await getDealersByCompany(String(currentUser.companyId))
        contractTypes = await getContractTypesByCompany(String(currentUser.companyId))
      }

      const formattedData: ContractTableData[] = data
        .filter(item => (isSuperAdmin(currentUser) ? item.companyId === getUserCompany(currentUser.id) : true))
        .filter(item => (isCompanyUser(currentUser) ? item.createdBy === currentUser.id : true))
        .map(item => ({
          contractCode: item.contractCode,
          customer: `${item.customer.firstName} ${item.customer.lastName}`,
          dealer: dealers.find(dealer => dealer.id === item.dealerId)?.name || '',
          contractType: contractTypes.find(contractType => contractType.id === item.contractTypeId)?.name || '',
          customerType: contractTypes.find(contractType => contractType.id === item.contractTypeId)?.type || '',
          status: item.status,
          date: formatDate(item.createdAt, getUserTimeFormat(String(currentUser?.id))),
          id: item.id || '',
        }))
      setRows(formattedData)

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {loadData, navigate, visibilityModel, t, rows, columns}
}
