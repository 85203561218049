import {Button, Stack, styled} from '@mui/material'

export const FormButton = styled(Button)(() => ({
  minWidth: '10rem',
}))

export const ButtonsWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '1.5rem',
}))
