import {Stack, styled} from '@mui/material'

export const ErrorIndicator = styled(Stack)(() => ({
  height: '42px',
  paddingLeft: '8px',
  justifyContent: 'center',
}))

export const FormGroupContent = styled(Stack)(() => ({
  // height: '48px',
  justifyContent: 'center',
}))
