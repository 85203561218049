import {CompanyResponse, getImage} from '@api'
import {onImageError} from '@common'
import {ImageType, TranslationKey} from '@enums'
import {Typography} from '@mui/material'

import React from 'react'

import {ContractStepper} from 'components/ui-kit'
import {useTranslation} from 'react-i18next'

import {UIKit} from '@components'

import {ContentWrapper, FullContentWrapper, imageStyle, StepperWrapper, TextWrapper} from './style'

interface Props {
  company?: CompanyResponse
  steps: string[]
  activeStep: number
}

/**
 * Contract Header component with Company logo and name and Contract Steps
 */
export function ContractHeader(props: Props) {
  const {company, steps, activeStep} = props

  const {t} = useTranslation()
  return (
    <UIKit.BackgroundCard>
      <FullContentWrapper>
        <ContentWrapper>
          <img style={imageStyle} src={getImage(ImageType.COMPANY, String(company?.id))} onError={onImageError} />
          <TextWrapper>
            <Typography variant="h1">{company?.name ?? t(TranslationKey['Amplio partner'])}</Typography>
            <Typography>{company?.description}</Typography>
          </TextWrapper>
        </ContentWrapper>
        <StepperWrapper>
          <ContractStepper activeStep={activeStep} steps={steps} />
        </StepperWrapper>
      </FullContentWrapper>
    </UIKit.BackgroundCard>
  )
}
