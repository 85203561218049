import colors from '@colors'
import {Stack, styled} from '@mui/material'

export const imageStyle = {
  height: '200px',
  width: '200px',
  objectFit: 'cover' as const,
  borderRadius: '1rem',
  border: `1px solid ${colors.gray[2]}`,
}

export const ImageWrapper = styled(Stack)(() => ({
  height: 292,
  justifyContent: 'center',
  alignItems: 'center',
}))
