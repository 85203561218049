import colors from '@colors'
import {getStatusText} from '@common'
import {TranslationKey, Country, ContractStatus} from '@enums'
import {Stack, SvgIcon, Typography} from '@mui/material'
import {GridColDef, GridRenderCellParams, GridTreeNodeWithRender} from '@mui/x-data-grid'

import {ReactNode} from 'react'

import {TFunction} from 'i18next'

import {UIKit} from '@components'

import {countries, icons} from './consts'
import {
  TrueIcon,
  FalseIcon,
  CountryCellContainer,
  ActionButtonContainer,
  ActionButton,
  UnlinkButton,
  StatusCellInnerWrapper,
  StatusCellOuterWrapper,
  ClickableCell,
} from './style'

/**
 * @param country Country
 * @returns Country icon
 */
export const getCountryFlag = (country: string): JSX.Element => <SvgIcon>{icons[country as Country]}</SvgIcon>

/**
 * @param rows Table rows
 * @returns Table rows with 'no' prop
 */
export const getTableRows = <T extends object>(rows: T[]): T[] =>
  rows.map((item, index) => ({
    no: index + 1,
    ...item,
  }))

/**
 * @param columns Table columns
 * @returns Table columns with disabled column menu
 */
export const getTableColumns = (columns: GridColDef[]): GridColDef[] => [
  ...columns.map(column => ({
    disableColumnMenu: true,
    ...column,
  })),
]

/**
 * @returns Pagination page size options
 */
export const getPageSizes = (): UIKit.DropdownItem[] =>
  [10, 25, 50].map(item => ({
    label: item.toString(),
    value: item,
  }))

/**
 * @param t Translation function
 * @returns Cell with an Active status icon
 */
export const activeCell = (t: TFunction): GridColDef => ({
  field: 'active',
  headerName: t(TranslationKey.Active),
  width: 75,
  type: 'boolean',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) =>
    params.value ? <TrueIcon /> : <FalseIcon />,
})

/**
 * @param t Translation function
 * @param status Contract Status
 * @returns Cell with styled Status display
 */
export const getStatusCell = (t: TFunction, status: ContractStatus): ReactNode => {
  let color = colors.gray[2]

  switch (status) {
    case ContractStatus.QUOTE:
      color = colors.main.auxiliary
      break
    case ContractStatus.AWAITING_VERIFICATION:
      color = colors.warning.main
      break
    case ContractStatus.SENT_FOR_SIGNING:
      color = colors.confirmationGreen.light
      break
    case ContractStatus.CANCELED:
      color = colors.error.main
      break
  }

  return (
    <StatusCellOuterWrapper>
      <StatusCellInnerWrapper color={color}>
        <Typography fontSize={'0.7rem'}>{getStatusText(t, status)}</Typography>
      </StatusCellInnerWrapper>
    </StatusCellOuterWrapper>
  )
}

/**
 * @param t Translation function
 * @returns A cell with a country name and flag
 */
export const countryCell = (t: TFunction): GridColDef => ({
  field: 'country',
  headerName: t(TranslationKey.Country),
  width: 150,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
    <CountryCellContainer>
      <Typography>{countries(t).find(item => item.isoCode === params.value)?.name}</Typography>
      <SvgIcon>{icons[params.value as Country]}</SvgIcon>
    </CountryCellContainer>
  ),
})

/**
 * @param t Translation function
 * @param onClick On click handler function
 * @returns A cell with a View button
 */
export const viewButtonCell = (t: TFunction, onClick: (url: string) => void): GridColDef => ({
  field: 'id',
  headerName: t(TranslationKey.View),
  width: 100,
  filterable: false,
  hideable: false,
  sortable: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
    <ActionButtonContainer>
      <ActionButton variant="contained" onClick={() => onClick(params.value)}>
        {t(TranslationKey.View)}
      </ActionButton>
    </ActionButtonContainer>
  ),
})

export const renderClickableCell = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
  onClick: () => void,
) => (params.row.id ? <ClickableCell onClick={onClick}>{params.value}</ClickableCell> : <Stack>{params.value}</Stack>)

/**
 * @param t Translation function
 * @param onClick On click handler function
 * @returns A cell with an Unlink button
 */
export const unlinkButtonCell = (t: TFunction, onClick: (id: string) => Promise<void>): GridColDef => ({
  field: 'unlinkId',
  headerName: t(TranslationKey.Unlink),
  width: 100,
  filterable: false,
  hideable: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
    <ActionButtonContainer>
      <UnlinkButton
        variant="contained"
        onClick={() => {
          onClick(params.value)
        }}
      >
        {t(TranslationKey.Unlink)}
      </UnlinkButton>
    </ActionButtonContainer>
  ),
})
