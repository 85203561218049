import React from 'react'

import Dropzone from 'react-dropzone'

interface Props {
  image?: JSX.Element | null
  placeholder?: React.ReactNode
  handleDrop: (acceptedFiles: File[]) => void
}

/**
 * Dropzone component
 */
export function ImageDropzone(props: Props) {
  const {image, placeholder, handleDrop} = props

  return (
    <Dropzone onDrop={handleDrop}>
      {({getRootProps, getInputProps}) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />

            {image ?? placeholder}
          </div>
        </section>
      )}
    </Dropzone>
  )
}
