import {getErrorLogs, getSuccessLogs, handleAxiosError} from '@api'
import {getUserData, getUserTimeFormat, renderClickableCell} from '@common'
import {AppContext} from '@contexts'
import {LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef} from '@mui/x-data-grid'

import {useContext, useState} from 'react'

import {formatDate} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ComposedComponents} from '@components'

export enum LogsTab {
  ACTIVITY,
  ERROR,
}

export interface TableData {
  time: string
  message: string
  id?: string
}

export function useLogsSearchModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const {t} = useTranslation()
  const currentUser = getUserData()
  const userTimeFormat = getUserTimeFormat(currentUser.id)
  const [activeTab, setActiveTab] = useState<LogsTab>(LogsTab.ACTIVITY)
  const [errorRows, setErrorRows] = useState<TableData[]>([])
  const [activityRows, setActivityRows] = useState<TableData[]>([])

  const navigate = useNavigate()

  /**
   * Error table columns configuration
   */
  const errorColumns: GridColDef[] = [
    {field: 'message', headerName: t(TranslationKey.Description), flex: 1},
    {field: 'time', headerName: t(TranslationKey.Timestamp), flex: 0.5, align: 'right', headerAlign: 'right'},
  ]

  /**
   * Activity table columns configuration
   */
  const activityColumns: GridColDef[] = [
    {
      field: 'message',
      headerName: t(TranslationKey.Description),
      flex: 1,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.CONTRACT_VIEW}/${params.row.id}`)),
    },
    {field: 'time', headerName: t(TranslationKey.Timestamp), flex: 0.5, align: 'right', headerAlign: 'right'},
  ]

  /**
   * Tab buttons configuration
   */
  const tabButtons: ComposedComponents.TabButtonProps<LogsTab>[] = [
    {
      label: t(TranslationKey['Activity logs']),
      key: LogsTab.ACTIVITY,
      onClick: () => setActiveTab(LogsTab.ACTIVITY),
    },
    {
      label: t(TranslationKey['Error logs']),
      key: LogsTab.ERROR,
      onClick: () => setActiveTab(LogsTab.ERROR),
    },
  ]

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async () => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      const successLogs = await getSuccessLogs()
      const errorLogs = await getErrorLogs()

      const formattedSuccessLogs: TableData[] = successLogs.map(item => ({
        message: item.message.replace(item.contractId, ''),
        time: formatDate(item.createdAt, userTimeFormat),
        id: item.contractId,
      }))

      const formattedErrorLogs: TableData[] = errorLogs.map(item => ({
        message: item.errorStep,
        time: formatDate(item.createdAt, userTimeFormat),
      }))
      setErrorRows(formattedErrorLogs)
      setActivityRows(formattedSuccessLogs)
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }
  return {activeTab, errorColumns, activityColumns, errorRows, activityRows, tabButtons, t, loadData}
}
