import {getToken, getUserData} from '@common'
import {LocalStorageItem, NavigationPathKey, UserRole} from '@enums'

import React from 'react'

import {useJwt} from 'react-jwt'
import {Navigate, Outlet} from 'react-router-dom'

interface Props {
  allowedRoles: UserRole[]
}
export const PrivateRoutes = (props: Props) => {
  const {allowedRoles} = props
  const token = getToken()
  const currentUser = getUserData()
  const {isExpired} = useJwt(token)

  if (!token || isExpired) {
    localStorage.removeItem(LocalStorageItem.AUTHENTICATION)
    return <Navigate to={NavigationPathKey.LOGIN} />
  } else if (!allowedRoles.includes(currentUser?.role)) {
    return <Navigate to={NavigationPathKey.DASHBOARD} />
  }

  return <Outlet />
}
