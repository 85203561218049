import axios, {AxiosError, AxiosResponse} from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_URL

interface ErrorResponse {
  error: string
  message: string
  path: string
  status: number
  timestamp: string
}

interface ExtendedAxiosResponse extends AxiosResponse {
  data: ErrorResponse
}

export interface ExtendedAxiosError extends AxiosError {
  response?: ExtendedAxiosResponse
}

const axiosInstance = () => {
  return axios.create({
    baseURL: API_BASE_URL,
  })
}

export default axiosInstance()
