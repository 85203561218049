import {
  CompanyResponse,
  DealerResponse,
  getCompany,
  getDealer,
  getImage,
  getUser,
  handleAxiosError,
  UserData,
  UserResponse,
} from '@api'
import {AppContext} from '@contexts'
import {FormFieldType, ImageType, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'

import {useContext, useMemo, useState} from 'react'

import {FormFieldItem, roles} from 'common'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

/**
 * Initial data
 */
const initialUserData: UserData = {
  fullName: '',
  password: '',
  email: '',
  role: null,
  dealerId: null,
  companyId: null,
  active: true,
  image: '',
}

export function useUserViewModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const [userData, setUserData] = useState<UserData>(initialUserData)
  const [dealer, setDealer] = useState<DealerResponse | null>(null)
  const [company, setCompany] = useState<CompanyResponse | null>(null)
  const navigate = useNavigate()
  const {id} = useParams()
  const {t} = useTranslation()

  /**
   * Image src
   */
  const image = useMemo(() => userData.image, [userData.image])

  /**
   *  Dynamically generated title text for the User view page
   */
  const title: string = useMemo(() => `${t(TranslationKey['User view'])} - ${userData.fullName}`, [userData.fullName])

  /**
   * User form fields configuration - general section
   */
  const userGeneralFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Full name']),
      text: userData.fullName,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Email),
      text: userData.email,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Active),
      text: userData.active ? t(TranslationKey.Active) : t(TranslationKey.Inactive),
    },
  ]

  /**
   * User form fields configuration - permissions section
   */
  const userPermissionsFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Amplio partner']),
      text: company?.name || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Dealer),
      text: dealer?.name || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Role),
      text: roles(t).find(item => item.code === userData.role)?.name,
    },
  ]

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async () => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      if (!id) {
        throw new Error('No user ID')
      }
      const data: UserResponse = await getUser(id)
      if (data.dealerId) {
        const dealer: DealerResponse = await getDealer(String(data.dealerId))
        setDealer(dealer)
      }
      if (data.companyId) {
        const company: CompanyResponse = await getCompany(String(data.companyId))
        setCompany(company)
      }
      setUserData({...data, image: getImage(ImageType.USER, String(data.id)), password: ''})
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function that handles the Back button click
   */
  const onPressBack = () => {
    navigate(NavigationPathKey.USER_SEARCH)
  }

  /**
   * A function that handles the Edit button click
   */
  const onPressEdit = () => {
    navigate(`${NavigationPathKey.USER_CREATION}/${id}`)
  }

  return {
    loadData,
    navigate,
    onPressEdit,
    onPressBack,
    t,
    image,
    title,
    userPermissionsFormFields,
    userGeneralFormFields,
  }
}
