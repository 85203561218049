import {
  CompanyData,
  CompanyRequest,
  CompanyResponse,
  createCompany,
  getCompany,
  getImage,
  postImage,
  updateCompany,
  handleAxiosError,
} from '@api'
import {AppContext} from '@contexts'
import {FormFieldType, ImageType, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'

import {useContext, useMemo, useState} from 'react'

import {countries, FormFieldItem, generateReadableErrorMessages, validateFields} from 'common'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

/**
 * Initial data
 */
const initialCompanyData: CompanyData = {
  name: '',
  description: '',
  note: '',
  image: '',
  active: true,
  country: null,
}

export function useCompanyFormModel() {
  const {setLoadingStatus, setError, setIsFormDirty, handleNavigate} = useContext(AppContext)
  const [companyData, setCompanyData] = useState<CompanyData>(initialCompanyData)
  const [imageFile, setImageFile] = useState<undefined | File>(undefined)
  const [companyFormFieldsErrors, setCompanyFormFieldsErrors] = useState<string[]>([])
  const navigate = useNavigate()
  const {id} = useParams()
  const {t} = useTranslation()

  /**
   * Dynamically created title for the Company form page
   */
  const title = useMemo(
    () => (id ? `${t(TranslationKey['Edit partner'])} - ${companyData.name}` : t(TranslationKey['New partner'])),
    [id, companyData.name],
  )

  /**
   * Image src
   */
  const image = useMemo(() => companyData.image, [companyData.image])

  /**
   * A function handling Company form fields change
   * @param event Change event
   * @param checked Checked state for checkbox form fields
   */
  const onChangeCompanyGeneralFormField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean,
  ) => {
    setIsFormDirty(true)
    const fieldName = event.target.name
    const value = checked ?? event.target.value
    setCompanyFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    setCompanyData(prev => ({...prev, [fieldName]: value}))
  }

  /**
   * Company form fields configuration - general section
   */
  const companyGeneralFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Partner name']),
      name: 'name',
      onChange: onChangeCompanyGeneralFormField,
      value: companyData.name,
      error: companyFormFieldsErrors.includes('name'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Description),
      name: 'description',
      onChange: onChangeCompanyGeneralFormField,
      value: companyData.description,
      error: companyFormFieldsErrors.includes('description'),
    },
    {
      fieldType: FormFieldType.DROPDOWN,
      label: t(TranslationKey.Country),
      name: 'country',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeCompanyGeneralFormField(event),
      value: companyData.country,
      items: countries(t).map(item => ({label: item.name, value: item.isoCode})),
      error: companyFormFieldsErrors.includes('country'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Note),
      name: 'note',
      onChange: onChangeCompanyGeneralFormField,
      value: companyData.note,
      error: companyFormFieldsErrors.includes('note'),
      multiline: true,
      rows: 2,
      customStyle: {height: 'unset'},
    },
    {
      fieldType: FormFieldType.CHECKBOX_GROUP,
      label: t(TranslationKey.Active),
      name: 'active',
      itemsInColumn: 1,
      checkboxes: [
        {
          checked: companyData.active,
          label: t(TranslationKey.Active),
          value: companyData.active,
          name: 'active',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeCompanyGeneralFormField(event, checked),
        },
      ],
    },
  ]

  /**
   * A custom validation function for Company form data
   * @returns If there are any invalid fields
   */
  const validateData = (): boolean => {
    const notRequiredFields: string[] = ['image', 'note', 'description']

    const invalidValues = validateFields(companyData).filter(item => !notRequiredFields.includes(item))

    const isFormInvalid = invalidValues.length > 0
    if (isFormInvalid) {
      setError({
        message: generateReadableErrorMessages(t, invalidValues),
        name: '',
      })

      setCompanyFormFieldsErrors(invalidValues)
    }
    return isFormInvalid
  }

  /**
   * A function handling the Image upload
   * @param acceptedFiles Image file
   */
  const handleDrop = (acceptedFiles: File[]): void => {
    setImageFile(acceptedFiles[0])
    setCompanyData(prev => ({...prev, image: URL.createObjectURL(acceptedFiles[0])}))
  }

  /**
   * A function handling the Save button click
   * If form is valid updates or saves a new Company
   * @returns void
   */
  const onSubmit = async (): Promise<void> => {
    if (validateData()) return

    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (id) {
        await updateCompany(id, companyData as CompanyRequest)
        if (imageFile)
          await postImage({
            id: String(id),
            file: imageFile,
            type: ImageType.COMPANY,
          })
        setLoadingStatus(LoadingStatus.SUCCESS)
        setIsFormDirty(false)
        navigate(`${NavigationPathKey.COMPANY_VIEW}/${id}`)
        return
      }

      const data: CompanyResponse = await createCompany(companyData as CompanyRequest)
      if (imageFile) {
        await postImage({
          id: String(data.id),
          file: imageFile,
          type: ImageType.COMPANY,
        })
      }
      setIsFormDirty(false)
      setLoadingStatus(LoadingStatus.SUCCESS)

      navigate(`${NavigationPathKey.COMPANY_VIEW}/${data.id}`)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * Load data function
   * Should be executed once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (id) {
        const data = await getCompany(id)

        setCompanyData({
          active: data.active,
          country: data.country,
          description: data.description,
          image: getImage(ImageType.COMPANY, id),
          name: data.name,
          note: data.note,
          id: data.id,
        })
      }
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling the Discard button click
   */
  const onDiscard = (): void => {
    handleNavigate(() => navigate(-1))
  }

  return {
    onSubmit,
    handleDrop,
    onDiscard,
    loadData,
    t,
    image,
    title,
    companyGeneralFormFields,
  }
}
