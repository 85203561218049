import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {Stack, styled} from '@mui/material'

export const AddIcon = styled(AddCircleOutlineIcon)(() => ({
  fontSize: 30,
}))

export const SubtractIcon = styled(RemoveCircleOutlineIcon)(() => ({
  fontSize: 30,
}))

export const InputWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 2,
}))
