import colors from '@colors'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import {FormGroup, FormControlLabel, Checkbox, Stack, CheckboxProps} from '@mui/material'

import React from 'react'

import {LabeledWrapper} from '../wrapper-with-label'
import {ErrorIndicator, FormGroupContent} from './style'

export type CheckboxItem = {
  label: string
  checked: boolean
} & CheckboxProps

export interface CheckboxGroupProps {
  label?: string
  width?: number
  itemsInColumn?: number
  checkboxes: CheckboxItem[]
  error?: boolean
  disabled?: boolean
}

/**
 * Checkbox Group component with an error component
 */
export function CheckboxGroup(props: CheckboxGroupProps) {
  const {label, width, checkboxes, itemsInColumn = 3, error, disabled} = props

  const checkboxesWithError = [...checkboxes, {label: 'error', checked: false}]
  const columns = Math.ceil(checkboxesWithError.length / itemsInColumn)

  return (
    <LabeledWrapper label={label} width={width}>
      <FormGroup>
        <FormGroupContent>
          <Stack direction={'row'} gap={3}>
            {[...Array(columns)].map((_, index) => (
              <Stack key={index} spacing={1}>
                {checkboxesWithError
                  .slice(index * itemsInColumn, (index + 1) * itemsInColumn)
                  .map((checkbox, checkboxIndex) => {
                    const {label, ...rest} = checkbox
                    if (label === 'error')
                      return (
                        error && (
                          <ErrorIndicator>
                            <ErrorOutlineRoundedIcon style={{color: colors.error.main}} />
                          </ErrorIndicator>
                        )
                      )
                    return (
                      <FormControlLabel
                        key={`checkbox-group-item-${label}-${checkboxIndex}`}
                        name={label}
                        control={<Checkbox {...rest} disabled={disabled} />}
                        label={label}
                      />
                    )
                  })}
              </Stack>
            ))}
          </Stack>
        </FormGroupContent>
      </FormGroup>
    </LabeledWrapper>
  )
}
