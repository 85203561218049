import {
  ContractRequest,
  ContractResponse,
  getCompany,
  getContract,
  getContractType,
  getDealer,
  updateContract,
  handleAxiosError,
  sendForSigning,
} from '@api'
import {
  FormFieldItem,
  generatePdf,
  getImageBase64,
  getStatusText,
  getUserData,
  getUserTimeFormat,
  isSuperAdmin,
} from '@common'
import {AppContext} from '@contexts'
import {
  ContractStatus,
  FormFieldType,
  ImageType,
  LoadingStatus,
  NavigationPathKey,
  NotificationMethod,
  SigningMethod,
  TranslationKey,
} from '@enums'

import {useContext, useMemo, useState} from 'react'

import {formatDate, isAfter, parse} from 'date-fns'
import jsPDF from 'jspdf'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import {UIKit} from '@components'

import {generateHtml} from '../contract-form/contract-form-steps/contract-pdf-overview/contract-overview-html'
import {initialSigningFormData} from '../contract-form/initial-data'
import {SigningFormData} from '../contract-form/types'

export function useContractViewModel() {
  const {t} = useTranslation()
  const {id} = useParams()
  const navigate = useNavigate()
  const [pdfUrl, setPdfUrl] = useState<string>('')
  const [contractData, setContractData] = useState<ContractResponse>()
  const [pdfDoc, setPdfDoc] = useState<jsPDF | undefined>(undefined)
  const [isSignatureOptionsModalVisible, setIsSignatureOptionsModalVisible] = useState<boolean>(false)
  const [signingFormData, setSigningFormData] = useState<SigningFormData>(initialSigningFormData)
  const {setLoadingStatus, setError} = useContext(AppContext)
  const currentUser = getUserData()
  const userTimeFormat = getUserTimeFormat(currentUser.id)

  /**
   * Contract form field configuration
   */
  const contractOverviewFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Status),
      text: contractData?.status ? getStatusText(t, contractData?.status) : '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Contract code']),
      text: contractData?.contractCode || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Created at']),
      text: contractData?.createdAt
        ? formatDate(contractData.createdAt, getUserTimeFormat(String(currentUser?.id)))
        : '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(
        TranslationKey[contractData?.status !== ContractStatus.SENT_FOR_SIGNING ? 'Valid until' : 'Sent for signing'],
      ),
      text:
        contractData?.status !== ContractStatus.SENT_FOR_SIGNING
          ? contractData?.expiry
            ? formatDate(contractData.expiry, getUserTimeFormat(String(currentUser?.id)))
            : '-'
          : contractData.updatedAt
            ? formatDate(contractData.updatedAt, getUserTimeFormat(String(currentUser?.id)))
            : '-',
    },
  ]

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async () => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (!id) throw new Error('no ID')
      const data = await getContract(id)
      const company = await getCompany(data.companyId)
      const dealer = await getDealer(data.dealerId)
      const contractType = await getContractType(data.contractTypeId)

      const imageUrl = await getImageBase64(ImageType.COMPANY, data.companyId)
      setContractData(data)
      const doc = await generatePdf(
        generateHtml(
          t,
          {
            company,
            customer: data.customer,
            contractType,
            quote: data.status === ContractStatus.QUOTE,
            dealer,
            disclaimer: contractType.disclaimer,
            extraCosts: data.extraCosts,
            length: data.length,
            products: data.products,
            signatory: data.officialSignatory,
            expiry: data.expiry,
            createdAt: formatDate(data.createdAt, userTimeFormat),
          },
          imageUrl,
        ),
      )
      setPdfDoc(doc)
      const pdfBlob = doc.output('blob')
      const pdfUrl = URL.createObjectURL(pdfBlob)
      setPdfUrl(pdfUrl)

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function checking if a date if after today
   * @param dateString Date
   * @returns If date is after today
   */
  const checkDateAfterToday = (dateString: string) => {
    const inputDate = parse(dateString, getUserTimeFormat(String(currentUser?.id)), new Date())

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    return isAfter(inputDate, today)
  }

  /**
   * A function handling Cancel button click
   */
  const onCancelBtnClick = async () => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (!id) throw new Error('no contract ID')
      await updateContract({...contractData, status: ContractStatus.CANCELED} as ContractRequest, id)

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling singing form fields change
   * @param event Change event
   * @param checked Checked state for checkbox form field
   */
  const onChangeSigningFormField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean,
  ) => {
    const fieldName = event.target.name
    const value = checked ?? event.target.value
    setSigningFormData(prev => ({...prev, [fieldName]: value}))
  }

  /**
   * Notification method dropdown options
   */
  const notificationMethodOptions: UIKit.DropdownItem[] = [
    {
      label: t(TranslationKey.Email),
      value: NotificationMethod.EMAIL,
    },
    {
      label: t(TranslationKey.SMS),
      value: NotificationMethod.SMS,
    },
  ]

  /**
   * Signing method dropdown options
   */
  const signingMethodOptions: UIKit.DropdownItem[] = [
    {
      value: SigningMethod.EMAIL,
      label: t(TranslationKey.Email),
    },
    {
      value: SigningMethod.SMS,
      label: t(TranslationKey.SMS),
    },
    {
      value: SigningMethod.BANKID_NO,
      label: `${t(TranslationKey['Bank ID'])} NO`,
    },
    {
      value: SigningMethod.BANKID_SE,
      label: `${t(TranslationKey['Bank ID'])} SE`,
    },
  ]

  /**
   * Siginig form fields configuration
   */
  const signingFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Message),
      name: 'greeting',
      onChange: onChangeSigningFormField,
      value: signingFormData.greeting,
    },
    {
      fieldType: FormFieldType.DROPDOWN,
      label: t(TranslationKey['Notification method']),
      name: 'notificationMethod',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeSigningFormField(event),
      value: signingFormData.notificationMethod,
      items: notificationMethodOptions,
    },
    {
      fieldType: FormFieldType.DROPDOWN,
      label: t(TranslationKey['Signing method']),
      name: 'signingMethod',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeSigningFormField(event),
      value: signingFormData.signingMethod,
      items: signingMethodOptions,
    },
    {
      fieldType: FormFieldType.CHECKBOX_GROUP,
      name: 'sms',
      itemsInColumn: 1,
      checkboxes: [
        {
          checked: signingFormData.sms,
          label: t(TranslationKey['Send SMS notification']),
          value: signingFormData.sms,
          name: 'sms',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeSigningFormField(event, checked),
        },
      ],
    },
  ]

  /**
   * A function handling Send for signing button clicks
   */
  const onSendForSigningBtnClick = async () => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (!pdfDoc) throw new Error('no pdf doc')
      if (!contractData) throw new Error('no contract data')
      const {customer} = contractData
      const {email, firstName, lastName, phone} = customer

      await sendForSigning({
        file: pdfDoc?.output('arraybuffer'),
        documentName: `${customer.firstName} ${customer.lastName} ${t(TranslationKey.Contract)}`,
        contractId: contractData?.id || '',
        greeting: signingFormData.greeting,
        recipientDto: {
          email: email || '',
          familyName: lastName,
          givenName: firstName,
          language: signingFormData.language,
          notificationMethod: signingFormData.notificationMethod,
          order: 1,
          role: {
            action: 'sign',
            label: 'Signer',
            name: 'signer',
          },
          signingMethod: signingFormData.signingMethod,
          sms: signingFormData.sms,
          telephone: phone || '',
        },
      }).then(() => {
        if (contractData.id) updateContract({...contractData, status: ContractStatus.SENT_FOR_SIGNING}, contractData.id)
      })
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling Download button click
   */
  const onDownloadBtnClick = () => {
    if (pdfUrl) {
      const link = document.createElement('a')
      link.href = pdfUrl
      link.download = `${contractData?.customer.firstName} ${contractData?.customer.lastName} - ${t(TranslationKey.Contract)}`
      link.click()
    }
  }

  /**
   * A function handling Edit button click
   */
  const onEditBtnClick = () => navigate(`${NavigationPathKey.CONTRACT_CREATION}/${id}`)

  /**
   * A function handling Approve button click
   */
  const onApproveBtnClick = () => setIsSignatureOptionsModalVisible(true)

  /**
   * Approve button is enabled for Active contracts and Super Administrator users
   */
  const isApproveBtnEnabled = useMemo(
    () => contractData?.status === ContractStatus.ACTIVE && isSuperAdmin(currentUser),
    [contractData],
  )

  /**
   * Edit button is enabled if Contract is not Sent for signing and not expired
   */
  const isEditBtnEnabled = useMemo(
    () => contractData?.status !== ContractStatus.SENT_FOR_SIGNING && !checkDateAfterToday(contractData?.expiry || ''),
    [contractData],
  )

  /**
   * Cancel button is enabled if Contract is not already Sent, Signed or Cancelled
   */
  const isCancelBtnEnabled = useMemo(
    () =>
      contractData?.status !== ContractStatus.SENT_FOR_SIGNING &&
      contractData?.status !== ContractStatus.CANCELED &&
      contractData?.status !== ContractStatus.SIGNED,
    [contractData],
  )

  return {
    loadData,
    onDownloadBtnClick,
    onEditBtnClick,
    onCancelBtnClick,
    onApproveBtnClick,
    setIsSignatureOptionsModalVisible,
    onSendForSigningBtnClick,
    isApproveBtnEnabled,
    isEditBtnEnabled,
    isCancelBtnEnabled,
    signingFormFields,
    isSignatureOptionsModalVisible,
    pdfUrl,
    contractOverviewFormFields,
    t,
  }
}
