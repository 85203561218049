import {getPersonInfo, handleAxiosError} from '@api'
import {FormFieldItem, phoneMasks} from '@common'
import {AppContext} from '@contexts'
import {FormFieldType, TranslationKey, Country, LoadingStatus, ContractType} from '@enums'

import {SetStateAction, useContext, useMemo} from 'react'

import {useTranslation} from 'react-i18next'

import {ContractFormData, ContractFormFieldErrors, UpdateErrorsType, UpdateFormDataType} from '../../types'

export interface CustomerFormProps {
  formData: ContractFormData
  formFieldErrors: ContractFormFieldErrors
  updateErrors: UpdateErrorsType
  updateFormData: UpdateFormDataType<ContractFormData>
  setFormData: React.Dispatch<SetStateAction<ContractFormData>>
}

export function useCustomerFormModel(props: CustomerFormProps) {
  const {formData, formFieldErrors, updateErrors, updateFormData, setFormData} = props
  const {setLoadingStatus, setError} = useContext(AppContext)
  const {t} = useTranslation()

  const isB2B = useMemo(
    () => formData.contractSetupData.contractType?.type === ContractType.B2B,
    [formData.contractSetupData],
  )

  /**
   * Dynamic generation of phone input mask
   */
  const phoneMask: string = useMemo(() => {
    const country = formData.contractSetupData.company?.country

    if (!country) return '###############'
    return phoneMasks[country]
  }, [formData])

  /**
   * A function handling Get Person Data button click
   */
  const onGetPersonDataBtnClick = async () => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (!formData.contractSetupData.company?.country) throw new Error('No country')
      if (!formData.customerData.personalNumber) throw new Error('No person number')

      const data = await getPersonInfo({
        country: formData.contractSetupData.company?.country,
        personNumber: formData.customerData.personalNumber,
      })

      setFormData(prev => ({
        ...prev,
        customerData: {
          ...prev.customerData,
          firstName: data.firstName,
          lastName: data.lastName,
          city: data.town,
          zipCode: data.zipCode,
          street: data.address,
        },
      }))

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling Customer form date form field change
   * @param fieldName Field name
   * @param newValue New date value
   */
  const onChangeDateFormField = (fieldName: string, newValue: string | null) => {
    onChangeCustomerFormField(undefined, fieldName, newValue)
  }

  /**
   * A function handling Customer form field change
   * @param event Change event
   * @param overrideFieldName Field name
   * @param newValue New value
   */
  const onChangeCustomerFormField = (
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    overrideFieldName?: string,
    newValue?: string | null,
  ) => {
    const fieldName = event ? event.target.name : overrideFieldName || ''
    const value = event ? event.target.value : newValue

    updateErrors(
      'customerFormFieldErrors',
      formFieldErrors.customerFormFieldErrors.filter(item => item !== fieldName),
    )
    updateFormData('customerData', fieldName, value)
  }

  /**
   * Personal number form field configuration
   */
  const personalNumberFormField: FormFieldItem = {
    fieldType: FormFieldType.INPUT,
    label: t(TranslationKey[isB2B ? 'Company number' : 'Personal number']),
    value: formData.customerData.personalNumber,
    name: 'personalNumber',
    onChange: onChangeCustomerFormField,
    error: formFieldErrors.customerFormFieldErrors.includes('personalNumber'),
  }

  /**
   * Search button should be visible only for some countries
   */
  const isSearchBtnVisible = useMemo(
    () =>
      [Country.SWEDEN, Country.NORWAY, Country.DENMARK].includes(
        formData.contractSetupData.company?.country as Country,
      ) && !isB2B,
    [formData],
  )

  /**
   * Customer form fields configuration
   */
  const customerFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey[isB2B ? 'Company name' : 'First name']),
      value: formData.customerData.firstName,
      name: 'firstName',
      onChange: onChangeCustomerFormField,
      error: formFieldErrors.customerFormFieldErrors.includes('firstName'),
    },
    ...(isB2B
      ? []
      : [
          {
            fieldType: FormFieldType.INPUT,
            label: t(TranslationKey['Last name']),
            value: formData.customerData.lastName,
            name: 'lastName',
            onChange: onChangeCustomerFormField,
            error: formFieldErrors.customerFormFieldErrors.includes('lastName'),
          },
          {
            fieldType: FormFieldType.DATE_PICKER,
            label: t(TranslationKey['Date of birth']),
            name: 'dateOfBirth',
            onChange: (value: string) => onChangeDateFormField('dateOfBirth', value),
            value: formData.customerData.dateOfBirth,
            error: formFieldErrors.customerFormFieldErrors.includes('dateOfBirth'),
          },
        ]),
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Email),
      value: formData.customerData.email,
      name: 'email',
      onChange: onChangeCustomerFormField,
      error: formFieldErrors.customerFormFieldErrors.includes('email'),
    },
    {
      fieldType: FormFieldType.PHONE_INPUT,
      label: t(TranslationKey.Phone),
      value: formData.customerData.phone,
      mask: phoneMask,
      name: 'phone',
      onChange: onChangeCustomerFormField,
      error: formFieldErrors.customerFormFieldErrors.includes('phone'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Street),
      value: formData.customerData.street,
      name: 'street',
      onChange: onChangeCustomerFormField,
      error: formFieldErrors.customerFormFieldErrors.includes('street'),
    },
    ...(isSearchBtnVisible
      ? [
          {
            fieldType: FormFieldType.INPUT,
            label: t(TranslationKey['House number']),
            value: formData.customerData.houseNumber,
            name: 'houseNumber',
            onChange: onChangeCustomerFormField,
            error: formFieldErrors.customerFormFieldErrors.includes('houseNumber'),
          },
        ]
      : []),
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Zip code']),
      value: formData.customerData.zipCode,
      name: 'zipCode',
      onChange: onChangeCustomerFormField,
      error: formFieldErrors.customerFormFieldErrors.includes('zipCode'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.City),
      value: formData.customerData.city,
      name: 'city',
      onChange: onChangeCustomerFormField,
      error: formFieldErrors.customerFormFieldErrors.includes('city'),
    },
  ]

  /**
   * Search button should be disabled if there is no personal number
   */
  const isSearchBtnDisabled = useMemo(() => !formData.customerData.personalNumber, [formData])

  return {
    customerFormFields,
    isSearchBtnVisible,
    isSearchBtnDisabled,
    personalNumberFormField,
    t,
    onGetPersonDataBtnClick,
  }
}
