import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material'

import React, {ReactNode} from 'react'

import {StyledButton} from './style'

type ButtonProps = {
  buttonText: string
  onClick: () => void
}

interface Props {
  open: boolean
  title: string
  content: ReactNode
  cancelButtonProps?: ButtonProps
  confirmButtonProps: ButtonProps
}

/**
 * Modal component
 */
export function Modal(props: Props) {
  const {title, content, cancelButtonProps, confirmButtonProps, open} = props
  return (
    <Dialog open={open} onClose={() => undefined}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {cancelButtonProps && (
          <StyledButton onClick={cancelButtonProps.onClick} variant="outlined">
            {cancelButtonProps.buttonText}
          </StyledButton>
        )}
        <StyledButton onClick={confirmButtonProps.onClick} variant="contained">
          {confirmButtonProps.buttonText}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}
