import colors from '@colors'
import {Stack, styled} from '@mui/material'

export const DropzoneWrapper = styled(Stack)(({theme}) => ({
  height: '200px',
  border: `1px dashed ${theme.palette.grey[100]}`,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '1rem',
  cursor: 'pointer',
}))

export const iconStyle = {
  fontSize: '3rem',
}

export const disabledTextStyle = {
  color: colors.gray['2'],
}

export const disabledIconStyle = {
  fontSize: '3rem',
  color: colors.gray['2'],
}

export const errorIconStyle = {
  fontSize: '2rem',
  color: colors.error.main,
}

export const codeWrapperStyle = {
  border: `1px dashed ${colors.gray[1]}`,
  borderRadius: '1rem',
  fontSize: '0.8rem',
  textAlign: 'left' as const,
  padding: '1rem',
}

export const IllustrationWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  paddingBottom: '3rem',
}))
