import {TranslationKey} from '@enums'
import {Grid} from '@mui/material'
import {PageWrapper} from '@wrappers'

import React from 'react'

import {onImageError, renderFormField, useLoadOnce} from 'common'

import {ComposedComponents, UIKit} from '@components'

import {ImageWrapper} from '../user-form/style'
import {imageGridItemStyle, imageStyle} from './style'
import {useUserViewModel} from './user-view-model'

/**
 * User view page
 */

export function UserViewPage() {
  const {userGeneralFormFields, userPermissionsFormFields, title, image, t, loadData, onPressBack, onPressEdit} =
    useUserViewModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={title}>
      <UIKit.BackgroundCard>
        <Grid container spacing={2}>
          <Grid style={imageGridItemStyle} item sm={4}>
            <ImageWrapper>
              <img src={image} alt="User image" style={imageStyle} onError={onImageError} />
            </ImageWrapper>
          </Grid>

          <Grid item sm={4}>
            <Grid container spacing={2}>
              {userGeneralFormFields.map((item, index) => (
                <Grid sm={12} item key={`user-form-general-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item sm={4}>
            <Grid container spacing={2}>
              {userPermissionsFormFields.map((item, index) => (
                <Grid sm={12} item key={`user-permissions-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </UIKit.BackgroundCard>

      <ComposedComponents.FormButtons
        leftButton={{
          label: t(TranslationKey.Back),
          onClick: onPressBack,
        }}
        rightButton={{label: t(TranslationKey.Edit), onClick: onPressEdit}}
      />
    </PageWrapper>
  )
}
