import colors from '@colors'
import {Stack, styled} from '@mui/material'

export const ContentWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: '1rem',
}))

export const TextWrapper = styled(Stack)(() => ({
  gap: '0.5rem',
  alignItems: 'flex-start',
  paddingTop: '1rem',
  width: '15rem',
}))

export const imageStyle = {
  height: '80px',
  width: '80px',
  objectFit: 'cover' as const,
  borderRadius: '0.5rem',
  border: `1px solid ${colors.gray[2]}`,
}

export const FullContentWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const StepperWrapper = styled(Stack)(() => ({
  flex: 1,
  paddingTop: '1.1rem',
}))
