import {Box, Button, Stack, styled} from '@mui/material'

export const StyledButton = styled(Button)(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 10,
  textTransform: 'unset',
  height: '3rem',
  border: '1px solid transparent',
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[200]}`,
  },
}))

export const Avatar = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: '2rem',
  width: '2.25rem',
  height: '2.25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledStack = styled(Stack)(() => ({
  width: 250,
  alignItems: 'center',
  gap: '1rem',
  flexDirection: 'row',
}))

export const imageStyle = {
  width: '2.25rem',
  height: '2.25rem',
  objectFit: 'cover' as const,
  borderRadius: '50%',
}

export const menuSlotStyle = {width: 266}
