import {useLoadOnce} from '@common'
import {TranslationKey} from '@enums'
import {PageWrapper, SearchTableWrapper} from '@wrappers'

import React from 'react'

import {ComposedComponents, UIKit} from '@components'

import {LogsTab, useLogsSearchModel} from './logs-search-model'

/**
 * Logs Search page
 */
export function LogsSearchPage() {
  const {errorColumns, activityColumns, activeTab, tabButtons, errorRows, activityRows, t, loadData} =
    useLogsSearchModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={t(TranslationKey['Logs search'])}>
      <ComposedComponents.TabButtons activeTab={activeTab} buttons={tabButtons} />
      <SearchTableWrapper>
        {activeTab === LogsTab.ACTIVITY ? (
          <UIKit.Table
            hidePagination
            columns={activityColumns}
            rows={activityRows}
            title={t(TranslationKey['Activity logs'])}
          />
        ) : (
          <UIKit.Table hidePagination columns={errorColumns} rows={errorRows} title={t(TranslationKey['Error logs'])} />
        )}
      </SearchTableWrapper>
    </PageWrapper>
  )
}
