import {Backdrop, CircularProgress, Stack, styled, Typography} from '@mui/material'

export const StyledBackdrop = styled(Backdrop)(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
  '&.MuiBackdrop-invisible': {
    pointerEvents: 'none',
  },
}))

export const StyledProgress = styled(CircularProgress)(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  width: '3rem',
  height: '3rem',
  borderRadius: '4rem',
}))

export const LoaderWrapper = styled(Stack)(({theme}) => ({
  flexDirection: 'row',
  backgroundColor: theme.palette.common.white,
  borderRadius: '4rem',
  alignItems: 'center',
  padding: '0.5rem',
}))

export const LoaderText = styled(Typography)(() => ({
  paddingLeft: '0.75rem',
  paddingRight: '0.25rem',
  fontWeight: 'bold',
}))
