import {ContractExtraCost, ContractItem} from '@api'
import {ContractVariant, ContractStatus, SigningLanguage, NotificationMethod, SigningMethod} from '@enums'

import {
  ContractFormDropdownOptions,
  ContractFormFieldErrors,
  SetupData,
  ContractFormData,
  SigningFormData,
} from './types'

export const initialDropdownOptions: ContractFormDropdownOptions = {
  dealerOptions: [],
  companyOptions: [],
  contractTypeOptions: [],
  productOptions: [],
  accessoryOptions: [],
}

export const initialFormFieldErrors: ContractFormFieldErrors = {
  contractSetupFormFieldErrors: [],
  customerFormFieldErrors: [],
  productFormFieldErrors: [[]],
  extraCostsFormFieldErrors: [],
}

export const initialItemData: ContractItem = {
  id: undefined,
  productNo: '',
  name: '',
  quantity: 1,
  installationCompensation: undefined,
  price: undefined,
  residualValue: undefined,
  servicePrice: undefined,
  deliveryDate: '',
  insurancePrice: undefined,
  amortization: 0,
  annuity: 0,
  averageFunctionAndInterest: 0,
  totalCost: 0,
  serialNumber: '',
}

export const initialSetupData: SetupData = {
  companies: [],
  dealers: [],
  contractTypes: [],
  accessories: [],
  products: [],
}

export const initialFormData: ContractFormData = {
  contractSetupData: {
    type: ContractVariant.CREATION,
    length: 60,
    company: undefined,
    dealer: undefined,
    contractType: undefined,
    creditCheckPassed: false,
    contractStatus: ContractStatus.DRAFT,
    expiry: undefined,
    createdAt: undefined,
    officialSignatory: undefined,
    contractCode: undefined,
  },
  customerData: {
    firstName: '',
    lastName: '',
    city: '',
    dateOfBirth: '',
    email: '',
    phone: '',
    street: '',
    zipCode: '',
    personalNumber: '',
  },
  productData: [
    {
      ...initialItemData,
      productId: undefined,
      accessories: [],
    },
  ],
  extraCosts: [],
}

export const initialExtraCostsData: ContractExtraCost = {
  label: '',
  value: 0,
}

export const initialSigningFormData: SigningFormData = {
  greeting: '',
  language: SigningLanguage.EN_US,
  notificationMethod: NotificationMethod.EMAIL,
  signingMethod: SigningMethod.EMAIL,
  sms: false,
}
