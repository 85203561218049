import {
  CompanyResponse,
  createDealer,
  DealerData,
  DealerRequest,
  DealerResponse,
  getCompanies,
  getCompany,
  getDealer,
  LoggedInUser,
  updateDealer,
  handleAxiosError,
} from '@api'
import {AppContext} from '@contexts'
import {Country, FormFieldType, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'

import {useContext, useMemo, useState} from 'react'

import {
  FormFieldItem,
  generateDropdownOptions,
  generateReadableErrorMessages,
  getUserCompany,
  getUserData,
  isCompanyAdmin,
  isSuperAdmin,
  phoneMasks,
  validateFields,
} from 'common'
import {DropdownItem} from 'components/ui-kit'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

/**
 * Initial data
 */
const initialDealerData: DealerData = {
  name: '',
  customerNo: '',
  email: '',
  address: '',
  city: '',
  companyNo: '',
  dealerNo: '',
  officialSignatories: [
    {
      dateOfBirth: '',
      email: '',
      name: '',
      personalNumber: '',
    },
  ],
  phone: '',
  vatNumber: '',
  invoiceEmail: '',
  invoicePhone: '',
  zipCode: '',
  companyId: null,
  active: true,
  allowedContractTypes: [],
}

export function useDealerFormModel() {
  const {setLoadingStatus, setError, setIsFormDirty, handleNavigate} = useContext(AppContext)
  const [dealerData, setDealerData] = useState<DealerData>(initialDealerData)
  const [dealerFormFieldsErrors, setDealerFormFieldsErrors] = useState<string[]>([])
  const [companies, setCompanies] = useState<DropdownItem[]>([])
  const [companiesRawData, setCompaniesRawData] = useState<CompanyResponse[]>([])
  const navigate = useNavigate()
  const currentUser: LoggedInUser = getUserData()
  const {id} = useParams()
  const {t} = useTranslation()

  /**
   * Dynamically generated title text for Dealer Form page
   */
  const title = useMemo(
    () => (id ? `${t(TranslationKey['Edit dealer'])} - ${dealerData.name}` : t(TranslationKey['New dealer'])),
    [dealerData.name],
  )

  /**
   * Countries that require a personal number for official signatory
   */
  const personalNumberCountries = [Country.SWEDEN, Country.NORWAY, Country.FINLAND, Country.DENMARK]

  /**
   * A function handling Dealer form fields change
   * @param event Change event
   * @param checked Checked state for checkbox form fields
   */
  const onChangeDealerFormField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean,
  ) => {
    setIsFormDirty(true)
    const fieldName = event.target.name
    const value = checked ?? event.target.value

    setDealerFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    setDealerData(prev => ({...prev, [fieldName]: value}))
  }

  /**
   * A function handling Dealer dropdown form field change
   * @param fieldName Field name
   * @param item Dropdown item
   */
  const onChangeDealerDropdownFormField = (fieldName: string, item: DropdownItem | null) => {
    setIsFormDirty(true)
    setDealerFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    setDealerData(prev => ({...prev, [fieldName]: item?.value ?? null}))
  }

  /**
   * Dynamically generated phone input mask
   */
  const phoneMask: string = useMemo(() => {
    const country = companiesRawData.find(company => company.id === dealerData.companyId)?.country

    if (!country) return '###############'
    return phoneMasks[country]
  }, [dealerData])

  /**
   * A function handling Official signatory form fields change
   * @param index Official signatory section index
   * @param event Change event
   * @param date Date value
   * @param overrideFieldName Field name
]   */
  const onChangeOfficialSignatoryFormField = (
    index: number,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    date?: string | null,
    overrideFieldName?: string,
  ) => {
    setIsFormDirty(true)
    const fieldName = overrideFieldName ?? event?.target.name
    const value = date ?? event?.target.value

    if (!fieldName || value === undefined) return

    setDealerFormFieldsErrors(prev => prev.filter(item => item !== `officialSignatories[${index}].${fieldName}`))
    setDealerData(prev => ({
      ...prev,
      officialSignatories: prev.officialSignatories.map((officialSignatory, i) => {
        return i === index ? {...officialSignatory, [fieldName]: value} : officialSignatory
      }),
    }))
  }

  /**
   * Dealer form fields configuration - general section
   */
  const dealerFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Dealer name']),
      name: 'name',
      onChange: onChangeDealerFormField,
      value: dealerData.name,
      error: dealerFormFieldsErrors.includes('name'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Dealer number']),
      name: 'dealerNo',
      onChange: onChangeDealerFormField,
      value: dealerData.dealerNo,
      error: dealerFormFieldsErrors.includes('dealerNo'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['VAT number']),
      name: 'vatNumber',
      onChange: onChangeDealerFormField,
      value: dealerData.vatNumber,
      error: dealerFormFieldsErrors.includes('vatNumber'),
    },
    {
      fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
      name: 'companyId',
      label: t(TranslationKey['Amplio partner']),
      value: dealerData.companyId || undefined,
      items: companies,
      error: dealerFormFieldsErrors.includes('companyId'),
      onChange: (item: DropdownItem | null) => onChangeDealerDropdownFormField('companyId', item),
      disabled: true,
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Company number']),
      name: 'companyNo',
      onChange: onChangeDealerFormField,
      value: dealerData.companyNo,
      error: dealerFormFieldsErrors.includes('companyNo'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Customer number']),
      name: 'customerNo',
      onChange: onChangeDealerFormField,
      value: dealerData.customerNo,
      error: dealerFormFieldsErrors.includes('customerNo'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Email),
      name: 'email',
      onChange: onChangeDealerFormField,
      value: dealerData.email,
      error: dealerFormFieldsErrors.includes('email'),
    },
    {
      fieldType: FormFieldType.PHONE_INPUT,
      label: t(TranslationKey.Phone),
      name: 'phone',
      mask: phoneMask,
      onChange: onChangeDealerFormField,
      value: dealerData.phone,
      error: dealerFormFieldsErrors.includes('phone'),
    },
    {
      fieldType: FormFieldType.CHECKBOX_GROUP,
      name: 'active',
      label: t(TranslationKey.Active),
      checkboxes: [
        {
          checked: dealerData.active,
          label: t(TranslationKey.Active),
          value: dealerData.active,
          name: 'active',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeDealerFormField(event, checked),
        },
      ],
    },
  ]

  /**
   * Dealer form fields configuration - contact section
   */
  const dealerContactFormFields = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Invoice email']),
      name: 'invoiceEmail',
      onChange: onChangeDealerFormField,
      value: dealerData.invoiceEmail,
      error: dealerFormFieldsErrors.includes('invoicePhone'),
    },
    {
      fieldType: FormFieldType.PHONE_INPUT,
      label: t(TranslationKey['Invoice phone']),
      name: 'invoicePhone',
      mask: phoneMask,
      onChange: onChangeDealerFormField,
      value: dealerData.invoicePhone,
      error: dealerFormFieldsErrors.includes('invoiceEmail'),
    },
    {} as FormFieldItem,
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Address),
      name: 'address',
      onChange: onChangeDealerFormField,
      value: dealerData.address,
      error: dealerFormFieldsErrors.includes('address'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Zip code']),
      name: 'zipCode',
      onChange: onChangeDealerFormField,
      value: dealerData.zipCode,
      error: dealerFormFieldsErrors.includes('zipCode'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.City),
      name: 'city',
      onChange: onChangeDealerFormField,
      value: dealerData.city,
      error: dealerFormFieldsErrors.includes('city'),
    },
  ]

  /**
   * Official signatory form fields configuration
   * @param index Official signatory section index
   * @returns Official signatory form fields
   */
  const dealerOfficialSignatoryFormFields = (index: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Name),
      name: 'name',
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => onChangeOfficialSignatoryFormField(index, event),
      value: dealerData.officialSignatories[index].name,
      error: dealerFormFieldsErrors.includes(`officialSignatories[${index}].name`),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Email),
      name: 'email',
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => onChangeOfficialSignatoryFormField(index, event),
      value: dealerData.officialSignatories[index].email,
      error: dealerFormFieldsErrors.includes(`officialSignatories[${index}].email`),
    },
    ...(personalNumberCountries.includes(
      companiesRawData.find(item => item.id === dealerData.companyId)?.country as Country,
    )
      ? [
          {
            fieldType: FormFieldType.INPUT,
            label: t(TranslationKey['Personal number']),
            name: 'personalNumber',
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => onChangeOfficialSignatoryFormField(index, event),
            value: dealerData.officialSignatories[index].personalNumber,
            error: dealerFormFieldsErrors.includes(`officialSignatories[${index}].personalNumber`),
          },
        ]
      : [
          {
            fieldType: FormFieldType.DATE_PICKER,
            label: t(TranslationKey['Date of birth']),
            name: 'dateOfBirth',
            onChange: (value: string) => onChangeOfficialSignatoryFormField(index, undefined, value, 'dateOfBirth'),
            value: dealerData.officialSignatories[index].dateOfBirth,
            error: dealerFormFieldsErrors.includes(`officialSignatories[${index}].dateOfBirth`),
          },
        ]),
  ]

  /**
   * A custom validation function for the Dealer form fields
   * @returns If any Dealer form field is invalid
   */
  const validateData = (): boolean => {
    const notRequiredFields: string[] = []

    const notRequiredSignatoryField = personalNumberCountries.includes(
      companiesRawData.find(company => company.id === dealerData.companyId)?.country as Country,
    )
      ? 'dateOfBirth'
      : 'personalNumber'

    const invalidValues = validateFields(dealerData)
      .filter(item => !notRequiredFields.includes(item))
      .filter(item => !item.includes(notRequiredSignatoryField))

    const isFormInvalid = invalidValues.length > 0
    if (isFormInvalid) {
      setError({
        message: generateReadableErrorMessages(t, invalidValues),
        name: '',
      })

      setDealerFormFieldsErrors(invalidValues)
    }
    return isFormInvalid
  }

  /**
   * A function handling the Add Official signatory button click
   */
  const onAddOfficialSignatory = () => {
    setDealerData(prev => ({
      ...prev,
      officialSignatories: [
        ...prev.officialSignatories,
        {
          name: '',
          dateOfBirth: '',
          email: '',
          personalNumber: '',
        },
      ],
    }))
  }

  /**
   * A function handling the Remove Official signatory button click
   * @param index Official signatory section index
   */
  const onRemoveOfficialSignatory = (index: number) => {
    setDealerData(prev => ({
      ...prev,
      officialSignatories: prev.officialSignatories.filter((_, i) => i !== index),
    }))
    setDealerFormFieldsErrors(prev => prev.filter(item => item.includes(`officialSignatory[${index}]`)))
  }

  /**
   * A function handling the Save button click
   * If form is valid, will update or save a new Dealer
   * @returns void
   */
  const onSubmit = async (): Promise<void> => {
    if (validateData()) return

    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (id) {
        await updateDealer(id, dealerData as DealerRequest)
        setLoadingStatus(LoadingStatus.SUCCESS)
        setIsFormDirty(false)
        navigate(`${NavigationPathKey.DEALER_VIEW}/${id}`)
        return
      }

      const data: DealerResponse = await createDealer(dealerData as DealerRequest)
      setIsFormDirty(false)
      setLoadingStatus(LoadingStatus.SUCCESS)

      navigate(`${NavigationPathKey.DEALER_VIEW}/${data.id}`)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (id) {
        const data: DealerResponse = await getDealer(id)

        setDealerData({
          active: data.active,
          address: data.address,
          companyId: data.companyId,
          customerNo: data.customerNo,
          email: data.email,
          name: data.name,
          id: data.id,
          city: data.city,
          companyNo: data.companyNo,
          dealerNo: data.dealerNo,
          officialSignatories: data.officialSignatories || [],
          allowedContractTypes: data.allowedContractTypes,
          phone: data.phone,
          invoiceEmail: data.invoiceEmail,
          invoicePhone: data.invoicePhone,
          vatNumber: data.vatNumber,
          zipCode: data.zipCode,
        })
      } else if (isSuperAdmin(currentUser) && getUserCompany(currentUser.id)) {
        setDealerData(prev => ({...prev, companyId: getUserCompany(currentUser.id)}))
      }

      // Company Administrators can only create Dealer profiles for their Company
      else if (isCompanyAdmin(currentUser)) {
        setDealerData(prev => ({...prev, companyId: currentUser.companyId}))
      }

      let companyData: CompanyResponse[] = []

      // Super Administrator can get ALL the Companies
      if (isSuperAdmin(currentUser)) {
        companyData = await getCompanies()
      }
      // Company Administrator can ONLY get their Company
      else {
        companyData = [await getCompany(String(currentUser.companyId))]
      }

      setCompaniesRawData(companyData)
      setCompanies(generateDropdownOptions(companyData))
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling Discard button click
   */
  const onDiscard = (): void => {
    handleNavigate(() => navigate(-1))
  }

  return {
    loadData,
    onDiscard,
    onSubmit,
    onAddOfficialSignatory,
    dealerOfficialSignatoryFormFields,
    onRemoveOfficialSignatory,
    dealerData,
    t,
    title,
    dealerContactFormFields,
    dealerFormFields,
  }
}
