import {ContractContractType} from '@api'
import {createNewFoundItem, formatCurrency, FormFieldItem, getCalculatedPrices, getTotalContractCost} from '@common'
import {FormFieldType, TranslationKey} from '@enums'

import {SetStateAction, useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'

import {UIKit} from '@components'

import {initialExtraCostsData, initialFormData, initialItemData} from '../../initial-data'
import {
  ContractFormData,
  ContractFormDropdownOptions,
  ContractFormFieldErrors,
  SetupData,
  UpdateErrorsType,
  UpdateFormDataType,
} from '../../types'

export type ContractProductFormProps = {
  formData: ContractFormData
  dropdownOptions: ContractFormDropdownOptions
  setupData: SetupData
  formFieldErrors: ContractFormFieldErrors
  setFormData: React.Dispatch<SetStateAction<ContractFormData>>
  updateFormData: UpdateFormDataType<ContractFormData>
  updateErrors: UpdateErrorsType
}

export type SerialNoTempStorage = {
  productIndex: number
  serialNoIndex: number
  value: string
}

export function useContractProductFormModel(props: ContractProductFormProps) {
  const {formData, dropdownOptions, formFieldErrors, setupData, updateFormData, updateErrors, setFormData} = props
  const [isContractTypeInformationModalVisible, setIsContractTypeInformationModalVisible] = useState<boolean>(false)
  const {t} = useTranslation()

  const [serialNoTempStorage, setSerialNoTempStorage] = useState<SerialNoTempStorage[]>(
    formData.productData.flatMap((product, productIndex) =>
      product.serialNumber.split('++').map((serialNo, serialNoIndex) => ({
        productIndex,
        serialNoIndex,
        value: serialNo,
      })),
    ),
  )

  /**
   * Handling Contract Type information modal visiblity
   */
  const handleContractInformationModalVisibility = () => {
    if (formData.contractSetupData.contractType?.note?.length) setIsContractTypeInformationModalVisible(true)
  }

  /**
   * A function handling date form field change
   * @param fieldName Field name
   * @param newValue New value
   * @param index Product index
   * @param accIndex Accessory index
   */
  const onChangeDateFormField = (fieldName: string, newValue: string | null, index: number, accIndex?: number) => {
    accIndex === undefined
      ? onChangeProductFormField(index, undefined, fieldName, newValue)
      : onChangeAccessoryFormField(index, accIndex, undefined, fieldName, newValue)
  }

  /**
   * A function handling contract length form field change
   * @param event Change event
   */
  const onSelectContractLength = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    setFormData(prev => ({
      ...prev,
      contractSetupData: {...prev.contractSetupData, length: Number(value)},
      productData: prev.productData.map(product => ({
        ...getCalculatedPrices(
          product,
          formData.contractSetupData.contractType as ContractContractType,
          Number(value) / 12,
          setupData,
        ),
        accessories: product.accessories.map(accessory => ({
          ...getCalculatedPrices(
            accessory,
            formData.contractSetupData.contractType as ContractContractType,
            Number(value) / 12,
            setupData,
            true,
          ),
        })),
      })),
    }))
  }

  /**
   * A function handling extra costs form fields change
   * @param event Change event
   * @param index Extra costs section index
   */
  const onChangeExtraCostsFormField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const value = event.target.value
    const fieldName = event.target.name

    updateErrors(
      'extraCostsFormFieldErrors',
      formFieldErrors.extraCostsFormFieldErrors.filter(item => item !== `extraCosts[${index}].${fieldName}`),
    )

    setFormData(prev => ({
      ...prev,
      extraCosts: prev.extraCosts.map((cost, i) => {
        return i === index ? {...cost, [fieldName]: value} : cost
      }),
    }))
  }

  /**
   * A function handling Accessory form field change
   * @param index Product index
   * @param accIndex Accessory index
   * @param event Change event
   * @param overrideFieldName Field name
   * @param overrideValue New value
   */
  const onChangeAccessoryFormField = (
    index: number,
    accIndex: number,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    overrideFieldName?: string,
    overrideValue?: string | number | null,
  ) => {
    const value = event ? event.target.value : overrideValue || null
    const fieldName = event ? event.target.name : overrideFieldName || ''

    const newProductFormFieldErrors = [...formFieldErrors.productFormFieldErrors]
    newProductFormFieldErrors[index] = newProductFormFieldErrors[index].filter(item =>
      fieldName === 'id'
        ? !item.includes(`accessories[${accIndex}]`)
        : item !== `accessories[${accIndex}].${fieldName}`,
    )

    updateErrors('productFormFieldErrors', newProductFormFieldErrors)

    setFormData(prev => {
      return {
        ...prev,
        productData: prev.productData.map((product, i) =>
          i === index
            ? {
                ...product,
                accessories: product.accessories.map((accessory, j) =>
                  j === accIndex
                    ? fieldName === 'id'
                      ? createNewFoundItem('accessories', value, setupData, formData.contractSetupData)
                      : getCalculatedPrices(
                          {...accessory, [fieldName]: value},
                          formData.contractSetupData.contractType as ContractContractType,
                          formData.contractSetupData.length / 12,
                          setupData,
                          true,
                        )
                    : accessory,
                ),
              }
            : product,
        ),
      }
    })
  }

  /**
   * A function handling Product form field change
   * @param index Product index
   * @param event Change index
   * @param overrideFieldName Field name
   * @param overrideValue New value
   */
  const onChangeProductFormField = (
    index: number,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    overrideFieldName?: string,
    overrideValue?: string | number | null,
  ) => {
    const value = event ? event.target.value : overrideValue || null
    const fieldName = event ? event.target.name : overrideFieldName || ''

    const newProductFormFieldErrors = [...formFieldErrors.productFormFieldErrors]
    newProductFormFieldErrors[index] =
      fieldName === 'id' ? [] : newProductFormFieldErrors[index].filter(item => item !== fieldName)

    updateErrors('productFormFieldErrors', newProductFormFieldErrors)

    setFormData(prev => {
      return {
        ...prev,
        productData: prev.productData.map((product, i) => {
          return i === index
            ? fieldName === 'id'
              ? {
                  ...createNewFoundItem('products', value, setupData, formData.contractSetupData),
                  accessories: [],
                }
              : {
                  ...getCalculatedPrices(
                    {...product, [fieldName]: value},
                    formData.contractSetupData.contractType as ContractContractType,
                    formData.contractSetupData.length / 12,
                    setupData,
                  ),
                  accessories: product.accessories,
                }
            : product
        }),
      }
    })

    if (fieldName === 'quantity') {
      setSerialNoTempStorage(prev =>
        prev.filter(item => item.productIndex !== index || item.serialNoIndex < Number(value)),
      )
    }
  }

  const onchangeSerialNumberFormField = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    serialNoFieldIndex: number,
  ) => {
    setSerialNoTempStorage(prev =>
      prev.find(item => item.productIndex === index && item.serialNoIndex === serialNoFieldIndex)
        ? prev.map(item =>
            item.productIndex === index && item.serialNoIndex === serialNoFieldIndex
              ? {...item, value: event.target.value}
              : item,
          )
        : [...prev, {productIndex: index, serialNoIndex: serialNoFieldIndex, value: event.target.value}],
    )
  }

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      productData: prev.productData.map((item, index) =>
        serialNoTempStorage.find(temp => temp.productIndex === index)
          ? {
              ...item,
              serialNumber: serialNoTempStorage
                .filter(temp => temp.productIndex === index)
                .map(temp => temp.value)
                .join('++'),
            }
          : item,
      ),
    }))
  }, [serialNoTempStorage])

  /**
   * A function handling Add extra costs button click
   */
  const onAddExtraCostsBtnClick = () => {
    setFormData(prev => ({...prev, extraCosts: [...prev.extraCosts, initialExtraCostsData]}))
  }

  /**
   * A function handling Remove extra costs button click
   * @param extraCostIndex Extra costs section index
   */
  const onRemoveExtraCostsBtnClick = (extraCostIndex: number) => {
    setFormData(prev => ({
      ...prev,
      extraCosts: prev.extraCosts.filter((_, i) => i !== extraCostIndex),
    }))
    updateErrors(
      'extraCostsFormFieldErrors',
      formFieldErrors.extraCostsFormFieldErrors.filter(it => !it.includes(`[${extraCostIndex}]`)),
    )
  }

  /**
   * A function handling Add Product button click
   */
  const onAddProductBtnClick = () => {
    setFormData(prev => ({...prev, productData: [...prev.productData, ...initialFormData.productData]}))
    const newProductFormFieldErrors = [...formFieldErrors.productFormFieldErrors, []]
    updateErrors('productFormFieldErrors', newProductFormFieldErrors)
  }

  /**
   * A function handling Add Accessory button click
   * @param productIndex Product index
   */
  const onAddAccessoryBtnClick = (productIndex: number) => {
    setFormData(prev => ({
      ...prev,
      productData: prev.productData.map((item, index) => {
        if (index !== productIndex) return item

        return {
          ...item,
          accessories: [...item.accessories, initialItemData],
        }
      }),
    }))
  }

  /**
   * A function handling Remove Product button click
   * @param index Product index
   */
  const onRemoveProductBtnClick = (index: number) => {
    setFormData(prev => ({
      ...prev,
      productData: prev.productData.filter((_, i) => i !== index),
    }))
    updateErrors(
      'productFormFieldErrors',
      formFieldErrors.productFormFieldErrors.filter((_, i) => i !== index),
    )
  }

  /**
   * A function handling Remove Accessory button click
   * @param productIndex Product index
   * @param accIndex Accessory index
   */
  const onRemoveAccessoryBtnClick = (productIndex: number, accIndex: number) => {
    setFormData(prev => ({
      ...prev,
      productData: prev.productData.map((item, index) => {
        if (index !== productIndex) return item

        return {
          ...item,
          accessories: item.accessories.filter((_, i) => i !== accIndex),
        }
      }),
    }))

    updateErrors(
      'productFormFieldErrors',
      formFieldErrors.productFormFieldErrors.map((item, index) => {
        if (index !== productIndex) return item

        return item.filter(it => !it.includes('acessories'))
      }),
    )
  }

  /**
   * Extra costs form fields configuration
   * @param index Extra costs section index
   * @returns Extra costs form fields
   */
  const extraCostsFormFields = (index: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Label),
      name: 'label',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeExtraCostsFormField(event, index),
      value: formData.extraCosts[index].label,
      error: formFieldErrors.extraCostsFormFieldErrors.includes(`extraCosts[${index}].label`),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Value),
      name: 'value',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeExtraCostsFormField(event, index),
      value: formData.extraCosts[index].value,
      error: formFieldErrors.extraCostsFormFieldErrors.includes(`extraCosts[${index}].value`),
      type: 'number',
    },
  ]

  /**
   * Product form fields configuration - general section
   * @param index Product index
   * @returns Product form fields - general section
   */
  const productGeneralFormFields = (index: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
      label: t(TranslationKey['Product name']),
      name: 'name',
      onChange: (item: UIKit.DropdownItem | null) => onChangeProductFormField(index, undefined, 'id', item?.value),
      value: formData.productData[index].id,
      items: dropdownOptions.productOptions,
      error: formFieldErrors.productFormFieldErrors[index].includes('name'),
      disabled: !!formData.productData[index].productId,
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Quantity),
      name: 'quantity',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeProductFormField(index, event),
      value: formData.productData[index].quantity,
      error: formFieldErrors.productFormFieldErrors[index].includes('quantity'),
      type: 'number',
    },
  ]

  const productSerialNumberFields = (index: number): FormFieldItem[] =>
    Array.from({length: formData.productData[index].quantity || 1}).map((item, itemIndex) => ({
      fieldType: FormFieldType.INPUT,
      label: `${t(TranslationKey['Serial number'])}${(formData.productData[index].quantity || 1) > 1 ? ` (${t(TranslationKey.Product)} ${itemIndex + 1})` : ''}`,
      name: 'serialNumber',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onchangeSerialNumberFormField(index, event, itemIndex),
      value: formData.productData[index].serialNumber.split('++')[itemIndex],
      error:
        formFieldErrors.productFormFieldErrors[index].includes('serialNumber') &&
        !formData.productData[index].serialNumber.split('++')[itemIndex],
    }))

  /**
   * Product form fields configuration - price section
   * @param index Product index
   * @returns Product form fields - price section
   */
  const productPriceFormFields = (index: number) => [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Product price']),
      disabled: formData.contractSetupData.contractType?.lockFields || !!formData.productData[index].productId,
      currency: true,
      name: 'price',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeProductFormField(index, event),
      value: formData.productData[index].price,
      error: formFieldErrors.productFormFieldErrors[index].includes('price'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Residual value']),
      disabled: formData.contractSetupData.contractType?.lockFields || !!formData.productData[index].productId,
      currency: true,
      name: 'residualValue',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeProductFormField(index, event),
      value: formData.productData[index].residualValue,
      error: formFieldErrors.productFormFieldErrors[index].includes('residualValue'),
      type: 'number',
    },
    ...(formData.contractSetupData.contractType?.displayServicePrice
      ? [
          {
            fieldType: FormFieldType.INPUT,
            label: `${t(TranslationKey['Service price'])} (${t(TranslationKey['Per year'])})`,
            disabled: formData.contractSetupData.contractType?.lockFields || !!formData.productData[index].productId,
            currency: true,
            name: 'servicePrice',
            onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              onChangeProductFormField(index, event),
            value: formData.productData[index].servicePrice,
            error: formFieldErrors.productFormFieldErrors[index].includes('servicePrice'),
            type: 'number',
          },
        ]
      : []),
  ]

  /**
   * Product form fields configuration - rest section
   * @param index Product index
   * @returns Product form fields - rest fields
   */
  const productRestFormFields = (index: number) => [
    ...(formData.contractSetupData.contractType?.displayInstallationCosts
      ? [
          {
            fieldType: FormFieldType.INPUT,
            label: t(TranslationKey['Installation compensation']),
            disabled: formData.contractSetupData.contractType?.lockFields || !!formData.productData[index].productId,
            currency: true,
            name: 'installationCompensation',
            onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              onChangeProductFormField(index, event),
            value: formData.productData[index].installationCompensation,
            error: formFieldErrors.productFormFieldErrors[index].includes('installationCompensation'),
            type: 'number',
          },
        ]
      : []),
    {
      fieldType: FormFieldType.DATE_PICKER,
      label: t(TranslationKey['Delivery date']),
      name: 'deliveryDate',
      onChange: (value: string) => onChangeDateFormField('deliveryDate', value, index),
      value: formData.productData[index].deliveryDate,
      error: formFieldErrors.productFormFieldErrors[index].includes('deliveryDate'),
    },
  ]

  /**
   * Price form fields configuration
   * @param index Product index
   * @param accIndex Accessory index
   * @returns Price form fields
   */
  const priceInformationFormFields = (index: number, accIndex?: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.LABEL,
      text: formatCurrency(
        (accIndex !== undefined
          ? formData.productData[index].accessories[accIndex].totalCost / formData.contractSetupData.length
          : formData.productData[index].totalCost / formData.contractSetupData.length) /
          (accIndex !== undefined
            ? formData.productData[index].accessories[accIndex].quantity || 1
            : formData.productData[index].quantity || 1),
      ),
      label: `${t(TranslationKey['Monthly fee'])} (${t(TranslationKey['Per item'])})`,
    },
    {
      fieldType: FormFieldType.LABEL,
      text: formatCurrency(
        (accIndex !== undefined
          ? formData.productData[index].accessories[accIndex].totalCost
          : formData.productData[index].totalCost) /
          (accIndex !== undefined
            ? formData.productData[index].accessories[accIndex].quantity || 1
            : formData.productData[index].quantity || 1),
      ),
      label: `${t(TranslationKey['Total cost'])} (${t(TranslationKey['Per item'])})`,
    },
  ]

  /**
   * Contract length form field configuration
   */
  const contractLengthFormField: FormFieldItem = {
    fieldType: FormFieldType.DROPDOWN,
    label: t(TranslationKey['Contract length']),
    name: 'length',
    onChange: onSelectContractLength,
    value: formData.contractSetupData.length,
    items: formData.contractSetupData.contractType?.lengths
      ? formData.contractSetupData.contractType?.lengths?.map(item => ({
          label: `${item} ${t(TranslationKey.Months)}`,
          value: item,
        }))
      : [],
    error: formFieldErrors.contractSetupFormFieldErrors.includes('length'),
  }

  /**
   * Official signatory form field configuration
   */
  const officialSignatoryFormField: FormFieldItem = {
    fieldType: FormFieldType.DROPDOWN,
    label: t(TranslationKey['Official signatory']),
    name: 'officialSignatory',
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      updateFormData('contractSetupData', 'officialSignatory', event.target.value),
    value: formData.contractSetupData.officialSignatory,
    items: formData.contractSetupData.dealer?.officialSignatories.map(item => ({
      label: item.name,
      value: item.name,
    })),
    error: formFieldErrors.contractSetupFormFieldErrors.includes('officialSignatory'),
  }

  /**
   * Contract total price form fields configuration
   */
  const contractTotalPriceFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      text: formatCurrency(
        getTotalContractCost(formData.productData, formData.extraCosts) / formData.contractSetupData.length,
      ),
      label: t(TranslationKey['Monthly fee']),
    },
    {
      fieldType: FormFieldType.LABEL,
      text: formatCurrency(getTotalContractCost(formData.productData, formData.extraCosts)),
      label: t(TranslationKey['Total cost']),
    },
  ]

  /**
   * Acessory form fields configuration - general section
   * @param index Product index
   * @param accIndex Accessory index
   * @returns Acessory form fields - general section
   */
  const accessoryFormFields = (index: number, accIndex: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
      label: t(TranslationKey['Product name']),
      name: 'name',
      onChange: (item: UIKit.DropdownItem | null) =>
        onChangeAccessoryFormField(index, accIndex, undefined, 'id', item?.value),

      value: formData.productData[index].accessories[accIndex].id,
      items:
        dropdownOptions.accessoryOptions.find(item => item.productId === formData.productData[index].id)?.options || [],
      error: formFieldErrors.productFormFieldErrors[index].includes(`accessories[${accIndex}].name`),
      disabled: !!formData.productData[index].accessories[accIndex].accessoryId,
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Quantity),
      name: 'quantity',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeAccessoryFormField(index, accIndex, event),
      value: formData.productData[index].accessories[accIndex].quantity,
      error: formFieldErrors.productFormFieldErrors[index].includes(`accessories[${accIndex}].quantity`),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Serial number']),
      name: 'serialNumber',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeAccessoryFormField(index, accIndex, event),
      value: formData.productData[index].accessories[accIndex].serialNumber,
      error: formFieldErrors.productFormFieldErrors[index].includes(`accessories[${accIndex}].serialNumber`),
      disabled: !!formData.productData[index].accessories[accIndex].accessoryId,
    },
  ]

  /**
   * Acessory form fields configuration - price section
   * @param index Product index
   * @param accIndex Accessory index
   * @returns Acessory form fields - price section
   */
  const accessoryPriceFormFields = (index: number, accIndex: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Product price']),
      disabled:
        formData.contractSetupData.contractType?.lockFields ||
        !!formData.productData[index].accessories[accIndex].accessoryId,
      currency: true,
      name: 'price',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeAccessoryFormField(index, accIndex, event),
      value: formData.productData[index].accessories[accIndex].price,
      error: formFieldErrors.productFormFieldErrors[index].includes(`accessories[${accIndex}].price`),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Residual value']),
      disabled:
        formData.contractSetupData.contractType?.lockFields ||
        !!formData.productData[index].accessories[accIndex].accessoryId,
      currency: true,
      name: 'residualValue',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeAccessoryFormField(index, accIndex, event),
      value: formData.productData[index].accessories[accIndex].residualValue,
      error: formFieldErrors.productFormFieldErrors[index].includes(`accessories[${accIndex}].residualValue`),
      type: 'number',
    },
    ...(formData.contractSetupData.contractType?.displayServicePrice
      ? [
          {
            fieldType: FormFieldType.INPUT,
            label: t(TranslationKey['Service price']),
            disabled:
              formData.contractSetupData.contractType?.lockFields ||
              !!formData.productData[index].accessories[accIndex].accessoryId,
            currency: true,
            name: 'servicePrice',
            onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              onChangeAccessoryFormField(index, accIndex, event),
            value: formData.productData[index].accessories[accIndex].servicePrice,
            error: formFieldErrors.productFormFieldErrors[index].includes(`accessories[${accIndex}].servicePrice`),
            type: 'number',
          },
        ]
      : []),
  ]

  /**
   * Acessory form fields configuration - rest section
   * @param index Product index
   * @param accIndex Accessory index
   * @returns Acessory form fields - rest section
   */
  const accessoryRestFormFields = (index: number, accIndex: number): FormFieldItem[] => [
    ...(formData.contractSetupData.contractType?.displayInstallationCosts
      ? [
          {
            fieldType: FormFieldType.INPUT,
            label: t(TranslationKey['Installation compensation']),
            disabled:
              formData.contractSetupData.contractType?.lockFields ||
              !!formData.productData[index].accessories[accIndex].accessoryId,
            currency: true,
            name: 'installationCompensation',
            onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              onChangeAccessoryFormField(index, accIndex, event),
            value: formData.productData[index].accessories[accIndex].installationCompensation,
            error: formFieldErrors.productFormFieldErrors[index].includes(
              `accessories[${accIndex}].installationCompensation`,
            ),
            type: 'number',
          },
        ]
      : []),
    {
      fieldType: FormFieldType.DATE_PICKER,
      label: t(TranslationKey['Delivery date']),
      name: 'deliveryDate',
      onChange: (value: string) => onChangeDateFormField('deliveryDate', value, index, accIndex),
      value: formData.productData[index].deliveryDate,
      error: formFieldErrors.productFormFieldErrors[index].includes(`accessories[${accIndex}].servicePrice`),
    },
  ]

  return {
    productGeneralFormFields,
    productPriceFormFields,
    productRestFormFields,
    accessoryFormFields,
    priceInformationFormFields,
    extraCostsFormFields,
    onAddAccessoryBtnClick,
    onRemoveAccessoryBtnClick,
    onAddProductBtnClick,
    onRemoveProductBtnClick,
    onAddExtraCostsBtnClick,
    onRemoveExtraCostsBtnClick,
    setIsContractTypeInformationModalVisible,
    handleContractInformationModalVisibility,
    accessoryPriceFormFields,
    accessoryRestFormFields,
    productSerialNumberFields,
    officialSignatoryFormField,
    isContractTypeInformationModalVisible,
    contractTotalPriceFormFields,
    contractLengthFormField,
    formData,
    t,
  }
}
