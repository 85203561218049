import {
  CompanyResponse,
  ContractResponse,
  ContractTypeResponse,
  createContract,
  creditCheck,
  DealerResponse,
  getCompanies,
  getCompany,
  getContract,
  getContractType,
  getContractTypes,
  getContractTypesByCompany,
  getDealer,
  getDealers,
  getDealersByCompany,
  getProducts,
  getProductsByCompany,
  LoggedInUser,
  ProductResponse,
  sendForSigning,
  updateContract,
  handleAxiosError,
  ContractRequest,
  reserveContractCode,
} from '@api'
import {
  contractSteps,
  generateDropdownOptions,
  generatePdf,
  generateReadableErrorMessages,
  getImageBase64,
  getTotalContractCost,
  getUserCompany,
  getUserData,
  getUserTimeFormat,
  isCompanyAdmin,
  isSuperAdmin,
  validateFields,
} from '@common'
import {AppContext} from '@contexts'
import {
  ContractStatus,
  ContractType,
  ContractVariant,
  ImageType,
  LoadingStatus,
  NavigationPathKey,
  TranslationKey,
} from '@enums'

import {useContext, useMemo, useState} from 'react'

import {addDays, formatDate, parse} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import {generateHtml} from './contract-form-steps/contract-pdf-overview/contract-overview-html'
import {
  initialFormFieldErrors,
  initialFormData,
  initialSigningFormData,
  initialDropdownOptions,
  initialSetupData,
} from './initial-data'
import {
  ContractFormDropdownOptions,
  ContractFormFieldErrors,
  SetupData,
  ContractFormData,
  SigningFormData,
  UpdateFormDataType,
  LookupMapType,
  UpdateErrorsType,
} from './types'

export function useContactFormModel() {
  const [formFieldErrors, setFormFieldErrors] = useState<ContractFormFieldErrors>(initialFormFieldErrors)
  const [formData, setFormData] = useState<ContractFormData>(initialFormData)
  const [signingFormData, setSigningFormData] = useState<SigningFormData>(initialSigningFormData)
  const [isSignatureOptionsModalVisible, setIsSignatureOptionsModalVisible] = useState<boolean>(false)
  const {handleNavigate, setLoadingStatus, setError, setIsFormDirty} = useContext(AppContext)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {id} = useParams()
  const currentUser: LoggedInUser = getUserData()
  const userTimeFormat = getUserTimeFormat(String(currentUser.id))
  const [activeStep, setActiveStep] = useState<number>(0)
  const [dropdownOptions, setDropdownOptions] = useState<ContractFormDropdownOptions>(initialDropdownOptions)
  const [setupData, setSetupData] = useState<SetupData>(initialSetupData)

  const steps = contractSteps(t)

  const isB2B = useMemo(
    () => formData.contractSetupData.contractType?.type === ContractType.B2B,
    [formData.contractSetupData],
  )

  /**
   * A function handling Discard button click
   */
  const onLeftBtnClick = () => handleNavigate((): void => navigate(NavigationPathKey.DASHBOARD))
  /**
   * A function handling Back button click
   */
  const onCenterBtnClick = () => setActiveStep(prev => prev - 1)

  /**
   * A custom validation function the the Contract setup form fields
   * @returns If the Contract setup form fields have invalid values
   */
  const validateSetupFormFields = (): string[] => {
    const notRequredSetupFields = ['contractType', 'expiry', 'length', 'createdAt', 'officialSignatory', 'contractCode']

    let errors: string[] = []
    errors = [...errors, ...validateFields(formData.contractSetupData)].filter(
      item => !notRequredSetupFields.includes(item),
    )
    setFormFieldErrors(prev => ({
      ...prev,
      contractSetupFormFieldErrors: validateFields(formData.contractSetupData).filter(
        item => !notRequredSetupFields.includes(item),
      ),
    }))

    return errors
  }

  /**
   * A custom validation function the the Contract products form fields
   * @returns If the Contract products form fields have invalid values
   */
  const validateProductFormFields = (): string[] => {
    const notRequiredProductFields = [
      'deliveryDate',
      'installationCompensation',
      'updatedAt',
      'updatedBy',
      'createdBy',
      'contractTypePrices',
    ]

    let errors: string[] = []
    errors = [
      ...errors,
      ...formData.productData.map(item => validateFields(item)).flat(),
      ...validateFields({extraCosts: formData.extraCosts}),
    ].filter(item => !notRequiredProductFields.some(notRequiredItem => item.includes(notRequiredItem)))
    setFormFieldErrors(prev => ({
      ...prev,
      extraCostsFormFieldErrors: validateFields({extraCosts: formData.extraCosts}),
      productFormFieldErrors: formData.productData.map(item =>
        validateFields(item).filter(
          item => !notRequiredProductFields.some(notRequiredItem => item.includes(notRequiredItem)),
        ),
      ),
    }))

    return errors
  }

  /**
   * A custom validation function the the Contract customer form fields
   * @returns If the Contract customer form fields have invalid values
   */
  const validateCustomerFormFields = (): string[] => {
    const notRequiredCustomerFields = [
      'dateOfBirth',
      'phone',
      'street',
      'zipCode',
      'city',
      'creditScore',
      'houseNumber',
    ]

    if (isB2B) notRequiredCustomerFields.push('lastName')

    let errors: string[] = []
    errors = [...errors, ...validateFields(formData.customerData)].filter(
      item => !notRequiredCustomerFields.includes(item),
    )
    setFormFieldErrors(prev => ({
      ...prev,
      customerFormFieldErrors: validateFields(formData.customerData).filter(
        item => !notRequiredCustomerFields.includes(item),
      ),
    }))

    return errors
  }

  /**
   * A function handling Next button click
   */
  const onRightBtnClick = async () => {
    let errors: string[] = []
    switch (activeStep) {
      case 0:
        errors = validateSetupFormFields()
        break
      case 1:
        errors = validateProductFormFields()
        break
      case 2:
        errors = validateCustomerFormFields()
        break
    }

    if (errors.length === 0) {
      switch (activeStep) {
        case 2:
          if (
            formData.contractSetupData.type !== ContractVariant.QUOTE &&
            !formData.contractSetupData.creditCheckPassed &&
            !isB2B
          ) {
            await performCreditCheck()
          } else {
            await assignContractCode()
            setActiveStep(prev => prev + 1)
          }
          break
        case 3:
          if (
            formData.contractSetupData.type === ContractVariant.CREATION &&
            (formData.contractSetupData.creditCheckPassed || isSuperAdmin(currentUser))
          )
            setIsSignatureOptionsModalVisible(true)
          else onSaveContract()
          break
        default:
          setActiveStep(prev => prev + 1)
      }
    } else {
      setError({
        message: generateReadableErrorMessages(t, errors),
        name: '',
      })
    }
  }

  /**
   * Check if the user is at the first step
   * @returns If creation process is at first step
   */
  const isFirstStep = () => activeStep === 0

  /**
   * Back button should be visible if the user is not at the first step
   */
  const isBackBtnVisible = () => activeStep > (id ? 1 : 0)

  /**
   * Check if the user is at the last step
   * @returns If creation process is at last step
   */
  const isLastStep = () => activeStep === steps.length - 1

  /**
   * A function handling the Contract form data change
   * @param dataKey Contract form data key
   * @param fieldName Contract form field name
   * @param newData New value
   */
  const updateFormData: UpdateFormDataType<ContractFormData> = (dataKey, fieldName, newData) => {
    setFormData(prev => ({
      ...prev,
      [dataKey]: {
        ...prev[dataKey],
        [fieldName]: newData,
      },
    }))
  }

  /**
   * A function handling updating the form fields errors
   * @param errorsKey Form fields errors key
   * @param errorList Erorrs array
   */
  const updateErrors: UpdateErrorsType = (errorsKey, errorList) => {
    setFormFieldErrors(prev => ({
      ...prev,
      [errorsKey]: errorList,
    }))
  }

  /**
   * A function returning a specific value from the Setup data
   * @param value Value to look for
   * @returns New value from the Setup data
   */
  const lookupMap: LookupMapType = value => ({
    company: setupData.companies.find(item => item.id === value),
    dealer: setupData.dealers.find(item => item.id === value),
    contractType: setupData.contractTypes.find(item => item.id === value),
    type: value as ContractVariant,
    deliveryDate: value,
    length: value,
  })

  /**
   * A function handling sending a Contract for signing
   * @param contractId Contract ID
   */
  const onSendForSigning = async (contractId: string) => {
    const {customerData} = formData
    const {email, firstName, lastName, phone} = customerData
    const imageUrl = await getImageBase64(ImageType.COMPANY, formData?.contractSetupData?.company?.id || '')
    const file = await generatePdf(
      generateHtml(
        t,
        {
          company: formData.contractSetupData.company as CompanyResponse,
          dealer: formData.contractSetupData.dealer as DealerResponse,
          length: formData.contractSetupData.length,
          contractType: formData.contractSetupData.contractType as ContractTypeResponse,
          quote: formData.contractSetupData.type === ContractVariant.QUOTE,
          customer: customerData,
          products: formData.productData,
          extraCosts: formData.extraCosts,
          signatory: formData.contractSetupData.officialSignatory || '',
          disclaimer: formData.contractSetupData.contractType?.disclaimer || '',
          createdAt: formData.contractSetupData.createdAt ?? formatDate(new Date(), userTimeFormat),
          expiry:
            formData.contractSetupData.expiry ??
            formatDate(
              addDays(
                parse(
                  formData.contractSetupData.createdAt ?? formatDate(new Date(), userTimeFormat),
                  userTimeFormat,
                  new Date(),
                ),
                Number(formData?.contractSetupData?.contractType?.expiry || 7),
              ),
              userTimeFormat,
            ),
        },
        imageUrl,
      ),
    )
    await sendForSigning({
      file: file.output('arraybuffer'),
      documentName: `${formData.contractSetupData.company?.name} - ${customerData.firstName} ${customerData.lastName} ${t(TranslationKey.Contract)} ${formatDate(new Date(), getUserTimeFormat(String(currentUser?.id)))}`,
      contractId,
      greeting: signingFormData.greeting,
      recipientDto: {
        email: email || '',
        familyName: lastName,
        givenName: firstName,
        language: signingFormData.language,
        notificationMethod: signingFormData.notificationMethod,
        order: 1,
        role: {
          action: 'sign',
          label: 'Signer',
          name: 'signer',
        },
        signingMethod: signingFormData.signingMethod,
        sms: signingFormData.sms,
        telephone: phone || '',
      },
    })
  }

  /**
   * A function handling clicking on the Send for signing button
   */
  const onSendForSigningBtnClick = () => {
    setIsSignatureOptionsModalVisible(false)
    onSaveContract()
  }

  /**
   * A function generating a Contract Status based on Contract Variant and Credit check value
   * @returns Contract status
   */
  const getContractStatus = () => {
    if (formData.contractSetupData.type === ContractVariant.QUOTE) return ContractStatus.QUOTE
    else if (!formData.contractSetupData.creditCheckPassed && !isSuperAdmin(currentUser))
      return ContractStatus.AWAITING_VERIFICATION
    else return ContractStatus.ACTIVE
  }

  /**
   * A function handling the Save button click
   * If form is valid updates or creates a new Contract
   */
  const onSaveContract = async (status?: ContractStatus): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      const contractPayloadData: ContractRequest = {
        contractCode: formData.contractSetupData.contractCode || '',
        companyId: formData.contractSetupData.company?.id || '',
        contractTypeId: formData.contractSetupData?.contractType?.id || '',
        creditCheckPassed: formData.contractSetupData.creditCheckPassed,
        customer: formData.customerData,
        dealerId: formData.contractSetupData?.dealer?.id || '',
        extraCosts: formData.extraCosts,
        expiry:
          formData.contractSetupData.expiry ??
          formatDate(
            new Date().setDate(new Date().getDate() + Number(formData?.contractSetupData?.contractType?.expiry || 7)),
            'yyyy-MM-dd',
          ),
        officialSignatory: formData.contractSetupData.officialSignatory || '',
        length: formData.contractSetupData.length,
        note: '',
        products: formData.productData.map(product => ({
          ...product,
          productId: product.id,
          accessories: product.accessories.map(accessory => ({...accessory, accessoryId: accessory.id})),
        })),
        status: status ?? getContractStatus(),
      }
      let data: ContractResponse | undefined
      if (id) data = await updateContract(contractPayloadData, id)
      else data = await createContract(contractPayloadData)
      if (status !== ContractStatus.DRAFT && getContractStatus() === ContractStatus.ACTIVE && data.id) {
        await onSendForSigning(data.id).then(() => {
          if (data.id) updateContract({...contractPayloadData, status: ContractStatus.SENT_FOR_SIGNING}, data.id)
        })
      }
      setIsFormDirty(false)
      navigate(`${NavigationPathKey.CONTRACT_VIEW}/${data.id}`)
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  const assignContractCode = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      const code = await reserveContractCode()
      setFormData(prev => ({...prev, contractSetupData: {...prev.contractSetupData, contractCode: code}}))
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling Customer Credit check
   */
  const performCreditCheck = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (!formData.contractSetupData.company?.country || !formData.customerData.personalNumber) return

      const purchasePrice = getTotalContractCost(formData.productData, formData.extraCosts)

      const creditScore = await creditCheck({
        country: formData.contractSetupData.company?.country,
        personNumber: formData.customerData.personalNumber,
        purchasePrice,
        customer: {
          city: formData.customerData.city || '',
          country: formData.contractSetupData.company.country,
          firstName: formData.customerData.firstName,
          lastName: formData.customerData.lastName,
          street: formData.customerData.street || '',
          zipCode: formData.customerData.zipCode || '',
          houseNumber: formData.customerData.houseNumber || '',
        },
      })

      const code = await reserveContractCode()

      setFormData(prev => ({
        ...prev,
        contractSetupData: {
          ...prev.contractSetupData,
          creditCheckPassed: creditScore.passingCredit,
          contractCode: code,
        },
      }))
      setActiveStep(prev => prev + 1)
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  const company = useMemo(() => formData.contractSetupData.company, [formData])

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      let dealers: DealerResponse[] = []
      let companies: CompanyResponse[] = []
      let contractTypes: ContractTypeResponse[] = []
      let products: ProductResponse[] = []

      if (id) {
        const data = await getContract(id)
        const company = await getCompany(data.companyId)
        const dealer = await getDealer(data.dealerId)
        const contractType = await getContractType(data.contractTypeId)

        setFormData({
          contractSetupData: {
            company,
            contractCode: data.contractCode,
            contractStatus: data.status,
            contractType,
            dealer,
            creditCheckPassed: data.creditCheckPassed,
            length: data.length,
            type: ContractVariant.CREATION,
            expiry: data.expiry,
            createdAt: formatDate(data.createdAt, userTimeFormat),
            officialSignatory: data.officialSignatory,
          },
          customerData: data.customer,
          extraCosts: data.extraCosts,
          productData: data.products.map(product => ({
            ...product,
            id: product.productId,
            accessories: product.accessories.map(accessory => ({...accessory, id: accessory.accessoryId})),
          })),
        })

        setActiveStep(1)
      }

      // Super Administrator can see ALL the data
      if (isSuperAdmin(currentUser)) {
        dealers = await getDealers()
        companies = await getCompanies()
        contractTypes = await getContractTypes()
        products = await getProducts()
      } else {
        // Company Administrator and Company User can see ONLY their Company
        companies = [await getCompany(String(currentUser.companyId))]
        contractTypes = await getContractTypesByCompany(String(currentUser.companyId))
        products = await getProductsByCompany(String(currentUser.companyId))
        if (!id) setFormData(prev => ({...prev, contractSetupData: {...prev.contractSetupData, company: companies[0]}}))
        // Company Administrator can see ONLY their Company's Dealers
        if (isCompanyAdmin(currentUser)) {
          dealers = await getDealersByCompany(String(currentUser.companyId))
        }
        // Company User can see ONLY their Dealer
        else {
          dealers = [await getDealer(String(currentUser.dealerId))]
          if (!id) setFormData(prev => ({...prev, contractSetupData: {...prev.contractSetupData, dealer: dealers[0]}}))
          setDropdownOptions(prev => ({
            ...prev,
            contractTypeOptions: generateDropdownOptions(
              contractTypes.filter(item => item.allowedDealers.filter(dealer => dealer.id === dealers[0].id)),
            ),
          }))
        }
      }

      setSetupData({
        companies,
        dealers,
        contractTypes,
        products,
        accessories: products.flatMap(item => item.accessories),
      })

      setDropdownOptions(prev => ({
        companyOptions: generateDropdownOptions(companies),
        dealerOptions: generateDropdownOptions(dealers),
        contractTypeOptions: prev.contractTypeOptions,
        productOptions: generateDropdownOptions(
          products.map(item => ({...item, name: `${item.productNo} ${item.name}`})),
        ),
        accessoryOptions: products.map(item => ({
          productId: item.id,
          options: generateDropdownOptions(
            item.accessories.map(item => ({...item, name: `${item.productNo} ${item.name}`})),
          ),
        })),
      }))

      if (isSuperAdmin(currentUser)) {
        if (!id)
          updateFormData(
            'contractSetupData',
            'company',
            companies.find(item => item.id === String(getUserCompany(currentUser.id))),
          )
        setDropdownOptions(prev => ({
          ...prev,
          dealerOptions: generateDropdownOptions(
            dealers.filter(item => item.companyId === String(getUserCompany(currentUser.id))),
          ),
        }))
      }
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {
    onRightBtnClick,
    onCenterBtnClick,
    onLeftBtnClick,
    isFirstStep,
    isBackBtnVisible,
    isLastStep,
    loadData,
    setFormData,
    onSendForSigningBtnClick,
    onSaveContract,
    setIsSignatureOptionsModalVisible,
    setSigningFormData,
    updateFormData,
    updateErrors,
    lookupMap,
    setDropdownOptions,
    setupData,
    company,
    signingFormData,
    isSignatureOptionsModalVisible,
    t,
    dropdownOptions,
    formFieldErrors,
    formData,
    activeStep,
    steps,
  }
}
