import {
  createAccessory,
  getAccessory,
  AccessoryData,
  AccessoryRequest,
  updateAccessory,
  CompanyResponse,
  getCompanies,
  getCompany,
  AccessoryResponse,
  handleAxiosError,
  ContractTypeResponse,
  getContractTypesByCompany,
  getContractTypes,
  linkProductToAccessory,
} from '@api'
import {
  validateFields,
  getUserData,
  isSuperAdmin,
  FormFieldItem,
  generateDropdownOptions,
  isCompanyAdmin,
  generateReadableErrorMessages,
  getUserCompany,
} from '@common'
import {AppContext} from '@contexts'
import {TranslationKey, LoadingStatus, FormFieldType, NavigationPathKey} from '@enums'

import React, {useContext, useMemo, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useParams} from 'react-router-dom'

import {UIKit} from '@components'

/**
 * Initial data
 */
const initialAccessoryData: AccessoryData = {
  active: true,
  companyId: null,
  name: '',
  price: 0,
  productNo: '',
  residualValue: 0,
  servicePrice: 0,
  installationCompensation: 0,
  contractTypePrices: [],
}

export function useAccessoryFormModel() {
  const {setError, setLoadingStatus, setIsFormDirty, handleNavigate} = useContext(AppContext)
  const [accessoryData, setAccessoryData] = useState<AccessoryData>(initialAccessoryData)
  const [accessoryFormFieldErrors, setAccessoryFormFieldsErrors] = useState<string[]>([])
  const [companies, setCompanies] = useState<UIKit.DropdownItem[]>([])
  const [contractTypes, setContractTypes] = useState<ContractTypeResponse[]>([])
  const navigate = useNavigate()
  const currentUser = getUserData()
  const {id} = useParams()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const productId = queryParams.get('productId')

  const {t} = useTranslation()

  /**
   * Generates a title for the Accessory screen.
   */
  const title = useMemo(
    () => (id ? `${t(TranslationKey['Edit accessory'])} - ${accessoryData.name}` : t(TranslationKey['New accessory'])),
    [id, accessoryData.name],
  )

  /**
   * Filters the Contract Types to leave only ones with the appropriate Company.
   */
  const contractTypeOptions = useMemo(
    () => contractTypes.filter(item => item.companyId === accessoryData.companyId),
    [accessoryData],
  )

  /**
   * Check if any more Contract Type prices can be added.
   */
  const isAddPriceBtnVisible = useMemo(
    () => contractTypeOptions.length === accessoryData.contractTypePrices.length,
    [accessoryData, contractTypeOptions, contractTypes],
  )

  /**
   * A function that generates a list of Contract Type options excluding already selected ones to avoid duplicate entries
   * @param value The value of the current selection to be included in the options
   */
  const getContractTypeOptions = (value: string) => {
    return accessoryData.companyId
      ? generateDropdownOptions(
          contractTypeOptions.filter(
            item =>
              item.id === value || !accessoryData.contractTypePrices.some(price => price.contractTypeId === item.id),
          ),
        )
      : []
  }

  /**
   * A custom validation function for the Accessory form fields
   * @returns If there are any invalid fields
   */
  const validateData = (): boolean => {
    const invalidValues = validateFields(accessoryData)

    const isFormInvalid = invalidValues.length > 0
    if (isFormInvalid) {
      setError({
        message: generateReadableErrorMessages(t, invalidValues),
        name: '',
      })

      setAccessoryFormFieldsErrors(invalidValues)
    }
    return isFormInvalid
  }

  /**
   * A function that updates the Accessory Contract Type price
   * @param index Index of the Contract Type price item
   * @param event Change event
   */
  const onChangeContractTypePrice = (
    index: number,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsFormDirty(true)
    const fieldName = event?.target.name
    const value = event?.target.value

    if (!fieldName || value === undefined) return

    setAccessoryFormFieldsErrors(prev => prev.filter(item => item !== `contractTypePrices[${index}].${fieldName}`))
    setAccessoryData(prev => ({
      ...prev,
      contractTypePrices: prev.contractTypePrices.map((contractTypePrice, i) => {
        return i === index ? {...contractTypePrice, [fieldName]: value} : contractTypePrice
      }),
    }))
  }

  /**
   * A function that handles Contract Type Override Price form fields change
   * @param index Contract Type Price index
   * @param event Change event
   */
  const onChangeContractTypeOverridePrice = (
    index: number,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsFormDirty(true)
    const fieldName = event?.target.name
    const value = event?.target.value

    if (!fieldName || value === undefined) return

    setAccessoryFormFieldsErrors(prev => prev.filter(item => item !== `contractTypePrices[${index}].${fieldName}`))
    setAccessoryData(prev => ({
      ...prev,
      contractTypePrices: prev.contractTypePrices.map((contractTypePrice, i) => {
        return i === index
          ? {
              ...contractTypePrice,
              override: [
                ...contractTypePrice.override.filter(item => item.contractLength !== Number(fieldName)),
                {contractLength: Number(fieldName), price: Number(value)},
              ],
            }
          : contractTypePrice
      }),
    }))
  }

  /**
   * A function that updates the Contract Type selection for the Accessory Contract Type prices
   * @param index Index of the Contract Type price item
   * @param fieldName Field name being updated
   * @param item Dropdown item
   */
  const onChangeContractType = (index: number, fieldName: string, item: UIKit.DropdownItem | null) => {
    setIsFormDirty(true)
    setAccessoryFormFieldsErrors(prev => prev.filter(item => item !== `contractTypePrices[${index}].${fieldName}`))
    setAccessoryData(prev => ({
      ...prev,
      contractTypePrices: prev.contractTypePrices.map((contractTypePrice, i) => {
        return i === index ? {...contractTypePrice, [fieldName]: item?.value || null} : contractTypePrice
      }),
    }))
  }

  /**
   * A function that updates the Accessory form fields
   * @param event Change event
   * @param checked Checked value for the checkbox inputs
   * @param percentage Boolean value defining if the field is a percentage input
   */
  const onChangeAccessoryGeneralFormField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean,
    percentage?: boolean,
  ): void => {
    setIsFormDirty(true)
    const fieldName = event.target.name
    const value = checked ?? (!percentage || Number(event.target.value) < 100 ? event.target.value : '100')
    setAccessoryFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    setAccessoryData(prev => ({...prev, [fieldName]: value}))
  }

  /**
   * A function that updates the Accesory form fields
   * @param fieldName Name of the field being updated
   * @param item Dropdown item
   */
  const onChangeAccessoryDropdownFormField = (fieldName: string, item: UIKit.DropdownItem | null) => {
    setIsFormDirty(true)
    setAccessoryFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    setAccessoryData(prev => ({...prev, [fieldName]: item?.value ?? null}))
  }

  /**
   * Accessory form fields configuration - General section
   */
  const accessoryGeneralFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Product number']),
      name: 'productNo',
      onChange: onChangeAccessoryGeneralFormField,
      value: accessoryData.productNo,
      error: accessoryFormFieldErrors.includes('productNo'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Product name']),
      name: 'name',
      onChange: onChangeAccessoryGeneralFormField,
      value: accessoryData.name,
      error: accessoryFormFieldErrors.includes('name'),
    },
    {
      fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
      name: 'companyId',
      label: t(TranslationKey['Amplio partner']),
      value: accessoryData?.companyId || undefined,
      items: companies,
      error: accessoryFormFieldErrors.includes('companyId'),
      onChange: (item: UIKit.DropdownItem | null) => onChangeAccessoryDropdownFormField('companyId', item),
      disabled: true,
    },
    {
      fieldType: FormFieldType.CHECKBOX_GROUP,
      name: 'active',
      label: t(TranslationKey.Active),
      checkboxes: [
        {
          checked: accessoryData.active,
          label: t(TranslationKey.Active),
          value: accessoryData.active,
          name: 'active',
          onChange: onChangeAccessoryGeneralFormField,
        },
      ],
    },
  ]

  /**
   * Accessory form fields configuration - Default price section
   */
  const accessoryDefaultPriceFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Price),
      name: 'price',
      currency: true,
      onChange: onChangeAccessoryGeneralFormField,
      value: accessoryData.price,
      error: accessoryFormFieldErrors.includes('price'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Residual value']),
      name: 'residualValue',
      percentage: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeAccessoryGeneralFormField(event, undefined, true),
      value: accessoryData.residualValue,
      error: accessoryFormFieldErrors.includes('residualValue'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Installation compensation']),
      name: 'installationCompensation',
      currency: true,
      onChange: onChangeAccessoryGeneralFormField,
      value: accessoryData.installationCompensation,
      error: accessoryFormFieldErrors.includes('installationCompensation'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Service price']),
      name: 'servicePrice',
      currency: true,
      onChange: onChangeAccessoryGeneralFormField,
      value: accessoryData.servicePrice,
      error: accessoryFormFieldErrors.includes('servicePrice'),
      type: 'number',
    },
  ]

  /**
   * Accessory form fields configuration - Contract Type price section
   * @param index Index of the Contract Type price item
   */
  const accessoryPriceFormFields = (index: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
      name: 'contractTypeId',
      label: t(TranslationKey['Contract type']),
      value: accessoryData?.contractTypePrices[index].contractTypeId || undefined,
      items: getContractTypeOptions(accessoryData.contractTypePrices[index].contractTypeId),
      error: accessoryFormFieldErrors.includes('contractTypeId'),
      onChange: (item: UIKit.DropdownItem | null) => onChangeContractType(index, 'contractTypeId', item),
    },
    {} as FormFieldItem,
    {} as FormFieldItem,
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Price),
      name: 'price',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypePrice(index, event),
      value: accessoryData.contractTypePrices[index].price,
      error: accessoryFormFieldErrors.includes(`contractTypePrices[${index}].price`),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Residual value']),
      name: 'residualValue',
      percentage: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypePrice(index, event),
      value: accessoryData.contractTypePrices[index].residualValue,
      error: accessoryFormFieldErrors.includes(`contractTypePrices[${index}].residualValue`),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Installation compensation']),
      name: 'installationCompensation',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypePrice(index, event),
      value: accessoryData.contractTypePrices[index].installationCompensation,
      error: accessoryFormFieldErrors.includes(`contractTypePrices[${index}].installationCompensation`),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Service price']),
      name: 'servicePrice',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypePrice(index, event),
      value: accessoryData.contractTypePrices[index].servicePrice,
      error: accessoryFormFieldErrors.includes(`contractTypePrices[${index}].servicePrice`),
      type: 'number',
    },
  ]

  /**
   * Accessory form fields configuration - Contract Type price per length section
   * @param index Index of the Contract Type price item
   */
  const accessoryPricePerLengthFormFields = (index: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.INPUT,
      label: `18 ${t(TranslationKey.Months)}`,
      name: '18',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeOverridePrice(index, event),
      error: accessoryFormFieldErrors.includes(`contractTypePrices[${index}].18`),
      value: accessoryData.contractTypePrices[index].override.find(item => item.contractLength === 18)?.price,
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: `24 ${t(TranslationKey.Months)}`,
      name: '24',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeOverridePrice(index, event),
      error: accessoryFormFieldErrors.includes(`contractTypePrices[${index}].24`),
      value: accessoryData.contractTypePrices[index].override.find(item => item.contractLength === 24)?.price,
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: `36 ${t(TranslationKey.Months)}`,
      name: '36',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeOverridePrice(index, event),
      error: accessoryFormFieldErrors.includes(`contractTypePrices[${index}].36`),
      value: accessoryData.contractTypePrices[index].override.find(item => item.contractLength === 36)?.price,
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: `60 ${t(TranslationKey.Months)}`,
      name: '60',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeOverridePrice(index, event),
      error: accessoryFormFieldErrors.includes(`contractTypePrices[${index}].60`),
      value: accessoryData.contractTypePrices[index].override.find(item => item.contractLength === 60)?.price,
      type: 'number',
    },
  ]

  /**
   * Discard button handler
   */
  const onDiscard = (): void => {
    handleNavigate(() => navigate(-1))
  }

  /**
   * Handler for adding a new Contract Type price
   */
  const onAddAccessoryPrice = () => {
    setAccessoryData(prev => ({
      ...prev,
      contractTypePrices: [
        ...prev.contractTypePrices,
        {
          contractTypeId: '',
          price: 0,
          residualValue: 0,
          servicePrice: 0,
          installationCompensation: 0,
          override: [
            {
              contractLength: 18,
              price: 0,
            },
            {
              contractLength: 24,
              price: 0,
            },
            {
              contractLength: 36,
              price: 0,
            },
            {
              contractLength: 60,
              price: 0,
            },
          ],
        },
      ],
    }))
  }

  /**
   * A function that removes a Contract Type price
   * @param index Index of the Contract Type price item
   */
  const onRemoveAccessoryPrice = (index: number) => {
    setAccessoryData(prev => ({
      ...prev,
      contractTypePrices: prev.contractTypePrices.filter((_, i) => i !== index),
    }))
    setAccessoryFormFieldsErrors(prev => prev.filter(item => item.includes(`contractTypePrices[${index}]`)))
  }

  /**
   * A function that handles the Save button click
   * If form is valid updates existing or saves a new Accessory
   */
  const onSubmit = async (): Promise<void> => {
    if (validateData()) return

    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (id) {
        await updateAccessory(id, accessoryData as AccessoryRequest)
        setLoadingStatus(LoadingStatus.SUCCESS)
        setIsFormDirty(false)
        navigate(`${NavigationPathKey.ACCESSORY_VIEW}/${id}`)
        return
      }

      const data: AccessoryResponse = await createAccessory(accessoryData as AccessoryRequest)
      if (productId) await linkProductToAccessory(productId, data.id)
      setIsFormDirty(false)
      setLoadingStatus(LoadingStatus.SUCCESS)

      navigate(`${NavigationPathKey.ACCESSORY_VIEW}/${data.id}`)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * Loading function, retrieves initial data. Should run only once on render.
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      let contractTypeData: ContractTypeResponse[] = []
      if (id) {
        const data: AccessoryResponse = await getAccessory(id)

        setAccessoryData({
          active: data.active,
          companyId: data.companyId,
          price: data.price,
          productNo: data.productNo,
          residualValue: data.residualValue,
          servicePrice: data.servicePrice,
          name: data.name,
          id: data.id,
          contractTypePrices: data.contractTypePrices,
          installationCompensation: data.installationCompensation,
        })
      } else if (isSuperAdmin(currentUser)) {
        setAccessoryData(prev => ({...prev, companyId: getUserCompany(currentUser.id)}))
      } else if (isCompanyAdmin(currentUser)) {
        setAccessoryData(prev => ({...prev, companyId: currentUser.companyId}))
      }

      let companyData: CompanyResponse[] = []

      // Super Administrator can see ALL the data
      if (isSuperAdmin(currentUser)) {
        companyData = await getCompanies()
        contractTypeData = await getContractTypes()
      }
      // Company Administrator can see only their Company
      else {
        companyData = [await getCompany(String(currentUser.companyId))]
        contractTypeData = await getContractTypesByCompany(String(currentUser.companyId))
      }

      setContractTypes(contractTypeData)
      setCompanies(generateDropdownOptions(companyData))
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setError(handleAxiosError(error))
    }
  }

  return {
    loadData,
    onDiscard,
    onAddAccessoryPrice,
    onRemoveAccessoryPrice,
    onSubmit,
    accessoryPricePerLengthFormFields,
    accessoryPriceFormFields,
    t,
    isAddPriceBtnVisible,
    accessoryData,
    title,
    accessoryGeneralFormFields,
    accessoryDefaultPriceFormFields,
  }
}
