import colors from '@colors'
import {Autocomplete, SelectProps, styled} from '@mui/material'
import {CommonProps} from '@mui/material/OverridableComponent'

export const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: prop => prop !== 'hideBorder' && prop !== 'customStyle',
})<SelectProps & {hideBorder?: boolean; customStyle?: CommonProps['style']}>(({theme, hideBorder, customStyle}) => ({
  ...customStyle,
  ...(hideBorder && {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.white,
    },
  }),
}))

export const iconStyle = {
  color: colors.gray[2],
  marginRight: -30,
}

export const adornmentStyle = {
  marginRight: '-25px',
  marginTop: '-5px',
}
