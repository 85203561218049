import {APIMethod} from '@enums'

import {fetchData} from 'api/api'
import {ItemSignature} from 'common/types'

import {ProductPriceData, ProductResponse} from './product'

interface BaseAccessory {
  productNo: string
  name: string
  price: number
  residualValue: number
  servicePrice: number
  installationCompensation: number
  active: boolean
  companyId: string | null
  contractTypePrices: ProductPriceData[]
}

export interface AccessoryData extends BaseAccessory {
  id?: string
}

export interface AccessoryRequest extends BaseAccessory {
  companyId: string
}

export interface AccessoryResponse extends BaseAccessory, ItemSignature {
  id: string
  products: ProductResponse[]
}

/**
 * @returns All Accessories
 */
export const getAccessories = async (): Promise<AccessoryResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: 'accessory',
  })
}

/**
 * @param id Company ID
 * @returns All Accessories with a specific Company ID
 */
export const getAccessoriesByCompany = async (id: string): Promise<AccessoryResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `accessory/company/${id}`,
  })
}

/**
 * @param id Accessory ID
 * @returns A single Accessory with a specific ID
 */
export const getAccessory = async (id: string): Promise<AccessoryResponse> => {
  return fetchData({
    method: APIMethod.GET,
    url: `accessory/${id}`,
  })
}

/**
 * Updates an existing Accessory.
 * @param id Accessory ID
 * @param body Accessory payload data
 * @returns Updated Accessory data
 */
export const updateAccessory = async (id: string, body: AccessoryRequest): Promise<AccessoryResponse> => {
  return fetchData({
    method: APIMethod.PUT,
    url: `accessory/${id}`,
    body,
  })
}

/**
 * Creates a new Accessory.
 * @param body Accessory payload data
 * @returns New Accessory data
 */
export const createAccessory = async (body: AccessoryRequest): Promise<AccessoryResponse> => {
  return fetchData({
    method: APIMethod.POST,
    url: `accessory`,
    body,
  })
}
