import {NavigationPathKey, TranslationKey} from '@enums'
import {Grid, Stack, Typography} from '@mui/material'
import {PageWrapper, SearchTableWrapper} from '@wrappers'

import React from 'react'

import {renderFormField, useLoadOnce} from 'common'

import {ComposedComponents, UIKit} from '@components'

import {AccessoryTab, useAccessoryViewModel} from './accessory-view-model'

/**
 * Accessory View page
 */
export function AccessoryViewPage() {
  const {
    loadData,
    onPressBack,
    onPressEdit,
    setIsLinkModalShown,
    onLink,
    onCloseLinkModal,
    accessoryOverridePriceFormFields,
    contractTypePrices,
    t,
    linkProductFormField,
    modalText,
    productsRows,
    isEditButtonVisible,
    isLinkModalShown,
    title,
    columns,
    activeTab,
    tabButtons,
    accessoryGeneralFormFields,
    showCreateButton,
    navigate,
    accessoryPriceFormFields,
  } = useAccessoryViewModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={title}>
      <ComposedComponents.TabButtons activeTab={activeTab} buttons={tabButtons} />
      {activeTab === AccessoryTab.GENERAL ? (
        <>
          <UIKit.BackgroundCard title={t(TranslationKey['General information'])}>
            <Grid container spacing={2}>
              {accessoryGeneralFormFields.map((item, index) => (
                <Grid sm={3} item key={`accessory-general-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>

          <UIKit.BackgroundCard title={t(TranslationKey['Price information'])}>
            <Stack gap={2}>
              <UIKit.BackgroundCard title={t(TranslationKey['Default prices'])}>
                <Grid container spacing={2}>
                  {accessoryPriceFormFields().map((item, index) => (
                    <Grid sm={3} item key={`accessory-price-form-field-${index}`}>
                      {renderFormField(item)}
                    </Grid>
                  ))}
                </Grid>
              </UIKit.BackgroundCard>
              {contractTypePrices.map((price, index) => (
                <UIKit.BackgroundCard key={`contract-type-prices-${index}`} title={price.contractType}>
                  <Stack gap={2}>
                    <Grid container spacing={2}>
                      {accessoryPriceFormFields(index).map((item, fieldIndex) => (
                        <Grid sm={3} item key={`accessory-price-form-field-${index}-${fieldIndex}`}>
                          {renderFormField(item)}
                        </Grid>
                      ))}
                    </Grid>
                    <Stack>
                      {accessoryOverridePriceFormFields(index)?.length && (
                        <Stack alignItems={'flex-start'}>
                          <Typography fontWeight={'bold'}>{t(TranslationKey['Custom monthly fees'])}</Typography>
                        </Stack>
                      )}
                      <Grid container spacing={2}>
                        {accessoryOverridePriceFormFields(index)?.map((item, fieldIndex) => (
                          <Grid sm={3} item key={`accessory-override-price-form-field-${index}-${fieldIndex}`}>
                            {renderFormField(item)}
                          </Grid>
                        ))}
                      </Grid>
                    </Stack>
                  </Stack>
                </UIKit.BackgroundCard>
              ))}
            </Stack>
          </UIKit.BackgroundCard>

          <ComposedComponents.FormButtons
            leftButton={{
              label: t(TranslationKey.Back),
              onClick: onPressBack,
            }}
            rightButton={{label: t(TranslationKey.Edit), onClick: onPressEdit}}
          />
        </>
      ) : (
        <>
          <SearchTableWrapper>
            <UIKit.Table
              {...(isEditButtonVisible && {
                buttonProps: {
                  buttonText: t(TranslationKey['Link a product']),
                  onClick: () => setIsLinkModalShown(true),
                },
              })}
              {...(showCreateButton && {
                additionalButtonProps: {
                  buttonText: t(TranslationKey['Add new product']),
                  onClick: () => navigate(NavigationPathKey.PRODUCT_CREATION),
                },
              })}
              columns={columns}
              rows={productsRows}
              title={t(TranslationKey['Linked products'])}
            />
          </SearchTableWrapper>

          <UIKit.Modal
            open={isLinkModalShown}
            title={`${t(TranslationKey['Link a product'])}?`}
            content={
              <Stack alignItems={'center'} gap={2}>
                <Typography>{modalText}</Typography>
                <Stack style={{width: '15rem'}}>{renderFormField(linkProductFormField)}</Stack>
              </Stack>
            }
            confirmButtonProps={{buttonText: t(TranslationKey.Link), onClick: onLink}}
            cancelButtonProps={{buttonText: t(TranslationKey.Cancel), onClick: onCloseLinkModal}}
          />
        </>
      )}
    </PageWrapper>
  )
}
