import {getCurrency} from '@common'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import {
  FilledInputProps,
  IconButton,
  InputAdornment,
  InputProps,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material'
import {CommonProps} from '@mui/material/OverridableComponent'

import React from 'react'

import {NumericFormat} from 'react-number-format'

import {LabeledWrapper} from '../wrapper-with-label/labeled-wrapper'
import {AddIcon, SubtractIcon, SubtractInputWrapper} from './style'

export type InputFieldProps = TextFieldProps & {
  label?: string
  error?: boolean
  percentage?: boolean
  currency?: boolean
  hideLabel?: boolean
  customStyle?: CommonProps['style']
  width?: number
  isSearch?: boolean
  disabledAdd?: boolean
}

/**
 * Input field component with percentage, currency and quantity variants
 */
export function InputField({
  label,
  error,
  hideLabel,
  customStyle,
  width,
  isSearch,
  value,
  percentage,
  currency,
  type,
  disabledAdd,
  ...rest
}: InputFieldProps) {
  const theme = useTheme()

  const getInputProps = () =>
    ({
      style: {
        ...theme.components?.MuiTextField?.defaultProps?.InputProps?.style,
        ...customStyle,
      },
      ...(isSearch && {
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
      }),
      ...(error && {
        endAdornment: (
          <InputAdornment position="end">
            <ErrorOutlineRoundedIcon style={{color: theme.palette.error.main}} />
          </InputAdornment>
        ),
      }),
    }) as Partial<FilledInputProps | OutlinedInputProps | InputProps>

  const handleSubtractClick = () => {
    if (Number(value) === 0) return
    const event = {
      target: {value: value !== undefined ? Number(value) - 1 : '0', name: rest.name},
    } as React.ChangeEvent<HTMLInputElement>
    rest.onChange && rest.onChange(event)
  }

  const handleAddClick = () => {
    const event = {
      target: {value: value !== undefined ? Number(value) + 1 : '0', name: rest.name},
    } as React.ChangeEvent<HTMLInputElement>
    rest.onChange && rest.onChange(event)
  }

  return (
    <LabeledWrapper width={width} label={hideLabel ? undefined : label}>
      {rest.name === 'quantity' ? (
        <SubtractInputWrapper>
          <TextField
            style={{...customStyle, flex: 1}}
            disabled
            placeholder={label}
            type={type}
            inputProps={{style: {...(type === 'number' ? {textAlign: 'right'} : {})}}}
            InputProps={getInputProps()}
            value={value ?? ''}
            {...rest}
          />
          <IconButton onClick={handleSubtractClick}>
            <SubtractIcon />
          </IconButton>
          <IconButton disabled={disabledAdd} onClick={handleAddClick}>
            <AddIcon />
          </IconButton>
        </SubtractInputWrapper>
      ) : currency || percentage ? (
        <NumericFormat
          value={(value as string) ?? ''}
          onValueChange={({floatValue}) => {
            const event = {
              target: {value: floatValue ?? '', name: rest.name},
            } as React.ChangeEvent<HTMLInputElement>
            rest.onChange && rest.onChange(event)
          }}
          thousandSeparator="."
          decimalSeparator=","
          suffix={currency ? ` ${getCurrency()}` : ' %'}
          decimalScale={2}
          isAllowed={({floatValue}) =>
            floatValue === undefined || floatValue <= (currency ? Number.MAX_SAFE_INTEGER : 100)
          }
          fixedDecimalScale
          disabled={rest.disabled}
          allowNegative={false}
          customInput={TextField}
          placeholder={label}
          InputProps={{
            ...getInputProps(),
            inputProps: {style: {textAlign: value !== undefined ? 'right' : 'left'}},
          }}
        />
      ) : (
        <TextField
          style={customStyle}
          placeholder={label}
          type={type}
          inputProps={{style: {...(type === 'number' ? {textAlign: 'right'} : {})}}}
          InputProps={getInputProps()}
          value={value ?? ''}
          {...rest}
        />
      )}
    </LabeledWrapper>
  )
}
