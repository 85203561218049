import {
  CompanyResponse,
  ContractCustomer,
  ContractExtraCost,
  ContractProduct,
  ContractTypeResponse,
  DealerResponse,
} from '@api'
import {FormFieldItem, getUserData, getUserTimeFormat} from '@common'
import {ContractType, ContractVariant, FormFieldType, NotificationMethod, SigningMethod, TranslationKey} from '@enums'

import React, {SetStateAction, useMemo} from 'react'

import {addDays, formatDate, parse} from 'date-fns'
import {useTranslation} from 'react-i18next'

import {UIKit} from '@components'

import {SigningFormData, ContractFormData} from '../../types'

export type ContractPdfOverviewProps = {
  signingFormData: SigningFormData
  formData: ContractFormData
  setSigningFormData: React.Dispatch<SetStateAction<SigningFormData>>
}

export interface ContractHTMLData {
  quote: boolean
  company: CompanyResponse
  dealer: DealerResponse
  contractType: ContractTypeResponse
  signatory: string
  length: number
  customer: ContractCustomer
  products: ContractProduct[]
  extraCosts: ContractExtraCost[]
  disclaimer: string
  expiry: string
  createdAt: string
}

export function useContractPDFOverviewModel(props: ContractPdfOverviewProps) {
  const {t} = useTranslation()
  const {signingFormData, formData, setSigningFormData} = props
  const currentUser = getUserData()
  const userTimeFormat = getUserTimeFormat(String(currentUser.id))

  const isB2B = useMemo(
    () => formData.contractSetupData.contractType?.type === ContractType.B2B,
    [formData.contractSetupData],
  )

  /**
   * A function handling Signing form fields change
   * @param event Change event
   * @param checked Checked state for checkbox form fields
   */
  const onChangeSigningFormField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean,
  ) => {
    const fieldName = event.target.name
    const value = checked ?? event.target.value
    setSigningFormData(prev => ({...prev, [fieldName]: value}))
  }

  /**
   * Notification method dropdown options
   */
  const notificationMethodOptions: UIKit.DropdownItem[] = [
    {
      label: t(TranslationKey.Email),
      value: NotificationMethod.EMAIL,
    },
    {
      label: t(TranslationKey.SMS),
      value: NotificationMethod.SMS,
    },
  ]

  /**
   * Signing method dropdown options
   */
  const signingMethodOptions: UIKit.DropdownItem[] = [
    {
      value: SigningMethod.EMAIL,
      label: t(TranslationKey.Email),
    },
    {
      value: SigningMethod.SMS,
      label: t(TranslationKey.SMS),
    },
    {
      value: SigningMethod.BANKID_NO,
      label: `${t(TranslationKey['Bank ID'])} NO`,
    },
    {
      value: SigningMethod.BANKID_SE,
      label: `${t(TranslationKey['Bank ID'])} SE`,
    },
  ]

  /**
   * Signing form fields configuration
   */
  const signingFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Message),
      name: 'greeting',
      onChange: onChangeSigningFormField,
      value: signingFormData.greeting,
    },
    {
      fieldType: FormFieldType.DROPDOWN,
      label: t(TranslationKey['Notification method']),
      name: 'notificationMethod',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeSigningFormField(event),
      value: signingFormData.notificationMethod,
      items: notificationMethodOptions,
    },
    {
      fieldType: FormFieldType.DROPDOWN,
      label: t(TranslationKey['Signing method']),
      name: 'signingMethod',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeSigningFormField(event),
      value: signingFormData.signingMethod,
      items: signingMethodOptions,
    },
    {
      fieldType: FormFieldType.CHECKBOX_GROUP,
      name: 'sms',
      itemsInColumn: 1,
      checkboxes: [
        {
          checked: signingFormData.sms,
          label: t(TranslationKey['Send SMS notification']),
          value: signingFormData.sms,
          name: 'sms',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeSigningFormField(event, checked),
        },
      ],
    },
  ]

  /**
   * Dynamically generated creation date
   */
  const creationDate = useMemo(
    () => formData.contractSetupData.createdAt ?? formatDate(new Date(), userTimeFormat),
    [formData, userTimeFormat],
  )

  /**
   * Dynamically generated expiry date
   */
  const expiryDate = useMemo(
    () =>
      formData.contractSetupData.expiry ??
      formatDate(
        addDays(
          parse(creationDate, userTimeFormat, new Date()),
          Number(formData?.contractSetupData?.contractType?.expiry || 7),
        ),
        userTimeFormat,
      ),
    [formData, userTimeFormat, creationDate],
  )

  /**
   * Dynamically generated modal text
   */
  const modalMessage = useMemo(() => {
    if (isB2B) {
      return t(
        TranslationKey[
          'Customer Credit score is not checked for B2B contracts. You may complete the contract and set it for signing.'
        ],
      )
    } else if (formData.contractSetupData.type === ContractVariant.QUOTE) {
      return `${t(TranslationKey['Customer Credit score is not checked for Quotes. If saved, Contract will remain in Quote status and can be completed until'])} ${expiryDate}.`
    } else if (!formData.contractSetupData.creditCheckPassed) {
      return `${t(TranslationKey['The Customer has a negative Credit score. To complete the contract, an Amplio Administrator must manually approve it. If you are not an Amplio Administrator, the contract will be saved in status Active and can be completed until'])} ${expiryDate}.`
    } else
      return `${t(TranslationKey['The Customer has a positive Credit score. You may complete the contract and set it for signing.'])}`
  }, [formData])

  /**
   * Contract data needed for HTML generation
   */
  const dataForHtml = useMemo((): ContractHTMLData => {
    return {
      quote: formData.contractSetupData.type === ContractVariant.QUOTE,
      company: formData.contractSetupData.company as CompanyResponse,
      dealer: formData.contractSetupData.dealer as DealerResponse,
      contractType: formData.contractSetupData.contractType as ContractTypeResponse,
      length: formData.contractSetupData.length,
      customer: formData.customerData,
      products: formData.productData,
      extraCosts: formData.extraCosts,
      signatory: formData.contractSetupData.officialSignatory || '',
      disclaimer: formData.contractSetupData.contractType?.disclaimer || '',
      expiry: expiryDate,
      createdAt: creationDate,
    }
  }, [formData])

  return {signingFormFields, t, dataForHtml, modalMessage, formData}
}
