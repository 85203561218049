import {NavigationPathKey, TranslationKey} from '@enums'
import {Grid, Stack, Typography} from '@mui/material'
import {PageWrapper, SearchTableWrapper} from '@wrappers'

import React from 'react'

import {renderFormField, useLoadOnce} from 'common'

import {ComposedComponents, UIKit} from '@components'

import {ProductTab, useProductViewModel} from './product-view-model'

/**
 * Product View page
 */
export function ProductViewPage() {
  const {
    title,
    accessoriesRows,
    columns,
    isEditButtonVisible,
    productGeneralFormFields,
    isLinkModalShown,
    modalText,
    activeTab,
    tabButtons,
    linkAccessoryFormField,
    contractTypePrices,
    t,
    showCreateButton,
    id,
    navigate,
    productPriceFormFields,
    productOverridePriceFormFields,
    onLink,
    setIsLinkModalShown,
    onCloseLinkModal,
    loadData,
    onPressBack,
    onPressEdit,
  } = useProductViewModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={title}>
      <ComposedComponents.TabButtons activeTab={activeTab} buttons={tabButtons} />
      {activeTab === ProductTab.GENERAL ? (
        <>
          <UIKit.BackgroundCard title={t(TranslationKey['General information'])}>
            <Grid container spacing={2}>
              {productGeneralFormFields.map((item, index) => (
                <Grid sm={3} item key={`product-general-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>

          <UIKit.BackgroundCard title={t(TranslationKey['Price information'])}>
            <Stack gap={2}>
              <UIKit.BackgroundCard title={t(TranslationKey['Default prices'])}>
                <Grid container spacing={2}>
                  {productPriceFormFields().map((item, index) => (
                    <Grid sm={3} item key={`product-price-form-field-${index}`}>
                      {renderFormField(item)}
                    </Grid>
                  ))}
                </Grid>
              </UIKit.BackgroundCard>
              {contractTypePrices.map((price, index) => (
                <UIKit.BackgroundCard key={`contract-type-prices-${index}`} title={price.contractType}>
                  <Stack gap={2}>
                    <Grid container spacing={2}>
                      {productPriceFormFields(index).map((item, fieldIndex) => (
                        <Grid sm={3} item key={`product-price-form-field-${index}-${fieldIndex}`}>
                          {renderFormField(item)}
                        </Grid>
                      ))}
                    </Grid>
                    <Stack>
                      {productOverridePriceFormFields(index)?.length && (
                        <Stack alignItems={'flex-start'}>
                          <Typography fontWeight={'bold'}>{t(TranslationKey['Custom monthly fees'])}</Typography>
                        </Stack>
                      )}
                      <Grid container spacing={2}>
                        {productOverridePriceFormFields(index)?.map((item, fieldIndex) => (
                          <Grid sm={3} item key={`product-override-price-form-field-${index}-${fieldIndex}`}>
                            {renderFormField(item)}
                          </Grid>
                        ))}
                      </Grid>
                    </Stack>
                  </Stack>
                </UIKit.BackgroundCard>
              ))}
            </Stack>
          </UIKit.BackgroundCard>

          <ComposedComponents.FormButtons
            leftButton={{
              label: t(TranslationKey.Back),
              onClick: onPressBack,
            }}
            {...(isEditButtonVisible && {
              rightButton: {label: t(TranslationKey.Edit), onClick: onPressEdit},
            })}
          />
        </>
      ) : (
        <>
          <SearchTableWrapper>
            <UIKit.Table
              {...(isEditButtonVisible && {
                buttonProps: {
                  buttonText: t(TranslationKey['Link an accessory']),
                  onClick: () => setIsLinkModalShown(true),
                },
              })}
              {...(showCreateButton && {
                additionalButtonProps: {
                  buttonText: t(TranslationKey['Add new accessory']),
                  onClick: () => navigate(`${NavigationPathKey.ACCESSORY_CREATION}?productId=${id}`),
                },
              })}
              columns={columns}
              rows={accessoriesRows}
              title={t(TranslationKey['Linked accessories'])}
            />
          </SearchTableWrapper>

          <UIKit.Modal
            open={isLinkModalShown}
            title={`${t(TranslationKey['Link an accessory'])}?`}
            content={
              <Stack alignItems={'center'} gap={2}>
                <Typography>{modalText}</Typography>
                <Stack style={{width: '15rem'}}>{renderFormField(linkAccessoryFormField)}</Stack>
              </Stack>
            }
            confirmButtonProps={{buttonText: t(TranslationKey.Link), onClick: onLink}}
            cancelButtonProps={{buttonText: t(TranslationKey.Cancel), onClick: onCloseLinkModal}}
          />
        </>
      )}
    </PageWrapper>
  )
}
