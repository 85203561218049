/* eslint-disable @typescript-eslint/no-explicit-any */
import {getImage, LoggedInUser, UserData, UserResponse} from '@api'
import {
  ContractStatus,
  ContractType,
  ContractVariant,
  ImageType,
  Language,
  LocalStorageItem,
  TranslationKey,
  UserRole,
} from '@enums'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {SvgIcon} from '@mui/material'

import {SyntheticEvent, useEffect, useRef} from 'react'

import {TFunction} from 'i18next'
import jsPDF from 'jspdf'

import {UIKit} from '@components'

import placeholder from '@images/placeholder.png'

import {icons} from './consts'
import {UserCompany, UserLanguage, UserTimeFormat} from './types'

/**
 * A function that executes a passed function only once on component render.
 * @param loadData Function
 */
export function useLoadOnce(loadData: () => void): void {
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      loadData()
      isFirstRender.current = false
    }
  }, [loadData])
}

/**
 * @param type Type of Contract Variant
 * @param creditCheckPassed  Credit Check result
 * @returns An icon based on the Credit Check result and type of Contract Variant.
 */
export const getCreditCheckModalIcon = (
  type: ContractVariant,
  contractType: ContractType,
  creditCheckPassed: boolean,
) => {
  if (type === ContractVariant.QUOTE || contractType === ContractType.B2B)
    return <InfoOutlinedIcon style={{fontSize: 100}} />
  else if (creditCheckPassed) return <CheckCircleOutlineOutlinedIcon style={{fontSize: 100}} />
  return <ErrorOutlineOutlinedIcon style={{fontSize: 100}} />
}

/**
 * @param t Translation function
 * @param status Contract Status
 * @returns Readable text representing the Contract Status.
 */
export const getStatusText = (t: TFunction, status: ContractStatus): string => {
  switch (status) {
    case ContractStatus.QUOTE:
      return t(TranslationKey.Quote)
    case ContractStatus.AWAITING_VERIFICATION:
      return t(TranslationKey['Pending'])
    case ContractStatus.DRAFT:
      return t(TranslationKey.Draft)
    case ContractStatus.SENT_FOR_SIGNING:
      return t(TranslationKey['Sent'])
    case ContractStatus.CANCELED:
      return t(TranslationKey.Cancelled)
    default:
      return t(TranslationKey.Unknown)
  }
}

/**
 * @returns Logged in User
 */
export const getUserData = (): LoggedInUser =>
  JSON.parse(String(localStorage.getItem(LocalStorageItem.AUTHENTICATION)))?.userData || null

export const getToken = (): string =>
  JSON.parse(String(localStorage.getItem(LocalStorageItem.AUTHENTICATION)))?.token || null

export const setAuthenticationStorage = (data: string): void =>
  localStorage.setItem(LocalStorageItem.AUTHENTICATION, data)

export const refreshUserDataAuthStorage = (userData: UserResponse) => {
  const token = getToken()

  setAuthenticationStorage(
    JSON.stringify({
      token,
      userData,
    }),
  )
}

/**
 * @param userUid User ID
 * @returns Locally stored User language selection
 */
export const getUserLanguage = (userUid: string): Language | undefined => {
  return (
    JSON.parse(localStorage.getItem(LocalStorageItem.USER_LANGUAGES) || '[]').find(
      (item: UserLanguage) => item.userUid === userUid,
    )?.language || undefined
  )
}

/**
 * Stores User language selection to local storage
 * @param userLanguage User language payload data
 */
export const setLanguageStorage = (userLanguage: UserLanguage): void => {
  const userLanguages = JSON.stringify([
    ...JSON.parse(localStorage.getItem(LocalStorageItem.USER_LANGUAGES) || '[]').filter(
      (item: UserLanguage) => item.userUid !== userLanguage.userUid,
    ),
    userLanguage,
  ])

  localStorage.setItem(LocalStorageItem.USER_LANGUAGES, userLanguages)
}

/**
 * @param userUid User ID
 * @returns Locally stored User time format selection
 */
export const getUserTimeFormat = (userUid: string): string => {
  return (
    JSON.parse(localStorage.getItem(LocalStorageItem.USER_TIME_FORMAT) || '[]').find(
      (item: UserTimeFormat) => item.userUid === userUid,
    )?.format || 'dd-MM-yyyy'
  )
}

/**
 * Stores User time format selection to local storage
 * @param userTimeFormat User time foramt payload data
 */
export const setTimeFormatStorage = (userTimeFormat: UserTimeFormat): void => {
  const timeFormats = JSON.stringify([
    ...JSON.parse(localStorage.getItem(LocalStorageItem.USER_TIME_FORMAT) || '[]').filter(
      (item: UserTimeFormat) => item.userUid !== userTimeFormat.userUid,
    ),
    userTimeFormat,
  ])

  localStorage.setItem(LocalStorageItem.USER_TIME_FORMAT, timeFormats)
}

/**
 * @param userUid User ID
 * @returns Locally stored User Company selection
 */
export const getUserCompany = (userUid: string): string => {
  return (
    JSON.parse(localStorage.getItem(LocalStorageItem.USER_COMPANIES) || '[]').find(
      (item: UserCompany) => item.userUid === userUid,
    )?.companyUid || ''
  )
}

/**
 * Stores User Company selection to local storage
 * @param userCompany User company payload data
 */
export const setUserCompany = (userCompany: UserCompany): void => {
  const userCompanies = JSON.stringify([
    ...JSON.parse(localStorage.getItem(LocalStorageItem.USER_COMPANIES) || '[]').filter(
      (item: UserCompany) => item.userUid !== userCompany.userUid,
    ),
    userCompany,
  ])

  localStorage.setItem(LocalStorageItem.USER_COMPANIES, userCompanies)
}

/**
 * Stores Company currency to local storage
 * @param currency Currency payload data
 */
export const setCurrency = (currency: string): void => {
  localStorage.setItem(LocalStorageItem.CURRENCY, currency)
}

/**
 * @returns Locally stored Company currency
 */
export const getCurrency = (): string => {
  return localStorage.getItem(LocalStorageItem.CURRENCY) || ''
}

/**
 * @param userData Logged in User data
 * @returns If the User is Super Administrator
 */
export const isSuperAdmin = (userData: UserData) => userData.role === UserRole.SUPER_ADMIN

/**
 * @param userData Logged in User data
 * @returns If the User is Company Administrator
 */
export const isCompanyAdmin = (userData: UserData) => userData.role === UserRole.COMPANY_ADMIN

/**
 * @param userData Logged in User data
 * @returns If the User is Company User
 */
export const isCompanyUser = (userData: UserData) => userData.role === UserRole.COMPANY_USER

/**
 * @param amount Amount to be formatted
 * @returns Formatted amount
 */
export const formatCurrency = (amount: number | string) => {
  const currency = getCurrency()
  const number = Number(amount)
  const nanGuard = isNaN(number) ? 0 : number
  return Intl.NumberFormat('de-DE', {style: 'currency', currency: currency || 'EUR'}).format(nanGuard)
}

/**
 * @param value Amount to be formatted
 * @returns Formatted amount
 */
export const formatPercentage = (value: number | string) => {
  const number = Number(value)
  const nanGuard = isNaN(number) ? 0 : number

  return Intl.NumberFormat('de-DE', {style: 'percent', minimumFractionDigits: 2}).format(nanGuard / 100)
}

/**
 * A function to generate DropdownItem array from a given array
 * @param items Data to be transformed into DropdownItems
 * @param labelProp Prop to be used for label
 * @param valueProp Prop to be used for value
 * @param iconProp Prop to be used for icon
 * @returns An array of DropdownItems
 */
export const generateDropdownOptions = <T extends Record<string, any>>(
  items: T[],
  labelProp?: keyof T,
  valueProp?: keyof T,
  iconProp?: keyof T,
): UIKit.DropdownItem[] =>
  items
    .filter(item => item.active)
    .map(item => ({
      label: String(item[labelProp ?? 'name']),
      value: String(item[valueProp ?? 'id']),
      ...(iconProp && {icon: <SvgIcon> {icons[item[iconProp]]}</SvgIcon>}),
    }))

/**
 * Image fetch error fallback
 * @param e Error event
 */
export const onImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
  const target = e.target as HTMLImageElement
  target.src = placeholder
  target.onerror = null
}

/**
 * A function to generate a PDF from HTML
 * @param html HTML string
 * @returns PDF document
 */
export const generatePdf = async (html: string): Promise<jsPDF> => {
  const doc = new jsPDF({
    format: 'a4',
    orientation: 'portrait',
    unit: 'mm',
  })

  return new Promise(resolve => {
    doc.html(html, {
      async callback(doc: jsPDF) {
        resolve(doc)
      },
      x: 5,
      y: 5,
      html2canvas: {
        scale: 0.225,
      },
    })
  })
}

/**
 * To avoid same-origin errors when fetching images for PDF generation, this function is used to create a new image object
 * @param type Image type, can be USER or COMPANY
 * @param id User ID or Company ID
 * @returns New image object that can be used for PDF generation
 */
export const getImageBase64 = async (type: ImageType, id: string): Promise<string> => {
  try {
    const image = await fetch(getImage(type, id))
    const blob = await image.blob()
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    return placeholder
  }
}
