import React from 'react'

import {LoadingStatus} from 'enums/enums'

import {LoaderText, LoaderWrapper, StyledBackdrop, StyledProgress} from './style'

interface Props {
  loadingStatus: LoadingStatus
  message?: string
}

/**
 * Full screen Loader Overlay component
 */
export function LoaderOverlay(props: Props) {
  const {loadingStatus, message} = props
  return (
    <StyledBackdrop open={loadingStatus === LoadingStatus.LOADING}>
      <LoaderWrapper>
        <StyledProgress />
        {message && <LoaderText>{message}</LoaderText>}
      </LoaderWrapper>
    </StyledBackdrop>
  )
}
