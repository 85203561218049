import {NavigationPathKey, TranslationKey} from '@enums'
import {Grid} from '@mui/material'
import {PageWrapper, SearchTableWrapper} from '@wrappers'

import React from 'react'

import {onImageError, renderFormField, useLoadOnce} from 'common'

import {ComposedComponents, UIKit} from '@components'

import {CompanyTab, useCompanyViewModel} from './company-view-model'
import {imageStyle, ImageWrapper} from './style'

/**
 * Company View page
 */
export function CompanyViewPage() {
  const {
    companyGeneralFormFields,
    isEditButtonVisible,
    companyData,
    title,
    t,
    activeTab,
    tabButtons,
    dealerColumns,
    dealerRows,
    userColumns,
    userRows,
    contractTypeColumns,
    contractTypeRows,
    showCreateButton,
    navigate,
    dealerTableVisiblityModel,
    loadData,
    onPressBack,
    onPressEdit,
  } = useCompanyViewModel()

  useLoadOnce(loadData)
  return (
    <PageWrapper title={title}>
      <ComposedComponents.TabButtons activeTab={activeTab} buttons={tabButtons} />
      {activeTab === CompanyTab.GENERAL && (
        <>
          <Grid container spacing={4}>
            <Grid item sm={3}>
              <UIKit.BackgroundCard>
                <ImageWrapper>
                  <img src={companyData.image} alt="Partner logo" style={imageStyle} onError={onImageError} />
                </ImageWrapper>
              </UIKit.BackgroundCard>
            </Grid>
            <Grid item sm={9}>
              <UIKit.BackgroundCard>
                <Grid container spacing={2}>
                  {companyGeneralFormFields.map((item, index) => (
                    <Grid item sm={6} key={`company-general-form-field-${index}`}>
                      {renderFormField(item)}
                    </Grid>
                  ))}
                </Grid>
              </UIKit.BackgroundCard>
            </Grid>
          </Grid>
        </>
      )}
      {activeTab === CompanyTab.DEALERS && (
        <SearchTableWrapper>
          <UIKit.Table
            {...(showCreateButton && {
              buttonProps: {
                buttonText: t(TranslationKey['Add new dealer']),
                onClick: () => navigate(NavigationPathKey.DEALER_CREATION),
              },
            })}
            visibilityModel={dealerTableVisiblityModel}
            columns={dealerColumns}
            rows={dealerRows}
            title={t(TranslationKey.Dealers)}
          />
        </SearchTableWrapper>
      )}
      {activeTab === CompanyTab.CONTRACT_TYPES && (
        <SearchTableWrapper>
          <UIKit.Table
            {...(showCreateButton && {
              buttonProps: {
                buttonText: t(TranslationKey['Add new contract type']),
                onClick: () => navigate(NavigationPathKey.CONTRACT_TYPE_CREATION),
              },
            })}
            columns={contractTypeColumns}
            rows={contractTypeRows}
            title={t(TranslationKey['Contract types'])}
          />
        </SearchTableWrapper>
      )}
      {activeTab === CompanyTab.USERS && (
        <SearchTableWrapper>
          <UIKit.Table
            {...(showCreateButton && {
              buttonProps: {
                buttonText: t(TranslationKey['Add new user']),
                onClick: () => navigate(NavigationPathKey.USER_CREATION),
              },
            })}
            columns={userColumns}
            rows={userRows}
            title={t(TranslationKey.Users)}
          />
        </SearchTableWrapper>
      )}
      <ComposedComponents.FormButtons
        leftButton={{
          label: t(TranslationKey.Back),
          onClick: onPressBack,
        }}
        {...(isEditButtonVisible && {rightButton: {label: t(TranslationKey.Edit), onClick: onPressEdit}})}
      />
    </PageWrapper>
  )
}
