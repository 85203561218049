import React, {useEffect, useState} from 'react'

import {InputField, InputFieldProps} from '../input-field'
import {LabeledWrapper} from '../wrapper-with-label/labeled-wrapper'
import {InputWrapper} from './style'

export type StackedInputFieldProps = InputFieldProps & {
  total: number
}

/**
 * Stacked input with two input fields for 'residual value' handling
 */
export function StackedInput({label, hideLabel, width, onChange, total, value, ...rest}: StackedInputFieldProps) {
  const [numberValue, setNumberValue] = useState<number>((total / 100) * Number(value))

  useEffect(() => {
    const newValue = (total / 100) * Number(value)
    setNumberValue(newValue > total ? total : newValue)
  }, [total, value])

  const onChangeNumberValue = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = Number(event.target.value) > total ? total : Number(event.target.value)
    const percentageVal = (val / total) * 100
    onChange?.({...event, target: {...event.target, value: String(percentageVal)}})
  }

  return (
    <LabeledWrapper width={width} label={hideLabel ? undefined : label}>
      <InputWrapper>
        <InputField currency value={numberValue} onChange={onChangeNumberValue} {...rest} />
        /
        <InputField percentage value={value} onChange={onChange} {...rest} />
      </InputWrapper>
    </LabeledWrapper>
  )
}
