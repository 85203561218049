import {renderFormField, useLoadOnce} from '@common'
import {TranslationKey} from '@enums'
import {Grid, Stack} from '@mui/material'
import {PageWrapper} from '@wrappers'

import React from 'react'

import {UIKit} from '@components'

import {useContractViewModel} from './contract-view-model'
import {CancelBtn, StyledButton} from './style'

/**
 * Contract View page
 */
export function ContractViewPage() {
  const {
    pdfUrl,
    contractOverviewFormFields,
    signingFormFields,
    isSignatureOptionsModalVisible,
    isApproveBtnEnabled,
    isCancelBtnEnabled,
    isEditBtnEnabled,
    setIsSignatureOptionsModalVisible,
    onSendForSigningBtnClick,
    loadData,
    onCancelBtnClick,
    onEditBtnClick,
    onDownloadBtnClick,
    onApproveBtnClick,
    t,
  } = useContractViewModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={t(TranslationKey['Contract overview'])}>
      <UIKit.BackgroundCard title={t(TranslationKey['PDF overview'])}>
        <Grid container direction={'row'}>
          <Grid item sm={4}>
            <Stack gap={2}>
              <Grid container gap={2}>
                {contractOverviewFormFields.map((item, index) => (
                  <Grid key={`contract-overview-form-field-${index}`} item sm={12}>
                    {renderFormField(item)}
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Grid>
          <Grid item sm={8}>
            <Stack gap={2}>
              <Stack gap={2} flexDirection={'row'}>
                <StyledButton variant="contained" onClick={onDownloadBtnClick}>
                  {t(TranslationKey['Download PDF'])}
                </StyledButton>
                <StyledButton disabled={!isEditBtnEnabled} variant="outlined" onClick={onEditBtnClick}>
                  {t(TranslationKey.Edit)}
                </StyledButton>
                <StyledButton disabled={!isApproveBtnEnabled} variant="contained" onClick={onApproveBtnClick}>
                  {t(TranslationKey.Approve)}
                </StyledButton>
                <CancelBtn disabled={!isCancelBtnEnabled} variant="contained" onClick={onCancelBtnClick}>
                  {t(TranslationKey.Cancel)}
                </CancelBtn>
              </Stack>

              {pdfUrl && (
                <iframe
                  src={`${pdfUrl}#toolbar=0&navpanes=0&view=FitH`}
                  title="PDF Viewer"
                  width="100%"
                  height="800px"
                  style={{border: 'none'}}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </UIKit.BackgroundCard>
      <UIKit.Modal
        open={isSignatureOptionsModalVisible}
        title={t(TranslationKey['Send for signing'])}
        cancelButtonProps={{
          buttonText: t(TranslationKey.Cancel),
          onClick: () => setIsSignatureOptionsModalVisible(false),
        }}
        confirmButtonProps={{
          buttonText: t(TranslationKey['Send for signing']),
          onClick: onSendForSigningBtnClick,
        }}
        content={
          <Stack gap={2}>
            {signingFormFields.map((item, index) => (
              <Stack key={`signing-form-field-${index}`}>{renderFormField(item)}</Stack>
            ))}
          </Stack>
        }
      />
    </PageWrapper>
  )
}
