import {NavigationPathKey, TranslationKey} from '@enums'
import {Box, Typography} from '@mui/material'

import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import logo from '@images/amplio-logo.png'

import {ContentContainer, BackButton, imageStyle} from './style'

/**
 * Logout page with a message and a Login button
 */
export function LogoutPage() {
  const navigate = useNavigate()
  const {t} = useTranslation()
  return (
    <ContentContainer>
      <Box component="img" src={logo} style={imageStyle} onClick={() => navigate(NavigationPathKey.DASHBOARD)} />
      <Typography variant="h2">{t(TranslationKey['You have successfully logged out.'])}</Typography>
      <BackButton variant="contained" onClick={() => navigate(-1)}>
        {t(TranslationKey.Login)}
      </BackButton>
    </ContentContainer>
  )
}
