import {Grid, Link, Stack, styled} from '@mui/material'

export const ContentContainer = styled(Stack)(() => ({
  width: '70%',
  minWidth: '1100px',
  gap: '15px',
}))

export const LabelContainer = styled(Grid)(() => ({
  gap: '50px',
  flexDirection: 'row',
  display: 'flex',
}))

export const InfoContainer = styled(Stack)(() => ({
  alignItems: 'end',
}))

export const LinkWrapper = styled(Link)(() => ({
  textDecoration: 'none',
}))
