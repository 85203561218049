import {
  AccessoryResponse,
  ContractTypeResponse,
  getAccessoriesByCompany,
  getContractTypesByCompany,
  getProduct,
  linkProductToAccessory,
  LoggedInUser,
  OverridePrice,
  ProductData,
  ProductResponse,
  unlinkProductFromAccessory,
  handleAxiosError,
  CompanyResponse,
  getCompany,
} from '@api'
import {AppContext} from '@contexts'
import {FormFieldType, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef} from '@mui/x-data-grid'

import {useContext, useMemo, useState} from 'react'

import {
  activeCell,
  formatCurrency,
  formatPercentage,
  FormFieldItem,
  generateDropdownOptions,
  generateReadableErrorMessages,
  getUserData,
  isCompanyUser,
  unlinkButtonCell,
  renderClickableCell,
} from 'common'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import {ComposedComponents, UIKit} from '@components'

export interface AccessoriesTableData {
  name: string
  price: string
  active: boolean
}

export enum ProductTab {
  GENERAL,
  LINKED_ITEMS,
}

export interface ContractTypePrice {
  contractType: string
  price: number
  residualValue: number
  servicePrice: number
  installationCompensation: number
  override: OverridePrice[]
}

/**
 * Initial data
 */
const initialProductData: ProductData = {
  name: '',
  active: false,
  companyId: null,
  price: 0,
  productNo: '',
  residualValue: 0,
  servicePrice: 0,
  installationCompensation: 0,
  contractTypePrices: [],
}

export function useProductViewModel() {
  const {setLoadingStatus, setError, setSuccessMessage} = useContext(AppContext)
  const [productData, setProductData] = useState<ProductData>(initialProductData)
  const [company, setCompany] = useState<CompanyResponse | undefined>(undefined)
  const [accessoriesRows, setAccessoriesRows] = useState<AccessoriesTableData[]>([])
  const [isLinkModalShown, setIsLinkModalShown] = useState<boolean>(false)
  const [accessoriesOptions, setAccessoriesOptions] = useState<UIKit.DropdownItem[]>([])
  const [accessoryToLink, setAccessoryToLink] = useState<string | undefined>(undefined)
  const [accessoryFormFieldError, setAccessoryFormFieldError] = useState<string>('')
  const [activeTab, setActiveTab] = useState<ProductTab>(ProductTab.GENERAL)
  const [contractTypePrices, setContractTypePrices] = useState<ContractTypePrice[]>([])
  const navigate = useNavigate()
  const currentUser: LoggedInUser = getUserData()
  const {id} = useParams()
  const {t} = useTranslation()

  /**
   * Dynamically generated title text for Product View page
   */
  const title: string = useMemo(() => `${t(TranslationKey['Product view'])} - ${productData.name}`, [productData.name])

  /**
   * Dynamically generated modal text
   */
  const modalText: string = useMemo(
    () => `${t(TranslationKey['Choose an accessory to link to'])} ${productData.name}`,
    [productData.name],
  )

  /**
   * Edit button should be visible only for Administrator users
   */
  const isEditButtonVisible = !isCompanyUser(currentUser)

  /**
   * Tab buttons configuration
   */
  const tabButtons: ComposedComponents.TabButtonProps<ProductTab>[] = [
    {
      label: t(TranslationKey['General information']),
      key: ProductTab.GENERAL,
      onClick: () => setActiveTab(ProductTab.GENERAL),
    },
    {
      label: t(TranslationKey['Linked accessories']),
      key: ProductTab.LINKED_ITEMS,
      onClick: () => setActiveTab(ProductTab.LINKED_ITEMS),
    },
  ]

  /**
   * Product table columns configuration
   */
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 1.5,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.ACCESSORY_VIEW}/${params.row.id}`)),
    },
    {field: 'price', headerName: t(TranslationKey.Price), flex: 0.5, headerAlign: 'right', align: 'right'},
    activeCell(t),
    ...(isEditButtonVisible ? [unlinkButtonCell(t, id => onUnlink(id))] : []),
  ]

  /**
   * A function that handles unlinking the Product from an Accessory
   * @param accessoryId Accessory ID
   */
  const onUnlink = async (accessoryId: string): Promise<void> => {
    try {
      setLoadingStatus(LoadingStatus.LOADING)

      await unlinkProductFromAccessory(String(id), accessoryId)
      await loadData()
      setSuccessMessage(t(TranslationKey['Successfully unlinked an accessory']))

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function that handles linking the Product to an Accessory
   * @returns void
   */
  const onLink = async (): Promise<void> => {
    if (!accessoryToLink) {
      setAccessoryFormFieldError('accessoryToLink')
      setError({
        message: generateReadableErrorMessages(t, ['accessoryToLink']),
        name: '',
      })
      return
    }
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      setIsLinkModalShown(false)
      setAccessoryToLink(undefined)
      await linkProductToAccessory(String(id), accessoryToLink)

      await loadData()
      setSuccessMessage(t(TranslationKey['Successfully linked an accessory']))
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * Product form fields configuration - general section
   */
  const productGeneralFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Product number']),
      text: productData.productNo,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Product name']),
      text: productData.name,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Amplio partner']),
      text: company?.name,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Active),
      text: productData.active ? t(TranslationKey.Active) : t(TranslationKey.Inactive),
    },
  ]

  /**
   * Contract Type Price form fields configuration
   * @param index Contract Type Price section index
   * @returns Contrac Type Price form fields
   */
  const productPriceFormFields = (index?: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Price),
      text: formatCurrency(index !== undefined ? contractTypePrices[index].price : productData.price),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Residual value']),
      text: `${formatCurrency(index !== undefined ? (contractTypePrices[index].price / 100) * contractTypePrices[index].residualValue : (productData.price / 100) * productData.residualValue)} (${formatPercentage(index !== undefined ? contractTypePrices[index].residualValue : productData.residualValue)})`,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Installation compensation']),
      text: formatCurrency(
        index !== undefined ? contractTypePrices[index].installationCompensation : productData.installationCompensation,
      ),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Service price']),
      text: formatCurrency(index !== undefined ? contractTypePrices[index].servicePrice : productData.servicePrice),
    },
  ]

  /**
   * Contract Type Override Price form fields configuration
   * @param index Contract Type Override Price section index
   * @returns Contract Type Override Price form fields
   */
  const productOverridePriceFormFields = (index?: number): FormFieldItem[] =>
    index !== undefined
      ? contractTypePrices[index].override?.map(item => ({
          fieldType: FormFieldType.LABEL,
          label: `${item.contractLength} ${t(TranslationKey.Months)}`,
          text: formatCurrency(item.price),
        }))
      : []

  const linkAccessoryFormField: FormFieldItem = {
    fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
    items: accessoriesOptions,
    value: accessoryToLink,
    onChange: (item: UIKit.DropdownItem | null) => {
      setAccessoryToLink(String(item?.value))
      setAccessoryFormFieldError('')
    },
    error: !!accessoryFormFieldError,
    placeholder: t(TranslationKey['Choose an accessory to link to']),
    listboxHeight: 100,
  }

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      if (!id) {
        throw new Error('No product ID')
      }
      const data: ProductResponse = await getProduct(id)
      const companyData: CompanyResponse = await getCompany(String(data.companyId))
      const contractTypeData: ContractTypeResponse[] = await getContractTypesByCompany(data.companyId)

      let accessoriesData: AccessoryResponse[] = []

      accessoriesData = await getAccessoriesByCompany(String(data.companyId))

      const formattedAccessoriesData: UIKit.DropdownItem[] = generateDropdownOptions(
        accessoriesData.filter(item => !data.accessories.map(accessory => accessory.id).includes(item.id)),
      )

      const formattedContractTypePrices: ContractTypePrice[] = data.contractTypePrices.map(item => ({
        contractType: contractTypeData.find(contractType => contractType.id === item.contractTypeId)?.name || '-',
        price: item.price,
        residualValue: item.residualValue,
        servicePrice: item.servicePrice,
        installationCompensation: item.installationCompensation,
        override: item.override,
      }))

      setContractTypePrices(formattedContractTypePrices)

      setAccessoriesOptions(formattedAccessoriesData)
      setCompany(companyData)
      setProductData(data)

      const formattedData: AccessoriesTableData[] = data.accessories.map(item => ({
        name: item.name,
        price: formatCurrency(item.price),
        active: item.active,
        id: item.id,
        unlinkId: item.id,
      }))

      setAccessoriesRows(formattedData)
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function that handles the Back button click
   */
  const onPressBack = (): void => {
    navigate(NavigationPathKey.PRODUCT_SEARCH)
  }

  /**
   * A function that handles the Edit button click
   */
  const onPressEdit = (): void => {
    navigate(`${NavigationPathKey.PRODUCT_CREATION}/${id}`)
  }

  /**
   * A function that handles Tab button click
   * @param tabKey Tab key
   */
  const onChangeTab = (tabKey: ProductTab): void => setActiveTab(tabKey)

  /**
   * A function that handles modal close
   */
  const onCloseLinkModal = () => {
    setIsLinkModalShown(false)
    setAccessoryToLink(undefined)
    setAccessoryFormFieldError('')
  }

  /**
   * Create button should only be visible for Administrator users
   */
  const showCreateButton = !isCompanyUser(currentUser)

  return {
    title,
    productGeneralFormFields,
    isEditButtonVisible,
    accessoriesRows,
    columns,
    isLinkModalShown,
    modalText,
    activeTab,
    tabButtons,
    t,
    linkAccessoryFormField,
    contractTypePrices,
    id,
    productPriceFormFields,
    productOverridePriceFormFields,
    onChangeTab,
    onLink,
    onCloseLinkModal,
    setIsLinkModalShown,
    loadData,
    onPressBack,
    onPressEdit,
    navigate,
    showCreateButton,
  }
}
