import {CompanyResponse, getCompany, LoggedInUser, handleAxiosError} from '@api'
import {activeCell, countryCell, getUserCompany, getUserData, isSuperAdmin, renderClickableCell} from '@common'
import {AppContext} from '@contexts'
import {LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef} from '@mui/x-data-grid'

import {useContext, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

export interface CompanyTableData {
  name: string
  description: string
  country: string
  active: boolean
}

export function useCompanySearchModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const {t} = useTranslation()
  const [rows, setRows] = useState<CompanyTableData[]>([])
  const navigate = useNavigate()
  const currentUser: LoggedInUser = getUserData()

  /**
   * Company table columns definition
   */
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 0.8,
      hideable: false,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.COMPANY_VIEW}/${params.row.id}`)),
    },
    {field: 'description', headerName: t(TranslationKey.Description), flex: 1},
    countryCell(t),
    activeCell(t),
  ]

  /**
   * Create button should be visible only for Super Administrators
   */
  const showCreateButton = isSuperAdmin(currentUser)

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      let data: CompanyResponse[] = []

      // Super Administators can see ALL the Companies
      if (isSuperAdmin(currentUser)) data = [await getCompany(getUserCompany(currentUser.id))]
      // Company Administrators and Company Users can see ONLY their company
      else data = [await getCompany(String(currentUser.companyId))]

      setLoadingStatus(LoadingStatus.SUCCESS)
      const formattedData: CompanyTableData[] = data.map(item => ({
        name: item.name,
        description: item.description,
        country: item.country || '',
        active: item.active,
        id: item.id,
      }))

      setRows(formattedData)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {loadData, navigate, t, showCreateButton, rows, columns}
}
