import colors from '@colors'
import {Stack, styled} from '@mui/material'

export const LabelsContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  gap: '2rem',
}))

export const LabelsLeftContainer = styled(Stack)(() => ({
  width: '100%',
  gap: '2rem',
}))

export const LabelsRightContainer = styled(Stack)(() => ({
  width: '100%',
  gap: '2rem',
}))

export const imageStyle = {
  height: '200px',
  width: '200px',
  objectFit: 'cover' as const,
  borderRadius: '1rem',
  border: `1px solid ${colors.gray[2]}`,
}

export const ImageWrapper = styled(Stack)(() => ({
  height: 188,
  justifyContent: 'center',
  alignItems: 'center',
}))
