import {LoggedInUser, LoginRequest, LoginResponse, getImage, login, handleAxiosError, getCompanies} from '@api'
import {AppContext} from '@contexts'
import {FormFieldType, ImageType, Language, LoadingStatus, NavigationPathKey, TranslationKey, UserRole} from '@enums'

import {useContext, useState} from 'react'

import {
  FormFieldItem,
  generateReadableErrorMessages,
  getUserCompany,
  getUserLanguage,
  setAuthenticationStorage,
  setUserCompany,
} from 'common'
import i18next from 'i18next'
import {useTranslation} from 'react-i18next'
import {decodeToken} from 'react-jwt'
import {useNavigate} from 'react-router-dom'

import {customInputStyle} from './style'

/**
 * Initial data
 */
const initialData: LoginRequest = {
  email: '',
  password: '',
}
export function useLoginModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const {t} = useTranslation()
  const [loginData, setLoginData] = useState<LoginRequest>(initialData)
  const [loginDataErrors, setLoginDataErrors] = useState<string[]>([])
  const navigate = useNavigate()

  /**
   * A function to handle change of the login form fields
   * @param event Change event
   */
  const onChangeLoginField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const fieldName = event.target.name
    const value = event.target.value
    setLoginDataErrors(prev => prev.filter(item => item !== fieldName))
    setLoginData(prev => ({...prev, [fieldName]: value}))
  }

  /**
   * Validation function
   * @returns If there are any invalid fields
   */
  const validateData = (): boolean => {
    const invalidValues: string[] = []

    for (const prop in loginData) {
      if (Object.prototype.hasOwnProperty.call(loginData, prop)) {
        const value = loginData[prop as keyof LoginRequest]

        if (prop === 'email') {
          if (typeof value === 'string' && (!value || !/\S+@\S+\.\S+/.test(value))) {
            invalidValues.push(prop)
          }
        } else if (typeof value === 'string' && (value === undefined || value === '')) {
          invalidValues.push(prop)
        }
      }
      setLoginDataErrors(invalidValues)
    }
    if (invalidValues.length > 0)
      setError({
        message: generateReadableErrorMessages(t, invalidValues),
        name: '',
      })
    return invalidValues.length > 0
  }

  /**
   * Login form fields configuration
   */
  const loginFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Username']),
      name: 'email',
      onChange: onChangeLoginField,
      value: loginData.email,
      error: loginDataErrors.includes('email'),
      customStyle: customInputStyle,
      onKeyDown: (key: React.KeyboardEvent) => {
        if (key.key === 'Enter') onLogin()
      },
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Password']),
      name: 'password',
      onChange: onChangeLoginField,
      value: loginData.password,
      error: loginDataErrors.includes('password'),
      customStyle: customInputStyle,
      type: 'password',
      onKeyDown: (key: React.KeyboardEvent) => {
        if (key.key === 'Enter') onLogin()
      },
    },
  ]

  /**
   * A function to handle the Login button click. If successfull, will log in the user.
   */
  const onLogin = async (): Promise<void> => {
    if (validateData()) return
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      const {token}: LoginResponse = await login(loginData)
      setLoadingStatus(LoadingStatus.SUCCESS)

      const decodedToken: LoggedInUser | null = decodeToken(token)

      if (!decodedToken) {
        throw new Error('INVALID_TOKEN')
      }

      setAuthenticationStorage(
        JSON.stringify({
          token,
          userData: {...decodedToken, image: getImage(ImageType.USER, String(decodedToken?.id))},
        }),
      )

      if (decodedToken.companyId) {
        setUserCompany({
          userUid: decodedToken.id,
          companyUid: decodedToken.companyId || '',
        })
      } else if (decodedToken.role === UserRole.SUPER_ADMIN && !getUserCompany(decodedToken.id)) {
        const companies = await getCompanies()

        setUserCompany({
          userUid: decodedToken.id,
          companyUid: companies[0].id,
        })
      }
      i18next.changeLanguage(getUserLanguage(decodedToken.id) || Language.EN)

      navigate(NavigationPathKey.DASHBOARD)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {onLogin, onChangeLoginField, t, loginData, loginFormFields}
}
