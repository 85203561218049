import {ContractType, Country, CreditStatus, TranslationKey, UserRole} from '@enums'
import {SvgIcon} from '@mui/material'

import {TFunction} from 'i18next'

import {ReactComponent as AT} from '@flags/austria.svg'
import {ReactComponent as DK} from '@flags/denmark.svg'
import {ReactComponent as FI} from '@flags/finland.svg'
import {ReactComponent as FR} from '@flags/france.svg'
import {ReactComponent as DE} from '@flags/germany.svg'
import {ReactComponent as GB} from '@flags/greatBritain.svg'
import {ReactComponent as NL} from '@flags/netherlands.svg'
import {ReactComponent as NO} from '@flags/norway.svg'
import {ReactComponent as SE} from '@flags/sweden.svg'

import {BadScoreIcon, GoodScoreIcon, UnknownScoreIcon} from './style'

export type CountryFormData = {
  name: string
  isoCode: Country
}

export type DealerFormData = {
  name: string
  uid: string
  company: string
}

export type RolesFormData = {
  name: string
  code: UserRole
}

export const phoneMasks = {
  [Country.SWEDEN]: '+46 ### ### ### ###',
  [Country.DENMARK]: '+45 #### ####',
  [Country.NORWAY]: '+47 #### ####',
  [Country.AUSTRIA]: '+43 ### ##########',
  [Country.FINLAND]: '+358 ### ##########',
  [Country.FRANCE]: '+33 #### #### ####',
  [Country.GERMANY]: '+49 ### ##########',
  [Country.NETHERLANDS]: '+31 ### #######',
  [Country.UNITED_KINGDOM]: '+44 #### #### ####',
}

export const timeFormats = ['dd-MM-yyyy', 'dd/MM/yyyy', 'dd.MM.yyyy'].map(item => ({value: item, label: item}))

export const countries = (t: TFunction): CountryFormData[] => [
  {isoCode: Country.SWEDEN, name: t(TranslationKey.Sweden)},
  {isoCode: Country.NORWAY, name: t(TranslationKey.Norway)},
  {isoCode: Country.DENMARK, name: t(TranslationKey.Denmark)},
  {isoCode: Country.FINLAND, name: t(TranslationKey.Finland)},
  {isoCode: Country.GERMANY, name: t(TranslationKey.Germany)},
  {isoCode: Country.AUSTRIA, name: t(TranslationKey.Austria)},
  {isoCode: Country.NETHERLANDS, name: t(TranslationKey.Netherlands)},
  {isoCode: Country.FRANCE, name: t(TranslationKey.France)},
  {isoCode: Country.UNITED_KINGDOM, name: t(TranslationKey['United Kingdom'])},
]

export const getCreditIcon = (status: CreditStatus) => {
  switch (status) {
    case CreditStatus.GOOD:
      return <GoodScoreIcon />
    case CreditStatus.BAD:
      return <BadScoreIcon />
    default:
      return <UnknownScoreIcon />
  }
}

export const contractLengths = (t: TFunction) => [
  {
    value: 12,
    label: `12 ${t(TranslationKey.Months)}`,
  },
  {
    value: 18,
    label: `18 ${t(TranslationKey.Months)}`,
  },
  {
    value: 24,
    label: `24 ${t(TranslationKey.Months)}`,
  },
  {
    value: 36,
    label: `36 ${t(TranslationKey.Months)}`,
  },
]

export const languages = (t: TFunction) => [
  {
    value: 'en',
    label: t(TranslationKey.English),
    icon: (
      <SvgIcon>
        <GB />
      </SvgIcon>
    ),
  },
  {
    value: 'se',
    label: t(TranslationKey.Swedish),
    icon: (
      <SvgIcon>
        <SE />
      </SvgIcon>
    ),
  },
  {
    value: 'de',
    label: t(TranslationKey.German),
    icon: (
      <SvgIcon>
        <DE />
      </SvgIcon>
    ),
  },
  {
    value: 'fr',
    label: t(TranslationKey.French),
    icon: (
      <SvgIcon>
        <FR />
      </SvgIcon>
    ),
  },
  {
    value: 'fi',
    label: t(TranslationKey.Finnish),
    icon: (
      <SvgIcon>
        <FI />
      </SvgIcon>
    ),
  },
  {
    value: 'no',
    label: t(TranslationKey.Norwegian),
    icon: (
      <SvgIcon>
        <NO />
      </SvgIcon>
    ),
  },
  {
    value: 'dk',
    label: t(TranslationKey.Danish),
    icon: (
      <SvgIcon>
        <DK />
      </SvgIcon>
    ),
  },
  {
    value: 'nl',
    label: t(TranslationKey.Dutch),
    icon: (
      <SvgIcon>
        <NL />
      </SvgIcon>
    ),
  },
]

export const roles = (t: TFunction): RolesFormData[] => [
  {
    name: t(TranslationKey['Dealer user']),
    code: UserRole.COMPANY_USER,
  },
  {
    name: t(TranslationKey.Administrator),
    code: UserRole.COMPANY_ADMIN,
  },
  {
    name: t(TranslationKey['Super administrator']),
    code: UserRole.SUPER_ADMIN,
  },
]

export const icons: Record<Country, JSX.Element> = {
  [Country.SWEDEN]: <SE />,
  [Country.NORWAY]: <NO />,
  [Country.DENMARK]: <DK />,
  [Country.FINLAND]: <FI />,
  [Country.GERMANY]: <DE />,
  [Country.AUSTRIA]: <AT />,
  [Country.NETHERLANDS]: <NL />,
  [Country.FRANCE]: <FR />,
  [Country.UNITED_KINGDOM]: <GB />,
}

export const contractSteps = (t: TFunction) => [
  t(TranslationKey['Contract setup']),
  t(TranslationKey['Products and accessories']),
  t(TranslationKey['Customer information']),
  t(TranslationKey.Overview),
]

export const contractTypeOptions = () => [
  {
    value: ContractType.B2C,
    label: 'B2C',
  },
  {
    value: ContractType.B2B,
    label: 'B2B',
  },
]

export const currencies: Record<Country, string> = {
  [Country.SWEDEN]: 'SEK',
  [Country.NORWAY]: 'NOK',
  [Country.DENMARK]: 'DKK',
  [Country.FINLAND]: 'EUR',
  [Country.GERMANY]: 'EUR',
  [Country.AUSTRIA]: 'EUR',
  [Country.NETHERLANDS]: 'EUR',
  [Country.FRANCE]: 'EUR',
  [Country.UNITED_KINGDOM]: 'GBP',
}
