import {APIMethod, LocalStorageItem} from '@enums'

import axiosInstance from './config'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface FetchParams<TBody, TData> {
  method: APIMethod
  url: string
  body?: TBody
  contentType?: string
}

export const fetchData = async <TBody = unknown, TData = unknown>({
  method,
  url,
  body,
  contentType,
}: FetchParams<TBody, TData>): Promise<TData> => {
  const token = JSON.parse(localStorage.getItem(LocalStorageItem.AUTHENTICATION) || '{}').token

  const headers = {
    'Content-Type': contentType || 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const response = await axiosInstance({method, url, data: body, headers})
  return response.data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleAxiosError = (error: any) => ({
  message: error.response?.data?.message ?? error.message,
  name: error.name,
  stack: error.stack,
  cause: error.cause,
})
