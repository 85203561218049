import {Stack, Typography} from '@mui/material'

import React, {ReactNode} from 'react'

import {Label} from './style'

export interface TextWithLabelProps {
  text: string
  label: string
  width?: number
  icon: ReactNode
}

/**
 * Text with Label component for read-only texts
 */
export function TextWithLabel(props: TextWithLabelProps) {
  const {text, label, width, icon} = props

  return (
    <Stack style={{width: width}} alignItems={'flex-start'} gap={1}>
      <Label>{label}</Label>
      <Stack flexDirection={'row'} gap={2}>
        <Typography textAlign={'start'} fontWeight={'bold'} variant="body1">
          {text}
        </Typography>
        {icon}
      </Stack>
    </Stack>
  )
}
