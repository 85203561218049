import {APIMethod} from '@enums'

import {fetchData} from 'api/api'
import {ItemSignature} from 'common/types'

export interface ContractTypeDealer {
  name: string
  id: string
}
interface BaseContractType {
  name: string
  type: string
  note: string
  companyId: string | null
  active: boolean
  disclaimer: string
  lockFields: boolean
  displayServicePrice: boolean
  displayInstallationCosts: boolean
  displayExtraCosts: boolean
  insurancePercentage: number
  productInsuranceMinimum: number
  accessoryInsuranceMinimum: number
  productDiscount: number
  serviceDiscount: number
  accessoryDiscount: number
  advancedPayment: number
  interest: number
  functionInterest: number
  expiry: number | undefined
  lengths: number[]
}
export interface ContractTypeData extends BaseContractType {
  id?: string
  allowedDealers: ContractTypeDealer[]
}
export interface ContractTypeRequest extends BaseContractType {
  companyId: string
}
export interface ContractTypeResponse extends BaseContractType, ItemSignature {
  id: string
  allowedDealers: ContractTypeDealer[]
}

/**
 * @returns All Contract Types
 */
export const getContractTypes = async (): Promise<ContractTypeResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: 'contract-type',
  })
}

/**
 * @param id Company ID
 * @returns All Contract Types with a specific Company ID
 */
export const getContractTypesByCompany = async (id: string): Promise<ContractTypeResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `contract-type/company/${id}`,
  })
}

/**
 * @param id Contract Type ID
 * @returns A single Contract Type with a specific ID
 */
export const getContractType = async (id: string): Promise<ContractTypeResponse> => {
  return fetchData({
    method: APIMethod.GET,
    url: `contract-type/${id}`,
  })
}

/**
 * Updates an existing Contract Type
 * @param id Contract Type ID
 * @param body Contract Type payload data
 * @returns Updated Contract Type data
 */
export const updateContractType = async (id: string, body: ContractTypeRequest): Promise<ContractTypeResponse> => {
  return fetchData({
    method: APIMethod.PUT,
    url: `contract-type/${id}`,
    body,
  })
}

/**
 * Creates a new Contract Type
 * @param body Contract Type payload data
 * @returns New Contract Type data
 */

export const createContractType = async (body: ContractTypeRequest): Promise<ContractTypeResponse> => {
  return fetchData({
    method: APIMethod.POST,
    url: `contract-type`,
    body,
  })
}
