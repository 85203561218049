import {NavigationPathKey, TranslationKey} from '@enums'
import {Box, Typography} from '@mui/material'

import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import logo from '@images/amplio-logo.png'

import {ContentContainer, BackButton, imageStyle} from './style'

/**
 * Not Found page with a message and a Back button
 */
export function NotFoundPage() {
  const navigate = useNavigate()
  const {t} = useTranslation()
  return (
    <ContentContainer>
      <Box component="img" src={logo} style={imageStyle} onClick={() => navigate(NavigationPathKey.DASHBOARD)} />
      <Typography fontSize={'3rem'}>404</Typography>
      <Typography variant="h2">{t(TranslationKey['The page you requested does not exist.'])}</Typography>
      <BackButton variant="contained" onClick={() => navigate(-1)}>
        {t(TranslationKey['Go back'])}
      </BackButton>
    </ContentContainer>
  )
}
