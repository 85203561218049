import {
  getAccessory,
  AccessoryData,
  getCompany,
  AccessoryResponse,
  ProductResponse,
  getProducts,
  getProductsByCompany,
  LoggedInUser,
  linkProductToAccessory,
  unlinkProductFromAccessory,
  handleAxiosError,
  ContractTypeResponse,
  getContractTypesByCompany,
} from '@api'
import {CompanyResponse} from '@api'
import {AppContext} from '@contexts'
import {FormFieldType, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef} from '@mui/x-data-grid'

import {useContext, useMemo, useState} from 'react'

import {
  activeCell,
  formatCurrency,
  formatPercentage,
  FormFieldItem,
  generateDropdownOptions,
  generateReadableErrorMessages,
  getUserData,
  isCompanyUser,
  isSuperAdmin,
  unlinkButtonCell,
  renderClickableCell,
} from 'common'
import {TabButtonProps} from 'components/componsed-components'
import {ContractTypePrice} from 'components/pages/products/product-view/product-view-model'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import {UIKit} from '@components'

export interface ProductsTableData {
  name: string
  price: string
  active: boolean
}

export enum AccessoryTab {
  GENERAL,
  LINKED_ITEMS,
}

/**
 * Initial data
 */
const initialAccessoryData: AccessoryData = {
  name: '',
  active: false,
  companyId: null,
  price: 0,
  productNo: '',
  residualValue: 0,
  servicePrice: 0,
  installationCompensation: 0,
  contractTypePrices: [],
}

export function useAccessoryViewModel() {
  const {setLoadingStatus, setError, setSuccessMessage} = useContext(AppContext)
  const [accessoryData, setAccessoryData] = useState<AccessoryData>(initialAccessoryData)
  const [company, setCompany] = useState<CompanyResponse | undefined>(undefined)
  const [productsRows, setProductsRows] = useState<ProductsTableData[]>([])
  const [isLinkModalShown, setIsLinkModalShown] = useState<boolean>(false)
  const [productsOptions, setProductsOptions] = useState<UIKit.DropdownItem[]>([])
  const [productToLink, setProductToLink] = useState<string | undefined>(undefined)
  const [productFormFieldError, setProductFormFieldError] = useState<string>('')
  const [activeTab, setActiveTab] = useState<AccessoryTab>(AccessoryTab.GENERAL)
  const [contractTypePrices, setContractTypePrices] = useState<ContractTypePrice[]>([])
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {id} = useParams()
  const currentUser: LoggedInUser = getUserData()

  /**
   * Only Administrator Users should be available to edit
   */
  const isEditButtonVisible = !isCompanyUser(currentUser)

  /**
   * Dynamically created title text for the page
   */
  const title = useMemo(() => `${t(TranslationKey['Accessory view'])} - ${accessoryData.name}`, [accessoryData.name])

  /**
   * Dynamically created text for the modal
   */
  const modalText: string = useMemo(
    () => `${t(TranslationKey['Choose a product to link to'])} ${accessoryData.name}`,
    [accessoryData.name],
  )

  /**
   *  Tab Buttons configuration
   */
  const tabButtons: TabButtonProps<AccessoryTab>[] = [
    {
      label: t(TranslationKey['General information']),
      key: AccessoryTab.GENERAL,
      onClick: () => setActiveTab(AccessoryTab.GENERAL),
    },
    {
      label: t(TranslationKey['Linked products']),
      key: AccessoryTab.LINKED_ITEMS,
      onClick: () => setActiveTab(AccessoryTab.LINKED_ITEMS),
    },
  ]

  /**
   * Product table columns configuration
   */
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 1.5,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.PRODUCT_VIEW}/${params.row.id}`)),
    },
    {field: 'price', headerName: t(TranslationKey.Price), flex: 0.5, headerAlign: 'right', align: 'right'},
    activeCell(t),
    ...(isEditButtonVisible ? [unlinkButtonCell(t, id => onUnlink(id))] : []),
  ]

  /**
   * Accessory form fields configuration - general section
   */
  const accessoryGeneralFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Product number']),
      text: accessoryData.productNo,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Product name']),
      text: accessoryData.name,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Amplio partner']),
      text: company?.name,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Active),
      text: accessoryData.active ? t(TranslationKey.Active) : t(TranslationKey.Inactive),
    },
  ]

  /**
   * Accessory price form fields configuration
   * @param index Index of the price form field section
   */
  const accessoryPriceFormFields = (index?: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Price),
      text: formatCurrency(index !== undefined ? contractTypePrices[index].price : accessoryData.price),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Residual value']),
      text: formatPercentage(
        index !== undefined ? contractTypePrices[index].residualValue : accessoryData.residualValue,
      ),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Installation compensation']),
      text: formatCurrency(
        index !== undefined
          ? contractTypePrices[index].installationCompensation
          : accessoryData.installationCompensation,
      ),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Service price']),
      text: formatCurrency(index !== undefined ? contractTypePrices[index].servicePrice : accessoryData.servicePrice),
    },
  ]

  /**
   * Accessory monthly price override form field configuration
   * @param index Index of the price form field section
   */
  const accessoryOverridePriceFormFields = (index?: number): FormFieldItem[] =>
    index !== undefined
      ? contractTypePrices[index].override?.map(item => ({
          fieldType: FormFieldType.LABEL,
          label: `${item.contractLength} ${t(TranslationKey.Months)}`,
          text: formatCurrency(item.price),
        }))
      : []

  const linkProductFormField: FormFieldItem = {
    fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
    items: productsOptions,
    value: productToLink,
    onChange: (item: UIKit.DropdownItem | null) => {
      setProductToLink(String(item?.value))
      setProductFormFieldError('')
    },
    error: !!productFormFieldError,
    placeholder: t(TranslationKey['Choose a product to link to']),
    listboxHeight: 100,
  }

  /**
   * A function that handles unlinking the Accessory from a Product
   * @param productId Product ID
   * @returns void
   */
  const onUnlink = async (productId: string): Promise<void> => {
    try {
      setLoadingStatus(LoadingStatus.LOADING)

      await unlinkProductFromAccessory(productId, String(id))
      await loadData()
      setSuccessMessage(t(TranslationKey['Successfully unlinked a product']))

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function that handles linking the Accessory to a Product
   * @returns void
   */
  const onLink = async (): Promise<void> => {
    if (!productToLink) {
      setProductFormFieldError('productToLink')
      setError({
        message: generateReadableErrorMessages(t, ['productToLink']),
        name: '',
      })
      return
    }
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      setIsLinkModalShown(false)
      setProductToLink(undefined)
      await linkProductToAccessory(productToLink, String(id))
      await loadData()
      setSuccessMessage(t(TranslationKey['Successfully linked a product']))
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * Load data function
   * Should execute only once on the component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      if (!id) {
        throw new Error('No accessory ID')
      }
      const data: AccessoryResponse = await getAccessory(id)
      const companyData: CompanyResponse = await getCompany(String(data.companyId))
      const contractTypeData: ContractTypeResponse[] = await getContractTypesByCompany(String(data.companyId))

      let productData: ProductResponse[] = []

      //Super Administrator can see ALL the data
      if (isSuperAdmin(currentUser)) productData = await getProducts()
      // Company Administrator can ONLY see it's Company's Accessories
      else productData = await getProductsByCompany(String(currentUser.companyId))

      const formattedProductsData: UIKit.DropdownItem[] = generateDropdownOptions(
        productData.filter(item => !data.products?.map(product => product.id).includes(item.id)),
        'name',
        'id',
      )

      const formattedContractTypePrices: ContractTypePrice[] = data.contractTypePrices?.map(item => ({
        contractType: contractTypeData.find(contractType => contractType.id === item.contractTypeId)?.name || '-',
        price: item.price,
        residualValue: item.residualValue,
        servicePrice: item.servicePrice,
        installationCompensation: item.installationCompensation,
        override: item.override,
      }))

      setContractTypePrices(formattedContractTypePrices)

      setProductsOptions(formattedProductsData)

      setCompany(companyData)
      setAccessoryData(data)

      const formattedData: ProductsTableData[] = data.products.map(item => ({
        name: item.name,
        price: formatCurrency(item.price),
        active: item.active,
        id: item.id,
        unlinkId: item.id,
      }))

      setProductsRows(formattedData)
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function to handle Back button click
   */
  const onPressBack = (): void => {
    navigate(NavigationPathKey.ACCESSORY_SEARCH)
  }

  /**
   * A function to handle Edit button click
   */
  const onPressEdit = (): void => {
    navigate(`${NavigationPathKey.ACCESSORY_CREATION}/${id}`)
  }

  /**
   * A function to handle closing of the modal
   */
  const onCloseLinkModal = () => {
    setIsLinkModalShown(false)
    setProductToLink(undefined)
    setProductFormFieldError('')
  }

  /**
   * Only administrator users should be able to create new Products
   */
  const showCreateButton = !isCompanyUser(currentUser)

  return {
    title,
    accessoryGeneralFormFields,
    productsRows,
    isLinkModalShown,
    columns,
    activeTab,
    tabButtons,
    isEditButtonVisible,
    modalText,
    linkProductFormField,
    t,
    contractTypePrices,
    showCreateButton,
    accessoryPriceFormFields,
    navigate,
    accessoryOverridePriceFormFields,
    onLink,
    setIsLinkModalShown,
    loadData,
    onPressBack,
    onPressEdit,
    onCloseLinkModal,
  }
}
