import {TranslationKey} from '@enums'
import {Button, Grid, Stack, Typography} from '@mui/material'
import {PageWrapper} from '@wrappers'

import React from 'react'

import {renderFormField, useLoadOnce} from 'common'
import {ButtonWrapper} from 'components/pages/contracts/contract-form/contract-form-steps/product-form/style'

import {ComposedComponents, UIKit} from '@components'

import {useDealerFormModel} from './dealer-form-model'
import {RemoveButton, RemoveButtonIcon, SignatoryTitleWrapper} from './style'

/**
 * Dealer Form page
 */
export function DealerFormPage() {
  const {
    onDiscard,
    onSubmit,
    loadData,
    onAddOfficialSignatory,
    dealerOfficialSignatoryFormFields,
    onRemoveOfficialSignatory,
    dealerData,
    t,
    title,
    dealerFormFields,
    dealerContactFormFields,
  } = useDealerFormModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={title}>
      <UIKit.BackgroundCard title={t(TranslationKey['General information'])}>
        <Grid container spacing={2}>
          {dealerFormFields.map((item, index) => (
            <Grid sm={4} item key={`dealer-form-field-${index}`}>
              {renderFormField(item)}
            </Grid>
          ))}
        </Grid>
      </UIKit.BackgroundCard>

      <UIKit.BackgroundCard title={t(TranslationKey['Contact information'])}>
        <Grid container spacing={2}>
          {dealerContactFormFields.map((item, index) => (
            <Grid sm={4} item key={`dealer-contact-form-field-${index}`}>
              {renderFormField(item)}
            </Grid>
          ))}
        </Grid>
      </UIKit.BackgroundCard>

      <UIKit.BackgroundCard title={t(TranslationKey['Official signatories'])}>
        <Stack gap={2}>
          {dealerData.officialSignatories.map((item, index) => (
            <UIKit.BackgroundCard key={`signatory-section-${index}`}>
              <SignatoryTitleWrapper>
                <Typography fontWeight={'bold'}>{`${t(TranslationKey['Official signatory'])} ${index + 1}`}</Typography>
                {index !== 0 && (
                  <RemoveButton onClick={() => onRemoveOfficialSignatory(index)} startIcon={<RemoveButtonIcon />}>
                    {t(TranslationKey['Remove signatory'])}
                  </RemoveButton>
                )}
              </SignatoryTitleWrapper>
              <Grid container spacing={2}>
                {dealerOfficialSignatoryFormFields(index).map((item, signatoryIndex) => (
                  <Grid sm={4} item key={`dealer-official-signatory-form-field-${index}-${signatoryIndex}`}>
                    {renderFormField(item)}
                  </Grid>
                ))}
              </Grid>
            </UIKit.BackgroundCard>
          ))}
          <ButtonWrapper>
            <Button variant="contained" onClick={onAddOfficialSignatory}>
              {t(TranslationKey['Add signatory'])}
            </Button>
          </ButtonWrapper>
        </Stack>
      </UIKit.BackgroundCard>

      <ComposedComponents.FormButtons
        leftButton={{label: t(TranslationKey.Discard), onClick: onDiscard}}
        rightButton={{label: t(TranslationKey.Save), onClick: onSubmit}}
      />
    </PageWrapper>
  )
}
