import {getUserData, isCompanyUser} from '@common'
import {AppContext} from '@contexts'
import {NavigationPathKey, TranslationKey} from '@enums'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined'

import {useContext} from 'react'

import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'

import {iconStyle} from './style'

export interface NavigationLink {
  label: string
  key: NavigationPathKey
  onClick?: () => void
  icon?: JSX.Element
  endIcon?: JSX.Element
}

export function useSideNavigationModel() {
  const location = useLocation()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {handleNavigate} = useContext(AppContext)
  const currentUser = getUserData()

  /**
   * Current location
   */
  const currentLink: NavigationPathKey = location.pathname as NavigationPathKey

  /**
   * @param key Navigation path key
   * @returns If the current link is active
   */
  const getIsActive = (key: NavigationPathKey): boolean => currentLink.startsWith(key)

  /**
   * Navigation links for the Side Panel
   */
  const navigationLinks: NavigationLink[] = [
    {
      label: t(TranslationKey.Dashboard),
      key: NavigationPathKey.DASHBOARD,
      icon: <DashboardOutlinedIcon style={iconStyle} />,
    },
    {
      label: t(TranslationKey['Contract creation']),
      key: NavigationPathKey.CONTRACT_CREATION,
      icon: <NoteAddOutlinedIcon style={iconStyle} />,
    },
    {
      label: t(TranslationKey['Contract search']),
      key: NavigationPathKey.CONTRACT_SEARCH,
      icon: <PlagiarismOutlinedIcon style={iconStyle} />,
    },
    ...(isCompanyUser(currentUser)
      ? []
      : [
          {
            label: t(TranslationKey['Admin panel']),
            key: NavigationPathKey.ADMIN_PANEL,
            icon: <AdminPanelSettingsOutlinedIcon style={iconStyle} />,
          },
        ]),
  ]

  return {
    navigate,
    handleNavigate,
    getIsActive,
    t,
    navigationLinks,
  }
}
