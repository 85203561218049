import {fetchData} from '@api'
import {APIMethod} from '@enums'

export interface SuccessLogResponse {
  id: string
  contractId: string
  message: string
  createdAt: string
}

export interface ErrorLogResponse {
  id: string
  contractId: string
  errorMessage: string
  errorStep: string
  createdAt: string
}

/**
 * @returns Logs of successfull actions
 */
export const getSuccessLogs = async (): Promise<SuccessLogResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `contract/log/success`,
  })
}

/**
 * @returns Logs of errors
 */
export const getErrorLogs = async (): Promise<ErrorLogResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `contract/log/error`,
  })
}

/**
 * @param id Company ID
 * @returns All Logs with a specific Company ID
 */
export const getSuccessLogsByCompany = async (id: string): Promise<SuccessLogResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `contract/log/success/companyId/${id}`,
  })
}

/**
 * @param id Company ID
 * @returns All Logs with a specific Company ID
 */
export const getErrorLogsByCompany = async (id: string): Promise<ErrorLogResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `contract/log/error/companyId/${id}`,
  })
}
