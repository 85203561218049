import {handleAxiosError, getContractsByCompany, getSuccessLogsByCompany, getErrorLogsByCompany} from '@api'
import {getUserCompany, getUserData, getUserTimeFormat, isCompanyUser, renderClickableCell} from '@common'
import {AppContext} from '@contexts'
import {ContractStatus, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import contractCreationImage from '@images/contract-creation-card.jpg'
import contractSearchImage from '@images/contract-search-card.jpg'
import creditCheckImage from '@images/credit-check-card.jpg'
import {GridColDef} from '@mui/x-data-grid'

import {useContext, useState} from 'react'

import {formatDate} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {ComposedComponents} from '@components'

interface TableData {
  time: string
  message: string
  id: string
}

interface ChartData {
  [key: string]: number | string
}

export function useDashboardModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const currentUser = getUserData()
  const [tableRows, setTableRows] = useState<TableData[]>([])
  const [chartData, setChartData] = useState<ChartData[]>([])
  const userTimeFormat = getUserTimeFormat(currentUser.id)

  /**
   * Table columns for the Activity Logs section
   */
  const tableColumns: GridColDef[] = [
    {
      field: 'message',
      headerName: t(TranslationKey.Description),
      flex: 1,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.CONTRACT_VIEW}/${params.row.id}`)),
    },
    {field: 'time', headerName: t(TranslationKey.Timestamp), flex: 0.5, align: 'right', headerAlign: 'right'},
  ]

  /**
   * Navigation Cards props
   */
  const navigationCardItems: ComposedComponents.CardItem[] = [
    {
      title: t(TranslationKey['Contract creation']),
      key: NavigationPathKey.CONTRACT_CREATION,
      image: contractCreationImage,
    },
    {
      title: t(TranslationKey['Contract search']),
      key: NavigationPathKey.CONTRACT_SEARCH,
      image: contractSearchImage,
    },
    ...(isCompanyUser(currentUser)
      ? []
      : [
          {
            title: t(TranslationKey['Admin panel']),
            key: NavigationPathKey.ADMIN_PANEL,
            image: creditCheckImage,
          },
        ]),
  ]

  /**
   * Load data function to be executed only once on component render
   */
  const loadData = async () => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      const successLogs = await getSuccessLogsByCompany(getUserCompany(currentUser.id))
      const errorLogs = await getErrorLogsByCompany(getUserCompany(currentUser.id))

      const formattedLogs = [
        ...successLogs.map(item => ({...item, id: item.contractId, success: true})),
        ...errorLogs.map(item => ({message: item.errorStep, createdAt: item.createdAt, id: '', success: false})),
      ]
        .map(item => ({
          message: item.success ? item.message.replace(item.id, '') : item.message,
          time: item.createdAt,
          id: item.id,
        }))
        .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
        .map(item => ({...item, time: formatDate(item.time, userTimeFormat)}))
        .slice(0, 5)

      const dates = Array.from({length: 7}, (_, i) => {
        const date = new Date()
        date.setDate(date.getDate() - (6 - i))
        return String(date)

        //formatDate(date, userTimeFormat.replaceAll('y', '').slice(0, -1))
      })

      const contracts = await getContractsByCompany(getUserCompany(currentUser.id))

      const datesWithContracts = dates.map(date => {
        const contractsByDate = contracts.filter(
          item => formatDate(item.createdAt, 'dd-MM-yyyy') === formatDate(date, 'dd-MM-yyyy'),
        )

        return {
          date,
          contracts: contractsByDate,
        }
      })

      setChartData(
        datesWithContracts.map(item => ({
          name: formatDate(item.date, userTimeFormat.replaceAll('y', '').slice(0, -1)),
          [t(TranslationKey.Active)]: item.contracts.filter(item => item.status === ContractStatus.ACTIVE).length,
          [t(TranslationKey.Cancelled)]: item.contracts.filter(item => item.status === ContractStatus.CANCELED).length,
          [t(TranslationKey.Draft)]: item.contracts.filter(item => item.status === ContractStatus.DRAFT).length,
          [t(TranslationKey.Quote)]: item.contracts.filter(item => item.status === ContractStatus.QUOTE).length,
          [t(TranslationKey['Sent for signing'])]: item.contracts.filter(
            item => item.status === ContractStatus.SENT_FOR_SIGNING,
          ).length,
          [t(TranslationKey.Signed)]: item.contracts.filter(item => item.status === ContractStatus.SIGNED).length,
        })),
      )

      setTableRows(formattedLogs)
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {
    navigationCardItems,
    tableColumns,
    tableRows,
    chartData,
    loadData,
    t,
  }
}
