import colors from '@colors'
import {TranslationKey} from '@enums'
import {Grid, Stack, Typography} from '@mui/material'
import {PageWrapper} from '@wrappers'

import {renderFormField} from 'common'

import {UIKit} from '@components'

import {useSettingsModel} from './settings-model'
import {ContentContainer, LinkWrapper, InfoContainer, LabelContainer} from './style'

/**
 * Settings page with application information and language and time options
 */
export function SettingsPage() {
  const {settingsFields, informationFields, t} = useSettingsModel()
  return (
    <PageWrapper title={t(TranslationKey['Application information'])}>
      <ContentContainer>
        <Stack gap={2}>
          <UIKit.BackgroundCard title={t(TranslationKey.Information)}>
            <LabelContainer>
              {informationFields.map((item, index) => (
                <Grid key={`information-label-${index}`} item>
                  {renderFormField(item)}
                </Grid>
              ))}
            </LabelContainer>
          </UIKit.BackgroundCard>
          <UIKit.BackgroundCard title={t(TranslationKey.Settings)}>
            <LabelContainer>
              {settingsFields.map((item, index) => (
                <Grid key={`settings-field-${index}`} item>
                  {renderFormField(item)}
                </Grid>
              ))}
            </LabelContainer>
          </UIKit.BackgroundCard>
        </Stack>
        <InfoContainer>
          <LinkWrapper href="https://ampliorental.com/">
            <Typography style={{color: colors.gray[2]}} variant="caption">
              {`Amplio Rental AB | ${new Date().getFullYear()}`}
            </Typography>
          </LinkWrapper>
        </InfoContainer>
      </ContentContainer>
    </PageWrapper>
  )
}
