import {TranslationKey} from '@enums'
import {Stack, Grid, Typography} from '@mui/material'
import {PageWrapper, SearchTableWrapper} from '@wrappers'

import React from 'react'

import {renderFormField, useLoadOnce} from 'common'

import {ComposedComponents, UIKit} from '@components'

import {ContractTypeTab, useContractTypeViewModel} from './contract-type-view-model'

/**
 * Contract Type View page
 */
export function ContractTypeViewPage() {
  const {
    contractTypeFormFields,
    contractTypeAdditionalFormFields,
    contractTypeDiscountFormFields,
    contractTypeRateInfoFormFields,
    title,
    isEditButtonVisible,
    contractTypeDescriptionFormFields,
    t,
    activeTab,
    dealerColumns,
    dealerRows,
    tabButtons,
    isLinkModalShown,
    modalText,
    linkDealerFormField,
    onCloseLinkModal,
    setIsLinkModalShown,
    loadData,
    onPressBack,
    onPressEdit,
    onLink,
  } = useContractTypeViewModel()

  useLoadOnce(loadData)
  return (
    <PageWrapper title={title}>
      <ComposedComponents.TabButtons activeTab={activeTab} buttons={tabButtons} />
      {activeTab === ContractTypeTab.GENERAL && (
        <Stack gap={5}>
          <UIKit.BackgroundCard>
            <Grid container spacing={2}>
              {contractTypeFormFields.map((item, index) => (
                <Grid sm={4} item key={`contract-type-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>

          <UIKit.BackgroundCard title={t(TranslationKey.Discounts)}>
            <Grid container spacing={2}>
              {contractTypeDiscountFormFields.map((item, index) => (
                <Grid sm={4} item key={`contract-type-form-discount-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>

          <UIKit.BackgroundCard title={t(TranslationKey['Rate information'])}>
            <Grid container spacing={2}>
              {contractTypeRateInfoFormFields.map((item, index) => (
                <Grid sm={4} item key={`contract-type-form-rate-information-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>

          <UIKit.BackgroundCard title={t(TranslationKey['Additional Information'])}>
            <Grid container spacing={2}>
              {contractTypeAdditionalFormFields.map((item, index) => (
                <Grid sm={3} item key={`contract-type-form-additional-information-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>

          <UIKit.BackgroundCard title={t(TranslationKey['Description and disclaimer'])}>
            <Grid container spacing={2}>
              {contractTypeDescriptionFormFields.map((item, index) => (
                <Grid sm={6} item key={`contract-type-form-description-information-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>
        </Stack>
      )}
      {activeTab === ContractTypeTab.DEALERS && (
        <SearchTableWrapper>
          <UIKit.Table
            {...(isEditButtonVisible && {
              buttonProps: {
                buttonText: t(TranslationKey['Link a dealer']),
                onClick: () => setIsLinkModalShown(true),
              },
            })}
            columns={dealerColumns}
            rows={dealerRows}
            title={t(TranslationKey['Linked dealers'])}
          />
        </SearchTableWrapper>
      )}
      <ComposedComponents.FormButtons
        leftButton={{
          label: t(TranslationKey.Back),
          onClick: onPressBack,
        }}
        {...(isEditButtonVisible && {
          rightButton: {
            label: t(TranslationKey.Edit),
            onClick: onPressEdit,
          },
        })}
      />
      <UIKit.Modal
        open={isLinkModalShown}
        title={`${t(TranslationKey['Link a contract type'])}?`}
        content={
          <Stack alignItems={'center'} gap={2}>
            <Typography>{modalText}</Typography>
            <Stack style={{width: '15rem'}}>{renderFormField(linkDealerFormField)}</Stack>
          </Stack>
        }
        confirmButtonProps={{buttonText: t(TranslationKey.Link), onClick: onLink}}
        cancelButtonProps={{buttonText: t(TranslationKey.Cancel), onClick: onCloseLinkModal}}
      />
    </PageWrapper>
  )
}
