import {renderFormField, useLoadOnce} from '@common'
import {TranslationKey} from '@enums'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone'
import ForwardIcon from '@mui/icons-material/Forward'
import SaveIcon from '@mui/icons-material/Save'
import {Grid, Stack, Typography} from '@mui/material'
import {PageWrapper} from '@wrappers'

import React from 'react'

import {ComposedComponents, UIKit} from '@components'

import {useBulkProductUploadModel} from './bulk-product-upload-model'
import {
  disabledIconStyle,
  disabledTextStyle,
  DropzoneWrapper,
  errorIconStyle,
  iconStyle,
  IllustrationWrapper,
} from './style'

/**
 * Bulk Product Upload page
 */
export function BulkProductUploadPage() {
  const {
    companyFormField,
    formData,
    formFieldErrors,
    isDownloadDisabled,
    isUploadDisabled,
    t,
    handleDownload,
    loadData,
    handleDrop,
    onSubmit,
    onDiscard,
  } = useBulkProductUploadModel()

  useLoadOnce(loadData)
  return (
    <PageWrapper title={t(TranslationKey['Bulk upload'])}>
      <UIKit.BackgroundCard>
        <Grid container spacing={5}>
          <Grid item sm={6}>
            <Stack style={{justifyContent: 'space-between', height: '100%'}} gap={2}>
              <Stack gap={2}>
                <Typography textAlign={'justify'}>
                  {t(
                    TranslationKey[
                      'In order to bulk upload, you must prepare an Excel file in a specific format. You can download the template file, populate it, and upload.'
                    ],
                  )}
                </Typography>
                <Typography textAlign={'justify'}>
                  {t(
                    TranslationKey[
                      'On the sheet Products enter the required Product information in each row. In the Accessories columns, enter all the Accessories ID you want to link to the specific product, separated with a comma. You can find the Accessory ID on the Accessories sheet.'
                    ],
                  )}
                </Typography>
                <Typography textAlign={'justify'}>
                  {t(
                    TranslationKey[
                      'For each Contract type you will have a separate sheet. There, you can enter a specific set of prices for each Product or Accessory. You may leave these fields blank.'
                    ],
                  )}
                </Typography>
                <Typography fontWeight={'bold'} textAlign={'justify'}>
                  {t(TranslationKey['Some important notes:'])}
                </Typography>
                <Typography textAlign={'justify'}>
                  {`- ${t(TranslationKey['For each new bulk upload, please download a new template file and do not reuse an old one'])}`}
                </Typography>
                <Typography textAlign={'justify'}>
                  {`- ${t(TranslationKey['Do not add or remove any rows, columns or sheets'])}`}
                </Typography>
                <Typography textAlign={'justify'}>
                  {`- ${t(TranslationKey['Do not edit or remove any of the highlighted cells'])}`}
                </Typography>
              </Stack>
              <IllustrationWrapper>
                <Stack alignItems={'center'}>
                  <DownloadForOfflineOutlinedIcon style={{fontSize: '3rem'}} />
                  <Typography>Download template</Typography>
                </Stack>
                <ForwardIcon style={{fontSize: '2rem'}} />
                <Stack alignItems={'center'}>
                  <EditNoteIcon style={{fontSize: '3rem'}} />
                  <Typography>Populate file</Typography>
                </Stack>
                <ForwardIcon style={{fontSize: '2rem'}} />
                <Stack alignItems={'center'}>
                  <AttachFileIcon style={{fontSize: '3rem'}} />
                  <Typography>Upload file</Typography>
                </Stack>
                <ForwardIcon style={{fontSize: '2rem'}} />
                <Stack alignItems={'center'}>
                  <SaveIcon style={{fontSize: '3rem'}} />
                  <Typography>Bulk upload</Typography>
                </Stack>
              </IllustrationWrapper>
            </Stack>
          </Grid>
          <Grid item sm={6}>
            <Stack gap={2}>
              {renderFormField(companyFormField)}
              <DropzoneWrapper onClick={handleDownload}>
                <DownloadForOfflineOutlinedIcon style={isDownloadDisabled ? disabledIconStyle : iconStyle} />
                <Typography style={isDownloadDisabled ? disabledTextStyle : {}}>
                  {t(TranslationKey['Click to download the template file'])}
                </Typography>
              </DropzoneWrapper>
              {isUploadDisabled ? (
                <DropzoneWrapper>
                  {formFieldErrors.includes('data') ? (
                    <ErrorOutlineIcon style={errorIconStyle} />
                  ) : (
                    <>
                      <AttachFileIcon style={disabledIconStyle} />
                      <Typography style={disabledTextStyle}>
                        {t(TranslationKey['Click or drag a file to upload'])}
                      </Typography>
                    </>
                  )}
                </DropzoneWrapper>
              ) : (
                <UIKit.ImageDropzone
                  handleDrop={handleDrop}
                  image={
                    formData.data ? (
                      <DropzoneWrapper>
                        <FileDownloadDoneIcon style={iconStyle} />
                        <Typography>{t(TranslationKey['File successfully uploaded'])}</Typography>
                      </DropzoneWrapper>
                    ) : null
                  }
                  placeholder={
                    <DropzoneWrapper>
                      {formFieldErrors.includes('data') ? (
                        <ErrorOutlineIcon style={errorIconStyle} />
                      ) : (
                        <>
                          <AttachFileIcon style={iconStyle} />
                          <Typography>{t(TranslationKey['Click or drag a file to upload'])}</Typography>
                        </>
                      )}
                    </DropzoneWrapper>
                  }
                />
              )}
              <ComposedComponents.FormButtons
                leftButton={{label: t(TranslationKey.Discard), onClick: onDiscard}}
                rightButton={{label: t(TranslationKey.Save), onClick: onSubmit}}
              />
            </Stack>
          </Grid>
        </Grid>
      </UIKit.BackgroundCard>
    </PageWrapper>
  )
}
