import {APIMethod, UserRole} from '@enums'

import {fetchData} from 'api/api'
import {ItemSignature} from 'common/types'

interface BaseUser {
  fullName: string
  password: string
  email: string
  image: string
  companyId: string | null
  dealerId: string | null
  role: UserRole | null
  active: boolean
}

export type UserData = BaseUser
export interface LoggedInUser extends BaseUser {
  id: string
  role: UserRole
}
export interface UserRequest extends BaseUser {
  id?: string
  companyId: string
  dealerId: string
  role: UserRole
}
export interface UserResponse extends BaseUser, ItemSignature {
  id: string
  email: string
  fullName: string
  image: string
  companyId: string
  dealerId: string
  role: UserRole
}

/**
 * @returns All Users
 */
export const getUsers = async (): Promise<UserResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: 'user/all',
  })
}

/**
 * @param id Company ID
 * @returns All Users with a specific Company ID
 */
export const getUsersByCompany = async (id: string): Promise<UserResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `user/company/${id}`,
  })
}

/**
 * @param id User ID
 * @returns A single User with a specific ID
 */
export const getUser = async (id: string): Promise<UserResponse> => {
  return fetchData({
    method: APIMethod.GET,
    url: `user/${id}`,
  })
}

/**
 * Updates an existing User
 * @param id User ID
 * @param body User payload data
 * @returns Updated User data
 */
export const updateUser = async (id: string, body: UserRequest): Promise<UserResponse> => {
  return fetchData({
    method: APIMethod.PUT,
    url: `user/${id}`,
    body,
  })
}
