import {APIMethod} from '@enums'

import {fetchData} from 'api/api'

import {UserRequest, UserResponse} from './user'

export interface LoginRequest {
  email: string
  password: string
}

export interface LoginResponse {
  token: string
}

/**
 * Authentication request
 * @param body Authentication payload data
 * @returns A valid token
 */
export const login = async (body: LoginRequest): Promise<LoginResponse> => {
  return fetchData({
    method: APIMethod.POST,
    url: 'authenticate',
    body,
  })
}

/**
 * Registration request
 * @param body User payload data
 * @returns New User data
 */
export const register = async (body: UserRequest): Promise<UserResponse> => {
  return fetchData({
    method: APIMethod.POST,
    url: 'user/register',
    body,
  })
}
