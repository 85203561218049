import {InputLabel, Stack, styled} from '@mui/material'

export const InputWithLabelWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== 'width',
})(({width}) => ({
  width: (width as number) ?? 'auto',
}))

export const Label = styled(InputLabel)(({theme}) => ({
  textAlign: 'start',
  color: theme.palette.grey[100],
  ...theme.typography.body1,
}))
