import {Stack, StackProps, styled} from '@mui/material'

export const CardContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'image',
})<StackProps & {image: string}>(({image}) => ({
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPositionY: '50%',
  width: '100%',
  height: '15rem',
  borderRadius: '1rem',
}))

export const ContentWrapper = styled(Stack)(({theme}) => ({
  backgroundColor: '#252424B2',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  borderRadius: '1rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: `${theme.palette.primary.main}AB`,
  },
}))

export const CardsRowWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: '2rem',
  justifyContent: 'space-between',
}))

export const CardsWrapper = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'column',
  gap: '2rem',
}))
