import {AppContext} from '@contexts'
import {ThemeProvider} from '@emotion/react'
import {TranslationKey} from '@enums'
import {Alert, Slide, Snackbar, Stack, Typography} from '@mui/material'
import {theme} from '@styles'

import React, {ReactNode, useContext, useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'

import {UIKit} from '@components'

interface Props {
  children: ReactNode
}

/**
 * App wrapper component used as a container for all application screens
 */
export function AppWrapper(props: Props) {
  const {
    loadingStatus,
    error,
    setError,
    successMessage,
    setSuccessMessage,
    isWarningModalShown,
    setIsWarningModalShown,
    handleDiscard,
    loadingMessage,
  } = useContext(AppContext)

  const {children} = props

  const [isMessageOpen, setIsMessageOpen] = useState(false)

  useEffect(() => {
    if (!!error || !!successMessage) setIsMessageOpen(true)
  }, [error, successMessage])

  const onClose = () => {
    setIsMessageOpen(false)
    setTimeout(() => {
      setError(undefined)
      setSuccessMessage(undefined)
    }, 250)
  }

  const {t} = useTranslation()

  return (
    <ThemeProvider theme={theme}>
      <UIKit.LoaderOverlay loadingStatus={loadingStatus} message={loadingMessage} />

      <UIKit.Modal
        open={isWarningModalShown}
        title={`${t(TranslationKey.Discard)}?`}
        content={
          <Stack alignItems={'center'} gap={2}>
            <Typography>{t(TranslationKey['You have unsaved changes.'])}</Typography>
            <Typography fontWeight={'bold'}>
              {`${t(TranslationKey['If you continue, your changes will not be saved'])}!`}
            </Typography>
            <Typography>{t(TranslationKey['Continue and discard?'])}</Typography>
          </Stack>
        }
        confirmButtonProps={{buttonText: t(TranslationKey['Go back']), onClick: () => setIsWarningModalShown(false)}}
        cancelButtonProps={{buttonText: t(TranslationKey.Discard), onClick: () => handleDiscard()}}
      />

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        TransitionComponent={Slide}
        open={isMessageOpen}
        onClose={onClose}
        autoHideDuration={5000}
      >
        <Alert severity={error ? 'error' : 'success'} variant="filled" sx={{width: '100%'}}>
          {error?.message}
          {successMessage}
        </Alert>
      </Snackbar>
      {children}
    </ThemeProvider>
  )
}
