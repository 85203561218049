import {useLoadOnce} from '@common'
import {ContractStatus, TranslationKey} from '@enums'
import {PageWrapper} from '@wrappers'

import React from 'react'

import {ComposedComponents} from '@components'

import {useContactFormModel} from './contract-form-model'
import {ContractProductForm, ContractPDFOverview, ContractSetupForm, CustomerForm} from './contract-form-steps'
import {BottomButtonsWrapper} from './style'

/**
 * Contract Form page
 */
export function ContractFormPage() {
  const {
    steps,
    activeStep,
    formData,
    company,
    dropdownOptions,
    formFieldErrors,
    t,
    isSignatureOptionsModalVisible,
    signingFormData,
    setupData,
    onSaveContract,
    setDropdownOptions,
    setSigningFormData,
    setFormData,
    isBackBtnVisible,
    onLeftBtnClick,
    onCenterBtnClick,
    onRightBtnClick,
    isLastStep,
    loadData,
    onSendForSigningBtnClick,
    setIsSignatureOptionsModalVisible,
    isFirstStep,
    lookupMap,
    updateErrors,
    updateFormData,
  } = useContactFormModel()

  useLoadOnce(loadData)

  const getCurrentForm = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return (
          <ContractSetupForm
            dropdownOptions={dropdownOptions}
            formData={formData}
            formFieldErrors={formFieldErrors}
            setupData={setupData}
            setDropdownOptions={setDropdownOptions}
            updateErrors={updateErrors}
            updateFormData={updateFormData}
            lookupMap={lookupMap}
            onRightBtnClick={onRightBtnClick}
            setFormData={setFormData}
          />
        )
      case 1:
        return (
          <ContractProductForm
            dropdownOptions={dropdownOptions}
            formData={formData}
            formFieldErrors={formFieldErrors}
            setupData={setupData}
            setFormData={setFormData}
            updateErrors={updateErrors}
            updateFormData={updateFormData}
          />
        )
      case 2:
        return (
          <CustomerForm
            formData={formData}
            formFieldErrors={formFieldErrors}
            updateErrors={updateErrors}
            updateFormData={updateFormData}
            setFormData={setFormData}
          />
        )
      case 3:
        return (
          <ContractPDFOverview
            isSignatureOptionsModalVisible={isSignatureOptionsModalVisible}
            setIsSignatureOptionsModalVisible={setIsSignatureOptionsModalVisible}
            signingFormData={signingFormData}
            formData={formData}
            setSigningFormData={setSigningFormData}
            onSendForSigningBtnClick={onSendForSigningBtnClick}
          />
        )
      default:
        return <></>
    }
  }
  return (
    <PageWrapper title={t(TranslationKey['Contract creation'])}>
      <ComposedComponents.ContractHeader company={company} steps={steps} activeStep={activeStep} />
      {getCurrentForm(activeStep)}
      <BottomButtonsWrapper>
        <ComposedComponents.FormButtons
          leftButton={{label: t(TranslationKey.Discard), onClick: onLeftBtnClick}}
          {...(isBackBtnVisible() && {centerButton: {label: t(TranslationKey.Back), onClick: onCenterBtnClick}})}
          {...(isLastStep() && {
            auxiliaryButton: {
              label: t(TranslationKey['Save as draft']),
              onClick: async () => await onSaveContract(ContractStatus.DRAFT),
            },
          })}
          {...(!isFirstStep() && {
            rightButton: {
              label: t(TranslationKey[isLastStep() ? 'Save and send for signing' : 'Next']),
              onClick: onRightBtnClick,
            },
          })}
        />
      </BottomButtonsWrapper>
    </PageWrapper>
  )
}
