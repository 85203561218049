import {ContractTypeResponse, getContractTypes, getContractTypesByCompany, LoggedInUser, handleAxiosError} from '@api'
import {activeCell, getUserCompany, getUserData, isCompanyUser, isSuperAdmin, renderClickableCell} from '@common'
import {AppContext} from '@contexts'
import {LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef} from '@mui/x-data-grid'

import {useContext, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

export interface ContractTypeTableData {
  name: string
  description: string
  active: boolean
}

export function useContractTypeSearchModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const currentUser: LoggedInUser = getUserData()
  const {t} = useTranslation()
  const [rows, setRows] = useState<ContractTypeTableData[]>([])
  const navigate = useNavigate()

  /**
   * Create button should only be available to Administrator users
   */
  const showCreateButton = !isCompanyUser(currentUser)

  /**
   * Contract Type table columns configuration
   */
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 0.8,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.CONTRACT_TYPE_VIEW}/${params.row.id}`)),
    },
    {field: 'description', headerName: t(TranslationKey.Description), flex: 1},
    activeCell(t),
  ]

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      let data: ContractTypeResponse[] = []

      // Super Administrator can see ALL the data
      if (isSuperAdmin(currentUser)) {
        data = await getContractTypes()
      }
      // Company Administrator and Company User see ONLY their Contract types and ONLY their Company
      else {
        data = await getContractTypesByCompany(String(currentUser.companyId))
      }

      const formattedData: ContractTypeTableData[] = data
        .filter(item => (isSuperAdmin(currentUser) ? item.companyId === getUserCompany(currentUser.id) : true))
        .map(item => ({
          name: item.name,
          description: item.note,
          active: item.active,
          id: item.id,
        }))

      setRows(formattedData)
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {loadData, navigate, t, showCreateButton, rows, columns}
}
