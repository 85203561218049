import {getPageSizes, getTableColumns, getTableRows} from '@common'
import {Stack, Typography} from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid'

import React, {useMemo, useState} from 'react'

import {UIKit} from '@components'

import {SearchContainer, StyledButton, TableContainer, TableHeightWrapper, TopBarContainer} from './style'

type RowType<T extends object> = T

type ButtonProps = {
  buttonText: string
  onClick: () => void
}

interface Props<T extends object> {
  columns: GridColDef[]
  rows: RowType<T>[]
  title: string
  hidePagination?: boolean
  hideSearch?: boolean
  hideToolbar?: boolean
  buttonProps?: ButtonProps
  additionalButtonProps?: ButtonProps
  visibilityModel?: GridColumnVisibilityModel
}

/**
 * Table Component with optional title and button props
 */
export function Table<T extends object>(props: Props<T>) {
  const {
    columns,
    rows,
    hidePagination,
    additionalButtonProps,
    hideToolbar,
    hideSearch,
    title,
    visibilityModel,
    buttonProps,
  } = props

  const pageSizeOptions: UIKit.DropdownItem[] = useMemo(() => getPageSizes(), [])
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: Number(pageSizeOptions[0].value),
  })

  const displayRows = useMemo(() => getTableRows(rows), [rows])
  const displayColumns = useMemo(() => getTableColumns(columns), [columns])

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <Stack style={{flexDirection: 'row', gap: '1rem'}}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport printOptions={{disableToolbarButton: true}} />
        </Stack>
        {!hideSearch && <GridToolbarQuickFilter />}
      </GridToolbarContainer>
    )
  }

  return (
    <TableContainer>
      <TopBarContainer>
        <Typography variant="h2">{title}</Typography>
        <SearchContainer>
          {buttonProps && (
            <StyledButton onClick={buttonProps.onClick} variant="contained">
              {buttonProps.buttonText}
            </StyledButton>
          )}
          {additionalButtonProps && (
            <StyledButton onClick={additionalButtonProps.onClick} variant="outlined">
              {additionalButtonProps.buttonText}
            </StyledButton>
          )}
        </SearchContainer>
      </TopBarContainer>
      <TableHeightWrapper>
        <DataGrid
          autoHeight
          disableRowSelectionOnClick
          rowHeight={40}
          rows={displayRows}
          initialState={{
            columns: {
              columnVisibilityModel: visibilityModel,
            },
          }}
          columns={displayColumns}
          getRowId={row => `${title}-${row.no}`}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={model => setPaginationModel(model)}
          pageSizeOptions={pageSizeOptions.map(option => Number(option.value))}
          slotProps={{
            toolbar: {
              showQuickFilter: !hideSearch,
            },
          }}
          slots={!hideToolbar ? {toolbar: CustomToolbar} : undefined}
          hideFooter={hidePagination}
        />
      </TableHeightWrapper>
    </TableContainer>
  )
}
