import {Paper, PaperProps, Stack, styled} from '@mui/material'

export const StyledPaper = styled(Paper, {
  shouldForwardProp: prop => prop !== 'width' && prop !== 'height',
})<PaperProps & {width?: number | string; height?: number | string}>(({width, height}) => ({
  padding: '2rem',
  borderRadius: '1rem',
  width: width,
  height: height,
}))

export const TitleWrapper = styled(Stack)(() => ({
  alignItems: 'start',
  paddingBottom: '3rem',
}))
