import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import {InputAdornment, TextField} from '@mui/material'
import {CommonProps} from '@mui/material/OverridableComponent'
import {theme} from '@styles'

import React from 'react'

import {DropdownItem} from '../dropdown'
import {LabeledWrapper} from '../wrapper-with-label'
import {adornmentStyle, StyledAutocomplete} from './style'

export interface AutocompleteProps {
  items: DropdownItem[]
  renderValue?: () => JSX.Element
  customStyle?: CommonProps['style']
  label?: string
  value?: string | number
  hideBorder?: boolean
  width?: number
  error?: boolean
  disabled?: boolean
  placeholder?: string
  listboxHeight?: number
  onChange?: (item: DropdownItem | null) => void
}

/**
 * Searchable Dropdown component
 */
export function SearchableDropdown(props: AutocompleteProps) {
  const {label, listboxHeight, hideBorder, width, items, error, disabled, value, placeholder, onChange} = props

  return (
    <LabeledWrapper width={width} label={label}>
      <StyledAutocomplete
        hideBorder={hideBorder}
        disablePortal
        disabled={disabled}
        options={items}
        {...(listboxHeight && {
          ListboxProps: {
            style: {
              maxHeight: listboxHeight,
            },
          },
        })}
        value={(value && items.find(item => item.value == value)) || null}
        renderInput={params => {
          const {InputProps, ...restParams} = params
          return (
            <TextField
              placeholder={placeholder ?? label}
              InputProps={{
                ...InputProps,
                endAdornment: error ? (
                  <InputAdornment style={adornmentStyle} position="end">
                    <ErrorOutlineRoundedIcon style={{color: theme.palette.error.main}} />
                  </InputAdornment>
                ) : disabled ? (
                  <></>
                ) : (
                  InputProps.endAdornment
                ),
              }}
              {...restParams}
            />
          )
        }}
        onChange={(event, item) => {
          if (onChange) onChange(item as DropdownItem | null)
        }}
      />
    </LabeledWrapper>
  )
}
