import {TranslationKey} from '@enums'
import {Button, Grid, Stack, Typography} from '@mui/material'
import {PageWrapper} from '@wrappers'

import React from 'react'

import {renderFormField, useLoadOnce} from 'common'
import {RemoveButton} from 'components/pages/dealers/dealer-form/style'

import {ComposedComponents, UIKit} from '@components'

import {useAccessoryFormModel} from './accessory-form-model'
import {ButtonWrapper, PriceTitleWrapper, RemoveButtonIcon} from './style'

/**
 * Accessory Form page
 */
export function AccessoryFormPage() {
  const {
    onDiscard,
    onSubmit,
    onAddAccessoryPrice,
    onRemoveAccessoryPrice,
    loadData,
    accessoryPriceFormFields,
    accessoryPricePerLengthFormFields,
    isAddPriceBtnVisible,
    accessoryData,
    t,
    title,
    accessoryGeneralFormFields,
    accessoryDefaultPriceFormFields,
  } = useAccessoryFormModel()

  useLoadOnce(loadData)
  return (
    <PageWrapper title={title}>
      <UIKit.BackgroundCard title={t(TranslationKey['General information'])}>
        <Grid container spacing={2}>
          {accessoryGeneralFormFields.map((item, index) => (
            <Grid sm={3} item key={`accessory-general-form-field-${index}`}>
              {renderFormField(item)}
            </Grid>
          ))}
        </Grid>
      </UIKit.BackgroundCard>

      <UIKit.BackgroundCard title={t(TranslationKey.Prices)}>
        <Stack gap={2}>
          <UIKit.BackgroundCard title={t(TranslationKey['Default prices'])}>
            <Grid container spacing={2}>
              {accessoryDefaultPriceFormFields.map((item, index) => (
                <Grid sm={3} item key={`accessory-price-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>
          {accessoryData.contractTypePrices.map((item, index) => (
            <UIKit.BackgroundCard>
              <PriceTitleWrapper>
                <Typography fontWeight={'bold'}>{`${t(TranslationKey.Price)} ${index + 1}`}</Typography>

                <RemoveButton onClick={() => onRemoveAccessoryPrice(index)} startIcon={<RemoveButtonIcon />}>
                  {t(TranslationKey['Remove price'])}
                </RemoveButton>
              </PriceTitleWrapper>
              <Stack gap={2}>
                <Grid container spacing={2}>
                  {accessoryPriceFormFields(index).map((item, setIndex) => (
                    <Grid sm={setIndex === 0 ? 6 : 3} item key={`accessory-price-form-field-${index}-${setIndex}`}>
                      {renderFormField(item)}
                    </Grid>
                  ))}
                </Grid>
                <Stack>
                  <Stack alignItems={'flex-start'}>
                    <Typography fontWeight={'bold'}>{t(TranslationKey['Custom monthly fees'])}</Typography>
                  </Stack>
                  <Grid container spacing={2}>
                    {accessoryPricePerLengthFormFields(index).map((item, setIndex) => (
                      <Grid sm={3} item key={`accessory-price-per-length-form-field-${index}-${setIndex}`}>
                        {renderFormField(item)}
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Stack>
            </UIKit.BackgroundCard>
          ))}
          {!isAddPriceBtnVisible && (
            <ButtonWrapper>
              <Button variant="contained" onClick={onAddAccessoryPrice}>
                {t(TranslationKey['Add price'])}
              </Button>
            </ButtonWrapper>
          )}
        </Stack>
      </UIKit.BackgroundCard>

      <ComposedComponents.FormButtons
        leftButton={{label: t(TranslationKey.Discard), onClick: onDiscard}}
        rightButton={{label: t(TranslationKey.Save), onClick: onSubmit}}
      />
    </PageWrapper>
  )
}
