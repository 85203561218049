import {NavigationPathKey, UserRole} from '@enums'
import {PrivateRoutes} from '@routes'
import {AppWrapper} from '@wrappers'

import React from 'react'

import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'

import {Pages} from '@components'

import './App.css'

function App() {
  return (
    <div className="App">
      <AppWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Pages.NotFoundPage />} />
            <Route path="/" element={<Navigate to={NavigationPathKey.LOGIN} />} />

            {/* Authentication */}
            <Route path={NavigationPathKey.LOGOUT} element={<Pages.LogoutPage />} />
            <Route path={NavigationPathKey.LOGIN} element={<Pages.LoginPage />} />

            {/* All Users */}
            <Route
              element={
                <PrivateRoutes allowedRoles={[UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN, UserRole.COMPANY_USER]} />
              }
            >
              <Route path={NavigationPathKey.DASHBOARD} element={<Pages.DashboardPage />} />

              {/* Companies */}
              <Route path={NavigationPathKey.COMPANY_SEARCH} element={<Pages.CompanySearchPage />} />
              <Route path={`${NavigationPathKey.COMPANY_VIEW}/:id`} element={<Pages.CompanyViewPage />} />

              {/* Dealers */}
              <Route path={NavigationPathKey.DEALER_SEARCH} element={<Pages.DealerSearchPage />} />
              <Route path={`${NavigationPathKey.DEALER_VIEW}/:id`} element={<Pages.DealerViewPage />} />

              {/* Contract types */}
              <Route path={NavigationPathKey.CONTRACT_TYPE_SEARCH} element={<Pages.ContractTypeSearchPage />} />
              <Route path={`${NavigationPathKey.CONTRACT_TYPE_VIEW}/:id`} element={<Pages.ContractTypeViewPage />} />

              {/* Users */}
              <Route path={NavigationPathKey.USER_SEARCH} element={<Pages.UserSearchPage />} />
              <Route path={`${NavigationPathKey.USER_CREATION}/:id`} element={<Pages.UserFormPage />} />
              <Route path={`${NavigationPathKey.USER_VIEW}/:id`} element={<Pages.UserViewPage />} />

              {/* Products */}
              <Route path={NavigationPathKey.PRODUCT_SEARCH} element={<Pages.ProductSearchPage />} />
              <Route path={`${NavigationPathKey.PRODUCT_VIEW}/:id`} element={<Pages.ProductViewPage />} />

              {/* Accessories */}
              <Route path={NavigationPathKey.ACCESSORY_SEARCH} element={<Pages.AccessorySearchPage />} />
              <Route path={`${NavigationPathKey.ACCESSORY_VIEW}/:id`} element={<Pages.AccessoryViewPage />} />

              {/* Settings */}
              <Route path={NavigationPathKey.SETTINGS} element={<Pages.SettingsPage />} />

              {/* Contracts */}
              <Route path={NavigationPathKey.CONTRACT_SEARCH} element={<Pages.ContractSearchPage />} />
              <Route path={NavigationPathKey.CONTRACT_CREATION} element={<Pages.ContractFormPage />} />
              <Route path={`${NavigationPathKey.CONTRACT_CREATION}/:id`} element={<Pages.ContractFormPage />} />
              <Route path={`${NavigationPathKey.CONTRACT_VIEW}/:id`} element={<Pages.ContractViewPage />} />
            </Route>

            {/* Administrators Only */}
            <Route element={<PrivateRoutes allowedRoles={[UserRole.SUPER_ADMIN, UserRole.COMPANY_ADMIN]} />}>
              {/* Companies */}
              <Route path={`${NavigationPathKey.COMPANY_CREATION}/:id`} element={<Pages.CompanyFormPage />} />

              {/* Dealers */}
              <Route path={NavigationPathKey.DEALER_CREATION} element={<Pages.DealerFormPage />} />
              <Route path={`${NavigationPathKey.DEALER_CREATION}/:id`} element={<Pages.DealerFormPage />} />

              {/* Contract types */}
              <Route path={NavigationPathKey.CONTRACT_TYPE_CREATION} element={<Pages.ContractTypeFormPage />} />
              <Route
                path={`${NavigationPathKey.CONTRACT_TYPE_CREATION}/:id`}
                element={<Pages.ContractTypeFormPage />}
              />

              {/* Users */}
              <Route path={NavigationPathKey.USER_CREATION} element={<Pages.UserFormPage />} />

              {/* Products */}
              <Route path={NavigationPathKey.PRODUCT_CREATION} element={<Pages.ProductFormPage />} />
              <Route path={`${NavigationPathKey.PRODUCT_CREATION}/:id`} element={<Pages.ProductFormPage />} />
              <Route path={NavigationPathKey.PRODUCT_BULK_CREATION} element={<Pages.BulkProductUploadPage />} />

              {/* Accessories */}
              <Route path={NavigationPathKey.ACCESSORY_CREATION} element={<Pages.AccessoryFormPage />} />
              <Route path={`${NavigationPathKey.ACCESSORY_CREATION}/:id`} element={<Pages.AccessoryFormPage />} />

              {/* Logs */}
              <Route path={NavigationPathKey.LOGS} element={<Pages.LogsSearchPage />} />

              {/* Admin Panel */}
              <Route path={NavigationPathKey.ADMIN_PANEL} element={<Pages.AdminDashboardPage />} />
              <Route
                path={NavigationPathKey.PRODUCT_DASHBOARD}
                element={<Pages.ProductAndAccessoriesDashboardPage />}
              />
            </Route>

            {/* Super Administrator Only */}
            <Route element={<PrivateRoutes allowedRoles={[UserRole.SUPER_ADMIN]} />}>
              {/* Companies */}
              <Route path={NavigationPathKey.COMPANY_CREATION} element={<Pages.CompanyFormPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AppWrapper>
    </div>
  )
}

export default App
