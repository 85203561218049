import {TranslationKey} from '@enums'
import {Grid} from '@mui/material'
import {PageWrapper} from '@wrappers'

import React from 'react'

import {onImageError, renderFormField, useLoadOnce} from 'common'

import {ComposedComponents, UIKit} from '@components'

import {useCompanyFormModel} from './company-form-model'
import {imageStyle, ImageWrapper} from './style'

/**
 * Company Form page
 */
export function CompanyFormPage() {
  const {companyGeneralFormFields, image, title, t, handleDrop, onSubmit, onDiscard, loadData} = useCompanyFormModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={title}>
      <Grid container spacing={4}>
        <Grid item sm={3}>
          <UIKit.BackgroundCard>
            <UIKit.ImageDropzone
              image={
                <ImageWrapper>
                  <img src={image} alt="Company logo" style={imageStyle} onError={onImageError} />
                </ImageWrapper>
              }
              handleDrop={handleDrop}
            />
          </UIKit.BackgroundCard>
        </Grid>
        <Grid item sm={9}>
          <UIKit.BackgroundCard>
            <Grid container spacing={2}>
              {companyGeneralFormFields.map((item, index) => (
                <Grid item sm={6} key={`company-general-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>
        </Grid>
      </Grid>

      <ComposedComponents.FormButtons
        leftButton={{label: t(TranslationKey.Discard), onClick: onDiscard}}
        rightButton={{label: t(TranslationKey.Save), onClick: onSubmit}}
      />
    </PageWrapper>
  )
}
