import {NavigationPathKey} from '@enums'
import {Typography} from '@mui/material'

import React from 'react'

import {useNavigate} from 'react-router-dom'

import {CardContainer, CardsRowWrapper, CardsWrapper, ContentWrapper} from './style'

export type CardItem = {
  title: string
  image: string
  key: NavigationPathKey
}

interface Props {
  items: CardItem[]
  itemsInRow?: number
}

/**
 * Navigation Cards component that renders cards with a background image and label
 * @param props Navigation Cards props
 */
export function NavigationCards(props: Props) {
  const {items, itemsInRow = 3} = props

  const rows = Math.ceil(items.length / itemsInRow)

  const navigate = useNavigate()

  return (
    <CardsWrapper>
      {[...Array(rows)].map((_, index) => (
        <CardsRowWrapper key={`navigation-cards-row-wrapper-${index}`}>
          {items.slice(index * itemsInRow, (index + 1) * itemsInRow).map((item, itemIndex) => {
            const {title, image, key} = item
            return (
              <CardContainer key={`navigation-card-item-${index}-${itemIndex}`} image={image}>
                <ContentWrapper onClick={() => navigate(key)}>
                  <Typography variant="h2">{title}</Typography>
                </ContentWrapper>
              </CardContainer>
            )
          })}
        </CardsRowWrapper>
      ))}
    </CardsWrapper>
  )
}
