/* eslint-disable @typescript-eslint/no-explicit-any */
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import {InputAdornment, TextField, TextFieldProps, useTheme} from '@mui/material'
import {CommonProps} from '@mui/material/OverridableComponent'

import React, {useState, useEffect, useMemo} from 'react'

import {IMaskInput} from 'react-imask'

import {LabeledWrapper} from '../wrapper-with-label/labeled-wrapper'

export type PhoneInputFieldProps = TextFieldProps & {
  label?: string
  error?: boolean
  hideLabel?: boolean
  mask?: string
  customStyle?: CommonProps['style']
  width?: number
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, any>((props, ref) => {
  const {onChange, mask, ...rest} = props

  return (
    <IMaskInput
      {...rest}
      mask={mask}
      definitions={{
        '#': /[0-9]/,
      }}
      placeholderChar={'_'}
      inputRef={ref}
      onAccept={(newValue: string) =>
        onChange?.({target: {value: newValue, name: props.name}} as React.ChangeEvent<HTMLInputElement>)
      }
      overwrite
    />
  )
})

/**
 * Phone input component with mask prop
 */
export function PhoneInput({
  label,
  error,
  hideLabel,
  customStyle,
  width,
  value,
  type,
  mask,
  onChange,
  ...rest
}: PhoneInputFieldProps) {
  const theme = useTheme()
  const [inputValue, setInputValue] = useState(value || '')

  useEffect(() => {
    setInputValue(value || '') // Sync local state with prop value
  }, [value])

  const getInputProps = () => ({
    style: {
      ...theme.components?.MuiTextField?.defaultProps?.InputProps?.style,
      ...customStyle,
    },
    ...(error && {
      endAdornment: (
        <InputAdornment position="end">
          <ErrorOutlineRoundedIcon style={{color: theme.palette.error.main}} />
        </InputAdornment>
      ),
    }),
  })

  // Memoize the input component to avoid re-renders
  const MemoizedTextMaskCustom = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (props: any) => <TextMaskCustom {...props} mask={mask} />
  }, [mask])

  return (
    <LabeledWrapper width={width} label={hideLabel ? undefined : label}>
      <TextField
        style={customStyle}
        type={type}
        placeholder={mask?.includes('+') ? mask.replaceAll('#', '_') : label}
        value={inputValue}
        InputProps={{
          ...getInputProps(),
          inputComponent: MemoizedTextMaskCustom, // Use memoized component
        }}
        onChange={event => {
          setInputValue(event.target.value) // Update local state
          onChange?.(event) // Propagate change to parent
        }}
        {...rest}
      />
    </LabeledWrapper>
  )
}
