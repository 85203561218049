import {createTheme} from '@mui/material/styles'

import colors from './colors.json'

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.main.light,
      dark: colors.main.dark,
    },
    grey: {
      100: colors.gray[1],
      200: colors.gray[2],
      300: colors.gray[3],
      400: colors.gray[4],
      500: colors.gray[5],
      600: colors.gray[6],
      700: colors.gray[7],
      800: colors.gray[8],
    },
    error: {
      main: colors.error.main,
    },
    success: {
      main: colors.confirmationGreen.main,
    },
  },
  typography: {
    fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
    h1: {
      fontWeight: 'bold',
      fontSize: 26,
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 21,
    },
    body1: {
      fontWeight: 'regular',
      fontSize: 16,
    },
    body2: {
      fontWeight: 'regular',
      fontSize: 14,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '1rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.gray[8]}`,
          justifyContent: 'center',
          textAlign: 'center',
          fontWeight: 'bold',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          minWidth: '20rem',
          margin: '2rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          paddingBottom: '2rem',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          style: {
            borderRadius: 10,
            fontSize: 14,
            height: '3rem',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          height: '3rem',
          borderRadius: 10,
          fontSize: 14,
          backgroundColor: 'white',
          '& .MuiInputBase-notchedOutline': {
            borderColor: colors.gray[2],
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.gray[2],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.gray[2],
            borderWidth: 'thin',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '3rem',
          borderRadius: 10,
          fontSize: 14,
          backgroundColor: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.gray[2],
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.gray[2],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.gray[2],
            borderWidth: 'thin',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          borderRadius: 10,
          height: 35,
          '&:active': {
            backgroundColor: colors.common.white,
            color: colors.main.light,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.MuiPaginationItem-previousNext, &.MuiPaginationItem-firstLast': {
            backgroundColor: colors.main.light,
            color: colors.common.white,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-cell': {
            border: 'none',
            borderRight: `0.5px solid ${colors.gray[6]}`,
            fontWeight: 'bold',
            '&:focus': {
              outline: 'none',
            },
          },
          '& .MuiDataGrid-cell:last-child': {
            borderRight: 'none',
          },
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-overlay': {
            backgroundColor: colors.common.white,
            border: 'none',
            color: colors.gray[5],
            '&:focus': {
              outline: 'none',
            },
          },
          '& .first-numbering-cell': {
            fontWeight: 'unset',
          },
          '& .MuiDataGrid-row': {
            backgroundColor: colors.common.white,
            borderBottom: `0.5px solid ${colors.gray[6]}`,
            '&.Mui-selected': {
              backgroundColor: colors.common.white,
            },
          },
          '& .MuiDataGrid-footerContainer': {
            border: 'none',
          },
          '& .MuiDataGrid-topContainer::after': {
            height: 0,
          },
        },
      },
    },
  },
})
