import {getProducts, getProductsByCompany, LoggedInUser, ProductResponse, handleAxiosError} from '@api'
import {activeCell, getUserCompany, getUserData, isCompanyUser, isSuperAdmin, renderClickableCell} from '@common'
import {AppContext} from '@contexts'
import {LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef, GridColumnVisibilityModel} from '@mui/x-data-grid'

import {useContext, useState} from 'react'

import {formatCurrency} from 'common'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

export interface ProductTableData {
  name: string
  accessories: number
  price: string
  active: boolean
}

export function usePRoductSearchModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const {t} = useTranslation()
  const [rows, setRows] = useState<ProductTableData[]>([])
  const navigate = useNavigate()
  const currentUser: LoggedInUser = getUserData()

  /**
   * Product table columns configuration
   */
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 1.2,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.PRODUCT_VIEW}/${params.row.id}`)),
    },
    {field: 'accessories', headerName: t(TranslationKey['No. of accessories']), flex: 0.5},
    {field: 'price', headerName: t(TranslationKey['Default price']), flex: 0.5, headerAlign: 'right', align: 'right'},
    activeCell(t),
  ]

  /**
   * Product table visibility model configuration
   */
  const visibilityModel: GridColumnVisibilityModel = {
    company: false,
    accessories: false,
  }

  /**
   * Create button should only be available for Administrator users
   */
  const showCreateButton = !isCompanyUser(currentUser)

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      let data: ProductResponse[] = []

      // Super Administrator can see ALL the data
      if (isSuperAdmin(currentUser)) {
        data = await getProducts()
      }
      // Company Administrator or Company User can ONLY see their Company's Products
      else {
        data = await getProductsByCompany(String(currentUser.companyId))
      }
      setLoadingStatus(LoadingStatus.SUCCESS)
      const formattedData: ProductTableData[] = data
        .filter(item => (isSuperAdmin(currentUser) ? item.companyId === getUserCompany(currentUser.id) : true))
        .map(item => ({
          name: item.name,
          accessories: item.accessories.length,
          price: formatCurrency(item.price),
          active: item.active,
          id: item.id,
        }))
      setRows(formattedData)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {loadData, navigate, t, visibilityModel, showCreateButton, rows, columns}
}
