import {
  ProductResponse,
  AccessoryResponse,
  ContractContractType,
  ContractItem,
  ContractAccessory,
  ContractProduct,
  ContractExtraCost,
} from '@api'

import {initialFormData} from 'components/pages/contracts/contract-form/initial-data'
import {SetupData, ContractFormSetupData} from 'components/pages/contracts/contract-form/types'

/**
 * A fuction that calculates the product prices with discounts and dependant on the Contract Type. Only used initially.
 * @param product Product for which the prices are calculated
 * @param contractType Contract Type by which the discounts and Contract Type prices are calculated
 * @param accessory A flag to differentiate it the product parameter is a Product or Accessory
 * @returns Product with newly calculated prices
 */
export const getActuallPrices = (
  product: ProductResponse | AccessoryResponse,
  contractType: ContractContractType,
  accessory?: boolean,
): ProductResponse | AccessoryResponse => {
  const {accessoryDiscount, productDiscount, serviceDiscount, id} = contractType

  const prices = product.contractTypePrices.find(item => item.contractTypeId === id) ?? {
    price: product.price,
    residualValue: product.residualValue,
    servicePrice: product.servicePrice,
  }

  const discount = accessory ? accessoryDiscount : productDiscount
  const residualValue = prices.price * (prices.residualValue / 100) || 0
  const discountedPrice = (prices?.price || 0) * (1 - discount / 100)
  const discountedServicePrice = (prices.servicePrice || 0) * (1 - serviceDiscount / 100)

  return {
    ...product,
    price: discountedPrice,
    servicePrice: discountedServicePrice,
    residualValue: residualValue,
  }
}

/**Calcualtes the total price for a product. Used on each change. */
/**
 * A function that calcualtes the total price for a product. Used on each change.
 * @param product Product for which the prices are calculated
 * @param contractType Contract Type by which the discounts and Contract Type prices are calculated
 * @param years Length of the Contract in years
 * @param setupData Setup data that stores raw information about Products and Accessories
 * @param accessory A flag to differentiate it the product parameter is a Product or Accessory
 * @returns Product with newly calculated prices
 */
export const getCalculatedPrices = (
  product: ContractItem | ContractAccessory | ContractProduct,
  contractType: ContractContractType,
  years: number,
  setupData: SetupData,
  accessory?: boolean,
): ContractItem | ContractAccessory | ContractProduct => {
  const {
    accessoryInsuranceMinimum,
    advancedPayment,
    functionInterest,
    insurancePercentage,
    interest,
    productInsuranceMinimum,
  } = contractType
  const insuranceMinimum = accessory ? accessoryInsuranceMinimum : productInsuranceMinimum
  const price = product.price || 0
  const residualValue = product.residualValue || 0
  const servicePrice = product.servicePrice || 0
  const installationCompensation = product.installationCompensation || 0

  const calculatedInsurancePrice = product.id
    ? Math.max((price * insurancePercentage) / 100, insuranceMinimum) * years
    : 0

  const rate = ((functionInterest || 0) + (interest || 0)) / 100 / 12
  const type = advancedPayment || 0

  const q = Math.pow(1 + rate, years * 12)
  const discountedPriceWithInstallation = Number(price) + (Number(product.installationCompensation) || 0)
  const annuity =
    ((rate * (discountedPriceWithInstallation * q - residualValue)) / ((q - 1) * (1 + rate * type))) * years * 12

  const foundItem = setupData[accessory ? 'accessories' : 'products'].find(item => item.id === product.id)
  const prices = foundItem?.contractTypePrices.find(item => item.contractTypeId === contractType.id)
  const overridePrice = prices?.override?.[length]?.price || 0
  const totalCost =
    (overridePrice !== 0
      ? overridePrice * length
      : annuity + calculatedInsurancePrice + installationCompensation + servicePrice * years) * (product.quantity || 1)

  return {
    ...product,
    insurancePrice: calculatedInsurancePrice,
    installationCompensation: product.installationCompensation,
    quantity: product.quantity,
    annuity: annuity * (product.quantity || 1),
    amortization: ((discountedPriceWithInstallation - residualValue) / (years * 12)) * (product.quantity || 1),
    averageFunctionAndInterest: (annuity - (discountedPriceWithInstallation - residualValue)) * (product.quantity || 1),
    totalCost,
  }
}

/**
 * A function that creates a new Product item with calculated prices. User for searchable dropdown inx Contract Creation Products and Accessories step
 * @param type A flag to differentiate if the item is a Product or an Accessory
 * @param id Item ID
 * @param setupData Setup data that stores raw information about Products and Accessories
 * @param contractSetupData Setup data from the Contract object
 * @returns A new Product with initial values for the Contract creation
 */
export const createNewFoundItem = (
  type: 'products' | 'accessories',
  id: string | number | null,
  setupData: SetupData,
  contractSetupData: ContractFormSetupData,
): ContractProduct | ContractAccessory | ContractItem => {
  const foundItem = setupData[type].find(item => item.id === id) as AccessoryResponse | ProductResponse
  const newItem: ContractProduct = foundItem
    ? {
        ...getActuallPrices(foundItem, contractSetupData?.contractType as ContractContractType, type === 'accessories'),
        accessories: [],
        amortization: 0,
        annuity: 0,
        averageFunctionAndInterest: 0,
        deliveryDate: '',
        insurancePrice: 0,
        serialNumber: '',
        quantity: 1,
        totalCost: 0,
      }
    : initialFormData.productData[0]
  return foundItem
    ? getCalculatedPrices(
        newItem,
        contractSetupData?.contractType as ContractContractType,
        contractSetupData.length / 12,
        setupData,
        type === 'accessories',
      )
    : initialFormData.productData[0]
}

export const getTotalContractCost = (productData: ContractProduct[], extraCosts: ContractExtraCost[]) =>
  productData.flatMap(product => [product, ...product.accessories]).reduce((acc, item) => (acc += item.totalCost), 0) +
  extraCosts.map(item => item.value).reduce((acc, item) => (acc += item), 0)
