import {TranslationKey} from '@enums'
import {Box, Stack, Typography} from '@mui/material'

import React from 'react'

import {renderFormField} from 'common'

import logo from '@images/amplio-logo-light.png'

import {useLoginModel} from './login-model'
import {
  ContentContainer,
  LeftContentContainer,
  WelcomeMessageContainer,
  LoginButton,
  InfoText,
  RightContentContainer,
  RightContentOverlay,
  imageStyle,
  LeftContentInputsWrapper,
} from './style'

/**
 * Login page
 */
export function LoginPage() {
  const {onLogin, t, loginFormFields} = useLoginModel()

  return (
    <ContentContainer>
      <LeftContentContainer>
        <Box component={'img'} src={logo} style={imageStyle} />
        <LeftContentInputsWrapper>
          <WelcomeMessageContainer gap={1}>
            <Typography variant="h1">{t(TranslationKey['Welcome back'])}</Typography>
            <Typography>{t(TranslationKey['Sign in to start managing your contracts.'])}</Typography>
          </WelcomeMessageContainer>
          <Stack gap={2}>
            {loginFormFields.map((item, index) => (
              <Stack key={`login-form-input-${index}`}>{renderFormField(item)}</Stack>
            ))}
            <LoginButton onClick={() => onLogin()}>{t(TranslationKey['Sign in'])}</LoginButton>
          </Stack>
          <InfoText>
            <Typography fontSize={'0.75rem'}>
              {t(TranslationKey['If you do not have an account, please contact your system administrator.'])}
            </Typography>
          </InfoText>
        </LeftContentInputsWrapper>
      </LeftContentContainer>
      <RightContentContainer>
        <RightContentOverlay />
      </RightContentContainer>
    </ContentContainer>
  )
}
