import translationDe from '@translations/de.json'
import translationDk from '@translations/dk.json'
import translationEn from '@translations/en.json'
import translationFi from '@translations/fi.json'
import translationFr from '@translations/fr.json'
import translationNl from '@translations/nl.json'
import translationNo from '@translations/no.json'
import translationSe from '@translations/se.json'

import React from 'react'

import {AppProvider} from 'contexts/AppContext'
import i18next from 'i18next'
import ReactDOM from 'react-dom/client'
import {initReactI18next} from 'react-i18next'

import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

i18next.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  resources: {
    en: {translation: translationEn},
    se: {translation: translationSe},
    de: {translation: translationDe},
    dk: {translation: translationDk},
    no: {translation: translationNo},
    nl: {translation: translationNl},
    fr: {translation: translationFr},
    fi: {translation: translationFi},
  },
})

root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
