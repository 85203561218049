import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import {Button, Stack, StackProps, styled} from '@mui/material'

export const TrueIcon = styled(CheckCircleIcon)(({theme}) => ({
  color: theme.palette.success.main,
}))

export const FalseIcon = styled(CancelRoundedIcon)(({theme}) => ({
  color: theme.palette.error.main,
}))

export const ActionButtonContainer = styled(Stack)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}))

export const ActionButton = styled(Button)(() => ({
  padding: '0.25rem',
  height: '1.5rem',
}))

export const UnlinkButton = styled(Button)(({theme}) => ({
  padding: '0.25rem 0.5rem',
  height: '1.5rem',
  backgroundColor: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}))

export const CountryCellContainer = styled(Stack)(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: '1rem',
  alignItems: 'center',
  flexDirection: 'row',
}))

export const GoodScoreIcon = styled(CheckCircleRoundedIcon)(({theme}) => ({
  color: theme.palette.success.main,
}))

export const WarningScoreIcon = styled(WarningRoundedIcon)(({theme}) => ({
  color: theme.palette.warning.main,
}))

export const BadScoreIcon = styled(ErrorRoundedIcon)(({theme}) => ({
  color: theme.palette.error.main,
}))

export const UnknownScoreIcon = styled(HelpRoundedIcon)(({theme}) => ({
  color: theme.palette.grey['100'],
}))

export const StatusCellOuterWrapper = styled(Stack)(() => ({
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StatusCellInnerWrapper = styled(Stack, {shouldForwardProp: prop => prop !== 'color'})<
  StackProps & {color: string}
>(({color}) => ({
  borderRadius: '1rem',
  height: '1.5rem',
  backgroundColor: `${color}AB`,
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}))

export const ClickableCell = styled(Stack)(({theme}) => ({
  color: theme.palette.primary.light,
  textDecoration: 'underline',
  cursor: 'pointer',
}))
