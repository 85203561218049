import {styled, Button, InputLabel} from '@mui/material'

export const CancelBtn = styled(Button)(({theme}) => ({
  minWidth: '8rem',
  backgroundColor: theme.palette.error.light,
}))

export const StyledButton = styled(Button)(() => ({
  minWidth: '8rem',
}))

export const Label = styled(InputLabel)(({theme}) => ({
  textAlign: 'start',
  color: theme.palette.grey[100],
  ...theme.typography.body2,
}))
