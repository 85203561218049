import {Typography} from '@mui/material'
import {CommonProps} from '@mui/material/OverridableComponent'

import React, {ReactNode} from 'react'

import {StyledPaper, TitleWrapper} from './style'

interface Props {
  children: ReactNode
  width?: number | string
  height?: number | string
  title?: string
  style?: CommonProps['style']
}

/**
 * Background Card component with an optional Title
 */
export function BackgroundCard(props: Props) {
  const {children, width, height, title, style} = props
  return (
    <StyledPaper style={style} width={width} height={height} elevation={1}>
      {title && (
        <TitleWrapper>
          <Typography variant="h2">{title}</Typography>
        </TitleWrapper>
      )}
      {children}
    </StyledPaper>
  )
}
