import {
  ContractTypeResponse,
  createProduct,
  getContractTypes,
  getProduct,
  LoggedInUser,
  ProductData,
  ProductRequest,
  ProductResponse,
  updateProduct,
  handleAxiosError,
  getContractTypesByCompany,
  CompanyResponse,
  getCompanies,
  getCompany,
} from '@api'
import {AppContext} from '@contexts'
import {TranslationKey, LoadingStatus, FormFieldType, NavigationPathKey} from '@enums'

import {useContext, useMemo, useState} from 'react'

import {
  validateFields,
  getUserData,
  isSuperAdmin,
  isCompanyAdmin,
  FormFieldItem,
  generateDropdownOptions,
  generateReadableErrorMessages,
  getUserCompany,
} from 'common'
import {DropdownItem} from 'components/ui-kit'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

/**
 * Initial data
 */
const initialProductData: ProductData = {
  active: true,
  companyId: null,
  name: '',
  price: 0,
  productNo: '',
  residualValue: 0,
  servicePrice: 0,
  installationCompensation: 0,
  contractTypePrices: [],
}

export function useProductFormModel() {
  const {setError, setLoadingStatus, setIsFormDirty, handleNavigate} = useContext(AppContext)
  const [productData, setProductData] = useState<ProductData>(initialProductData)
  const [productFormFieldErrors, setProductFormFieldsErrors] = useState<string[]>([])
  const [companies, setCompanies] = useState<DropdownItem[]>([])
  const [contractTypes, setContractTypes] = useState<ContractTypeResponse[]>([])
  const navigate = useNavigate()
  const {id} = useParams()
  const {t} = useTranslation()
  const currentUser: LoggedInUser = getUserData()

  /**
   * Dynamically render title text for Product form page
   */
  const title = useMemo(
    () => (id ? `${t(TranslationKey['Edit product'])} - ${productData.name}` : t(TranslationKey['New product'])),
    [id, productData.name],
  )

  /**
   * Contract Type options filtered by Company ID
   */
  const contractTypeOptions = useMemo(
    () => contractTypes.filter(item => item.companyId === productData.companyId),
    [productData, contractTypes],
  )

  /**
   * A function that filters out already selected Contract Types to prevent duplicate selection
   * @param value Contract Type ID
   * @returns Contract Types with filtered out already selected options
   */
  const getContractTypeOptions = (value: string) => {
    return productData.companyId
      ? generateDropdownOptions(
          contractTypeOptions.filter(
            item =>
              item.id === value || !productData.contractTypePrices.some(price => price.contractTypeId === item.id),
          ),
        )
      : []
  }

  /**
   * A custom validation function that valides the Product form fields
   * @returns If Product form data has invalid values
   */
  const validateData = (): boolean => {
    const invalidValues = validateFields(productData)

    const isFormInvalid = invalidValues.length > 0
    if (isFormInvalid) {
      setError({
        message: generateReadableErrorMessages(t, invalidValues),
        name: '',
      })

      setProductFormFieldsErrors(invalidValues)
    }
    return isFormInvalid
  }

  /**
   * A function that handles Contract Type Price form fields change
   * @param index Contract Type Price index
   * @param event Change event
   */
  const onChangeContractTypePrice = (
    index: number,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsFormDirty(true)
    const fieldName = event?.target.name
    const value = event?.target.value

    if (!fieldName || value === undefined) return

    setProductFormFieldsErrors(prev => prev.filter(item => item !== `contractTypePrices[${index}].${fieldName}`))
    setProductData(prev => ({
      ...prev,
      contractTypePrices: prev.contractTypePrices.map((contractTypePrice, i) => {
        return i === index ? {...contractTypePrice, [fieldName]: value} : contractTypePrice
      }),
    }))
  }

  /**
   * A function that handles Contract Type Override Price form fields change
   * @param index Contract Type Price index
   * @param event Change event
   */
  const onChangeContractTypeOverridePrice = (
    index: number,
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsFormDirty(true)
    const fieldName = event?.target.name
    const value = event?.target.value

    if (!fieldName || value === undefined) return

    setProductFormFieldsErrors(prev => prev.filter(item => item !== `contractTypePrices[${index}].${fieldName}`))
    setProductData(prev => ({
      ...prev,
      contractTypePrices: prev.contractTypePrices.map((contractTypePrice, i) => {
        return i === index
          ? {
              ...contractTypePrice,
              override: [
                ...contractTypePrice.override.filter(item => item.contractLength !== Number(fieldName)),
                {contractLength: Number(fieldName), price: Number(value)},
              ],
            }
          : contractTypePrice
      }),
    }))
  }

  /**
   * A function that hanldes Contract Type Price dropdown form field change
   * @param index Contract Type Price section index
   * @param fieldName Field name
   * @param item Dropdown item
   */
  const onChangeContractType = (index: number, fieldName: string, item: DropdownItem | null) => {
    setIsFormDirty(true)
    setProductFormFieldsErrors(prev => prev.filter(item => item !== `contractTypePrices[${index}].${fieldName}`))
    setProductData(prev => ({
      ...prev,
      contractTypePrices: prev.contractTypePrices.map((contractTypePrice, i) => {
        return i === index ? {...contractTypePrice, [fieldName]: item?.value || null} : contractTypePrice
      }),
    }))
  }

  /**
   * A function that handles Product form fields change
   * @param event Change event
   * @param checked Checked state for checkbox form fields
   * @param percentage A flag to determine if a form field is for percentage input
   */
  const onChangeProductGeneralFormField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean,
    percentage?: boolean,
  ) => {
    setIsFormDirty(true)
    const fieldName = event.target.name
    const value = checked ?? (!percentage || Number(event.target.value) < 100 ? event.target.value : '100')
    setProductFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    setProductData(prev => ({...prev, [fieldName]: value}))
  }

  /**
   * A function that handles Product dropdown form field change
   * @param fieldName Field name
   * @param item Dropdown item
   */
  const onChangeProductDropdownFormField = (fieldName: string, item: DropdownItem | null) => {
    setIsFormDirty(true)

    setProductFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    setProductData(prev => ({
      ...prev,
      contractTypePrices: fieldName === 'companyId' ? [] : prev.contractTypePrices,
      [fieldName]: item?.value ?? null,
    }))
  }

  /**
   * Product form fields configuration - general section
   */
  const productGeneralFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Product number']),
      name: 'productNo',
      onChange: onChangeProductGeneralFormField,
      value: productData.productNo,
      error: productFormFieldErrors.includes('productNo'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Product name']),
      name: 'name',
      onChange: onChangeProductGeneralFormField,
      value: productData.name,
      error: productFormFieldErrors.includes('name'),
    },
    {
      fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
      name: 'companyId',
      label: t(TranslationKey['Amplio partner']),
      value: productData?.companyId || undefined,
      items: companies,
      error: productFormFieldErrors.includes('companyId'),
      onChange: (item: DropdownItem | null) => onChangeProductDropdownFormField('companyId', item),
      disabled: true,
    },
    {
      fieldType: FormFieldType.CHECKBOX_GROUP,
      name: 'active',
      label: t(TranslationKey.Active),
      checkboxes: [
        {
          checked: productData.active,
          label: t(TranslationKey.Active),
          value: productData.active,
          name: 'active',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeProductGeneralFormField(event, checked),
        },
      ],
    },
  ]

  /**
   * Product form fields configuration - default price sections
   */
  const productDefaultPriceFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Price),
      name: 'price',
      currency: true,
      onChange: onChangeProductGeneralFormField,
      value: productData.price,
      error: productFormFieldErrors.includes('price'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.STACKED_INPUT,
      label: t(TranslationKey['Residual value']),
      name: 'residualValue',
      total: productData.price,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeProductGeneralFormField(event, undefined, true),
      value: productData.residualValue,
      error: productFormFieldErrors.includes('residualValue'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Installation compensation']),
      name: 'installationCompensation',
      currency: true,
      onChange: onChangeProductGeneralFormField,
      value: productData.installationCompensation,
      error: productFormFieldErrors.includes('installationCompensation'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Service price']),
      name: 'servicePrice',
      currency: true,
      onChange: onChangeProductGeneralFormField,
      value: productData.servicePrice,
      error: productFormFieldErrors.includes('servicePrice'),
      type: 'number',
    },
  ]

  /**
   * Contract Type Prices form fields configuration
   * @param index Contract Type Price section index
   * @returns Contract Type Prices form fields
   */
  const productPriceFormFields = (index: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
      name: 'contractTypeId',
      label: t(TranslationKey['Contract type']),
      value: productData?.contractTypePrices[index].contractTypeId || undefined,
      items: getContractTypeOptions(productData.contractTypePrices[index].contractTypeId),
      error: productFormFieldErrors.includes('contractTypeId'),
      onChange: (item: DropdownItem | null) => onChangeContractType(index, 'contractTypeId', item),
    },
    {} as FormFieldItem,
    {} as FormFieldItem,
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Price),
      name: 'price',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypePrice(index, event),
      value: productData.contractTypePrices[index].price,
      error: productFormFieldErrors.includes(`contractTypePrices[${index}].price`),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Residual value']),
      name: 'residualValue',
      percentage: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypePrice(index, event),
      value: productData.contractTypePrices[index].residualValue,
      error: productFormFieldErrors.includes(`contractTypePrices[${index}].residualValue`),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Installation compensation']),
      name: 'installationCompensation',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypePrice(index, event),
      value: productData.contractTypePrices[index].installationCompensation,
      error: productFormFieldErrors.includes(`contractTypePrices[${index}].installationCompensation`),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Service price']),
      name: 'servicePrice',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypePrice(index, event),
      value: productData.contractTypePrices[index].servicePrice,
      error: productFormFieldErrors.includes(`contractTypePrices[${index}].servicePrice`),
      type: 'number',
    },
  ]

  /**
   * Contract Type Price Per Length form fields configuration
   * @param index Contract Type Price section index
   * @returns Contract Type Price per length form fields
   */
  const productPricePerLengthFormFields = (index: number): FormFieldItem[] => [
    {
      fieldType: FormFieldType.INPUT,
      label: `18 ${t(TranslationKey.Months)}`,
      name: '18',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeOverridePrice(index, event),
      error: productFormFieldErrors.includes(`contractTypePrices[${index}].18`),
      value: productData.contractTypePrices[index].override.find(item => item.contractLength === 18)?.price,
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: `24 ${t(TranslationKey.Months)}`,
      name: '24',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeOverridePrice(index, event),
      error: productFormFieldErrors.includes(`contractTypePrices[${index}].24`),
      value: productData.contractTypePrices[index].override.find(item => item.contractLength === 24)?.price,
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: `36 ${t(TranslationKey.Months)}`,
      name: '36',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeOverridePrice(index, event),
      error: productFormFieldErrors.includes(`contractTypePrices[${index}].36`),
      value: productData.contractTypePrices[index].override.find(item => item.contractLength === 36)?.price,
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: `60 ${t(TranslationKey.Months)}`,
      name: '60',
      currency: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeOverridePrice(index, event),
      error: productFormFieldErrors.includes(`contractTypePrices[${index}].60`),
      value: productData.contractTypePrices[index].override.find(item => item.contractLength === 60)?.price,
      type: 'number',
    },
  ]

  /**
   * A function that handles the Save button click
   * If form is valid, will update or create a new Product
   * @returns void
   */
  const onSubmit = async (): Promise<void> => {
    if (validateData()) return

    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (id) {
        await updateProduct(id, productData as ProductRequest)
        setLoadingStatus(LoadingStatus.SUCCESS)
        setIsFormDirty(false)
        navigate(`${NavigationPathKey.PRODUCT_VIEW}/${id}`)
        return
      }

      const data: ProductResponse = await createProduct(productData as ProductRequest)
      setIsFormDirty(false)
      setLoadingStatus(LoadingStatus.SUCCESS)

      navigate(`${NavigationPathKey.PRODUCT_VIEW}/${data.id}`)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      let contractTypeData: ContractTypeResponse[] = []
      if (id) {
        const data = await getProduct(id)

        setProductData({
          active: data.active,
          companyId: data.companyId,
          price: data.price,
          productNo: data.productNo,
          residualValue: data.residualValue,
          servicePrice: data.servicePrice,
          name: data.name,
          id: data.id,
          contractTypePrices: data.contractTypePrices,
          installationCompensation: data.installationCompensation,
        })
      } else if (isSuperAdmin(currentUser)) {
        setProductData(prev => ({...prev, companyId: getUserCompany(currentUser.id)}))
      }
      // Company Administrators can only create Dealer profiles for their Company
      else if (isCompanyAdmin(currentUser)) {
        setProductData(prev => ({...prev, companyId: currentUser.companyId}))
      }

      let companyData: CompanyResponse[] = []

      // Super Administrator sees ALL the data
      if (isSuperAdmin(currentUser)) {
        companyData = await getCompanies()
        contractTypeData = await getContractTypes()
      }
      // Company Administrator or Company User see ONLY their Company
      else {
        companyData = [await getCompany(String(currentUser.companyId))]
        contractTypeData = await getContractTypesByCompany(String(currentUser.companyId))
      }

      setContractTypes(contractTypeData)
      setCompanies(generateDropdownOptions(companyData))
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function that handles Discard button click
   */
  const onDiscard = (): void => {
    handleNavigate(() => navigate(-1))
  }

  /**
   * A function that handles the Add Contract Type Price button click
   */
  const onAddProductPrice = () => {
    setProductData(prev => ({
      ...prev,
      contractTypePrices: [
        ...prev.contractTypePrices,
        {
          contractTypeId: '',
          price: 0,
          residualValue: 0,
          servicePrice: 0,
          installationCompensation: 0,
          override: [
            {
              contractLength: 18,
              price: 0,
            },
            {
              contractLength: 24,
              price: 0,
            },
            {
              contractLength: 36,
              price: 0,
            },
            {
              contractLength: 60,
              price: 0,
            },
          ],
        },
      ],
    }))
  }

  /**
   * A function that handles the Remove Contract Type Price button click
   * @param index Contract Type Price section index
   */
  const onRemoveProductPrice = (index: number) => {
    setProductData(prev => ({
      ...prev,
      contractTypePrices: prev.contractTypePrices.filter((_, i) => i !== index),
    }))
    setProductFormFieldsErrors(prev => prev.filter(item => item.includes(`contractTypePrices[${index}]`)))
  }

  /**
   * Add Contract Type Price button should be disabled if prices for each available Contract Type have been configured
   */
  const isAddPriceBtnDisabled = useMemo(
    () => contractTypeOptions.length === productData.contractTypePrices.length,
    [contractTypeOptions, productData],
  )

  return {
    loadData,
    onDiscard,
    onSubmit,
    productPriceFormFields,
    onAddProductPrice,
    onRemoveProductPrice,
    productPricePerLengthFormFields,
    t,
    isAddPriceBtnDisabled,
    title,
    productData,
    productGeneralFormFields,
    productDefaultPriceFormFields,
  }
}
