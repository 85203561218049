/* eslint-disable @typescript-eslint/no-explicit-any */
import {FormFieldType, TranslationKey} from '@enums'

import {TFunction} from 'i18next'

import {UIKit} from '@components'

type FormFieldProps = {
  [FormFieldType.DROPDOWN]: React.ComponentProps<typeof UIKit.Dropdown>
  [FormFieldType.INPUT]: React.ComponentProps<typeof UIKit.InputField>
  [FormFieldType.DATE_PICKER]: React.ComponentProps<typeof UIKit.DatePicker>
  [FormFieldType.LABEL]: React.ComponentProps<typeof UIKit.TextWithLabel>
  [FormFieldType.CHECKBOX_GROUP]: React.ComponentProps<typeof UIKit.CheckboxGroup>
  [FormFieldType.RADIO_BUTTON_GROUP]: React.ComponentProps<typeof UIKit.RadioButtonGroup>
  [FormFieldType.SEARCHABLE_DROPDOWN]: React.ComponentProps<typeof UIKit.SearchableDropdown>
  [FormFieldType.PHONE_INPUT]: React.ComponentProps<typeof UIKit.PhoneInput>
  [FormFieldType.STACKED_INPUT]: React.ComponentProps<typeof UIKit.StackedInput>
}

export type FormFieldItem = {
  fieldType: FormFieldType
} & (
  | FormFieldProps[FormFieldType.DROPDOWN]
  | FormFieldProps[FormFieldType.INPUT]
  | FormFieldProps[FormFieldType.DATE_PICKER]
  | FormFieldProps[FormFieldType.LABEL]
  | FormFieldProps[FormFieldType.CHECKBOX_GROUP]
  | FormFieldProps[FormFieldType.RADIO_BUTTON_GROUP]
  | FormFieldProps[FormFieldType.SEARCHABLE_DROPDOWN]
  | FormFieldProps[FormFieldType.PHONE_INPUT]
  | FormFieldProps[FormFieldType.STACKED_INPUT]
)

/**
 * A function to dynamically render a form field
 * @param item Parameters for the form field
 * @returns Form field component
 */
export const renderFormField = (item: FormFieldItem) => {
  const {fieldType, ...rest} = item

  switch (fieldType) {
    case FormFieldType.DROPDOWN:
      return <UIKit.Dropdown {...(rest as FormFieldProps[FormFieldType.DROPDOWN])} />

    case FormFieldType.INPUT:
      return <UIKit.InputField {...(rest as FormFieldProps[FormFieldType.INPUT])} />

    case FormFieldType.DATE_PICKER:
      return <UIKit.DatePicker {...(rest as FormFieldProps[FormFieldType.DATE_PICKER])} />

    case FormFieldType.LABEL:
      return <UIKit.TextWithLabel {...(rest as FormFieldProps[FormFieldType.LABEL])} />

    case FormFieldType.CHECKBOX_GROUP:
      return <UIKit.CheckboxGroup {...(rest as FormFieldProps[FormFieldType.CHECKBOX_GROUP])} />

    case FormFieldType.RADIO_BUTTON_GROUP:
      return <UIKit.RadioButtonGroup {...(rest as FormFieldProps[FormFieldType.RADIO_BUTTON_GROUP])} />

    case FormFieldType.PHONE_INPUT:
      return <UIKit.PhoneInput {...(rest as FormFieldProps[FormFieldType.PHONE_INPUT])} />

    case FormFieldType.SEARCHABLE_DROPDOWN:
      return <UIKit.SearchableDropdown {...(rest as FormFieldProps[FormFieldType.SEARCHABLE_DROPDOWN])} />

    case FormFieldType.STACKED_INPUT:
      return <UIKit.StackedInput {...(rest as FormFieldProps[FormFieldType.STACKED_INPUT])} />

    default:
      return null
  }
}

/**
 * A custom validation function for form fields
 * @param data Form data to be validated
 * @returns An array of invalid fields
 */
export const validateFields = <T extends Record<string, any>>(data: T): (keyof T)[] => {
  const invalidValues: (keyof T)[] = []

  const arrayFields = ['accessories', 'officialSignatories', 'extraCosts', 'contractTypePrices']

  for (const prop in data) {
    if (Object.prototype.hasOwnProperty.call(data, prop)) {
      const value = data[prop]

      if (prop === 'email') {
        if (typeof value === 'string' && (!value || !/\S+@\S+\.\S+/.test(value))) {
          invalidValues.push(prop)
        }
      } else if (value === undefined || value === '' || value === null) {
        invalidValues.push(prop)
      } else if (prop.includes('quantity') && Number(value) < 1) {
        invalidValues.push(prop)
      } else if (Array.isArray(value)) {
        if (arrayFields.includes(prop) && value.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value.forEach((accessory: any, index: number) => {
            const invalidAccessoryFields = validateFields(accessory)
            if (invalidAccessoryFields.length > 0) {
              invalidAccessoryFields.forEach(field => {
                invalidValues.push(`${prop}[${index}].${String(field)}`)
              })
            }
          })
        }
      }
    }
  }

  return invalidValues
}

/**
 * Transforms the field names into readable message for the user
 * @param t Translation function
 * @param errorMsgs Array of invalid field names
 * @returns A readable error message string
 */
export const generateReadableErrorMessages = (t: TFunction, errorMsgs: string[]): string => {
  const transformErrorMessage = (errorMsg: string): string => {
    return errorMsg
      .replace(/\[\d+\]\./g, ': ')
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, match => match.toUpperCase())
  }

  let transformedMessages: string[]

  if (errorMsgs.length > 3) {
    transformedMessages = errorMsgs.slice(0, 3).map(transformErrorMessage)
    transformedMessages.push(
      `${t(TranslationKey['and ${number} more']).replace('${number}', `${errorMsgs.length - 3}`)}.`,
    )
  } else {
    transformedMessages = errorMsgs.map(transformErrorMessage)
  }

  return `${t(TranslationKey['Please check the following fields'])}: ${transformedMessages.join(', ')}.`
}

/**
 * A function to generate Checkbox options from an array of data
 * @param valueField Prop to be used for value
 * @param labelField Prop to be used for label
 * @param checkCondition Function to determine if checked state should be true
 * @param disableCondition Function to determin if disabled state should be true
 * @param onChange On change handler function
 * @param data Data to be used to form Checkbox options
 * @returns An array of Checkbox options
 */
export const createCheckboxOptions = <T extends object>(
  valueField: keyof T,
  labelField: keyof T,
  checkCondition: (item: T) => boolean,
  disableCondition: (item: T) => boolean,
  onChange: () => void,
  data: T[],
) =>
  data.map(item => ({
    checked: checkCondition(item),
    label: item[labelField],
    value: item[valueField],
    name: item[valueField],
    disabled: disableCondition(item),
    onChange: onChange,
  }))

/**
 * A function to generate Radio button options from an array of data
 * @param valueField Prop to be used for value
 * @param labelField Prop to be used for label
 * @param name Name of the Radio Button group
 * @param disableCondition Function to determine if disabled state should be true
 * @param onChange On change handler function
 * @param data Date to be used to form Radio buttion options
 * @returns An array of Radio button options
 */
export const createRadioBtnOptions = <T extends object>(
  valueField: keyof T,
  labelField: keyof T,
  name: string,
  disableCondition: (item: T) => boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  data: T[],
) =>
  data.map(item => ({
    label: item[labelField],
    value: item[valueField],
    name,
    disabled: disableCondition(item),
    onChange: onChange,
  }))
