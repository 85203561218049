import CloseIcon from '@mui/icons-material/Close'
import {Stack, styled} from '@mui/material'

export const ButtonWrapper = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '1rem',
}))

export const RemoveButtonIcon = styled(CloseIcon)(({theme}) => ({
  color: theme.palette.warning.light,
}))

export const PriceTitleWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
}))
