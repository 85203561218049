import {styled, Stack, Button} from '@mui/material'

export const ContentContainer = styled(Stack)(() => ({
  height: '100vh',
  width: '100vw',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const BackButton = styled(Button)(() => ({
  marginTop: '2rem',
  width: '15rem',
  borderRadius: '1rem',
  height: '3rem',
}))

export const imageStyle = {
  height: '5rem',
  marginBottom: '5.5rem',
  cursor: 'pointer',
}
