import React from 'react'

import {ButtonsWrapper, FormButton} from './style'

export type TabButtonProps<T> = {
  label: string
  key: T
  onClick: () => void
}

interface Props<T> {
  buttons: TabButtonProps<T>[]
  activeTab: T
}

/**
 * Tab Buttons component to be used for tabs navigation
 * @param props Tabl Buttons props
 */
export function TabButtons<T>(props: Props<T>) {
  const {buttons, activeTab} = props

  return (
    <ButtonsWrapper>
      {buttons.map((item, index) => (
        <FormButton
          key={`tab-button-${index}`}
          variant={activeTab === item.key ? 'contained' : 'outlined'}
          onClick={item.onClick}
        >
          {item.label}
        </FormButton>
      ))}
    </ButtonsWrapper>
  )
}
