import {CompanyResponse, DealerResponse, getCompanies, getCompany, getDealer, handleAxiosError} from '@api'
import {
  currencies,
  FormFieldItem,
  generateDropdownOptions,
  getUserCompany,
  getUserData,
  icons,
  isSuperAdmin,
  setUserCompany,
  setCurrency,
} from '@common'
import {AppContext} from '@contexts'
import {FormFieldType, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {Stack, SvgIcon, Typography} from '@mui/material'

import {useContext, useMemo, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {UIKit} from '@components'

export function useTopBarModel() {
  const {t} = useTranslation()
  const currentUser = getUserData()
  const navigate = useNavigate()
  const {setLoadingStatus, handleNavigate, setError} = useContext(AppContext)
  const [companies, setCompanies] = useState<CompanyResponse[]>([])
  const [options, setOptions] = useState<UIKit.DropdownItem[]>([])
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [currentDealer, setCurrentDealer] = useState<DealerResponse | undefined>(undefined)

  const showDealer = useMemo(() => !isSuperAdmin(currentUser), [currentUser])

  /**
   * A function for custom DropdownItem render
   */
  const renderValue = () => {
    const foundCompany = companies.find(item => item.id === getUserCompany(currentUser.id))
    return (
      <Stack flexDirection={'row'} style={{width: '100%'}} gap={2} justifyContent={'flex-start'}>
        <Typography>{foundCompany?.name}</Typography>
        {foundCompany && <SvgIcon>{icons[foundCompany?.country]}</SvgIcon>}
      </Stack>
    )
  }

  /**
   * A function to handle Company selection
   * @param event Change event
   */
  const onChangeCompany = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleNavigate(() => {
      setLoadingStatus(LoadingStatus.LOADING)
      setTimeout(() => {
        setUserCompany({
          userUid: currentUser.id,
          companyUid: event.target.value,
        })
        const country = companies?.find(item => item.id === event.target.value)?.country
        if (country) setCurrency(currencies[country])
        navigate(NavigationPathKey.DASHBOARD)
        setLoadingStatus(LoadingStatus.SUCCESS)
      }, 500)
    })
  }

  /**
   * Company dropdown form field
   */
  const companyFormField: FormFieldItem = useMemo(
    () => ({
      fieldType: FormFieldType.DROPDOWN,
      items: options,
      value: getUserCompany(currentUser.id),
      renderValue: renderValue,
      onChange: onChangeCompany,
      disabled: !isSuperAdmin(currentUser),
      hideBorder: true,
      placeholder: t(TranslationKey['Amplio partner']),
    }),
    [options],
  )

  /**
   * Dealer dropdown form field - always disabled
   */
  const dealerFormField: FormFieldItem = useMemo(
    () => ({
      fieldType: FormFieldType.DROPDOWN,
      items: [
        {
          label: currentDealer?.name,
          value: currentDealer?.id,
        },
      ],
      value: currentDealer?.id,
      disabled: true,
      hideBorder: true,
      placeholder: t(TranslationKey.Dealer),
    }),
    [options],
  )

  /**
   * Load data function to be executed only once on render
   */
  const loadData = async (): Promise<void> => {
    try {
      let data: CompanyResponse[] = []
      let dealer: DealerResponse | undefined = undefined

      // Super Administators can see ALL the Companies
      if (isSuperAdmin(currentUser)) {
        data = await getCompanies()
      }
      // Company Administrators and Company Users can see ONLY their company
      else {
        data = [await getCompany(String(currentUser.companyId))]
        dealer = await getDealer(String(currentUser.dealerId))
        setCurrentDealer(dealer)
      }

      if (!getUserCompany(String(currentUser.id))) {
        setUserCompany({
          userUid: currentUser.id,
          companyUid: String(data[0]?.id),
        })
        setCurrency(currencies[data[0].country])
      } else {
        const country = data?.find(item => item.id === getUserCompany(currentUser.id))?.country
        if (country) setCurrency(currencies[country])
      }

      setCompanies(data)
      setOptions(generateDropdownOptions(data, 'name', 'id', 'country'))
    } catch (error) {
      setError(handleAxiosError(error))
    }
  }

  return {
    companyFormField,
    isDrawerOpen,
    showDealer,
    dealerFormField,
    loadData,
    setIsDrawerOpen,
  }
}
