import colors from '@colors'
import loginImage from '@images/login-image.jpg'
import {Stack, Button, styled} from '@mui/material'

export const ContentContainer = styled(Stack)(({theme}) => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.primary.main,
  justifyContent: 'space-evenly',
  flexDirection: 'row',
}))

export const LeftContentContainer = styled(Stack)(({theme}) => ({
  padding: '5rem 2rem',
  width: '50%',
  alignItems: 'center',
  color: theme.palette.common.white,
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

export const WelcomeMessageContainer = styled(Stack)(() => ({
  width: '20rem',
  paddingTop: '2rem',
  alignItems: 'flex-start',
}))

export const LoginButton = styled(Button)(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '2rem',
  width: '20rem',
  height: '3rem',
  marginTop: '2rem',
  color: theme.palette.grey[100],
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.white,
  },
}))

export const RightContentContainer = styled(Stack)(({theme}) => ({
  width: '50%',
  backgroundImage: `url(${loginImage})`,
  margin: '2rem',
  backgroundColor: '#12294494',
  borderRadius: '3rem',
  backgroundSize: 'cover',
  backgroundPositionX: '50%',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}))

export const RightContentOverlay = styled(Stack)(() => ({
  backgroundColor: '#12294494',
  width: '100%',
  height: '100%',
}))

export const InfoText = styled(Stack)(() => ({
  maxWidth: '19rem',
  paddingTop: '1rem',
}))

export const imageStyle = {
  height: '70px',
  width: '220px',
}

export const customInputStyle = {
  backgroundColor: '#FFFFFF1A',
  borderRadius: '2rem',
  width: '20rem',
  color: colors.common.white,
  '& :hover': {
    border: 'none',
  },
}
export const LeftContentInputsWrapper = styled(Stack)(() => ({
  height: '100%',
  justifyContent: 'center',
  gap: 15,
}))
