import {NavigationPathKey, TranslationKey} from '@enums'
import {Grid, Stack, Typography} from '@mui/material'
import {PageWrapper, SearchTableWrapper} from '@wrappers'

import React from 'react'

import {renderFormField, useLoadOnce} from 'common'

import {ComposedComponents, UIKit} from '@components'

import {DealerTab, useDealerViewModel} from './dealer-view-model'

/**
 * Dealer View page
 */
export function DealerViewPage() {
  const {
    isLinkModalShown,
    modalText,
    linkContractTypeFormField,
    userColumns,
    userRows,
    activeTab,
    tabButtons,
    t,
    title,
    officialSignatoriesColumns,
    contractTypeColumns,
    contractTypeRows,
    officialSignatoriesRows,
    isEditButtonVisible,
    dealerFormFields,
    dealerContactFormFields,
    showCreateButton,
    id,
    navigate,
    onPressEdit,
    onPressBack,
    loadData,
    onCloseLinkModal,
    onLink,
    setIsLinkModalShown,
  } = useDealerViewModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={title}>
      <ComposedComponents.TabButtons activeTab={activeTab} buttons={tabButtons} />
      {activeTab === DealerTab.GENERAL && (
        <>
          <UIKit.BackgroundCard title={t(TranslationKey['General information'])}>
            <Grid container spacing={2}>
              {dealerFormFields.map((item, index) => (
                <Grid sm={4} item key={`dealer-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>

          <UIKit.BackgroundCard title={t(TranslationKey['Contact information'])}>
            <Grid container spacing={2}>
              {dealerContactFormFields.map((item, index) => (
                <Grid sm={4} item key={`dealer-contact-form-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </UIKit.BackgroundCard>

          <UIKit.BackgroundCard>
            <UIKit.Table
              columns={officialSignatoriesColumns}
              rows={officialSignatoriesRows}
              title={t(TranslationKey['Official signatories'])}
              hidePagination
              hideSearch
              hideToolbar
            />
          </UIKit.BackgroundCard>
        </>
      )}
      {activeTab === DealerTab.CONTRACT_TYPES && (
        <SearchTableWrapper>
          <UIKit.Table
            {...(isEditButtonVisible && {
              buttonProps: {
                buttonText: t(TranslationKey['Link a contract type']),
                onClick: () => setIsLinkModalShown(true),
              },
            })}
            {...(showCreateButton && {
              additionalButtonProps: {
                buttonText: t(TranslationKey['Add new contract type']),
                onClick: () => navigate(`${NavigationPathKey.CONTRACT_TYPE_CREATION}?dealerId=${id}`),
              },
            })}
            columns={contractTypeColumns}
            rows={contractTypeRows}
            title={t(TranslationKey['Linked contract types'])}
          />
        </SearchTableWrapper>
      )}
      {activeTab === DealerTab.USERS && (
        <SearchTableWrapper>
          <UIKit.Table
            {...(showCreateButton && {
              buttonProps: {
                buttonText: t(TranslationKey['Add new user']),
                onClick: () => navigate(`${NavigationPathKey.USER_CREATION}?dealerId=${id}`),
              },
            })}
            columns={userColumns}
            rows={userRows}
            title={t(TranslationKey['Users'])}
          />
        </SearchTableWrapper>
      )}
      <ComposedComponents.FormButtons
        leftButton={{
          label: t(TranslationKey.Back),
          onClick: onPressBack,
        }}
        {...(isEditButtonVisible && {rightButton: {label: t(TranslationKey.Edit), onClick: onPressEdit}})}
      />
      <UIKit.Modal
        open={isLinkModalShown}
        title={`${t(TranslationKey['Link a contract type'])}?`}
        content={
          <Stack alignItems={'center'} gap={2}>
            <Typography>{modalText}</Typography>
            <Stack style={{width: '15rem'}}>{renderFormField(linkContractTypeFormField)}</Stack>
          </Stack>
        }
        confirmButtonProps={{buttonText: t(TranslationKey.Link), onClick: onLink}}
        cancelButtonProps={{buttonText: t(TranslationKey.Cancel), onClick: onCloseLinkModal}}
      />
    </PageWrapper>
  )
}
