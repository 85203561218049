import CloseIcon from '@mui/icons-material/Close'
import {Button, Stack, styled} from '@mui/material'

export const RemoveButton = styled(Button)(({theme}) => ({
  color: theme.palette.grey[100],
  borderLeft: `2px solid ${theme.palette.grey[100]}`,
  borderRadius: 0,
  fontSize: '0.75rem',
  textTransform: 'none',
  height: '1.5rem',
}))

export const RemoveButtonIcon = styled(CloseIcon)(({theme}) => ({
  color: theme.palette.warning.light,
}))

export const ProductTitleWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const ButtonWrapper = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '1rem',
}))

export const AccessoryFormsWrapper = styled(Stack)(() => ({
  margin: '2rem 1rem',
  gap: '1rem',
}))

export const PriceWrapper = styled(Stack)(() => ({
  alignItems: 'flex-start',
  gap: 2,
}))
