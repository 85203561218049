import colors from '@colors'
import {useLoadOnce} from '@common'
import {TranslationKey} from '@enums'
import {Stack} from '@mui/material'
import {PageWrapper} from '@wrappers'

import React from 'react'

import {ResponsiveContainer, BarChart, XAxis, YAxis, Bar, Tooltip} from 'recharts'

import {ComposedComponents, UIKit} from '@components'

import {useDashboardModel} from './dashboard-model'

/**
 * Dashboard page with navigation cards and activity overview
 * Used as home page
 */
export function DashboardPage() {
  const {navigationCardItems, chartData, tableColumns, tableRows, loadData, t} = useDashboardModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={t(TranslationKey.Dashboard)}>
      <Stack gap={5}>
        <ComposedComponents.NavigationCards items={navigationCardItems} />
        <Stack direction={'row'} gap={5} justifyContent={'space-evenly'}>
          <UIKit.BackgroundCard title={t(TranslationKey['Contract statistics'])} width={'100%'} height={'300px'}>
            <ResponsiveContainer width="100%" height={'100%'}>
              <BarChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                  left: -35,
                  bottom: 55,
                }}
              >
                <XAxis fontSize={'0.75rem'} dataKey="name" />
                <YAxis fontSize={'0.75rem'} tickFormatter={value => (Number.isInteger(value) ? value : '')} />
                <Tooltip />
                <Bar dataKey={t(TranslationKey.Cancelled)} stackId="a" fill={colors.error.main} />
                <Bar dataKey={t(TranslationKey.Draft)} stackId="a" fill={colors.main.auxiliary} />
                <Bar dataKey={t(TranslationKey.Quote)} stackId="a" fill={colors.main.auxiliary} />
                <Bar strokeWidth={1} dataKey={t(TranslationKey.Pending)} stackId="a" fill={colors.main.auxiliary} />
                <Bar strokeWidth={1} dataKey={t(TranslationKey.Active)} stackId="a" fill={colors.main.light} />
                <Bar dataKey={t(TranslationKey['Sent for signing'])} stackId="a" fill={colors.main.dark} />
                <Bar dataKey={t(TranslationKey.Signed)} stackId="a" fill={colors.main.dark} />
              </BarChart>
            </ResponsiveContainer>
          </UIKit.BackgroundCard>

          <UIKit.BackgroundCard width={'100%'}>
            <UIKit.Table
              hidePagination
              hideSearch
              hideToolbar
              rows={tableRows}
              columns={tableColumns}
              title={t(TranslationKey['Latest activity'])}
            />
          </UIKit.BackgroundCard>
        </Stack>
      </Stack>
    </PageWrapper>
  )
}
