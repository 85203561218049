import {APIMethod} from '@enums'

import {fetchData} from 'api/api'

interface Role {
  action: string
  label: string
  name: string
}

interface RecipientRequest {
  givenName: string
  familyName: string
  language: string
  signingMethod: string
  email: string
  sms: boolean
  telephone: string
  order: number
  notificationMethod: string
  role: Role
}

interface SigningRequest {
  file: ArrayBuffer
  documentName: string
  contractId: string
  greeting: string
  recipientDto: RecipientRequest
}

/**
 * Send a PDF Contract for signing
 * @param body Signing request payload data
 * @returns void
 */
export const sendForSigning = async (body: SigningRequest): Promise<string> => {
  const formData = new FormData()

  const fileBlob = new Blob([body.file], {type: 'application/pdf'})
  formData.append('file', fileBlob, body.documentName)

  formData.append('documentName', body.documentName)
  formData.append('contractId', body.contractId)
  formData.append('greeting', body.greeting)

  formData.append('recipientDto', new Blob([JSON.stringify(body.recipientDto)], {type: 'application/json'}))

  return fetchData({
    method: APIMethod.POST,
    url: `verified/process-contract`,
    contentType: 'multipart/form-data',
    body: formData,
  })
}
