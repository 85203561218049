import {useLoadOnce} from '@common'
import {NavigationPathKey, TranslationKey} from '@enums'
import {PageWrapper, SearchTableWrapper} from '@wrappers'

import React from 'react'

import {UIKit} from '@components'

import {usePRoductSearchModel} from './product-search-model'

/**
 * Product Search page
 */
export function ProductSearchPage() {
  const {navigate, loadData, t, visibilityModel, showCreateButton, rows, columns} = usePRoductSearchModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={t(TranslationKey['Product Search'])}>
      <SearchTableWrapper>
        <UIKit.Table
          {...(showCreateButton && {
            buttonProps: {
              buttonText: t(TranslationKey['Add new product']),
              onClick: () => navigate(NavigationPathKey.PRODUCT_CREATION),
            },
          })}
          visibilityModel={visibilityModel}
          columns={columns}
          rows={rows}
          title={t(TranslationKey['List of products'])}
        />
      </SearchTableWrapper>
    </PageWrapper>
  )
}
