export enum TranslationKey {
  'Dashboard' = 'Dashboard',
  'Contract creation' = 'Contract creation',
  'Contract search' = 'Contract search',
  'Credit check' = 'Credit check',
  'Admin panel' = 'Admin panel',
  'Settings' = 'Settings',
  'Users' = 'Users',
  'Companies' = 'Companies',
  'Dealers' = 'Dealers',
  'Contract types' = 'Contract types',
  'Logs' = 'Logs',
  'User search' = 'User search',
  'Dealer search' = 'Dealer search',
  'User logs' = 'User logs',
  'Error logs' = 'Error logs',
  'Contract type search' = 'Contract type search',
  'Contract Type view' = 'Contract Type view',

  'Page size' = 'Page size',
  'Show' = 'Show',
  'Search' = 'Search',
  'Latest activity' = 'Latest activity',
  'Name' = 'Name',
  'Version' = 'Version',
  'Last update' = 'Last update',
  'Status' = 'Status',
  'The page you requested does not exist.' = 'The page you requested does not exist.',
  'Go back' = 'Go back',
  'You have successfully logged out.' = 'You have successfully logged out.',
  'Login' = 'Login',
  'List of users' = 'List of users',
  'List of companies' = 'List of companies',
  'List of dealers' = 'List of dealers',
  'List of contracts' = 'List of contracts',
  'Add new contract' = 'Add new contract',
  'Add new user' = 'Add new user',
  'Add new dealer' = 'Add new dealer',
  'Add new product' = 'Add new product',
  'New user' = 'New user',
  'Back' = 'Back',
  'Next' = 'Next',
  'Cancel' = 'Cancel',
  'First name' = 'First name',
  'Last name' = 'Last name',
  'Email' = 'Email',
  'Discard' = 'Discard',
  'Save' = 'Save',
  'Welcome back' = 'Welcome back',
  'Sign in to start managing your contracts.' = 'Sign in to start managing your contracts.',
  'Username' = 'Username',
  'Password' = 'Password',
  'Sign in' = 'Sign in',
  "Don't have an account?" = "Don't have an account?",
  'Countries' = 'Countries',
  'You have unsaved changes.' = 'You have unsaved changes.',
  'If you continue, your changes will not be saved' = 'If you continue, your changes will not be saved',
  'Continue and discard?' = 'Continue and discard?',
  'Product number' = 'Product number',
  'Product price' = 'Product price',
  'Product name' = 'Product name',
  'Residual value' = 'Residual value',
  'Service price' = 'Service price',
  'Accessory connection' = 'Accessory connection',
  'Serial number' = 'Serial number',
  'Delivery date' = 'Delivery date',
  'Product' = 'Product',
  'Add product' = 'Add product',
  'Remove product' = 'Remove product',
  'Something went wrong.' = 'Something went wrong.',
  'If you do not have an account, please contact your system administrator.' = 'If you do not have an account, please contact your system administrator.',
  'Please check the following fields' = 'Please check the following fields',
  'View' = 'View',
  'Full name' = 'Full name',
  'At least one item is required' = 'At least one item is required',
  'Edit' = 'Edit',
  'User view' = 'User view',
  'Roles' = 'Roles',
  'Edit user' = 'Edit user',
  'General Info' = 'General info',
  'Permissions' = 'Permissions',
  'UI version' = 'UI version',
  'BE version' = 'BE version',
  'Information' = 'Information',
  'Active' = 'Active',
  'Privileges' = 'Privileges',
  'Inactive' = 'Inactive',
  'Description' = 'Description',
  'Notes' = 'Notes',
  'Suggested dimensions' = 'Suggested dimensions',
  'Suggested format' = 'Suggested format',
  'Sweden' = 'Sweden',
  'Norway' = 'Norway',
  'Denmark' = 'Denmark',
  'Finland' = 'Finland',
  'Germany' = 'Germany',
  'Austria' = 'Austria',
  'Netherlands' = 'Netherlands',
  'France' = 'France',
  'United Kingdom' = 'United Kingdom',
  'Country' = 'Country',
  'Contract type' = 'Contract type',
  'English' = 'English',
  'Swedish' = 'Swedish',
  'French' = 'French',
  'German' = 'German',
  'Finnish' = 'Finnish',
  'Norwegian' = 'Norwegian',
  'Danish' = 'Danish',
  'Dutch' = 'Dutch',
  'Language' = 'Language',
  'Products' = 'Products',
  'Role' = 'Role',
  'Dealer' = 'Dealer',
  'New dealer' = 'New dealer',
  'Address' = 'Address',
  'Customer number' = 'Customer number',
  'Dealer name' = 'Dealer name',
  'Dealer view' = 'Dealer view',
  'Contract type name' = 'Contract type name',
  'New contract type' = 'New contract type',
  'Note' = 'Note',
  'Type' = 'Type',
  'Add new contract type' = 'Add new contract type',
  'List of contract types' = 'List of contract types',
  'Edit contract type' = 'Edit contract type',
  'Edit dealer' = 'Edit dealer',
  'Product Search' = 'Product Search',
  'List of products' = 'List of products',
  'Edit product' = 'Edit product',
  'New product' = 'New product',
  'Price' = 'Price',
  'Prices' = 'Prices',
  'Created at' = 'Created at',
  'Super administrator' = 'Super administrator',
  'Accessories' = 'Accessories',
  'Product view' = 'Product view',
  'Accessory view' = 'Accessory view',
  'New accessory' = 'New accessory',
  'Edit accessory' = 'Edit accessory',
  'Accessory Search' = 'Accessory Search',
  'Add new accessory' = 'Add new accessory',
  'List of accessories' = 'List of accessories',
  'Logs search' = 'Logs search',
  'Logout' = 'Logout',
  'Profile' = 'Profile',
  'Linked accessories' = 'Linked accessories',
  'General information' = 'General information',
  'Price information' = 'Price information',
  'Unlink' = 'Unlink',
  'Link an accessory' = 'Link an accessory',
  'Link' = 'Link',
  'Choose an accessory to link to' = 'Choose an accessory to link to',
  'Successfully unlinked an accessory' = 'Successfully unlinked an accessory',
  'Successfully linked an accessory' = 'Successfully linked an accessory',
  'Link a product' = 'Link a product',
  'Linked products' = 'Linked products',
  'Choose a product to link to' = 'Choose a product to link to',
  'Successfully unlinked a product' = 'Successfully unlinked a product',
  'Successfully linked a product' = 'Successfully linked a product',
  'Contract setup' = 'Contract setup',
  'Products and accessories' = 'Products and accessories',
  'Customer information' = 'Customer information',
  'Overview' = 'Overview',
  'Create contract' = 'Create contract',
  'Create quote' = 'Create quote',
  'What do you want to do' = 'What do you want to do',
  'Accessory' = 'Accessory',
  'Remove accessory' = 'Remove accessory',
  'Add accessory' = 'Add accessory',
  'City' = 'City',
  'Date of birth' = 'Date of birth',
  'Phone' = 'Phone',
  'Street' = 'Street',
  'Zip code' = 'Zip code',
  'Personal number' = 'Personal number',
  'Check' = 'Check',
  'Choose a product' = 'Choose a product',
  'Choose an accessory' = 'Choose an accessory',
  'and ${number} more' = 'and ${number} more',
  'Credit score' = 'Credit score',
  'Unknown' = 'Unknown',
  'Contract overview' = 'Contract overview',
  'Customer' = 'Customer',
  'Date' = 'Date',
  'Additional Information' = 'Additional Information',
  'Quantity' = 'Quantity',
  'Price total' = 'Price total',
  'Residual value total' = 'Residual value total',
  'Service price total' = 'Service price total',
  'Insurance price' = 'Insurance price',
  'Insurance price total' = 'Insurance price total',
  'Period' = 'Period',
  'Annuity' = 'Annuity',
  'Average amortization' = 'Average amortization',
  'Average function and interest' = 'Average function and interest',
  'Insurance' = 'Insurance',
  'Service' = 'Service',
  'Total cost' = 'Total cost',
  'Month' = 'Month',
  'Total' = 'Total',
  'Advanced payment' = 'Advanced payment',
  'Function interest' = 'Function interest',
  'Interest' = 'Interest',
  'Minimum insurance' = 'Minimum insurance',
  'Discounts' = 'Discount',
  'Rate information' = 'Rate Information',
  'Lock fields' = 'Lock fields',
  'Disclaimer' = 'Disclaimer',
  'Yes' = 'Yes',
  'No' = 'No',
  'Months' = 'Months',
  'Contract length' = 'Contract length',
  'Residual' = 'Residual',
  'Qty.' = 'Qty.',
  'Function and interest' = 'Function and interest',
  'Amortization' = 'Amortization',
  'Avg.' = 'Avg.',
  'Bulk upload' = 'Bulk upload',
  'In order to bulk upload, you can prepare a JSON with all the products and linked accessories. The JSON must be of the following structure:' = 'In order to bulk upload, you can prepare a JSON with all the products and linked accessories. The JSON must be of the following structure:',
  'File successfully uploaded' = 'File succsessfully uploaded',
  'Click or drag a file to upload' = 'Click or drag a file to upload',
  'Activity logs' = 'Activity logs',
  'Timestamp' = 'Timestamp',
  'User' = 'User',
  'Activity' = 'Activity',
  'Close' = 'Close',
  'Credit check result' = 'Credit check result',
  'Price Information' = 'Price Information',
  'Product minimum insurance' = 'Product minimum insurance',
  'Accessory minimum insurance' = 'Accessory minimum insurance',
  'Generate PDF' = 'Generate PDF',
  'Contract' = 'Contract',
  'PDF downloaded' = 'PDF downloaded',
  'Products successfully uploaded' = 'Products successfully uploaded',
  'Save and send for signing' = 'Save and send for signing',
  'Save options' = 'Save options',
  'Send for signing' = 'Send for signing',
  'PDF overview' = 'PDF overview',
  'Download PDF' = 'Download PDF',
  'Greeting' = 'Greeting',
  'Notification method' = 'Notification method',
  'Signing method' = 'Signing method',
  'SMS' = 'SMS',
  'Bank ID' = 'Bank ID',
  'Send SMS notification' = 'Send SMS notification',
  'Please sign this document' = 'Please sign this document',
  'Message' = 'Message',
  'Amplio partner' = 'Amplio partner',
  'Dealer user' = 'Dealer user',
  'Administrator' = 'Administrator',
  'Amplio partners' = 'Amplio partners',
  'List of Amplio partners' = 'List of Amplio partners',
  'Add new partner' = 'Add new partner',
  'New partner' = 'New partner',
  'Edit partner' = 'Edit partner',
  'Partner view' = 'Partner view',
  'Partner name' = 'Partner name',
  'Partner search' = 'Partner search',
  'Info' = 'Info',
  'Application information' = 'Application information',
  'App info' = 'App info',
  'Product discount' = 'Product discount',
  'Accessory discount' = 'Accessory discount',
  'Service discount' = 'Service discount',
  'Display service price' = 'Display service price',
  'Display installation compensation' = 'Display installation compensation',
  'Display extra costs' = 'Display extra costs',
  'Contract product fields options' = 'Contract product fields options',
  'Allowed dealers' = 'Allowed dealers',
  'Dealer number' = 'Dealer number',
  'Company number' = 'Company number',
  'VAT number' = 'VAT number',
  'Contact information' = 'Contact information',
  'Official signatories' = 'Official signatories',
  'Official signatory' = 'Official signatory',
  'Add signatory' = 'Add signatory',
  'Remove signatory' = 'Remove signatory',
  'Allowed contract types' = 'Allowed contract types',
  'New password' = 'New password',
  'Installation compensation' = 'Installation compensation',
  'Incl.' = 'Incl.',
  'Continue' = 'Continue',
  'Successfully unlinked a contract type' = 'Successfully unlinked a contract type',
  'Linked contract types' = 'Linked contract types',
  'Link a contract type' = 'Link a contract type',
  'Choose a contract type to link to' = 'Choose a contract type to link to',
  'Successfully linked a contract type' = 'Successfully linked a contract type',
  'Get person data' = 'Get person data',
  'Link a dealer' = 'Link a dealer',
  'Successfully linked a dealer' = 'Successfully linked a dealer',
  'Successfully unlinked a dealer' = 'Successfully unlinked a dealer',
  'Choose a dealer to link to' = 'Choose a dealer to link to',
  'Linked dealers' = 'Linked dealers',
  'Default prices' = 'Default prices',
  'Add price' = 'Add price',
  'Remove price' = 'Remove price',
  'Valid for' = 'Valid for',
  'Day(s)' = 'Day(s)',
  'There are no Contract types available for the selected Dealership' = 'There are no Contract types available for the selected Dealership',
  'Valid until' = 'Valid until',
  'Quote' = 'Quote',
  'Pending' = 'Pending',
  'Sent' = 'Sent',
  'Cancelled' = 'Cancelled',
  'Default price' = 'Default price',
  'No. of accessories' = 'No. of accessories',
  'Customer Credit score is not checked for Quotes. If saved, Contract will remain in Quote status and can be completed until' = 'Customer Credit score is not checked for Quotes. If saved, Contract will remain in Quote status and can be completed until',
  'The Customer has a negative Credit score. To complete the contract, an Amplio Administrator must manually approve it. If you are not an Amplio Administrator, the contract will be saved in status Active and can be completed until' = 'The Customer has a negative Credit score. To complete the contract, an Amplio Administrator must manually approve it. If you are not an Amplio Administrator, the contract will be saved in status Active and can be completed until',
  'The Customer has a positive Credit score. You may complete the contract and set it for signing.' = 'The Customer has a positive Credit score. You may complete the contract and set it for signing.',
  'Contract length and fixed extra costs' = 'Contract length and fixed extra costs',
  'Label' = 'Label',
  'Value' = 'Value',
  'Extra cost' = 'Extra cost',
  'Remove item' = 'Remove item',
  'Extra costs' = 'Extra costs',
  'Add item' = 'Add item',
  'Prices for a single item' = 'Prices for a single item',
  'Per year' = 'Per year',
  'Total price' = 'Total price',
  'Per item' = 'Per item',
  'Contract ID' = 'Contract ID',
  'Approve' = 'Approve',
  'Monthly fee' = 'Monthly fee',
  'No. of products' = 'No. of products',
  'Sent for signing' = 'Sent for signing',
  'Click to download the template file' = 'Click to download the template file',
  'In order to bulk upload, you must prepare an Excel file in a specific format. You can download the template file, populate it, and upload.' = 'In order to bulk upload, you must prepare an Excel file in a specific format. You can download the template file, populate it, and upload.',
  'On the sheet Products enter the required Product information in each row. In the Accessories columns, enter all the Accessories ID you want to link to the specific product, separated with a comma. You can find the Accessory ID on the Accessories sheet.' = 'On the sheet Products enter the required Product information in each row. In the Accessories columns, enter all the Accessories ID you want to link to the specific product, separated with a comma. You can find the Accessory ID on the Accessories sheet.',
  'For each Contract type you will have a separate sheet. There, you can enter a specific set of prices for each Product or Accessory. You may leave these fields blank.' = 'For each Contract type you will have a separate sheet. There, you can enter a specific set of prices for each Product or Accessory. You may leave these fields blank.',
  'Some important notes:' = 'Some important notes:',
  'If not selected, you must first select an Amplio Partner for which you want to bulk upload' = 'If not selected, you must first select an Amplio Partner for which you want to bulk upload',
  'For each new bulk upload, please download a new template file and do not reuse an old one' = 'For each new bulk upload, please download a new template file and do not reuse an old one',
  'Accessory name' = 'Accessory name',
  'Do not add or remove any rows, columns or sheets' = 'Do not add or remove any rows, columns or sheets',
  'Do not edit or remove any of the highlighted cells' = 'Do not edit or remove any of the highlighted cells',
  'Terms and conditions' = 'Terms and conditions',
  'Description and disclaimer' = 'Description and disclaimer',
  'Environment' = 'Environment',
  'Important information' = 'Important information',
  'Time format' = 'Time format',
  'Contract lengths' = 'Contract lengths',
  'Month(s)' = 'Month(s)',
  'Custom monthly fees' = 'Custom monthly fees',
  'You must choose at least one contract length' = 'You must choose at least one contract length',
  'Draft' = 'Draft',
  'Signed' = 'Signed',
  'Contract statistics' = 'Contract statistics',
  'Invoice email' = 'Invoice email',
  'Invoice phone' = 'Invoice phone',
  'House number' = 'House number',

  'Save as draft' = 'Save as draft',
  'Currency' = 'Currency',
  'Company name' = 'Company name',
  'Customer Credit score is not checked for B2B contracts. You may complete the contract and set it for signing.' = 'Customer Credit score is not checked for B2B contracts. You may complete the contract and set it for signing.',
  'Customer type' = 'Customer type',
  'Contract code' = 'Contract code',
  'A unique code is assigned to this contract. You may add an additional optional identifier or leave it blank.' = 'A unique code is assigned to this contract. You may add an additional optional identifier or leave it blank.',
  'Optional' = 'Optional',
}
