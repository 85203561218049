import {
  DealerResponse,
  LoggedInUser,
  UserResponse,
  getDealers,
  getDealersByCompany,
  getUser,
  getUsers,
  getUsersByCompany,
  handleAxiosError,
} from '@api'
import {AppContext} from '@contexts'
import {LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef, GridColumnVisibilityModel} from '@mui/x-data-grid'

import {useContext, useState} from 'react'

import {
  activeCell,
  getUserData,
  isCompanyAdmin,
  isCompanyUser,
  isSuperAdmin,
  roles,
  renderClickableCell,
  getUserCompany,
} from 'common'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

export interface UsersTableData {
  name: string
  dealer: string
  email: string
  active: boolean
  role: string
}

export function useUserSearchModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const {t} = useTranslation()
  const [rows, setRows] = useState<UsersTableData[]>([])
  const navigate = useNavigate()
  const currentUser: LoggedInUser = getUserData()

  /**
   * User table columns configuration
   */
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 0.8,
      hideable: false,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.USER_VIEW}/${params.row.id}`)),
    },
    {field: 'email', headerName: t(TranslationKey.Email), flex: 1},
    {field: 'dealer', headerName: t(TranslationKey.Dealer), flex: 1},
    {field: 'role', headerName: t(TranslationKey.Role), flex: 0.8},
    activeCell(t),
  ]

  /**
   * User table visibility model configuration
   */
  const visibilityModel: GridColumnVisibilityModel = {
    company: false,
    dealer: false,
  }

  /**
   * Create button should be available only for Administrator users
   */
  const showCreateButton = !isCompanyUser(currentUser)

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async () => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      let data: UserResponse[] = []
      let dealers: DealerResponse[] = []

      // Super Administrator can see ALL the data
      if (isSuperAdmin(currentUser)) {
        data = await getUsers()
        dealers = await getDealers()
      } else {
        // Company Administrator can see ONLY it's Company Users
        if (isCompanyAdmin(currentUser)) {
          data = await getUsersByCompany(String(currentUser.companyId))
        }
        // Company User can see ONLY itselft
        else {
          data = [await getUser(String(currentUser.id))]
        }
        dealers = await getDealersByCompany(String(currentUser.companyId))
      }

      setLoadingStatus(LoadingStatus.SUCCESS)
      const formattedData: UsersTableData[] = data
        .filter(item =>
          isSuperAdmin(currentUser) ? item.companyId === getUserCompany(currentUser.id) || isSuperAdmin(item) : true,
        )
        .map(item => ({
          name: item.fullName,
          email: item.email,
          active: item.active,
          dealer: dealers.find(dealer => dealer.id === item.dealerId)?.name || '-',
          role: roles(t).find(roleItem => roleItem.code === item.role)?.name || '-',
          id: item.id,
        }))
      setRows(formattedData)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {loadData, navigate, t, visibilityModel, showCreateButton, rows, columns}
}
