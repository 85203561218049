import {Stepper, Step, StepLabel} from '@mui/material'

interface Props {
  steps: string[]
  activeStep: number
}

/**
 * Stepper component used for Contract Creation steps tracking
 */
export function ContractStepper(props: Props) {
  const {steps, activeStep} = props

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
