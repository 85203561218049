import React, {ReactNode} from 'react'

import {UIKit} from '@components'

import {ContentContainer, tableWrapperStyle} from './style'

interface Props {
  children: ReactNode
}

export function SearchTableWrapper(props: Props) {
  const {children} = props
  return (
    <UIKit.BackgroundCard style={tableWrapperStyle}>
      <ContentContainer>
        <UIKit.BackgroundCard width={'75%'}>{children}</UIKit.BackgroundCard>
      </ContentContainer>
    </UIKit.BackgroundCard>
  )
}
