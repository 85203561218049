import {generatePdf, getCreditCheckModalIcon, getImageBase64, renderFormField, useLoadOnce} from '@common'
import {ContractType, ImageType, TranslationKey} from '@enums'
import {Stack, Typography} from '@mui/material'

import {useState} from 'react'

import {UIKit} from '@components'

import {generateHtml} from './contract-overview-html'
import {ContractPdfOverviewProps, useContractPDFOverviewModel} from './contract-pdf-overview-model'
import {ModalContentWrapper} from './style'

type Props = {
  isSignatureOptionsModalVisible: boolean
  setIsSignatureOptionsModalVisible: (state: boolean) => void
  onSendForSigningBtnClick: () => void
} & ContractPdfOverviewProps

/**
 * Contract PDF Overview step
 */
export function ContractPDFOverview(props: Props) {
  const [pdfUrl, setPdfUrl] = useState<string>('')
  const {isSignatureOptionsModalVisible, setIsSignatureOptionsModalVisible, onSendForSigningBtnClick, ...rest} = props

  const {signingFormFields, t, dataForHtml, modalMessage, formData} = useContractPDFOverviewModel(rest)
  const [isCreditScoreModalOpen, setIsCreditScoreModalOpen] = useState(true)

  const createPdf = async () => {
    const imageUrl = await getImageBase64(ImageType.COMPANY, formData?.contractSetupData?.company?.id || '')
    const htmlData = generateHtml(t, dataForHtml, imageUrl)
    const doc = await generatePdf(htmlData)
    const pdfBlob = doc.output('blob')
    const pdfUrl = URL.createObjectURL(pdfBlob)
    setPdfUrl(pdfUrl)
  }

  useLoadOnce(createPdf)

  return (
    <>
      <UIKit.BackgroundCard title={t(TranslationKey['PDF overview'])}>
        {pdfUrl && (
          <iframe
            src={`${pdfUrl}#toolbar=0&navpanes=0&view=FitH`}
            title="PDF Viewer"
            width="50%"
            height="800px"
            style={{border: 'none'}}
          />
        )}
      </UIKit.BackgroundCard>
      <UIKit.Modal
        open={isSignatureOptionsModalVisible}
        title={t(TranslationKey['Send for signing'])}
        cancelButtonProps={{
          buttonText: t(TranslationKey.Cancel),
          onClick: () => setIsSignatureOptionsModalVisible(false),
        }}
        confirmButtonProps={{
          buttonText: t(TranslationKey['Save and send for signing']),
          onClick: onSendForSigningBtnClick,
        }}
        content={
          <Stack gap={2}>
            {signingFormFields.map((item, index) => (
              <Stack key={`signing-form-field-${index}`}>{renderFormField(item)}</Stack>
            ))}
          </Stack>
        }
      />
      <UIKit.Modal
        confirmButtonProps={{
          buttonText: t(TranslationKey.Continue),
          onClick: () => setIsCreditScoreModalOpen(false),
        }}
        content={
          <ModalContentWrapper>
            {getCreditCheckModalIcon(
              formData.contractSetupData.type,
              formData.contractSetupData.contractType?.type as ContractType,
              formData.contractSetupData.creditCheckPassed,
            )}
            <Typography textAlign={'justify'}>{modalMessage}</Typography>
          </ModalContentWrapper>
        }
        open={isCreditScoreModalOpen}
        title={t(TranslationKey['Credit check result'])}
      />
    </>
  )
}
