import {useLoadOnce} from '@common'
import {NavigationPathKey, TranslationKey} from '@enums'
import {PageWrapper, SearchTableWrapper} from '@wrappers'

import React from 'react'

import {UIKit} from '@components'

import {UsersTableData, useUserSearchModel} from './user-search-model'

/**
 * User Search page
 */
export function UserSearchPage() {
  const {navigate, loadData, t, showCreateButton, rows, visibilityModel, columns} = useUserSearchModel()

  useLoadOnce(loadData)

  return (
    <PageWrapper title={t(TranslationKey['User search'])}>
      <SearchTableWrapper>
        <UIKit.Table<UsersTableData>
          {...(showCreateButton && {
            buttonProps: {
              buttonText: t(TranslationKey['Add new user']),
              onClick: () => navigate(NavigationPathKey.USER_CREATION),
            },
          })}
          visibilityModel={visibilityModel}
          columns={columns}
          rows={rows}
          title={t(TranslationKey['List of users'])}
        />
      </SearchTableWrapper>
    </PageWrapper>
  )
}
