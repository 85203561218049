import {
  CompanyResponse,
  getCompanies,
  getCompany,
  getUser,
  register,
  updateUser,
  UserData,
  UserRequest,
  UserResponse,
  handleAxiosError,
  DealerResponse,
  getDealer,
  getDealers,
  getDealersByCompany,
  getImage,
  LoggedInUser,
  postImage,
} from '@api'
import {AppContext} from '@contexts'
import {FormFieldType, ImageType, LoadingStatus, NavigationPathKey, TranslationKey, UserRole} from '@enums'

import {useContext, useMemo, useState} from 'react'

import {
  createRadioBtnOptions,
  FormFieldItem,
  generateDropdownOptions,
  generateReadableErrorMessages,
  getUserCompany,
  getUserData,
  isCompanyAdmin,
  isCompanyUser,
  isSuperAdmin,
  refreshUserDataAuthStorage,
  roles,
  RolesFormData,
  validateFields,
} from 'common'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useParams} from 'react-router-dom'

import {UIKit} from '@components'

/**
 * Initial data
 */
const initialUserData: UserData = {
  fullName: '',
  password: '',
  email: '',
  active: true,
  role: UserRole.COMPANY_USER,
  dealerId: null,
  companyId: null,
  image: '',
}
export function useUserFormModel() {
  const {setLoadingStatus, setError, setIsFormDirty, handleNavigate} = useContext(AppContext)
  const [userData, setUserData] = useState<UserData>(initialUserData)
  const [userFormFieldsErrors, setUserFormFieldsErrors] = useState<string[]>([])
  const [dealers, setDealers] = useState<DealerResponse[]>([])
  const [companies, setCompanies] = useState<UIKit.DropdownItem[]>([])
  const [imageFile, setImageFile] = useState<undefined | File>(undefined)
  const currentUser: LoggedInUser = getUserData()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {id} = useParams()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const dealerId = queryParams.get('dealerId')

  /**
   * Dynamically generated title text for User form page
   */
  const title: string = useMemo(
    () => (id ? `${t(TranslationKey['Edit user'])} - ${userData.fullName}` : t(TranslationKey['New user'])),
    [id, userData.fullName],
  )

  /**
   * Image src
   */
  const image = useMemo(() => userData.image, [userData.image])

  /**
   * Dealer options filtered by Company ID
   */
  const filteredDealers: UIKit.DropdownItem[] = useMemo(
    () => generateDropdownOptions(dealers.filter(item => !userData.companyId || item.companyId === userData.companyId)),
    [dealers, userData.companyId],
  )

  /**
   * A function that handles User form fields change
   * @param event Change event
   * @param checked Checked state for checkbox form fields
   */
  const onChangeUserGeneralFormField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean,
  ) => {
    setIsFormDirty(true)
    const fieldName = event.target.name
    const value = checked ?? event.target.value
    setUserFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    setUserData(prev => ({...prev, [fieldName]: value}))
  }

  /**
   * A function that handles User dropdown form field change
   * @param fieldName Field name
   * @param item Dropdown item
   */
  const onChangeUserAdditionalFormField = (fieldName: string, item: UIKit.DropdownItem | null) => {
    setIsFormDirty(true)
    setUserFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    if (fieldName === 'companyId' && userData.dealerId && item !== null) {
      const currentDealer = dealers.find(item => item.id === userData.dealerId)
      if (currentDealer?.companyId !== item?.value) setUserData(prev => ({...prev, dealerId: null}))
    }
    setUserData(prev => ({...prev, [fieldName]: item?.value ?? null}))
  }

  /**
   * A function that handles image file drop
   * @param acceptedFiles File
   */
  const handleDrop = (acceptedFiles: File[]): void => {
    setImageFile(acceptedFiles[0])
    setUserData(prev => ({...prev, image: URL.createObjectURL(acceptedFiles[0])}))
  }

  /**
   * User form fields configuration - general section
   */
  const userGeneralFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Full name']),
      name: 'fullName',
      onChange: onChangeUserGeneralFormField,
      value: userData.fullName,
      error: userFormFieldsErrors.includes('fullName'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Email),
      name: 'email',
      onChange: onChangeUserGeneralFormField,
      value: userData.email,
      error: userFormFieldsErrors.includes('email'),
    },
    ...(id
      ? [
          {
            fieldType: FormFieldType.CHECKBOX_GROUP,
            name: 'active',
            label: t(TranslationKey.Active),
            checkboxes: [
              {
                checked: userData.active,
                label: t(TranslationKey.Active),
                value: userData.active,
                name: 'active',
                onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                  onChangeUserGeneralFormField(event, checked),
              },
            ],
          },
          ...(isSuperAdmin(currentUser)
            ? [
                {
                  fieldType: FormFieldType.INPUT,
                  label: t(TranslationKey['New password']),
                  type: 'password',
                  name: 'password',
                  onChange: onChangeUserGeneralFormField,
                  value: userData.password,
                  error: userFormFieldsErrors.includes('password'),
                },
              ]
            : []),
        ]
      : [
          {
            fieldType: FormFieldType.INPUT,
            label: t(TranslationKey.Password),
            type: 'password',
            name: 'password',
            onChange: onChangeUserGeneralFormField,
            value: userData.password,
            error: userFormFieldsErrors.includes('password'),
            disabled: !!id,
          },
        ]),
  ]

  /**
   * User form fields configuration - role section
   */
  const userRoleFormField: FormFieldItem = useMemo(
    () => ({
      fieldType: FormFieldType.RADIO_BUTTON_GROUP,
      name: 'role',
      label: t(TranslationKey.Role),
      itemsInColumn: 3,
      value: userData.role,
      radioButtons: createRadioBtnOptions(
        'code',
        'name',
        'role',
        // Super Administrator can assign all Roles
        // Company Administrator and Company User can only assign Company User Role
        (item: RolesFormData) => {
          if (isSuperAdmin(currentUser) || item.code === UserRole.COMPANY_USER) return false
          else return true
        },
        (event: React.ChangeEvent<HTMLInputElement>) => onChangeUserGeneralFormField(event),
        roles(t),
      ),
      error: userFormFieldsErrors.includes('role'),
    }),
    [userData.role, userFormFieldsErrors],
  )

  /**
   * User form fields configuration - additional section
   */
  const userAdditionalFormFields: FormFieldItem[] = useMemo(
    () => [
      {
        fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
        name: 'companyId',
        label: t(TranslationKey['Amplio partner']),
        value: userData.companyId || undefined,
        items: companies,
        error: userFormFieldsErrors.includes('companyId'),
        onChange: (item: UIKit.DropdownItem | null) => onChangeUserAdditionalFormField('companyId', item),
        disabled: true,
      },
      {
        fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
        name: 'dealerId',
        label: t(TranslationKey.Dealer),
        value: userData.dealerId || undefined,
        items: filteredDealers,
        error: userFormFieldsErrors.includes('dealerId'),
        onChange: (item: UIKit.DropdownItem | null) => onChangeUserAdditionalFormField('dealerId', item),
        // Company User cannot change it's Dealer
        disabled: isCompanyUser(currentUser),
      },
    ],

    [userData.companyId, companies, dealers, userFormFieldsErrors, userData.dealerId],
  )

  /**
   * A custom validation function tha checks if any User form field is invalid
   * @returns If the User form data contains invalid fields
   */
  const validateData = (): boolean => {
    const notRequiredFields: string[] = ['image']

    if (!(userData.role === UserRole.COMPANY_USER)) {
      notRequiredFields.push('dealerId')
    }
    if (userData.role === UserRole.SUPER_ADMIN) {
      notRequiredFields.push('companyId')
    }

    if (id) {
      notRequiredFields.push('password')
    }

    const invalidValues = validateFields(userData).filter(item => !notRequiredFields.includes(item))

    const isFormInvalid = invalidValues.length > 0
    if (isFormInvalid) {
      setError({
        message: generateReadableErrorMessages(t, invalidValues),
        name: '',
      })

      setUserFormFieldsErrors(invalidValues)
    }
    return isFormInvalid
  }

  /**
   * A function that handles the Save button click
   * If form is valid updates or creates a new User
   * @returns void
   */
  const onSubmit = async (): Promise<void> => {
    if (validateData()) return

    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (id) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {password, ...rest} = userData
        const data = await updateUser(id, {
          ...(rest as UserRequest),
          ...(isSuperAdmin(currentUser) && password !== '' ? {password} : {}),
        })
        if (imageFile)
          await postImage({
            file: imageFile,
            id,
            type: ImageType.USER,
          })
        if (id === String(currentUser.id)) refreshUserDataAuthStorage({...data, image})
        setLoadingStatus(LoadingStatus.SUCCESS)
        setIsFormDirty(false)
        navigate(`${NavigationPathKey.USER_VIEW}/${id}`)
        return
      }

      const data: UserResponse = await register(userData as UserRequest)
      setLoadingStatus(LoadingStatus.SUCCESS)
      setIsFormDirty(false)
      navigate(`${NavigationPathKey.USER_VIEW}/${data.id}`)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      let dealers: DealerResponse[] = []
      let companies: CompanyResponse[] = []

      // Super Administrator can see ALL the data
      if (isSuperAdmin(currentUser)) {
        dealers = await getDealers()
        companies = await getCompanies()
      } else {
        // Company Administrator and Company User can see ONLY their Company
        companies = [await getCompany(String(currentUser.companyId))]

        // Company Administrator can see ONLY their Company's Dealers
        if (isCompanyAdmin(currentUser)) {
          dealers = await getDealersByCompany(String(currentUser.companyId))
        }
        // Company User can see ONLY their Dealer
        else {
          dealers = [await getDealer(String(currentUser.dealerId))]
        }
      }
      setDealers(dealers)
      setCompanies(generateDropdownOptions(companies))
      if (id) {
        const data = await getUser(id)

        setUserData({
          active: data.active,
          companyId: data.companyId,
          dealerId: data.dealerId,
          email: data.email,
          fullName: data.fullName,
          image: getImage(ImageType.USER, id),
          role: data.role,
          password: '',
        })
      } else if (isSuperAdmin(currentUser)) {
        setUserData(prev => ({...prev, companyId: getUserCompany(currentUser.id)}))
      }
      // Company Administrators can only create User profiles for their Company
      else if (isCompanyAdmin(currentUser)) {
        setUserData(prev => ({...prev, companyId: currentUser.companyId}))
      }

      if (dealerId) setUserData(prev => ({...prev, dealerId: dealerId}))
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function that handles the Discard button click
   */
  const onDiscard = (): void => {
    handleNavigate(() => navigate(-1))
  }

  return {
    userGeneralFormFields,
    userRoleFormField,
    userAdditionalFormFields,
    title,
    image,
    t,
    handleDrop,
    onDiscard,
    loadData,
    onSubmit,
    navigate,
  }
}
