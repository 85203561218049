import {NavigationPathKey, TranslationKey} from '@enums'
import companiesCardImage from '@images/companies-card.jpg'
import contractsCardImage from '@images/contracts-card.jpg'
import dealersCardImage from '@images/dealers-card.jpg'
import logsCardImage from '@images/logs-card.jpg'
import productsDashboardCardImage from '@images/products-dashboard-card.jpg'
import usersCardImage from '@images/users-card.jpg'
import {Stack} from '@mui/material'
import {PageWrapper} from '@wrappers'

import {useTranslation} from 'react-i18next'

import {ComposedComponents} from '@components'

/**
 * Administration Panel Dashboard page with navigation cards
 */
export function AdminDashboardPage() {
  const {t} = useTranslation()

  const navigationCardItems: ComposedComponents.CardItem[] = [
    {
      title: t(TranslationKey['Amplio partners']),
      key: NavigationPathKey.COMPANY_SEARCH,
      image: companiesCardImage,
    },
    {
      title: t(TranslationKey.Dealers),
      key: NavigationPathKey.DEALER_SEARCH,
      image: dealersCardImage,
    },
    {
      title: t(TranslationKey.Users),
      key: NavigationPathKey.USER_SEARCH,
      image: usersCardImage,
    },
    {
      title: t(TranslationKey['Contract types']),
      key: NavigationPathKey.CONTRACT_TYPE_SEARCH,
      image: contractsCardImage,
    },
    {
      title: t(TranslationKey['Products and accessories']),
      key: NavigationPathKey.PRODUCT_DASHBOARD,
      image: productsDashboardCardImage,
    },
    {
      title: t(TranslationKey.Logs),
      key: NavigationPathKey.LOGS,
      image: logsCardImage,
    },
  ]

  return (
    <PageWrapper title={t(TranslationKey.Dashboard)}>
      <Stack gap={5}>
        <ComposedComponents.NavigationCards itemsInRow={3} items={navigationCardItems.slice(0, 3)} />
        <ComposedComponents.NavigationCards itemsInRow={3} items={navigationCardItems.slice(3, 6)} />
      </Stack>
    </PageWrapper>
  )
}
