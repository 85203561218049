import {renderFormField, useLoadOnce} from '@common'
import MenuIcon from '@mui/icons-material/Menu'
import {Drawer, Stack, Typography} from '@mui/material'

import React from 'react'

import {SideNavigation, UIKit} from '@components'

import {DropdownsWrapper, TopBarLeftSectionWrapper, TopBarMenuButton, TopNavigationWrapper} from './style'
import {useTopBarModel} from './top-bar-model'

interface Props {
  title: string
}

/**
 * Top Bar component present on all application screens
 */
export function TopBar(props: Props) {
  const {title} = props

  const {loadData, companyFormField, setIsDrawerOpen, isDrawerOpen, showDealer, dealerFormField} = useTopBarModel()

  useLoadOnce(loadData)

  return (
    <>
      <TopNavigationWrapper>
        <TopBarLeftSectionWrapper>
          <TopBarMenuButton onClick={() => setIsDrawerOpen(prev => !prev)}>
            <MenuIcon />
          </TopBarMenuButton>
          <Typography variant="h2" fontWeight={'bold'}>
            {title}
          </Typography>
        </TopBarLeftSectionWrapper>
        <DropdownsWrapper>
          {showDealer && <Stack style={{width: 250}}>{renderFormField(dealerFormField)}</Stack>}
          <Stack style={{width: 250}}>{renderFormField(companyFormField)}</Stack>
          <UIKit.UserMenuButton />
        </DropdownsWrapper>
      </TopNavigationWrapper>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <SideNavigation />
      </Drawer>
    </>
  )
}
