import colors from '@colors'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import {FormControlLabel, Radio, FormGroup, Stack, FormControl, RadioGroup} from '@mui/material'

import React from 'react'

import {LabeledWrapper} from '../wrapper-with-label'
import {FormGroupContent, ErrorIndicator} from './style'

export type RadioItem = {
  label: string
  value: string
}
export interface RadioGroupProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  label?: string
  width?: number
  itemsInColumn?: number
  value?: string
  radioButtons: RadioItem[]
  error?: boolean
  disabled?: boolean
}

/**
 * Radio Button Group component with error component
 */
export function RadioButtonGroup(props: RadioGroupProps) {
  const {label, width, radioButtons, onChange, itemsInColumn = 3, error, value} = props
  const radioButtonsWithError = [...radioButtons, {label: 'error', checked: false}]
  const columns = Math.ceil(radioButtonsWithError.length / itemsInColumn)
  return (
    <LabeledWrapper label={label} width={width}>
      <FormGroup>
        <FormGroupContent>
          <FormControl>
            <RadioGroup onChange={onChange} value={value}>
              <Stack direction={'row'} gap={5}>
                {[...Array(columns)].map((_, index) => (
                  <Stack key={index} spacing={1}>
                    {radioButtonsWithError
                      .slice(index * itemsInColumn, (index + 1) * itemsInColumn)
                      .map((radioButton, radioBtnIndex) => {
                        const {label, ...rest} = radioButton
                        if (label === 'error')
                          return (
                            error && (
                              <ErrorIndicator>
                                <ErrorOutlineRoundedIcon style={{color: colors.error.main}} />
                              </ErrorIndicator>
                            )
                          )
                        return (
                          <FormControlLabel
                            key={`radio-item-group-item-${label}-${radioBtnIndex}`}
                            name={label}
                            control={<Radio {...rest} />}
                            label={label}
                          />
                        )
                      })}
                  </Stack>
                ))}
              </Stack>
            </RadioGroup>
          </FormControl>
        </FormGroupContent>
      </FormGroup>
    </LabeledWrapper>
  )
}
