import {renderFormField, useLoadOnce} from '@common'
import {TranslationKey} from '@enums'
import {Stack, Typography, Button, Grid} from '@mui/material'

import {UIKit} from '@components'

import {ContractProductFormProps, useContractProductFormModel} from './contract-product-form-model'
import {AccessoryFormsWrapper, ButtonWrapper, ProductTitleWrapper, RemoveButton, RemoveButtonIcon} from './style'

/**
 * Contarct Products and Accessories step
 */
export function ContractProductForm(props: ContractProductFormProps) {
  const {
    productGeneralFormFields,
    productPriceFormFields,
    productRestFormFields,
    extraCostsFormFields,
    accessoryFormFields,
    priceInformationFormFields,
    onAddAccessoryBtnClick,
    onRemoveAccessoryBtnClick,
    onAddProductBtnClick,
    onRemoveProductBtnClick,
    onAddExtraCostsBtnClick,
    onRemoveExtraCostsBtnClick,
    setIsContractTypeInformationModalVisible,
    handleContractInformationModalVisibility,
    accessoryPriceFormFields,
    accessoryRestFormFields,
    productSerialNumberFields,
    isContractTypeInformationModalVisible,
    contractLengthFormField,
    contractTotalPriceFormFields,
    officialSignatoryFormField,
    formData,
    t,
  } = useContractProductFormModel(props)

  useLoadOnce(handleContractInformationModalVisibility)

  return (
    <>
      <Stack gap={2}>
        <UIKit.BackgroundCard>
          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid sm={4} item>
                {renderFormField(contractLengthFormField)}
              </Grid>
              <Grid sm={4} item>
                {renderFormField(officialSignatoryFormField)}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {contractTotalPriceFormFields.map((item, index) => (
                <Grid sm={4} item key={`contract-product-price-and-total-field-${index}`}>
                  {renderFormField(item)}
                </Grid>
              ))}
            </Grid>
          </Stack>
        </UIKit.BackgroundCard>

        {formData.productData.map((item, index) => (
          <UIKit.BackgroundCard key={`product-form-fields-${index}`}>
            <Stack>
              <ProductTitleWrapper>
                <Typography fontWeight={'bold'}>{`${t(TranslationKey.Product)} ${index + 1}`}</Typography>
                {index !== 0 && (
                  <RemoveButton onClick={() => onRemoveProductBtnClick(index)} startIcon={<RemoveButtonIcon />}>
                    {t(TranslationKey['Remove product'])}
                  </RemoveButton>
                )}
              </ProductTitleWrapper>
              <Stack gap={2}>
                <Grid container spacing={2}>
                  {productGeneralFormFields(index).map((field, fieldIndex) => (
                    <Grid sm={4} item key={`product-general-form-field-${index}-${fieldIndex}`}>
                      {renderFormField(field)}
                    </Grid>
                  ))}
                  <Grid sm={4} item key={`product-serial-no-form-field-${index}`}>
                    <Grid container spacing={2}>
                      {productSerialNumberFields(index).map((field, fieldIndex) => (
                        <Grid sm={12} item key={`product-serial-no-form-field-${index}-${fieldIndex}`}>
                          {renderFormField(field)}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  {productPriceFormFields(index).map((field, fieldIndex) => (
                    <Grid sm={4} item key={`product-price-form-field-${index}-${fieldIndex}`}>
                      {renderFormField(field)}
                    </Grid>
                  ))}

                  {productRestFormFields(index).map((field, fieldIndex) => (
                    <Grid sm={4} item key={`product-rest-form-field-${index}-${fieldIndex}`}>
                      {renderFormField(field)}
                    </Grid>
                  ))}
                </Grid>

                <Grid container spacing={2}>
                  {priceInformationFormFields(index).map((field, fieldIndex) => (
                    <Grid sm={4} item key={`product-price-information-form-field-${index}-${fieldIndex}`}>
                      {renderFormField(field)}
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Stack>

            <AccessoryFormsWrapper>
              {item.accessories.map((_, accIndex) => (
                <UIKit.BackgroundCard key={`product-${index}-form-fields-${accIndex}`}>
                  <Stack>
                    <ProductTitleWrapper>
                      <Typography fontWeight={'bold'}>{`${t(TranslationKey.Accessory)} ${accIndex + 1}`}</Typography>
                      <RemoveButton
                        onClick={() => onRemoveAccessoryBtnClick(index, accIndex)}
                        startIcon={<RemoveButtonIcon />}
                      >
                        {t(TranslationKey['Remove accessory'])}
                      </RemoveButton>
                    </ProductTitleWrapper>
                    <Stack gap={2}>
                      <Grid container spacing={2}>
                        {accessoryFormFields(index, accIndex).map((field, fieldIndex) => (
                          <Grid sm={4} item key={`product-general-${index}-form-field-${index}-${fieldIndex}`}>
                            {renderFormField(field)}
                          </Grid>
                        ))}
                        {accessoryPriceFormFields(index, accIndex).map((field, fieldIndex) => (
                          <Grid sm={4} item key={`product-price-${index}-form-field-${index}-${fieldIndex}`}>
                            {renderFormField(field)}
                          </Grid>
                        ))}
                        {accessoryRestFormFields(index, accIndex).map((field, fieldIndex) => (
                          <Grid sm={4} item key={`product-rest-${index}-form-field-${index}-${fieldIndex}`}>
                            {renderFormField(field)}
                          </Grid>
                        ))}
                      </Grid>
                      <Grid container spacing={2}>
                        {priceInformationFormFields(index, accIndex).map((field, fieldIndex) => (
                          <Grid
                            sm={4}
                            item
                            key={`product-${index}-price-information-form-field-${index}-${fieldIndex}`}
                          >
                            {renderFormField(field)}
                          </Grid>
                        ))}
                      </Grid>
                    </Stack>
                  </Stack>
                </UIKit.BackgroundCard>
              ))}
            </AccessoryFormsWrapper>
            <ButtonWrapper>
              {index === formData.productData.length - 1 && (
                <Button onClick={onAddProductBtnClick} variant="contained">
                  {t(TranslationKey['Add product'])}
                </Button>
              )}
              {item.id ? (
                <Button onClick={() => onAddAccessoryBtnClick(index)} variant="contained">
                  {t(TranslationKey['Add accessory'])}
                </Button>
              ) : undefined}
            </ButtonWrapper>
          </UIKit.BackgroundCard>
        ))}

        {formData.contractSetupData.contractType?.displayExtraCosts && (
          <UIKit.BackgroundCard title={t(TranslationKey['Extra costs'])}>
            <Stack gap={2}>
              {formData.extraCosts.map((_, index) => (
                <Grid container>
                  <Grid item sm={8}>
                    <ProductTitleWrapper>
                      <Typography fontWeight={'bold'}>{`${t(TranslationKey['Extra cost'])} ${index + 1}`}</Typography>

                      <RemoveButton onClick={() => onRemoveExtraCostsBtnClick(index)} startIcon={<RemoveButtonIcon />}>
                        {t(TranslationKey['Remove item'])}
                      </RemoveButton>
                    </ProductTitleWrapper>
                  </Grid>
                  <Grid item sm={12}>
                    <Grid container spacing={2}>
                      {extraCostsFormFields(index).map((item, fieldIndex) => (
                        <Grid sm={4} item key={`extra-cost-form-field-${index}-${fieldIndex}`}>
                          {renderFormField(item)}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <ButtonWrapper>
                <Button onClick={onAddExtraCostsBtnClick} variant="contained">
                  {t(TranslationKey['Add item'])}
                </Button>
              </ButtonWrapper>
            </Stack>
          </UIKit.BackgroundCard>
        )}

        <UIKit.Modal
          confirmButtonProps={{
            buttonText: t(TranslationKey.Continue),
            onClick: () => setIsContractTypeInformationModalVisible(false),
          }}
          content={
            <Stack>
              <Typography textAlign={'justify'}>{formData.contractSetupData.contractType?.note}</Typography>
            </Stack>
          }
          open={isContractTypeInformationModalVisible}
          title={t(TranslationKey['Important information'])}
        />
      </Stack>
    </>
  )
}
