import {APIMethod, ImageType} from '@enums'

import {fetchData} from 'api/api'

export interface ImageRequest {
  file: File | undefined
  type: ImageType
  id: string
}

/**
 * Fetches stored images
 * @param type Image Type, can be USER or COMPANY
 * @param id User ID or Company ID
 * @returns Image
 */
export const getImage = (type: ImageType, id: string) =>
  `https://amplio-images-test.s3.us-east-1.amazonaws.com/${String(type).toLowerCase()}/${id}`

export const postImage = async (body: ImageRequest): Promise<string> => {
  return fetchData({
    method: APIMethod.POST,
    url: `images/upload`,
    body,
    contentType: 'multipart/form-data',
  })
}
