import {Button, Stack, styled} from '@mui/material'

export const TableContainer = styled(Stack)(() => ({
  gap: '1rem',
}))

export const TopBarContainer = styled(Stack)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
}))

export const SearchContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '2rem',
}))

export const customInputStyle = {height: 35}

export const StyledButton = styled(Button)(() => ({
  height: '2.2rem',
  fontSize: '0.8rem',
}))

export const TableHeightWrapper = styled(Stack)(() => ({}))
