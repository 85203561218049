import {
  ContractTypeData,
  ContractTypeRequest,
  createContractType,
  getContractType,
  updateContractType,
  handleAxiosError,
  linkDealerToContractType,
} from '@api'
import {CompanyResponse, getCompanies, getCompany} from '@api'
import {AppContext} from '@contexts'
import {FormFieldType, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'

import {useContext, useMemo, useState} from 'react'

import {
  contractTypeOptions,
  FormFieldItem,
  generateDropdownOptions,
  generateReadableErrorMessages,
  getUserCompany,
  getUserData,
  isCompanyAdmin,
  isSuperAdmin,
  validateFields,
} from 'common'
import {DropdownItem} from 'components/ui-kit'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useParams} from 'react-router-dom'

/**
 * Initial data
 */
const initialContractTypeData: ContractTypeData = {
  name: '',
  type: '',
  note: '',
  companyId: null,
  active: true,
  disclaimer: '',
  lockFields: false,
  displayExtraCosts: false,
  displayInstallationCosts: false,
  displayServicePrice: false,
  advancedPayment: 1,
  accessoryDiscount: 0,
  productDiscount: 0,
  serviceDiscount: 0,
  functionInterest: 0,
  interest: 0,
  accessoryInsuranceMinimum: 0,
  productInsuranceMinimum: 0,
  insurancePercentage: 0,
  allowedDealers: [],
  expiry: undefined,
  lengths: [18, 24, 36, 60],
}

export function useContractTypeFormModel() {
  const {setLoadingStatus, setError, setIsFormDirty, handleNavigate} = useContext(AppContext)
  const [contractTypeData, setContractTypeData] = useState<ContractTypeData>(initialContractTypeData)
  const [contractTypeFormFieldsErrors, setContractTypeFormFieldsErrors] = useState<string[]>([])
  const [companies, setCompanies] = useState<DropdownItem[]>([])
  const navigate = useNavigate()
  const currentUser = getUserData()
  const {t} = useTranslation()
  const {id} = useParams()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const dealerId = queryParams.get('dealerId')

  /**
   * Dynamically created title text for Contract Type form page
   */
  const title = useMemo(
    () =>
      id
        ? `${t(TranslationKey['Edit contract type'])} - ${contractTypeData.name}`
        : t(TranslationKey['New contract type']),
    [contractTypeData.name],
  )

  /**
   * A function handling Contract Type form fields change
   * @param event Change event
   * @param checked Checked state for checkbox form fields
   * @param percentage A flag defining if the form field holds a percentage value
   */
  const onChangeContractTypeFormField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked?: boolean,
    percentage?: boolean,
  ) => {
    setIsFormDirty(true)
    const fieldName = event.target.name
    const value = checked ?? (!percentage || Number(event.target.value) < 100 ? event.target.value : '100')
    setContractTypeFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    setContractTypeData(prev => ({...prev, [fieldName]: value}))
  }

  /**
   * A function handling Contract Type dropdown form fields change
   * @param fieldName Form field name
   * @param item Dropdown item
   */
  const onChangeContractTypeDropdownFormField = (fieldName: string, item: DropdownItem | null) => {
    setIsFormDirty(true)
    setContractTypeFormFieldsErrors(prev => prev.filter(item => item !== fieldName))
    setContractTypeData(prev => ({...prev, [fieldName]: item?.value ?? null}))
  }

  /**
   * A function handling Contract Type lenght form field change
   * @param event Change event
   * @param checked Checked state
   */
  const onChangeContractLenghtFormField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked: boolean,
  ) => {
    setIsFormDirty(true)
    const fieldName = event.target.name
    let newValue = []
    if (checked) newValue = [...contractTypeData.lengths, Number(fieldName)].sort((a, b) => a - b)
    else newValue = contractTypeData.lengths.filter(item => item !== Number(fieldName))
    setContractTypeFormFieldsErrors(prev => prev.filter(item => item !== 'lengths'))
    setContractTypeData(prev => ({...prev, lengths: newValue}))
  }

  /**
   * A custom validation function for Contract Type form data
   * @returns If Contract Type form field containt any invalid fields
   */
  const validateData = (): boolean => {
    if (contractTypeData.lengths?.length === 0) {
      setError({
        message: t(TranslationKey['You must choose at least one contract length']),
        name: '',
      })
      return true
    }
    const notRequiredFields = ['note', 'type', 'disclaimer']

    const invalidValues = validateFields(contractTypeData).filter(item => !notRequiredFields.includes(item))

    const isFormInvalid = invalidValues.length > 0
    if (isFormInvalid) {
      setError({
        message: generateReadableErrorMessages(t, invalidValues),
        name: '',
      })

      setContractTypeFormFieldsErrors(invalidValues)
    }
    return isFormInvalid
  }

  /**
   * Contract Type form fields configuration
   */
  const contractTypeFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Contract type name']),
      name: 'name',
      onChange: onChangeContractTypeFormField,
      value: contractTypeData.name,
      error: contractTypeFormFieldsErrors.includes('name'),
    },
    {
      fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
      name: 'companyId',
      label: t(TranslationKey['Amplio partner']),
      value: contractTypeData.companyId || undefined,
      items: companies,
      error: contractTypeFormFieldsErrors.includes('companyId'),
      onChange: (item: DropdownItem | null) => onChangeContractTypeDropdownFormField('companyId', item),
      disabled: true,
    },
    {
      fieldType: FormFieldType.DROPDOWN,
      items: contractTypeOptions(),
      label: t(TranslationKey.Type),
      name: 'type',
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeDropdownFormField('type', {label: '', value: event.target.value}),
      value: contractTypeData.type,
      error: contractTypeFormFieldsErrors.includes('type'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Valid for']),
      name: 'expiry',
      type: 'number',
      onChange: onChangeContractTypeFormField,
      value: contractTypeData.expiry,
      error: contractTypeFormFieldsErrors.includes('expiry'),
    },
    {
      fieldType: FormFieldType.CHECKBOX_GROUP,
      name: 'lengths',
      label: t(TranslationKey['Contract lengths']),
      itemsInColumn: 1,
      checkboxes: [
        {
          checked: contractTypeData.lengths?.includes(18),
          label: '18',
          value: contractTypeData.lengths?.includes(18),
          name: '18',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeContractLenghtFormField(event, checked),
        },
        {
          checked: contractTypeData.lengths?.includes(24),
          label: '24',
          value: contractTypeData.lengths?.includes(24),
          name: '24',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeContractLenghtFormField(event, checked),
        },
        {
          checked: contractTypeData.lengths?.includes(36),
          label: '36',
          value: contractTypeData.lengths?.includes(36),
          name: '36',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeContractLenghtFormField(event, checked),
        },
        {
          checked: contractTypeData.lengths?.includes(60),
          label: '60',
          value: contractTypeData.lengths?.includes(60),
          name: '60',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeContractLenghtFormField(event, checked),
        },
      ],
    },
    {
      fieldType: FormFieldType.CHECKBOX_GROUP,
      name: 'active',
      label: t(TranslationKey.Active),
      checkboxes: [
        {
          checked: contractTypeData.active,
          label: t(TranslationKey.Active),
          value: contractTypeData.active,
          name: 'active',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeContractTypeFormField(event, checked),
        },
      ],
    },
  ]

  /**
   * Contract Type form fields configuration - additional options section
   */
  const contractTypeFormFieldOptionsFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.CHECKBOX_GROUP,
      label: t(TranslationKey['Contract product fields options']),
      itemsInColumn: 1,
      checkboxes: [
        {
          checked: contractTypeData.lockFields,
          label: t(TranslationKey['Lock fields']),
          value: contractTypeData.lockFields,
          name: 'lockFields',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeContractTypeFormField(event, checked),
        },
        {
          checked: contractTypeData.displayServicePrice,
          label: t(TranslationKey['Display service price']),
          value: contractTypeData.displayServicePrice,
          name: 'displayServicePrice',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeContractTypeFormField(event, checked),
        },
        {
          checked: contractTypeData.displayInstallationCosts,
          label: t(TranslationKey['Display installation compensation']),
          value: contractTypeData.displayInstallationCosts,
          name: 'displayInstallationCosts',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeContractTypeFormField(event, checked),
        },
        {
          checked: contractTypeData.displayExtraCosts,
          label: t(TranslationKey['Display extra costs']),
          value: contractTypeData.displayExtraCosts,
          name: 'displayExtraCosts',
          onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onChangeContractTypeFormField(event, checked),
        },
      ],
    },
  ]

  /**
   * Contract Type form fields configuration - description section
   */
  const contractTypeFormFieldDescriptionFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Description),
      name: 'note',
      onChange: onChangeContractTypeFormField,
      value: contractTypeData.note,
      multiline: true,
      rows: 6,
      customStyle: {height: 'unset'},
      error: contractTypeFormFieldsErrors.includes('note'),
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Disclaimer),
      name: 'disclaimer',
      onChange: onChangeContractTypeFormField,
      value: contractTypeData.disclaimer,
      error: contractTypeFormFieldsErrors.includes('disclaimer'),
      multiline: true,
      rows: 6,
      customStyle: {height: 'unset'},
    },
  ]

  /**
   * Contract Type form fields configuration - discounts section
   */
  const contractTypeDiscountFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Product discount']),
      name: 'productDiscount',
      percentage: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeFormField(event, undefined, true),
      value: contractTypeData.productDiscount,
      error: contractTypeFormFieldsErrors.includes('productDiscount'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Accessory discount']),
      name: 'accessoryDiscount',
      percentage: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeFormField(event, undefined, true),
      value: contractTypeData.accessoryDiscount,
      error: contractTypeFormFieldsErrors.includes('accessoryDiscount'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Service discount']),
      name: 'serviceDiscount',
      percentage: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeFormField(event, undefined, true),
      value: contractTypeData.serviceDiscount,
      error: contractTypeFormFieldsErrors.includes('serviceDiscount'),
      type: 'number',
    },
  ]

  /**
   * Contract Type form fields configuration - rate info section
   */
  const contractTypeRateInfoFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Advanced payment']),
      name: 'advancedPayment',
      onChange: onChangeContractTypeFormField,
      value: contractTypeData.advancedPayment,
      error: contractTypeFormFieldsErrors.includes('advancedPayment'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Function interest']),
      name: 'functionInterest',
      percentage: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeFormField(event, undefined, true),
      value: contractTypeData.functionInterest,
      error: contractTypeFormFieldsErrors.includes('functionInterest'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Interest),
      name: 'interest',
      percentage: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeFormField(event, undefined, true),
      value: contractTypeData.interest,
      error: contractTypeFormFieldsErrors.includes('interest'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey.Insurance),
      name: 'insurancePercentage',
      percentage: true,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChangeContractTypeFormField(event, undefined, true),
      value: contractTypeData.insurancePercentage,
      error: contractTypeFormFieldsErrors.includes('insurancePercentage'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Product minimum insurance']),
      name: 'productInsuranceMinimum',
      currency: true,
      onChange: onChangeContractTypeFormField,
      value: contractTypeData.productInsuranceMinimum,
      error: contractTypeFormFieldsErrors.includes('productInsuranceMinimum'),
      type: 'number',
    },
    {
      fieldType: FormFieldType.INPUT,
      label: t(TranslationKey['Accessory minimum insurance']),
      name: 'accessoryInsuranceMinimum',
      currency: true,
      onChange: onChangeContractTypeFormField,
      value: contractTypeData.accessoryInsuranceMinimum,
      error: contractTypeFormFieldsErrors.includes('accessoryInsuranceMinimum'),
      type: 'number',
    },
  ]

  /**
   * A function handling Save button click
   * If data is valid updated or saves a new Contract Type
   * @returns void
   */
  const onSubmit = async (): Promise<void> => {
    if (validateData()) return
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      if (id) {
        await updateContractType(id, contractTypeData as ContractTypeRequest)
        setLoadingStatus(LoadingStatus.SUCCESS)
        setIsFormDirty(false)
        navigate(`${NavigationPathKey.CONTRACT_TYPE_VIEW}/${id}`)
        return
      }

      const data = await createContractType(contractTypeData as ContractTypeRequest)
      if (dealerId) await linkDealerToContractType(dealerId, data.id)
      setIsFormDirty(false)
      setLoadingStatus(LoadingStatus.SUCCESS)
      navigate(`${NavigationPathKey.CONTRACT_TYPE_VIEW}/${data.id}`)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      let companies: CompanyResponse[] = []

      // Super Administrator can see ALL the data
      if (isSuperAdmin(currentUser)) {
        companies = await getCompanies()
      } else {
        // Company Administrator can see ONLY their Company
        companies = [await getCompany(String(currentUser.companyId))]
      }
      setCompanies(generateDropdownOptions(companies))
      if (id) {
        const data = await getContractType(id)

        setContractTypeData({
          accessoryDiscount: data.accessoryDiscount,
          accessoryInsuranceMinimum: data.accessoryInsuranceMinimum,
          active: data.active,
          advancedPayment: data.advancedPayment,
          companyId: data.companyId,
          disclaimer: data.disclaimer,
          functionInterest: data.functionInterest,
          insurancePercentage: data.insurancePercentage,
          interest: data.interest,
          lockFields: data.lockFields,
          name: data.name,
          note: data.note,
          productDiscount: data.productDiscount,
          productInsuranceMinimum: data.productInsuranceMinimum,
          serviceDiscount: data.serviceDiscount,
          type: data.type,
          id: data.id,
          displayExtraCosts: data.displayExtraCosts,
          displayInstallationCosts: data.displayInstallationCosts,
          displayServicePrice: data.displayServicePrice,
          allowedDealers: data.allowedDealers,
          expiry: data.expiry,
          lengths: data.lengths,
        })
      } else if (isSuperAdmin(currentUser)) {
        setContractTypeData(prev => ({...prev, companyId: getUserCompany(currentUser.id)}))
      }
      // Company Administrator can create Contract types ONLY for their Company
      else if (isCompanyAdmin(currentUser)) {
        setContractTypeData(prev => ({...prev, companyId: currentUser.companyId}))
      }
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling Discard button click
   */
  const onDiscard = (): void => {
    handleNavigate(() => navigate(-1))
  }

  return {
    loadData,
    onDiscard,
    onSubmit,
    t,
    contractTypeFormFieldOptionsFormFields,
    contractTypeFormFieldDescriptionFields,
    contractTypeRateInfoFormFields,
    contractTypeDiscountFormFields,
    title,
    contractTypeData,
    contractTypeFormFields,
    id,
  }
}
