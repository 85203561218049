import {getImage} from '@api'
import colors from '@colors'
import {getUserData, roles} from '@common'
import {ImageType, LocalStorageItem, NavigationPathKey, TranslationKey} from '@enums'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import {Menu, MenuItem, Stack, Typography} from '@mui/material'

import React, {useState} from 'react'

import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {Avatar, StyledButton, StyledStack, imageStyle, menuSlotStyle} from './style'

/**
 * User Menu Button component used for User dropdown in the Top Navigation component
 */
export function UserMenuButton() {
  const currentUser = getUserData()
  const {fullName, role, id} = currentUser
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showImage, setShowImage] = useState(true)
  const navigate = useNavigate()
  const {t} = useTranslation()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const logout = () => {
    setAnchorEl(null)
    localStorage.removeItem(LocalStorageItem.AUTHENTICATION)
    navigate(NavigationPathKey.LOGOUT)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getAvatarInitials = (fullName: string) => {
    return fullName
      ? fullName
          ?.split(' ')
          .map(word => word[0])
          .join('')
      : ''
  }

  return (
    <>
      <StyledButton onClick={handleClick}>
        <StyledStack>
          {showImage ? (
            <img
              src={getImage(ImageType.USER, String(id))}
              alt="User image"
              style={imageStyle}
              onError={() => setShowImage(false)}
            />
          ) : (
            <Avatar>
              <Typography>{getAvatarInitials(fullName)}</Typography>
            </Avatar>
          )}
          <Stack>
            <Typography
              color={colors.common.black}
              textAlign={'start'}
              fontWeight={'bold'}
              lineHeight={'1rem'}
              variant="body1"
            >
              {fullName}
            </Typography>
            <Typography color={colors.gray[4]} textAlign={'start'} lineHeight={'1rem'} fontSize={'0.8rem'}>
              {roles(t).find(item => item.code === role)?.name}
            </Typography>
          </Stack>
        </StyledStack>
      </StyledButton>
      <Menu
        slotProps={{
          paper: {
            style: menuSlotStyle,
          },
        }}
        id="user-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate(`${NavigationPathKey.USER_VIEW}/${id}`)}>
          <StyledStack direction="row" justifyContent={'space-between'}>
            <Typography>{t(TranslationKey.Profile)}</Typography>
            <PersonOutlineRoundedIcon />
          </StyledStack>
        </MenuItem>
        <MenuItem onClick={logout}>
          <StyledStack direction="row" justifyContent={'space-between'}>
            <Typography>{t(TranslationKey.Logout)}</Typography>
            <LogoutOutlinedIcon />
          </StyledStack>
        </MenuItem>
      </Menu>
    </>
  )
}
