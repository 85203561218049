import {NavigationPathKey, TranslationKey} from '@enums'
import accessoriesCardImage from '@images/accessories-card.jpg'
import bulkUploadCardImage from '@images/bulk-upload-card.jpg'
import productsCardImage from '@images/products-card.jpg'
import {Stack} from '@mui/material'
import {PageWrapper} from '@wrappers'

import {useTranslation} from 'react-i18next'

import {ComposedComponents} from '@components'

/**
 * Products and Accessories Dashboard page with navigation cards
 */
export function ProductAndAccessoriesDashboardPage() {
  const {t} = useTranslation()

  const navigationCardItems: ComposedComponents.CardItem[] = [
    {
      title: t(TranslationKey.Products),
      key: NavigationPathKey.PRODUCT_SEARCH,
      image: productsCardImage,
    },
    {
      title: t(TranslationKey.Accessories),
      key: NavigationPathKey.ACCESSORY_SEARCH,
      image: accessoriesCardImage,
    },
    {
      title: t(TranslationKey['Bulk upload']),
      key: NavigationPathKey.PRODUCT_BULK_CREATION,
      image: bulkUploadCardImage,
    },
  ]

  return (
    <PageWrapper title={t(TranslationKey.Dashboard)}>
      <Stack gap={5}>
        <ComposedComponents.NavigationCards itemsInRow={3} items={navigationCardItems} />
      </Stack>
    </PageWrapper>
  )
}
