import React, {ReactNode} from 'react'

import {ButtonsWrapper, FormButton} from './style'

export type ButtonProps = {
  label: string
  onClick: () => void
}

interface Props {
  leftButton?: ButtonProps
  centerButton?: ButtonProps
  additionalButton?: ReactNode
  auxiliaryButton?: ButtonProps
  rightButton?: ButtonProps
}

/**
 * Form Buttons component that renders multiple buttons for forms
 * @param props Buttons props
 */
export function FormButtons(props: Props) {
  const {leftButton, centerButton, auxiliaryButton, additionalButton, rightButton} = props

  return (
    <ButtonsWrapper>
      {leftButton && (
        <FormButton variant="outlined" onClick={leftButton.onClick}>
          {leftButton.label}
        </FormButton>
      )}
      {centerButton && (
        <FormButton variant="outlined" onClick={centerButton.onClick}>
          {centerButton.label}
        </FormButton>
      )}
      {additionalButton}
      {auxiliaryButton && (
        <FormButton variant="contained" onClick={auxiliaryButton.onClick}>
          {auxiliaryButton.label}
        </FormButton>
      )}
      {rightButton && (
        <FormButton variant="contained" onClick={rightButton.onClick}>
          {rightButton.label}
        </FormButton>
      )}
    </ButtonsWrapper>
  )
}
