import {APIMethod} from '@enums'

import {fetchData} from 'api/api'
import {ItemSignature} from 'common/types'

export interface DealerContractType {
  name: string
  id: string
}
interface BaseDealer {
  name: string
  customerNo: string
  email: string
  address: string
  companyId: string | null
  vatNumber: string
  dealerNo: string
  companyNo: string
  city: string
  zipCode: string
  phone: string
  invoiceEmail: string
  invoicePhone: string
  officialSignatories: OfficialSignatory[]
  active: boolean
}
export interface DealerData extends BaseDealer {
  id?: string
  allowedContractTypes?: DealerContractType[]
}
export interface DealerRequest extends BaseDealer {
  companyId: string
}
export interface DealerResponse extends BaseDealer, ItemSignature {
  id: string
  companyId: string
  allowedContractTypes: DealerContractType[]
}
export interface OfficialSignatory {
  name: string
  email: string
  dateOfBirth: string
  personalNumber: string
}

/**
 * @returns All Dealers
 */
export const getDealers = async (): Promise<DealerResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: 'dealer',
  })
}

/**
 * @param id Company ID
 * @returns All Dealers with a specific Company ID
 */
export const getDealersByCompany = async (id: string): Promise<DealerResponse[]> => {
  return fetchData({
    method: APIMethod.GET,
    url: `dealer/company/${id}`,
  })
}

/**
 * @param id Delaer ID
 * @returns A single Dealer with a specific ID
 */
export const getDealer = async (id: string): Promise<DealerResponse> => {
  return fetchData({
    method: APIMethod.GET,
    url: `dealer/${id}`,
  })
}

/**
 * Updates an existing Dealer
 * @param id Dealer ID
 * @param body Dealer payload data
 * @returns Updated Dealer data
 */
export const updateDealer = async (id: string, body: DealerRequest): Promise<DealerResponse> => {
  return fetchData({
    method: APIMethod.PUT,
    url: `dealer/${id}`,
    body,
  })
}

/**
 * Creates a new Dealer
 * @param body Dealer payload data
 * @returns New Dealer data
 */
export const createDealer = async (body: DealerRequest): Promise<DealerResponse> => {
  return fetchData({
    method: APIMethod.POST,
    url: `dealer`,
    body,
  })
}

/**
 * Links a Dealer to a Contract Type
 * @param dealerId Dealer ID
 * @param contractTypeId Contract Type ID
 * @returns void
 */
export const linkDealerToContractType = async (dealerId: string, contractTypeId: string): Promise<void> => {
  return fetchData({
    method: APIMethod.POST,
    url: `dealer/${dealerId}/contract-type/${contractTypeId}`,
  })
}

/**
 * Unlinks a Dealer from a Contract Type
 * @param dealerId Dealer ID
 * @param contractTypeId Contract Type ID
 * @returns void
 */
export const unlinkDealerFromContractType = async (dealerId: string, contractTypeId: string): Promise<void> => {
  return fetchData({
    method: APIMethod.DELETE,
    url: `dealer/${dealerId}/contract-type/${contractTypeId}`,
  })
}
