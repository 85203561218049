import React, {ReactNode, createContext, useState} from 'react'

import {LoadingStatus} from 'enums/enums'

interface Props {
  children: ReactNode
}

interface AppContext {
  loadingStatus: LoadingStatus
  setLoadingStatus: React.Dispatch<React.SetStateAction<LoadingStatus>>
  error: Error | undefined
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>
  successMessage: string | undefined
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | undefined>>
  isFormDirty: boolean
  setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>
  isWarningModalShown: boolean
  setIsWarningModalShown: React.Dispatch<React.SetStateAction<boolean>>
  handleNavigate: (callback: () => void) => void
  handleDiscard: () => void
  loadingMessage: string | undefined
  setLoadingMessage: React.Dispatch<React.SetStateAction<string | undefined>>
}

const initialState = {
  loadingStatus: LoadingStatus.NOT_STARTED,
  setLoadingStatus: () => undefined,
  error: undefined,
  setError: () => undefined,
  successMessage: undefined,
  setSuccessMessage: () => undefined,
  isFormDirty: false,
  setIsFormDirty: () => undefined,
  isWarningModalShown: false,
  setIsWarningModalShown: () => undefined,
  handleNavigate: () => undefined,
  handleDiscard: () => undefined,
  userData: undefined,
  setUserData: () => undefined,
  loadingMessage: undefined,
  setLoadingMessage: () => undefined,
}

export const AppContext = createContext<AppContext>(initialState)

/**
 * Application Context to store application-wide states
 */
export const AppProvider = (props: Props) => {
  const {children} = props
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_STARTED)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined)
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)
  const [isWarningModalShown, setIsWarningModalShown] = useState<boolean>(false)
  const [callback, setCallback] = useState<() => void>(() => undefined)
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined)

  /**
   * A function to handle (usually) navigation functions. Is to be used as a guard to first initiate a warning modal.
   * The function stores the callback, and if the User confirms the action, callback will be executed.
   * @param callback Callback function
   */
  const handleNavigate = (callback: () => void) => {
    if (isFormDirty) {
      setIsWarningModalShown(true)
      setCallback(() => callback)
      return
    }
    callback()
  }

  /**
   * Function to executed the stored callback function and reset the states.
   */
  const handleDiscard = () => {
    setIsFormDirty(false)
    setIsWarningModalShown(false)
    callback()
    setCallback(() => undefined)
  }

  const value = {
    loadingStatus,
    setLoadingStatus,
    error,
    setError,
    successMessage,
    setSuccessMessage,
    isFormDirty,
    setIsFormDirty,
    isWarningModalShown,
    setIsWarningModalShown,
    handleNavigate,
    handleDiscard,
    loadingMessage,
    setLoadingMessage,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
