import {DealerResponse, getDealer, getDealers, getDealersByCompany, LoggedInUser, handleAxiosError} from '@api'
import {AppContext} from '@contexts'
import {LoadingStatus, NavigationPathKey, TranslationKey, UserRole} from '@enums'
import {GridColDef, GridColumnVisibilityModel} from '@mui/x-data-grid'

import {useContext, useState} from 'react'

import {activeCell, getUserCompany, getUserData, isCompanyUser, isSuperAdmin, renderClickableCell} from 'common'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

export interface DealerTableData {
  name: string
  address: string
  city: string
  dealerNo: string
  companyNo: string
  active: boolean
}

export function useDealerSearchModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const {t} = useTranslation()
  const [rows, setRows] = useState<DealerTableData[]>([])
  const navigate = useNavigate()
  const currentUser: LoggedInUser = getUserData()

  /**
   * Dealer table columns configuration
   */
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 1,
      hideable: false,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.DEALER_VIEW}/${params.row.id}`)),
    },
    {field: 'dealerNo', headerName: t(TranslationKey['Dealer number']), flex: 1},
    {field: 'address', headerName: t(TranslationKey.Address), flex: 1.2},
    {field: 'city', headerName: t(TranslationKey.City), flex: 1},
    {field: 'companyNo', headerName: t(TranslationKey['Company number']), flex: 1},
    activeCell(t),
  ]

  /**
   * Dealer table visibility model configuration
   */
  const visibilityModel: GridColumnVisibilityModel = {
    dealerNo: false,
    address: false,
    companyNo: false,
  }

  /**
   * Create button should be available only for Administrator users
   */
  const showCreateButton = !isCompanyUser(currentUser)

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      let data: DealerResponse[] = []

      // For a Super Administrator, we fetch ALL the data
      if (currentUser.role === UserRole.SUPER_ADMIN) {
        data = await getDealers()
      } else {
        // For a Company Administrator, we fetch ONLY their Dealers
        if (currentUser.role === UserRole.COMPANY_ADMIN) {
          data = await getDealersByCompany(String(currentUser.companyId))
        }
        // For a Company User, we fetch ONLY their Dealer
        else {
          data = [await getDealer(String(currentUser.companyId))]
        }
        //For Company Administrator or Company User, we fetch ONLY their company
      }

      const formattedData: DealerTableData[] = data
        .filter(item => (isSuperAdmin(currentUser) ? item.companyId === getUserCompany(currentUser.id) : true))
        .map(item => ({
          name: item.name,
          address: item.address,
          city: item.city,
          companyNo: item.companyNo,
          dealerNo: item.dealerNo,
          active: item.active,
          id: item.id,
        }))
      setRows(formattedData)

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  return {loadData, navigate, visibilityModel, t, showCreateButton, rows, columns}
}
