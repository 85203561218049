import {
  getCompany,
  CompanyData,
  LoggedInUser,
  CompanyResponse,
  getImage,
  getDealersByCompany,
  getUsersByCompany,
  DealerResponse,
  UserResponse,
  ContractTypeResponse,
  getContractTypesByCompany,
  handleAxiosError,
} from '@api'
import {AppContext} from '@contexts'
import {FormFieldType, ImageType, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef, GridColumnVisibilityModel} from '@mui/x-data-grid'

import {useContext, useMemo, useState} from 'react'

import {
  activeCell,
  countries,
  FormFieldItem,
  getCountryFlag,
  getUserData,
  isCompanyUser,
  roles,
  renderClickableCell,
  currencies,
} from 'common'
import {ContractTypeTableData} from 'components/pages/contract-types/contract-type-search/contract-type-search-model'
import {DealerTableData} from 'components/pages/dealers/dealer-search/dealer-search-model'
import {UsersTableData} from 'components/pages/users/user-search/user-search-model'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import {ComposedComponents} from '@components'

export enum CompanyTab {
  GENERAL,
  DEALERS,
  CONTRACT_TYPES,
  USERS,
}

/**
 * Initial data
 */
const initialCompanyData: CompanyData = {
  name: '',
  description: '',
  note: '',
  image: '',
  active: true,
  country: null,
}

export function useCompanyViewModel() {
  const {setLoadingStatus, setError} = useContext(AppContext)
  const [companyData, setCompanyData] = useState<CompanyData>(initialCompanyData)
  const [dealerRows, setDealerRows] = useState<DealerTableData[]>([])
  const [userRows, setUserRows] = useState<UsersTableData[]>([])
  const [contractTypeRows, setContractTypeRows] = useState<ContractTypeTableData[]>([])
  const [activeTab, setActiveTab] = useState<CompanyTab>(CompanyTab.GENERAL)
  const currentUser: LoggedInUser = getUserData()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {id} = useParams()

  /**
   * Edit button should be available only for Administrator Users
   */
  const isEditButtonVisible = !isCompanyUser(currentUser)

  /**
   * Dynamically created title text for the Company view page
   */
  const title = useMemo(() => `${t(TranslationKey['Partner view'])} - ${companyData.name}`, [companyData.name])

  /**
   * User table columns configuration
   */
  const userColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 0.8,
      hideable: false,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.USER_VIEW}/${params.row.id}`)),
    },
    {field: 'email', headerName: t(TranslationKey.Email), flex: 1},
    {field: 'dealer', headerName: t(TranslationKey.Dealer), flex: 1},
    {field: 'role', headerName: t(TranslationKey.Role), flex: 0.8},
    activeCell(t),
  ]

  /**
   * Dealer table columns configuration
   */
  const dealerColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 1,
      hideable: false,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.DEALER_VIEW}/${params.row.id}`)),
    },
    {field: 'dealerNo', headerName: t(TranslationKey['Dealer number']), flex: 1},
    {field: 'address', headerName: t(TranslationKey.Address), flex: 1.2},
    {field: 'city', headerName: t(TranslationKey.City), flex: 1},
    activeCell(t),
  ]

  /**
   * Dealer table visibility model
   */
  const dealerTableVisiblityModel: GridColumnVisibilityModel = {
    address: false,
  }

  /**
   * Contract Type table column configuration
   */
  const contractTypeColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 0.8,
      hideable: false,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.CONTRACT_TYPE_VIEW}/${params.row.id}`)),
    },
    {field: 'description', headerName: t(TranslationKey.Description), flex: 1},
    activeCell(t),
  ]

  /**
   * Tab buttons configuration
   */
  const tabButtons: ComposedComponents.TabButtonProps<CompanyTab>[] = [
    {
      label: t(TranslationKey['General information']),
      key: CompanyTab.GENERAL,
      onClick: () => setActiveTab(CompanyTab.GENERAL),
    },
    {
      label: t(TranslationKey.Dealers),
      key: CompanyTab.DEALERS,
      onClick: () => setActiveTab(CompanyTab.DEALERS),
    },
    {
      label: t(TranslationKey['Contract types']),
      key: CompanyTab.CONTRACT_TYPES,
      onClick: () => setActiveTab(CompanyTab.CONTRACT_TYPES),
    },
    {
      label: t(TranslationKey.Users),
      key: CompanyTab.USERS,
      onClick: () => setActiveTab(CompanyTab.USERS),
    },
  ]

  /**
   * Company form fields configuration - general section
   */
  const companyGeneralFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Partner name']),
      text: companyData.name,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Description),
      text: companyData.description,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Country),
      text: countries(t).find(item => item.isoCode === companyData.country)?.name,
      icon: companyData.country && getCountryFlag(companyData.country),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Currency),
      text: companyData.country ? currencies[companyData.country] : '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Active),
      text: companyData.active ? t(TranslationKey.Active) : t(TranslationKey.Inactive),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Notes),
      text: companyData.note || '-',
    },
  ]

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      if (!id) {
        throw new Error('No company ID')
      }
      const data: CompanyResponse = await getCompany(id)
      setCompanyData({...data, image: getImage(ImageType.COMPANY, id)})

      const dealersData: DealerResponse[] = await getDealersByCompany(id)

      const usersData: UserResponse[] = await getUsersByCompany(id)

      const contractTypesData: ContractTypeResponse[] = await getContractTypesByCompany(id)

      const formattedDealersData: DealerTableData[] = dealersData.map(item => ({
        name: item.name,
        address: item.address,
        city: item.city,
        companyNo: item.companyNo,
        dealerNo: item.dealerNo,
        company: data.name,
        active: item.active,
        id: item.id,
      }))

      const formattedUsersData: UsersTableData[] = usersData.map(item => ({
        name: item.fullName,
        email: item.email,
        active: item.active,
        company: data.name,
        dealer: dealersData.find(dealer => dealer.id === item.dealerId)?.name || '-',
        role: roles(t).find(roleItem => roleItem.code === item.role)?.name || '',
        id: item.id,
      }))

      const formattedContractTypesData: ContractTypeTableData[] = contractTypesData.map(item => ({
        name: item.name,
        description: item.note,
        company: data.name,
        active: item.active,
        id: item.id,
      }))

      setUserRows(formattedUsersData)
      setDealerRows(formattedDealersData)
      setContractTypeRows(formattedContractTypesData)

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling the Back button click
   */
  const onPressBack = () => {
    navigate(NavigationPathKey.COMPANY_SEARCH)
  }

  /**
   * A function handling Edit button click
   */
  const onPressEdit = (): void => {
    navigate(`${NavigationPathKey.COMPANY_CREATION}/${id}`)
  }

  /**
   * Create button should only be visible for Administrator users
   */
  const showCreateButton = !isCompanyUser(currentUser)

  return {
    companyData,
    companyGeneralFormFields,
    title,
    t,
    showCreateButton,
    dealerTableVisiblityModel,
    contractTypeColumns,
    contractTypeRows,
    tabButtons,
    activeTab,
    dealerColumns,
    dealerRows,
    isEditButtonVisible,
    userColumns,
    userRows,
    loadData,
    onPressBack,
    onPressEdit,
    navigate,
  }
}
