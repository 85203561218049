import React, {ReactNode} from 'react'

import {InputWithLabelWrapper, Label} from './style'

interface Props {
  label?: string
  width?: number
  children: ReactNode
}

/**
 * Wrapper component with a label used for form fields
 */
export function LabeledWrapper(props: Props) {
  const {label, width, children} = props

  return (
    <InputWithLabelWrapper width={width} gap={1}>
      {label && <Label>{label}</Label>}
      {children}
    </InputWithLabelWrapper>
  )
}
