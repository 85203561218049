import {Stack, styled} from '@mui/material'

export const Background = styled(Stack)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
  minHeight: '100vh',
}))

export const RightSideWrapper = styled(Stack)(({theme}) => ({
  width: 'calc(100% - 18rem)',
  marginLeft: '18rem',
  padding: '2rem',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    marginLeft: 'unset',
  },
}))

export const ContentWrapper = styled(Stack)(() => ({
  paddingTop: '3rem',
}))

export const ContentContainer = styled(Stack)(() => ({
  alignItems: 'center',
  padding: '1rem',
}))

export const SideNavigationWrapper = styled(Stack)(({theme}) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}))

export const tableWrapperStyle = {
  minHeight: 'calc(100vh - 11rem - 48px)',
}
