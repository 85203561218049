import {TranslationKey} from '@enums'
import {Grid, Stack} from '@mui/material'
import {PageWrapper} from '@wrappers'

import React from 'react'

import {renderFormField, useLoadOnce} from 'common'

import {ComposedComponents, UIKit} from '@components'

import {useContractTypeFormModel} from './contract-type-form-model'

/**
 * Contract Type Form page
 */
export function ContractTypeFormPage() {
  const {
    onDiscard,
    onSubmit,
    loadData,
    t,
    title,
    contractTypeFormFieldDescriptionFields,
    contractTypeRateInfoFormFields,
    contractTypeFormFieldOptionsFormFields,
    contractTypeDiscountFormFields,
    contractTypeFormFields,
  } = useContractTypeFormModel()

  useLoadOnce(loadData)
  return (
    <PageWrapper title={title}>
      <UIKit.BackgroundCard>
        <Grid container spacing={2}>
          {contractTypeFormFields.map((item, index) => (
            <Grid sm={4} item key={`contract-type-form-field-${index}`}>
              {renderFormField(item)}
            </Grid>
          ))}
        </Grid>
      </UIKit.BackgroundCard>

      <UIKit.BackgroundCard title={t(TranslationKey.Discounts)}>
        <Grid container spacing={2}>
          {contractTypeDiscountFormFields.map((item, index) => (
            <Grid sm={4} item key={`contract-type-form-discount-form-field-${index}`}>
              {renderFormField(item)}
            </Grid>
          ))}
        </Grid>
      </UIKit.BackgroundCard>

      <UIKit.BackgroundCard title={t(TranslationKey['Rate information'])}>
        <Grid container spacing={2}>
          {contractTypeRateInfoFormFields.map((item, index) => (
            <Grid sm={4} item key={`contract-type-form-rate-information-form-field-${index}`}>
              {renderFormField(item)}
            </Grid>
          ))}
        </Grid>
      </UIKit.BackgroundCard>

      <UIKit.BackgroundCard title={t(TranslationKey['Additional Information'])}>
        <Stack gap={2}>
          <Grid container spacing={2}>
            {contractTypeFormFieldOptionsFormFields.map((item, index) => (
              <Grid sm={12} item key={`contract-type-form-form-field-options-form-field-${index}`}>
                {renderFormField(item)}
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2}>
            {contractTypeFormFieldDescriptionFields.map((item, index) => (
              <Grid sm={6} item key={`contract-type-form-form-field-description-form-field-${index}`}>
                {renderFormField(item)}
              </Grid>
            ))}
          </Grid>
        </Stack>
      </UIKit.BackgroundCard>

      <ComposedComponents.FormButtons
        leftButton={{label: t(TranslationKey.Discard), onClick: onDiscard}}
        rightButton={{label: t(TranslationKey.Save), onClick: onSubmit}}
      />
    </PageWrapper>
  )
}
