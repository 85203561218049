import {
  FormFieldItem,
  getUserData,
  getUserTimeFormat,
  languages,
  setLanguageStorage,
  setTimeFormatStorage,
  timeFormats,
} from '@common'
import {AppContext} from '@contexts'
import {FormFieldType, Language, LoadingStatus, TranslationKey} from '@enums'
import {Stack, Typography} from '@mui/material'

import React, {useContext} from 'react'

import {config} from 'constants/config'
import i18next from 'i18next'
import {useTranslation} from 'react-i18next'

export function useSettingsModel() {
  const settingsData = config
  const {setLoadingStatus} = useContext(AppContext)
  const currentUser = getUserData()
  const {t} = useTranslation()
  const userTimeFormat = getUserTimeFormat(currentUser.id)

  /**
   * A function for custom rendering of the DropdownItem
   */
  const renderValue = () => {
    const selectedLanguage = languages(t).find(item => item.value === i18next.language)

    return (
      <Stack flexDirection={'row'} style={{width: '100%'}} justifyContent={'space-between'}>
        <Typography fontWeight={'bold'}>{selectedLanguage?.label}</Typography>
        {selectedLanguage?.icon}
      </Stack>
    )
  }

  /**
   * Settings form fields configuration
   */
  const settingsFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.DROPDOWN,
      width: 200,
      value: userTimeFormat,
      label: t(TranslationKey['Time format']),
      customStyle: {height: '30px', marginTop: '-2px'},
      items: timeFormats,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTimeFormatStorage({userUid: currentUser.id, format: event.target.value})
        setLoadingStatus(LoadingStatus.LOADING)
        setTimeout(() => {
          setLoadingStatus(LoadingStatus.SUCCESS)
        }, 500)
      },
    },
    {
      fieldType: FormFieldType.DROPDOWN,
      width: 200,
      value: i18next.language,
      label: t(TranslationKey.Language),
      customStyle: {height: '30px', marginTop: '-2px'},
      renderValue: renderValue,
      items: languages(t),
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        i18next.changeLanguage(event.target.value as string)
        setLanguageStorage({userUid: currentUser.id, language: event.target.value as Language})
        setLoadingStatus(LoadingStatus.LOADING)
        setTimeout(() => {
          setLoadingStatus(LoadingStatus.SUCCESS)
        }, 500)
      },
    },
  ]

  /**
   * Information form fields configuration
   */
  const informationFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Name),
      text: settingsData.name,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['UI version']),
      text: settingsData.version.split(' ').join('.'),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['BE version']),
      text: settingsData.version.split(' ').join('.'),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Last update']),
      text: settingsData.lastUpdate,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Environment),
      text: settingsData.environment,
    },
  ]

  return {settingsFields, informationFields, t}
}
