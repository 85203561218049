import {NavigationPathKey, TranslationKey} from '@enums'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Box, Divider, Stack} from '@mui/material'

import React from 'react'

import {useSideNavigationModel} from './side-navigation-model'

import logo from '@images/amplio-logo.png'

import {LogoLink, NavigationButton, NavigationWrapper, SideNavigationWrapper, iconStyle, imageStyle} from './style'

/**
 * Side Navigation component present on all application screens
 */
export function SideNavigation() {
  const {handleNavigate, navigate, navigationLinks, getIsActive, t} = useSideNavigationModel()

  return (
    <SideNavigationWrapper>
      <LogoLink onClick={() => handleNavigate(() => navigate(NavigationPathKey.DASHBOARD))}>
        <Box component="img" src={logo} style={imageStyle} />
      </LogoLink>
      <NavigationWrapper justifyContent={'space-between'}>
        <Stack>
          {navigationLinks.map((item, index) => {
            const {key, icon, label, endIcon, onClick} = item
            return (
              <NavigationButton
                key={`navigation-button-${index}`}
                variant="text"
                active={getIsActive(key)}
                startIcon={icon}
                endIcon={endIcon}
                onClick={onClick ? onClick : () => handleNavigate(() => navigate(key))}
              >
                {label}
              </NavigationButton>
            )
          })}
        </Stack>
        <Stack gap={1}>
          <Divider variant="middle" />
          <NavigationButton
            key={`navigation-button-settings`}
            variant="text"
            active={getIsActive(NavigationPathKey.SETTINGS)}
            startIcon={<InfoOutlinedIcon style={iconStyle} />}
            onClick={() => navigate(NavigationPathKey.SETTINGS)}
          >
            {t(TranslationKey['App info'])}
          </NavigationButton>
        </Stack>
      </NavigationWrapper>
    </SideNavigationWrapper>
  )
}
