import {
  ContractTypeData,
  ContractTypeResponse,
  DealerResponse,
  getContractType,
  getDealersByCompany,
  linkDealerToContractType,
  LoggedInUser,
  unlinkDealerFromContractType,
  handleAxiosError,
} from '@api'
import {CompanyResponse, getCompany} from '@api'
import {AppContext} from '@contexts'
import {FormFieldType, LoadingStatus, NavigationPathKey, TranslationKey} from '@enums'
import {GridColDef} from '@mui/x-data-grid'

import {useContext, useMemo, useState} from 'react'

import {
  formatCurrency,
  formatPercentage,
  FormFieldItem,
  generateDropdownOptions,
  generateReadableErrorMessages,
  getUserData,
  isCompanyUser,
  unlinkButtonCell,
  renderClickableCell,
} from 'common'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'

import {ComposedComponents, UIKit} from '@components'

interface DealerTableData {
  name: string
  id: string
  unlinkId: string
}

export enum ContractTypeTab {
  GENERAL,
  DEALERS,
}

/**
 * Initial data
 */
const initialContractTypeData: ContractTypeData = {
  name: '',
  type: '',
  note: '',
  companyId: null,
  active: true,
  disclaimer: '',
  lockFields: false,
  displayExtraCosts: false,
  displayInstallationCosts: false,
  displayServicePrice: false,
  accessoryDiscount: 0,
  accessoryInsuranceMinimum: 0,
  advancedPayment: 1,
  functionInterest: 0,
  insurancePercentage: 0,
  interest: 0,
  productDiscount: 0,
  productInsuranceMinimum: 0,
  serviceDiscount: 0,
  allowedDealers: [],
  expiry: undefined,
  lengths: [],
}

export function useContractTypeViewModel() {
  const {setLoadingStatus, setError, setSuccessMessage} = useContext(AppContext)
  const [contractTypeData, setContractTypeData] = useState<ContractTypeData>(initialContractTypeData)
  const [company, setCompany] = useState<CompanyResponse | undefined>(undefined)
  const [activeTab, setActiveTab] = useState<ContractTypeTab>(ContractTypeTab.GENERAL)
  const [dealerOptions, setDealerOptions] = useState<UIKit.DropdownItem[]>([])
  const [dealerRows, setDealerRows] = useState<DealerTableData[]>([])
  const [isLinkModalShown, setIsLinkModalShown] = useState<boolean>(false)
  const [dealerToLink, setDealerToLink] = useState<string | undefined>(undefined)
  const [dealerFormFieldError, setDealerFormFieldError] = useState<string>('')
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {id} = useParams()
  const currentUser: LoggedInUser = getUserData()

  /**
   * Edit button is only available to Administrator users
   */
  const isEditButtonVisible = !isCompanyUser(currentUser)

  /**
   * Dynamically generated title text for the Contract Type view page
   */
  const title = useMemo(
    () => `${t(TranslationKey['Contract Type view'])} - ${contractTypeData.name}`,
    [contractTypeData.name],
  )

  /**
   * Dynamically generated modal text
   */
  const modalText: string = useMemo(
    () => `${t(TranslationKey['Choose a dealer to link to'])} ${contractTypeData.name}`,
    [contractTypeData.name],
  )

  /**
   * Tab buttons configuration
   */
  const tabButtons: ComposedComponents.TabButtonProps<ContractTypeTab>[] = [
    {
      label: t(TranslationKey['General information']),
      key: ContractTypeTab.GENERAL,
      onClick: () => setActiveTab(ContractTypeTab.GENERAL),
    },
    {
      label: t(TranslationKey.Dealers),
      key: ContractTypeTab.DEALERS,
      onClick: () => setActiveTab(ContractTypeTab.DEALERS),
    },
  ]

  /**
   * Dealers table columns configuration
   */
  const dealerColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t(TranslationKey.Name),
      flex: 1.5,
      renderCell: params =>
        renderClickableCell(params, () => navigate(`${NavigationPathKey.DEALER_VIEW}/${params.row.id}`)),
    },
    ...(isEditButtonVisible ? [unlinkButtonCell(t, id => onUnlink(id))] : []),
  ]

  /**
   * Contract Type form fields configuration
   */
  const contractTypeFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Contract type name']),
      text: contractTypeData.name,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Amplio partner']),
      text: company?.name,
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Type),
      text: contractTypeData.type || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Valid for']),
      text: contractTypeData.expiry ? `${contractTypeData.expiry} ${t(TranslationKey['Day(s)'])}` : '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Contract lengths']),
      text: contractTypeData.lengths?.length
        ? `${contractTypeData.lengths?.join(', ')} ${t(TranslationKey['Month(s)'])}`
        : '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Active),
      text: contractTypeData.active ? t(TranslationKey.Active) : t(TranslationKey.Inactive),
    },
  ]

  /**
   * Link Dealer form field configuration
   */
  const linkDealerFormField: FormFieldItem = {
    fieldType: FormFieldType.SEARCHABLE_DROPDOWN,
    items: dealerOptions,
    value: dealerToLink,
    onChange: (item: UIKit.DropdownItem | null) => {
      setDealerToLink(String(item?.value))
      setDealerFormFieldError('')
    },
    error: !!dealerFormFieldError,
    placeholder: t(TranslationKey['Choose a dealer to link to']),
    listboxHeight: 100,
  }

  /**
   * Contract Type form field configuration - additional section
   */
  const contractTypeAdditionalFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Lock fields']),
      text: contractTypeData.lockFields ? t(TranslationKey.Yes) : t(TranslationKey.No),
    },

    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Display service price']),
      text: contractTypeData.displayServicePrice ? t(TranslationKey.Yes) : t(TranslationKey.No),
    },

    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Display installation compensation']),
      text: contractTypeData.displayInstallationCosts ? t(TranslationKey.Yes) : t(TranslationKey.No),
    },

    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Display extra costs']),
      text: contractTypeData.displayExtraCosts ? t(TranslationKey.Yes) : t(TranslationKey.No),
    },
  ]

  /**
   * Contract Type form fields configuration - discount section
   */
  const contractTypeDiscountFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Product),
      text: formatPercentage(contractTypeData.productDiscount),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Accessories),
      text: formatPercentage(contractTypeData.accessoryDiscount),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Service),
      text: formatPercentage(contractTypeData.serviceDiscount),
    },
  ]

  /**
   * Contract Type form fields configuration - Rate info
   */
  const contractTypeRateInfoFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Advanced payment']),
      text: String(contractTypeData.advancedPayment),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Function interest']),
      text: formatPercentage(contractTypeData.functionInterest),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: TranslationKey.Interest,
      text: formatPercentage(contractTypeData.interest),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Insurance),
      text: formatPercentage(contractTypeData.insurancePercentage),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Product minimum insurance']),
      text: formatCurrency(contractTypeData.productInsuranceMinimum),
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey['Accessory minimum insurance']),
      text: formatCurrency(contractTypeData.accessoryInsuranceMinimum),
    },
  ]

  /**
   * Contract Type form fields configuration - description section
   */
  const contractTypeDescriptionFormFields: FormFieldItem[] = [
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Description),
      text: contractTypeData.note || '-',
    },
    {
      fieldType: FormFieldType.LABEL,
      label: t(TranslationKey.Disclaimer),
      text: contractTypeData.disclaimer || '-',
    },
  ]

  /**
   * Load data function
   * Should be executed only once on component render
   */
  const loadData = async (): Promise<void> => {
    setLoadingStatus(LoadingStatus.LOADING)

    try {
      if (!id) {
        throw new Error('No contract type ID')
      }

      const data: ContractTypeResponse = await getContractType(id)

      setContractTypeData(data)

      const companyData: CompanyResponse = await getCompany(String(data.companyId))

      setCompany(companyData)

      let dealerData: DealerResponse[] = []

      dealerData = await getDealersByCompany(String(data.companyId))

      const formattedDealerOptions: UIKit.DropdownItem[] = generateDropdownOptions(
        dealerData.filter(item => !data.allowedDealers?.map(dealer => dealer.id).includes(item.id)),
      )

      setDealerOptions(formattedDealerOptions)

      const formattedData: DealerTableData[] = data.allowedDealers.map(item => ({
        name: item.name,
        id: item.id,
        unlinkId: item.id,
      }))

      setDealerRows(formattedData)

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling unlinking the Contract Type from a Dealer
   * @param dealerId Dealer ID
   */
  const onUnlink = async (dealerId: string): Promise<void> => {
    try {
      setLoadingStatus(LoadingStatus.LOADING)

      await unlinkDealerFromContractType(dealerId, String(id))
      await loadData()
      setSuccessMessage(t(TranslationKey['Successfully unlinked a dealer']))

      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling linking the Contract Type to a Dealer
   * @returns void
   */
  const onLink = async (): Promise<void> => {
    if (!dealerToLink) {
      setDealerFormFieldError('dealerToLink')
      setError({
        message: generateReadableErrorMessages(t, ['dealerToLink']),
        name: '',
      })
      return
    }
    setLoadingStatus(LoadingStatus.LOADING)
    try {
      setIsLinkModalShown(false)
      setDealerToLink(undefined)
      await linkDealerToContractType(dealerToLink, String(id))

      await loadData()
      setSuccessMessage(t(TranslationKey['Successfully linked a dealer']))
      setLoadingStatus(LoadingStatus.SUCCESS)
    } catch (error) {
      setLoadingStatus(LoadingStatus.FAILED)
      setError(handleAxiosError(error))
    }
  }

  /**
   * A function handling the Back button click
   */
  const onPressBack = (): void => {
    navigate(NavigationPathKey.CONTRACT_TYPE_SEARCH)
  }

  /**
   * A function handling Edit button click
   */
  const onPressEdit = (): void => {
    navigate(`${NavigationPathKey.CONTRACT_TYPE_CREATION}/${id}`)
  }

  /**
   * A function handling modal close
   */
  const onCloseLinkModal = () => {
    setIsLinkModalShown(false)
    setDealerToLink(undefined)
    setDealerFormFieldError('')
  }

  return {
    loadData,
    navigate,
    onPressEdit,
    onPressBack,
    setIsLinkModalShown,
    onLink,
    onCloseLinkModal,
    t,
    linkDealerFormField,
    isEditButtonVisible,
    title,
    contractTypeData,
    contractTypeFormFields,
    contractTypeDescriptionFormFields,
    contractTypeAdditionalFormFields,
    contractTypeDiscountFormFields,
    contractTypeRateInfoFormFields,
    isLinkModalShown,
    activeTab,
    dealerColumns,
    dealerRows,
    dealerOptions,
    tabButtons,
    modalText,
  }
}
