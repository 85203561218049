import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import EventIcon from '@mui/icons-material/Event'
import {LocalizationProvider, DateTimePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {theme} from '@styles'

import React from 'react'

import dayjs from 'dayjs'

import {LabeledWrapper} from '../wrapper-with-label'
import {fieldStyle} from './style'

interface Props {
  width?: number
  label: string
  error?: boolean
  value: string | null
  onChange: (value: string) => void
}

/**
 * Date Picker component
 */
export function DatePicker(props: Props) {
  const {width, label, error, value, onChange} = props

  return (
    <LabeledWrapper width={width} label={label}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          slots={{
            openPickerIcon: error
              ? () => <ErrorOutlineRoundedIcon style={{color: theme.palette.error.main}} />
              : EventIcon,
          }}
          slotProps={{
            field: {
              sx: fieldStyle,
            },
          }}
          minDate={dayjs('01-01-1900')}
          format="DD.MM.YYYY"
          views={['day', 'month', 'year']}
          value={value ? dayjs(value) : null}
          onChange={value => {
            if (dayjs(value).isValid()) onChange(dayjs(value).toISOString() || '')
          }}
        />
      </LocalizationProvider>
    </LabeledWrapper>
  )
}
